/* eslint-disable indent */
import type { FunctionComponent } from 'react';
import { useContext, useEffect, useMemo, useReducer, useState } from 'react';
import {
  Button,
  ModalBody,
  ModalHeader,
  ModalFooter,
  InlineLoading,
} from '@carbon/react';
import { ChevronRight, UserAvatar, UserFollow } from '@carbon/icons-react';
import { USER_TYPE } from '../../../constants/metadata';
import { ModalContext } from '../../../providers/ModalProvider';
import {
  ADD_NEW_EMPTY_BANNER,
  REMOVE_BANNER_FROM_LIST,
  RESET_USER_FORM,
  UPDATE_USER_FORM,
  UPDATE_USER_FORM_BANNERS,
  UPDATE_USER_FORM_ERROR,
} from '../../../constants/reducers';
import { inviteNewUser, validateEmail } from '../../../utils/adminPanelUtils';
import UserAccessStep from './UserAccessStep';
import PersonalDetailsStep from './PersonalDetailsStep';
import type { UserDataConfig } from '../../../reducers/AdminPanelReducer';
import { useAuth0 } from '@auth0/auth0-react';
import { AppContext } from '../../../providers/AppProvider';
import { CACHE_KEY } from '../../../constants/api';
import type {
  BannerFormData,
  UserForm,
  UserFormError,
  UserFormWithError,
} from '../../../reducers/UserFormReducer';
import {
  userFormInitState,
  userFormReducer,
} from '../../../reducers/UserFormReducer';

interface InviteTeamMemberModalProps {
  readonly dataConfig: UserDataConfig;
}

const InviteTeamMemberModal: FunctionComponent<InviteTeamMemberModalProps> = ({
  dataConfig,
}) => {
  const { clearCacheForKey } = useContext(AppContext);
  const { open, toggleModal, updateModal } = useContext(ModalContext);
  const [step, setStep] = useState(1);
  const [formState, dispatch] = useReducer(userFormReducer, userFormInitState);
  const [isLoading, setIsLoading] = useState(false);
  const { getAccessTokenSilently } = useAuth0();

  const {
    name,
    email,
    authType,
    userRole,
    measureAccess,
    banners,
    userType,
    supplierName,
  } = formState;

  const updateUserForm = <T extends keyof UserForm>(
    key: T,
    value: UserFormWithError[T]
  ) => {
    dispatch({
      type: UPDATE_USER_FORM,
      key,
      value,
    });
  };

  const updateUserFormErrors = (value: UserFormError) => {
    dispatch({ type: UPDATE_USER_FORM_ERROR, value });
  };

  const addNewEmptyBannerItem = () => {
    dispatch({ type: ADD_NEW_EMPTY_BANNER });
  };

  const updateBanners = (id: number, value: Partial<BannerFormData>) => {
    dispatch({ type: UPDATE_USER_FORM_BANNERS, value, id });
  };

  const removeBanner = (id: number) => {
    dispatch({ type: REMOVE_BANNER_FROM_LIST, id });
  };

  useEffect(() => {
    if (open) {
      setStep(1);
    } else {
      dispatch({ type: RESET_USER_FORM });
    }
  }, [open]);

  const submitForm = async () => {
    try {
      setIsLoading(true);
      const token = await getAccessTokenSilently();
      await inviteNewUser(formState, token);
      clearCacheForKey(CACHE_KEY.USER_LIST);
      toggleModal(false);
    } catch (error) {
      const {
        status,
        data: { messages },
      } = error.response;
      if (status === 400) {
        updateUserFormErrors(messages);
        setStep(1);
      } else {
        updateModal({
          type: 'error',
          title: 'Something went wrong',
          body: 'There was a problem inviting a new user. Please try again, or refresh your browser.',
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  const stepData = useMemo(() => {
    switch (step) {
      case 1: {
        const serverErrorValidation =
          Object.values(formState.formErrors).filter(Boolean).length === 0;
        const nextButtonDisabled = !(
          name.trim().length > 0 &&
          validateEmail(email) &&
          userRole &&
          authType &&
          userType &&
          (userType.type !== USER_TYPE.SUPPLIER || supplierName) &&
          serverErrorValidation
        );

        return {
          content: (
            <PersonalDetailsStep
              userData={formState}
              dataConfig={dataConfig}
              updateUserForm={updateUserForm}
            />
          ),
          nextButton: (
            <>
              Next <ChevronRight />
            </>
          ),
          nextButtonDisabled,
          onNextButtonClick: () => setStep((prev) => prev + 1),
        };
      }
      case 2: {
        const bannerData = banners.filter(({ banner }) => banner);
        return {
          content: (
            <UserAccessStep
              userData={formState}
              dataConfig={dataConfig}
              updateUserForm={updateUserForm}
              updateBanners={updateBanners}
              addNewEmptyBannerItem={addNewEmptyBannerItem}
              removeBanner={removeBanner}
            />
          ),
          nextButton: (
            <>
              Invite Team Mate
              {isLoading ? <InlineLoading /> : <UserFollow />}
            </>
          ),
          onNextButtonClick: submitForm,
          nextButtonDisabled:
            !(
              bannerData.length > 0 &&
              bannerData.every(
                ({ banner, userGroups }) => banner && userGroups.length > 0
              ) &&
              measureAccess
            ) || isLoading,
        };
      }
    }
  }, [step, formState, isLoading]);

  const { content, nextButton, nextButtonDisabled, onNextButtonClick } =
    stepData;

  return (
    <>
      <ModalHeader buttonOnClick={() => toggleModal(false)}>
        <div className="InviteTeamMemberModal__header">
          <div className="InviteTeamMemberModal__header-title">
            <UserAvatar size={20} />
            <div>Invite Team Member</div>
          </div>
        </div>
      </ModalHeader>
      {content && (
        <ModalBody>
          <div
            className={`InviteTeamMemberModal__content ${
              step === 2 && !banners.every(({ banner }) => !!banner)
                ? 'InviteTeamMemberModal__content--with-bottom-space'
                : ''
            }`}
          >
            {content}
          </div>
        </ModalBody>
      )}
      <ModalFooter className="InviteTeamMemberModal__buttons">
        {step === 2 && (
          <Button
            size="md"
            kind="tertiary"
            onClick={() => setStep((prev) => prev - 1)}
          >
            Back
          </Button>
        )}
        <Button
          size="md"
          onClick={onNextButtonClick}
          disabled={nextButtonDisabled}
        >
          {nextButton}
        </Button>
      </ModalFooter>
    </>
  );
};

export default InviteTeamMemberModal;
