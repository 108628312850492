import type { FunctionComponent } from 'react';
import { useContext, useState } from 'react';
import { ShareKnowledge } from '@carbon/icons-react';
import { SidePanelContext } from '../../../providers/SidePanelProvider';
import SidePanelSection from '../../SidePanel/SidePanelSection';
import AllUsers from '../../ShareReport/AllUsers';
import SidePanelActions from '../../SidePanel/SidePanelActions';
import type { UserData } from '../../../reducers/AdminPanelReducer';
import Gravatar from '../../Gravatar';

interface TransferAllReportsProps {
  readonly selectedUsers: UserData[];
  readonly transferReports: (
    userIds: string[],
    transferUserId: UserData
  ) => Promise<void>;
}

const TransferAllReports: FunctionComponent<TransferAllReportsProps> = ({
  selectedUsers,
  transferReports,
}) => {
  const [transferUser, setTransferUser] = useState<UserData>(null);
  const [transferring, setTransferring] = useState<boolean>(false);
  const { toggleSidePanelExpand } = useContext(SidePanelContext);

  const handleTransfer = async () => {
    setTransferring(true);
    await transferReports(
      selectedUsers.map(({ id }) => id),
      transferUser
    );
    toggleSidePanelExpand(false);
    setTransferring(false);
  };

  return (
    <>
      <SidePanelSection
        sectionHeader={`Deleted User${selectedUsers.length > 1 ? 's' : ''}`}
      >
        {selectedUsers.map(({ id, name }) => (
          <Gravatar key={id} name={name} />
        ))}
      </SidePanelSection>
      <SidePanelSection
        sectionHeader="You can only transfer report to an Admin User:"
        showDivider={false}
      >
        <AllUsers
          apiUrl="/admin/users?filter=super_admin"
          useRadioButton
          checkedUsers={[transferUser]}
          filterUsers={(user) =>
            !selectedUsers.find(({ id }) => (user as UserData).id === id)
          }
          selectionButtons={{ primary: 'Clear', secondary: 'Select' }}
          handleUserClick={(user) => {
            setTransferUser(user === transferUser ? null : (user as UserData));
          }}
        />
      </SidePanelSection>
      <SidePanelActions
        actions={[
          {
            name: 'Transfer',
            disabled: transferring || !transferUser,
            loading: transferring,
            icon: ShareKnowledge,
            onClick: handleTransfer,
          },
        ]}
      />
    </>
  );
};

export default TransferAllReports;
