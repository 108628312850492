export const FULLSCREEN_CHART_HEIGHT_BUFFER = 400;
export const FULLSCREEN_CHART_WIDTH_BUFFER = 48;
export const IDLE_TIME_LIMIT_IN_MS = 1800000;
export const BROADCAST_CHANNEL_KEY = 'IDLE_SESSION';
export const BANNER_QUERY_PARAM = 'b';
export const USER_GROUP_QUERY_PARAM = 'ug';
export const SHARED_DROPDOWN_KEY = 'shared-dropdowns';
export const ALL_VISUALS_KEY = 'all-visuals';
export const VISUAL_CONTAINER_KEY = 'visual-container';
export const CHART_KEY = 'chart';
export const REPORT_OUTPUT_CHARACTER_LIMIT = 30;
export const DENDRO_TAB_INDEX = 0;
export const CDT_TAB_INDEX = 1;
export const OPTIMISER_TAB_INDEX = 4;
export const HOMEPAGE_REPORT_ID = 'homepage';
export const METRICS_CARDS_PER_PAGE = 5;
export const DEFAULT_COLUMN_SPAN = 16;
