import type { FunctionComponent } from 'react';
import { useContext, useState, useEffect, useMemo } from 'react';
import { ChartCombo } from '@carbon/icons-react';
import { Column, Grid } from '@carbon/react';
import { AppContext } from '../providers/AppProvider';
import Fetch from './Fetch';
import LoadingSkeleton from './Loading/LoadingSkeleton';
import Module from './Module';
import QuickFilter from './QuickFilter';
import { CACHE_KEY } from '../constants/api';
import '../styles/components/modules.scss';
import { PosthogContext } from '../providers/PosthogProvider';
import Maintenance from './Maintenance/Maintenance';
import IconTitle from './IconTitle';

const Modules: FunctionComponent = () => {
  const { bannerId, groupId, reportModules, updateReportModules } =
    useContext(AppContext);
  const { featureFlags } = useContext(PosthogContext);

  const [modules, setModules] = useState([]);

  const selectAllIndex = 0;
  const loadingComponent = (
    <div className="Modules__loading">
      <LoadingSkeleton type="modules" />
    </div>
  );

  useEffect(() => {
    setModules(
      reportModules.reduce((acc, item) => [...acc, ...item.reports], [])
    );
  }, [reportModules]);

  const filterOptions = useMemo(
    () => ['All modules', ...reportModules.map((module) => module.section)],
    [reportModules]
  );

  const getSection = (name: string) => {
    if (reportModules?.length) {
      const result = reportModules.find((each) =>
        each.reports.find((r) => r.name === name)
      );
      return result.section;
    }
  };

  const filterModules = (selectedTabs: number[]) => {
    if (selectedTabs[0] === selectAllIndex) {
      setModules(
        reportModules.reduce((acc, item) => [...acc, ...item.reports], [])
      );
    } else {
      const mods = selectedTabs.map((index) => filterOptions[index]);
      const res = reportModules.reduce(
        (acc, item) =>
          mods.includes(item.section) ? [...acc, ...item.reports] : acc,
        []
      );
      setModules(res);
    }
  };

  const modulesFlag = featureFlags.find(
    ({ name, payload }) =>
      name === 'modules-maintenance' &&
      payload &&
      (payload as { banner_ids?: number[] }).banner_ids?.includes(bannerId)
  );

  if (modulesFlag) {
    const { title, text, duration } = modulesFlag.payload as {
      title: string;
      text: string;
      duration?: {
        endTime: string;
      };
      banner_ids?: number[];
    };
    return (
      <Maintenance
        header={title}
        subheader={text}
        endTime={duration?.endTime}
      />
    );
  } else {
    return (
      <div data-testid="modules-wrapper">
        <div>
          <IconTitle
            icon={<ChartCombo size={20} />}
            title="Insight Modules"
            kind="primary"
          />

          <div className="Modules__page-description">
            Address a specific business process or set of question to enable the
            analysis by requesting a report
          </div>
        </div>

        <Fetch
          apiUrl={`/configs/${bannerId}/user-groups/${groupId}/modules`}
          cacheKey={CACHE_KEY.MODULES}
          initialData={[]}
          loadingComponent={loadingComponent}
          onReceiveData={(data) => {
            if (data) {
              updateReportModules(data);
            }
          }}
        >
          <QuickFilter
            options={filterOptions}
            selectAllIndex={selectAllIndex}
            size="md"
            hasDividers
            filterData={filterModules}
          />
          <Grid>
            {modules.map((module) => {
              return (
                <Column lg={4} md={4} sm={4} key={module.name}>
                  <div className="Module__container">
                    <Module
                      module={module}
                      section={modules && getSection(module.name)}
                    />
                  </div>
                </Column>
              );
            })}
          </Grid>
        </Fetch>
      </div>
    );
  }
};

export default Modules;
