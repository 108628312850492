/* eslint-disable indent */
import type { FunctionComponent } from 'react';
import { useContext, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Button, RadioButtonGroup, RadioButton } from '@carbon/react';
import { utils, writeFile } from 'xlsx-js-style';
import { Download } from '@carbon/icons-react';
import { AppContext } from '../../providers/AppProvider';
import type { ReportConfig, VisualData } from '../../reducers/ReportReducer';
import {
  tableExportAllTableOptionsClick,
  tableExportClick,
  tableServerExportAllTableOptionsClick,
} from '../../constants/posthog';
import {
  getAllDataTableOptions,
  mapExportableData,
  clickDownload,
  findVisualById,
  getExportedGridId,
} from '../../utils/reportUtils';
import usePosthog from '../../utils/posthog';
import { getRowsToExport } from '../../utils/DataGridUtils';
import {
  EXCEL_SHEET_NAME_LIMIT,
  initialDownloadOptions,
} from '../../constants/metadata';
import '../../styles/components/workspace.scss';
import type { GlobalSelection } from '../Report/ReportContent';

interface DownloadOptionsProps {
  id?: number;
  rowData: object[];
  headerData: Header[];
  visualData?: VisualData;
  visualHeader?: VisualHeader;
  reportConfig?: ReportConfig;
  isSkuSelected?: boolean;
  visibleHeaders: Header[];
  reportTemplateId?: string;
  exportedFileName?: string;
  globalSelections?: GlobalSelection[];
  handleGridServerExport?: (name: string) => Promise<void>;
}

const DownloadOptions: FunctionComponent<DownloadOptionsProps> = ({
  id,
  rowData,
  headerData,
  visualData,
  visualHeader,
  reportConfig,
  isSkuSelected,
  visibleHeaders,
  reportTemplateId,
  exportedFileName,
  globalSelections,
  handleGridServerExport,
}) => {
  const { groupId, user } = useContext(AppContext);
  const { pathname } = useLocation();
  const origin = pathname.split('/')[1];
  const isHomepage = pathname.includes('home');
  const posthogEvent = usePosthog();
  const fileName =
    isHomepage && visualHeader?.title
      ? visualHeader.title
      : exportedFileName.replaceAll(/[:\\/?*[\]]/gm, '_');
  const enabledDownloadOptions =
    visualData?.dropdownConfig?.length > 0
      ? initialDownloadOptions
      : initialDownloadOptions.filter((option) => !option.exportAll);

  const [selectedDownloadOption, setSelectedDownloadOption] = useState<string>(
    enabledDownloadOptions[0].label
  );
  const [selectedDownloadFileType, setSelectedDownloadFileType] = useState<
    'csv' | 'xlsx'
  >(enabledDownloadOptions[0].fileTypes[0]);

  const selectedOption = enabledDownloadOptions.find(
    (option) => option.label === selectedDownloadOption
  );

  const exportData = (type: 'csv' | 'xlsx') => {
    const workbook = utils.book_new();
    const headersToExport =
      selectedOption.headers === 'all' ? headerData : visibleHeaders;
    const rowsToExport = getRowsToExport(headersToExport, rowData);
    const exportableData = mapExportableData(rowsToExport, headersToExport);
    const worksheet = utils.aoa_to_sheet(exportableData);
    worksheet['!cols'] = headerData.map(() => ({
      wch: 20,
    }));
    utils.book_append_sheet(workbook, worksheet);
    writeFile(workbook, `${fileName}.${type}`);
    posthogEvent(tableExportClick, {
      type,
      selectedDownloadOption,
      reportType: reportConfig.url_route,
      origin,
    });
  };

  const exportAllReportData = () => {
    const workbook = utils.book_new();
    const worksheets = getAllDataTableOptions(visualData);
    worksheets.forEach(({ headers, rows, label }) => {
      const truncatedLabel =
        label?.length > EXCEL_SHEET_NAME_LIMIT
          ? label.substring(0, EXCEL_SHEET_NAME_LIMIT - 1) + '…'
          : label;
      const rowsToExport = getRowsToExport(headers, rows);
      const exportableData = mapExportableData(rowsToExport, headers);
      const worksheet = utils.aoa_to_sheet(exportableData);
      worksheet['!cols'] = headers.map(() => ({
        wch: 30,
      }));
      utils.book_append_sheet(workbook, worksheet, truncatedLabel);
    });
    writeFile(workbook, `${fileName}.xlsx`);
    posthogEvent(tableExportAllTableOptionsClick, {
      reportType: reportConfig.url_route,
      origin,
    });
  };

  const serverExportAllReportData = () => {
    const visualId = isSkuSelected ? `${id}_sku-grid` : id;
    const exportedFileId = getExportedGridId(visualId, globalSelections);
    const exportData = reportConfig.export_data?.[exportedFileId];
    if (exportData?.url) {
      clickDownload(exportData.url, fileName, 'xlsx', user.id, groupId, origin);
    } else {
      const reportVisual = findVisualById(
        reportConfig.configuration.visuals[reportTemplateId],
        id
      )?.apiUrl;
      if (reportVisual && handleGridServerExport) {
        handleGridServerExport(fileName);
      }
    }
    posthogEvent(tableServerExportAllTableOptionsClick, {
      reportType: reportConfig.url_route,
    });
  };

  const handleDataGridDownload = () => {
    if (selectedOption.exportAll) {
      if (reportConfig.report_version === 'v2') {
        serverExportAllReportData();
      } else {
        exportAllReportData();
      }
    } else {
      exportData(selectedDownloadFileType);
    }
  };

  return (
    <div className="DataGrid__download-wrapper">
      <RadioButtonGroup
        legendText="Data Export"
        hideLabel
        name="download-radio-options-group"
        orientation="vertical"
        valueSelected={selectedDownloadOption}
        onChange={(value) => {
          const { fileTypes } = initialDownloadOptions.find(
            ({ label }) => label == value
          );
          setSelectedDownloadOption(value);
          setSelectedDownloadFileType((activeFileType) =>
            fileTypes.includes(activeFileType) ? activeFileType : fileTypes[0]
          );
        }}
      >
        {enabledDownloadOptions.map(({ id, label }) => (
          <RadioButton
            data-testid={`download-radio-option-${id}`}
            key={id}
            labelText={label}
            value={label}
          />
        ))}
      </RadioButtonGroup>
      <div className="sidebar-divider" />
      <RadioButtonGroup
        legendText="File Type"
        hideLabel
        name="download-file-type-radio-group"
        orientation="vertical"
        valueSelected={selectedDownloadFileType}
        onChange={setSelectedDownloadFileType}
      >
        {enabledDownloadOptions
          .find((option) => option.label === selectedDownloadOption)
          ?.fileTypes.map((fileType) => (
            <RadioButton
              key={fileType}
              labelText={`.${fileType}`}
              value={fileType}
            />
          ))}
      </RadioButtonGroup>
      <div className="DataGrid__download-btn-wrapper">
        <Button
          renderIcon={Download}
          onClick={handleDataGridDownload}
          size="sm"
          className="has-icon"
          data-testid="download-excel-btn"
        >
          Export
        </Button>
      </div>
    </div>
  );
};
export default DownloadOptions;
