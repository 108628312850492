import type { FunctionComponent } from 'react';
import { truncateParameterName } from '../Report/TooltipFormatters';

interface LegendItemProps {
  text: string;
  colour: string;
}

const LegendItem: FunctionComponent<LegendItemProps> = ({ text, colour }) => {
  const { text: name, tooltip } = truncateParameterName(text);
  return (
    <div className="legend-item">
      <div className="legend-dot" style={{ backgroundColor: colour }} />
      <div className="legend-text" title={tooltip}>
        {name}
      </div>
    </div>
  );
};

export default LegendItem;
