import {
  ChartLineSmooth,
  Growth,
  Map,
  Percentage,
  TimePlot,
  UserProfile,
} from '@carbon/icons-react';
import { capitalizeFirstLetter } from './reportUtils';

export const getIcon = (iconKey: string | JSX.Element): JSX.Element => {
  const iconMap = {
    Arrow: <Growth />,
    LineGraph: <ChartLineSmooth />,
    Pin: <Map />,
    Percentage: <Percentage />,
    TimePlot: <TimePlot />,
    UserProfile: <UserProfile />,
  };

  return typeof iconKey === 'string' &&
    capitalizeFirstLetter(iconKey) in iconMap
    ? iconMap[capitalizeFirstLetter(iconKey)]
    : (iconKey as JSX.Element);
};
