/* eslint-disable indent */
import type { FunctionComponent } from 'react';
import { useContext } from 'react';
import ConditionalWrapper from '../../ConditionalWrapper';
import { sharedDropdownWrapper } from '../../../utils/chartUtils';
import type { FileData } from '../../../reducers/ReportReducer';
import { updateDropdownKey } from '../../../utils';
import { ReportContext } from '../../../providers/ReportProvider';
import type { UpdateDropdownSelectionParameters } from './VisualDropdown';
import VisualDropdown from './VisualDropdown';
import { visualDropdownOptionSelected } from '../../../constants/posthog';
import usePosthog from '../../../utils/posthog';
import type { GlobalSelection } from '../ReportContent';

export interface ExtendedDropdownConfig {
  readonly dropdown: DropdownConfig;
  readonly dropdownConfig: DropdownConfig[];
  readonly dropdownSelection?: string;
}

export interface DataVisualDropdownsProps {
  readonly tab: string | number;
  readonly id: string | number;
  readonly controlsContainerRef: HTMLDivElement;
  readonly useDropdownsWrapper: boolean;
  readonly isReportSplit: boolean;
  readonly dropdowns: ExtendedDropdownConfig[];
  readonly currentVisualData: FileData;
  readonly visualDropdownSelection: string;
  readonly globalSelections: GlobalSelection[];
  readonly hideLabel?: boolean;
}

const DataVisualDropdowns: FunctionComponent<DataVisualDropdownsProps> = ({
  tab,
  id,
  controlsContainerRef,
  useDropdownsWrapper,
  isReportSplit,
  dropdowns,
  currentVisualData,
  visualDropdownSelection,
  globalSelections,
  hideLabel,
}) => {
  const { visualsData, updateSingleSelection, updateFileDropdownSelection } =
    useContext(ReportContext);
  const posthogEvent = usePosthog();

  const updateDropdownSelection = ({
    selectedItem,
    dropdownId,
    dropdownConfig,
    fetch,
  }: UpdateDropdownSelectionParameters) => {
    if (isReportSplit && !fetch) {
      const newKey = updateDropdownKey(
        selectedItem,
        dropdownId,
        dropdownConfig,
        currentVisualData.fileDropdownSelections,
        globalSelections
      );
      updateFileDropdownSelection(id, newKey);
    } else {
      const newKey = updateDropdownKey(
        selectedItem,
        dropdownId,
        dropdownConfig,
        visualDropdownSelection,
        globalSelections
      );
      updateSingleSelection(tab, id, newKey);
    }
    posthogEvent(visualDropdownOptionSelected, {
      visualTitle: visualsData[id]?.visualHeader?.title,
      label: dropdownConfig.find((item) => item.id === dropdownId).label,
      option: selectedItem,
    });
  };

  const renderDropdowns = (
    conditionCallback: (dropdownData: { dropdown: DropdownConfig }) => boolean
  ) =>
    dropdowns
      .filter(conditionCallback)
      .map(({ dropdown, dropdownConfig, dropdownSelection }) => (
        <VisualDropdown
          dropdown={dropdown}
          key={dropdown.id}
          dropdownConfig={dropdownConfig}
          dropdownSelection={dropdownSelection}
          hideLabel={useDropdownsWrapper || hideLabel}
          updateDropdownSelection={updateDropdownSelection}
          size="sm"
        />
      ));

  if (dropdowns.length === 0) {
    return null;
  }

  return (
    <ConditionalWrapper
      wrapper={(children) =>
        sharedDropdownWrapper(
          children,
          controlsContainerRef,
          'DataVisual__dropdowns-wrapper'
        )
      }
      condition={useDropdownsWrapper && !!controlsContainerRef}
    >
      <div className="DataVisualDropdowns__wrapper">
        <div className="visual-dropdowns">
          {renderDropdowns(({ dropdown }) => !dropdown.switcher)}
        </div>
        <div className="visual-dropdowns visual-dropdowns--switchers">
          {renderDropdowns(({ dropdown }) => dropdown.switcher)}
        </div>
      </div>
    </ConditionalWrapper>
  );
};

export default DataVisualDropdowns;
