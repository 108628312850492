import * as primitives from '../constants/colour/primitives';

export const getPresentedColour = (
  colour: string | undefined
): string | undefined => {
  if (!colour) {
    return undefined;
  }

  // Hex
  if (colour.startsWith('#')) {
    return colour;
  }

  // Primitive
  if (colour.startsWith('%')) {
    const constituentProperties = colour.slice(1).split('-');

    if (constituentProperties.length !== 2) {
      // eslint-disable-next-line no-console
      console.error('Unsupported colour primitive syntax');

      return undefined;
    }

    const [hue, shade] = constituentProperties;
    const colourValue = primitives[hue]?.[shade];

    if (!colourValue) {
      // eslint-disable-next-line no-console
      console.error('Unsupported colour primitive value');
    }

    return colourValue;
  }

  // Semantic
  if (colour.startsWith('$')) {
    // TODO: Future semantic colour support
    // eslint-disable-next-line no-console
    console.error('Unsupported semantic colour system');
  }

  return undefined;
};
