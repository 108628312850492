import type { FunctionComponent } from 'react';
import { Tooltip } from '@carbon/react';
import { Add } from '@carbon/icons-react';

interface AddParameterProps {
  label: string;
  tooltip?: string;
  addParameter: () => void;
  children?: JSX.Element | JSX.Element[];
}

const AddParameterButton: FunctionComponent<AddParameterProps> = ({
  label,
  tooltip,
  addParameter,
  children,
}) => {
  return (
    <div
      className="add-action hoverable clickable text-secondary"
      onClick={addParameter}
    >
      <Tooltip align="right-top" label={tooltip || label}>
        <div className="add-action-button">
          <Add /> <span>{label}</span>
        </div>
      </Tooltip>
      <div className="add-action-children">{children}</div>
    </div>
  );
};

export default AddParameterButton;
