export enum WebsocketAction {
  Create = 'CREATE',
  Delete = 'DELETE',
  Refresh = 'REFRESH',
  Update = 'UPDATE',
  Notify = 'NOTIFY',
}

/** @deprecated use enum */
export const DELETE = WebsocketAction.Delete;
/** @deprecated use enum */
export const REFRESH = WebsocketAction.Refresh;
/** @deprecated use enum */
export const UPDATE = WebsocketAction.Update;
/** @deprecated use enum */
export const NOTIFY = WebsocketAction.Notify;
