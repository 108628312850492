/* eslint-disable indent */
import type { FunctionComponent } from 'react';
import { useContext } from 'react';
import {
  FileUploaderDropContainer,
  FileUploaderItem,
  TableHeader,
  TableRow,
  Table,
  TableBody,
  TableCell,
  TableHead,
} from '@carbon/react';
import { Upload } from '@carbon/icons-react';
import xlsx from 'xlsx-js-style';
import { CustomGroupsContext } from '../../../../providers/CustomGroupsProvider';
import { AppContext } from '../../../../providers/AppProvider';
import { useAuth0 } from '@auth0/auth0-react';
import {
  custGroupsDeleteUploadFile,
  custGroupsUploadFile,
  custGroupsUploadValidateFailure,
  custGroupsUploadValidateSuccess,
} from '../../../../constants/posthog';
import usePosthog from '../../../../utils/posthog';
import type { UploadData } from '../../../../reducers/CustomGroupsReducer';
import {
  alphanumericPattern,
  semicolonPattern,
} from '../../../../constants/metadata';
import { validateUploadData } from '../../../../utils/reportUtils';
import type { EditCustomGroup } from './CustomGroupsUpload';

interface FileDetails {
  lastModified: number;
  lastModifiedDate: Date;
  name: string;
  size: number;
  type: string;
  webkitRelativePath: string;
}

interface CustomGroupsUploadValidatorProps {
  editCustomGroup: EditCustomGroup;
}

const CustomGroupsUploadValidator: FunctionComponent<
  CustomGroupsUploadValidatorProps
> = ({ editCustomGroup }) => {
  const { bannerId, groupId } = useContext(AppContext);
  const { selectedDimGroup, updateUploadData, fileSpecs, updateFileSpecs } =
    useContext(CustomGroupsContext);
  const { getAccessTokenSilently } = useAuth0();

  const posthogEvent = usePosthog();

  const validateUploadedFile = async (
    file: FileDetails,
    fileData: UploadData[]
  ) => {
    const entityIds = fileData.map((item) => ({
      entity_no: item.entity_no.toString(),
    }));
    const { type, name, size } = file;
    updateFileSpecs([
      {
        ...{ name, size, type },
        status: 'uploading',
        invalid: false,
        iconDescription: 'Uploading',
      },
    ]);
    const token = await getAccessTokenSilently();
    try {
      const response = await validateUploadData(
        selectedDimGroup,
        entityIds,
        token,
        bannerId,
        groupId
      );
      posthogEvent(custGroupsUploadValidateSuccess);
      const updatedData = response.data.entities.map(
        ({ id, ...item }, index) => {
          const row = fileData[index];
          return {
            id: row.id,
            ...item,
            customGroup: editCustomGroup.isEditing
              ? editCustomGroup.name
              : row.customGroup,
            ...(id ? { entity_id: id.toString() } : {}),
          };
        }
      );

      updateUploadData(updatedData);
      if (file.size >= 6291456) {
        updateFileSpecs([
          {
            ...{ name, size, type },
            status: 'edit',
            invalid: true,
            iconDescription: 'Upload complete',
            errorSubject: 'File size exceeds the 6MB limit.',
          },
        ]);
      } else {
        updateFileSpecs([
          {
            ...{ name, size, type },
            status: 'edit',
            invalid: false,
            iconDescription: 'Upload complete',
          },
        ]);
      }
    } catch {
      posthogEvent(custGroupsUploadValidateFailure);
      updateFileSpecs([
        {
          ...{ name, size, type },
          status: 'edit',
          invalid: true,
          iconDescription: 'Error processing file, please try again',
          errorSubject: 'Error processing file, please try again',
        },
      ]);
    }
  };

  const convertFile = (file: FileDetails, successCallback) => {
    const { type, name, size } = file;
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target.result;
        const workbook = xlsx.read(data, {
          type: 'binary',
          cellStyles: true,
        });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const jsonResult: Array<string[]> = xlsx.utils.sheet_to_json(
          worksheet,
          { header: 1 }
        );
        const columnHeaders = jsonResult[0].map((h) => h.toLowerCase());
        const idIndex = columnHeaders.indexOf('id');
        let groupsIndex = columnHeaders.indexOf('group');

        if (idIndex >= 0) {
          posthogEvent(custGroupsUploadFile, {
            name,
            size,
            type,
          });
          if (groupsIndex < 0) {
            columnHeaders.push('Group');
            groupsIndex = columnHeaders.indexOf('Group');
          }
          const reformattedRes = jsonResult
            .slice(1)
            .filter((row) => row[idIndex] ?? null)
            .map((row, i) => ({
              id: i,
              entity_no: String(row[idIndex])
                .replace(alphanumericPattern, '')
                .trim(),
              customGroup: (row[groupsIndex] || 'My Custom Group')
                .toString()
                .replaceAll(semicolonPattern, ''),
            }));
          const idGroupCombinations = reformattedRes.map((row) => {
            return `${row.entity_no}-${row.customGroup}`.replace(/\s/g, '');
          });

          const distinctCombinations = new Set(idGroupCombinations);
          if (idGroupCombinations.length > distinctCombinations.size) {
            updateFileSpecs([
              {
                ...{ name, size, type },
                status: 'edit',
                invalid: true,
                iconDescription: 'Duplicates detected',
                errorSubject:
                  'This file includes duplicates. Please make sure the file you upload does not have duplicate ID and group combinations.',
              },
            ]);
          } else {
            successCallback(reformattedRes);
          }
        } else {
          updateFileSpecs([
            {
              ...{ name, size, type },
              status: 'edit',
              invalid: true,
              iconDescription: 'Error processing file, please try again',
              errorSubject:
                'Please include an ID column header in your file. This will help us match the product list you upload to the product IDs already available on the platform.',
            },
          ]);
        }
      };
      reader.onerror = () => {
        updateFileSpecs([
          {
            ...{ name, size, type },
            status: 'edit',
            invalid: true,
            iconDescription: 'Error processing file, please try again',
            errorSubject: 'Error processing file, please try again',
          },
        ]);
      };
      const blobFile: Blob = file as unknown as Blob;
      reader.readAsArrayBuffer(blobFile);
    }
  };

  const onAddFiles = (e: MouseEvent, { addedFiles }) => {
    e.stopPropagation();
    if (addedFiles.length > 0) {
      const file = addedFiles[0];
      const { type, name, size } = file;
      if (
        file.type ===
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
        file.type === 'text/csv'
      ) {
        convertFile(file, (result) => {
          if (result.length > 0) {
            validateUploadedFile(file, result);
          }
        });
      } else {
        updateFileSpecs([
          {
            ...{ name, size, type },
            status: 'edit',
            invalid: true,
            iconDescription:
              'Unsupported file type. Please upload an XLSX or CSV file.',
            errorSubject:
              'Unsupported file type. Please upload an XLSX or CSV file.',
          },
        ]);
      }
    }
  };

  const dragDropMessage = (
    <div className="CustomGroupsUploadValidator__drag-and-drop-wrapper">
      <Upload
        size={20}
        className="CustomGroupsUploadValidator__drag-and-drop-button"
      />
      <div>
        <span className="CustomGroupsUploadValidator__drag-and-drop-info">
          Drag and drop file here or{' '}
          <span className="CustomGroupsUploadValidator__drag-and-drop-info--highlighted">
            click to upload
          </span>
        </span>
      </div>
    </div>
  );

  const getDescriptionList = (): JSX.Element => {
    return editCustomGroup.isEditing ? (
      <ul style={{ listStyle: 'disc', marginLeft: '1rem' }}>
        <li>
          {`If you have ${selectedDimGroup.toLowerCase()}s, these will be combined into this group.`}
        </li>
        <li>
          If your ID has a leading zero, please start all records in the column
          with a single quote &apos;.
        </li>
      </ul>
    ) : (
      <ul style={{ listStyle: 'disc', marginLeft: '1rem' }}>
        <li>{`If you have ${selectedDimGroup.toLowerCase()}s groups, ensure they are organised in a dedicated column within your file. You can make modifications to these groups in steps 2 and 3.`}</li>
        <li>{`If you don't have any ${selectedDimGroup.toLowerCase()} groups, a default product group will be created for you, which can be edited in steps 2 and 3.`}</li>
        <li>
          If your ID has a leading zero, please start all records in the column
          with a single quote &apos;.
        </li>
      </ul>
    );
  };

  return (
    <div className="CustomGroupsUploadValidator__upload-container">
      <div className="CustomGroupsUploadValidator__upload-info">
        <p className="body-02">
          Supported file types are
          <span className="body-emphasis-02"> csv or xlsx.</span>
        </p>
        <p className="body-02">
          File Size Limit:
          <span className="body-emphasis-02"> 6MB</span>
        </p>
        <div className="body-02" style={{ lineHeight: '24px' }}>
          Please ensure the file contains a
          <span className="body-emphasis-02">{` ${selectedDimGroup} ID column. `}</span>
          <span>{` The ${selectedDimGroup} ID`}</span>
          <span className="body-emphasis-02">
            {` should match exactly to the  ${selectedDimGroup.toLowerCase()} ID already available in the
              platform.`}
          </span>
          <div className="body-02">
            The uploaded table should include the example columns below. The
            group column is optional.
          </div>
          <Table
            className="CustomGroupsUploadValidator__upload-preview-table "
            aria-label="Sample CSV structure"
            data-testid="upload-preview-table"
          >
            <TableHead>
              <TableRow>
                <TableHeader>ID</TableHeader>
                <TableHeader>Group</TableHeader>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>1234567</TableCell>
                <TableCell>My custom group name</TableCell>
              </TableRow>
            </TableBody>
            <TableBody>
              <TableRow>
                <TableCell>3456789</TableCell>
                <TableCell>My custom group name</TableCell>
              </TableRow>
            </TableBody>
          </Table>
          {getDescriptionList()}
        </div>
      </div>
      <FileUploaderDropContainer
        labelText={dragDropMessage}
        multiple={false}
        accept={['.csv', '.xlsx']}
        onAddFiles={onAddFiles}
        data-testid="upload-icon"
      />
      {fileSpecs.map(
        ({ name, size, status, iconDescription, invalid, ...rest }) => (
          <FileUploaderItem
            key="file-uploader-item"
            name={name}
            size={size}
            status={status}
            iconDescription={iconDescription}
            invalid={invalid}
            onDelete={() => {
              updateFileSpecs([]);
              updateUploadData([]);
              posthogEvent(custGroupsDeleteUploadFile, {
                name,
                size,
              });
            }}
            {...rest}
          />
        )
      )}
    </div>
  );
};
export default CustomGroupsUploadValidator;
