import {
  RENDER_COMPONENT,
  TOGGLE_MODAL,
  UPDATE_MODAL_OPTIONS,
} from '../constants/reducers';

export interface ModalState {
  open: boolean;
  modal: ModalOptions;
  component: JSX.Element | null;
  containerClassName: string;
}

export const initialModalState: ModalState = {
  open: false,
  modal: {},
  component: null,
  containerClassName: '',
};

interface ToggleModal {
  type: typeof TOGGLE_MODAL;
  open: boolean;
}

interface UpdateOptions {
  type: typeof UPDATE_MODAL_OPTIONS;
  options: ModalOptions;
}

interface RenderComponent {
  type: typeof RENDER_COMPONENT;
  component: JSX.Element;
  containerClassName?: string;
}

export type ModalAction = ToggleModal | UpdateOptions | RenderComponent;

const modalReducer = (state: ModalState, action: ModalAction): ModalState => {
  switch (action.type) {
    case TOGGLE_MODAL: {
      return {
        ...state,
        open: action.open,
      };
    }
    case UPDATE_MODAL_OPTIONS:
      return {
        ...state,
        open: true,
        modal: action.options,
        component: null,
      };

    case RENDER_COMPONENT:
      return {
        ...state,
        component: action.component,
        containerClassName: action.containerClassName,
        open: true,
      };
  }
};

export default modalReducer;
