import type { FunctionComponent } from 'react';
import { NumberInput, Checkbox, Column, Grid } from '@carbon/react';
import { getTrimmedValue } from './utils/reportBuilderUtils';

interface TransactionSpendProps {
  spendBands: SpendBands;
  setSpendBands: (spendBands: SpendBands) => void;
  includeUpperBand?: boolean;
  setIncludeUpperBand: (includeUpperBand: boolean) => void;
}
const TransactionSpend: FunctionComponent<TransactionSpendProps> = ({
  spendBands,
  setSpendBands,
  includeUpperBand,
  setIncludeUpperBand,
}) => {
  const handleBandChange = (type: string, value: number) => {
    setSpendBands({
      ...spendBands,
      [type]: value,
    });
  };
  const { min, max } = spendBands;
  return (
    <>
      <div className="transaction-spend-group">
        <Grid>
          <Column lg={8} md={8} sm={4}>
            <NumberInput
              className="spend-band-number-input"
              data-testid="lower-band-input"
              id="lower-band"
              invalidText="Lower band must be lower than upper band."
              invalid={min > max && min !== 0 && includeUpperBand}
              label="Lower band"
              max={1000000000}
              min={0}
              onChange={(_, { value }) =>
                handleBandChange('min', Number(value) || 0)
              }
              value={getTrimmedValue(min)}
            />
          </Column>
          <Column lg={8} md={8} sm={4}>
            <NumberInput
              className="spend-band-number-input"
              data-testid="upper-band-input"
              allowEmpty
              id="upper-band"
              label="Upper band"
              max={1000000000}
              min={0}
              onChange={(_, { value }) =>
                handleBandChange('max', Number(value) || 0)
              }
              value={getTrimmedValue(max)}
              disabled={!includeUpperBand}
            />
          </Column>
        </Grid>
      </div>
      <Checkbox
        className="transaction-spend-checkbox"
        labelText="Include upper band"
        data-testid="upper-band-checkbox"
        id="upper-band-checkbox"
        onChange={() => setIncludeUpperBand(!includeUpperBand)}
        checked={includeUpperBand}
      />
    </>
  );
};

export default TransactionSpend;
