import apiRequest from '../api';
import jwt_decode from 'jwt-decode';
import { BANNER_AND_USER_GROUP } from '../constants/localStorageKeys';
import {
  BANNER_QUERY_PARAM,
  USER_GROUP_QUERY_PARAM,
} from '../constants/values';
import { formatUTCDate } from './DateUtils';
import type { IdToken, LocalStorageCache } from '@auth0/auth0-react';
import type { WrappedCacheEntry } from '@auth0/auth0-spa-js/dist/typings/cache';
import type { TermsTab } from '../components/TermsAndConditionsModal';
import { USER_TYPE } from '../constants/metadata';
import type { ResponsePayload } from '../components/Fetch';
import type {
  AcceptanceStatusResponse,
  TokenResponse,
  UserTermsTabsResponse,
} from '../types/apiTypes';

export const getUser = (
  decodedAccessToken: Auth0AccessToken,
  idToken: IdToken,
  cache: LocalStorageCache,
  cacheKey: string
): User => {
  const {
    sub,
    user_role,
    user_type,
    job_title,
    department,
    supplier_name,
    show_preview,
    show_extension_measure,
  } = decodedAccessToken;
  return {
    id: sub,
    name: idToken.name,
    lastLogin: formatUTCDate(
      new Date(decodedAccessToken.iat * 1000).toISOString()
    ),
    tokenExpiry: cache.get<WrappedCacheEntry>(cacheKey).expiresAt,
    showPreview: show_preview,
    show_extension_measure,
    user_type,
    user_role,
    job_title,
    department,
    supplier_name,
  };
};

export const getBannersAndUserGroups = async (accessToken: string) => {
  const decodedAccessToken: Auth0AccessToken = jwt_decode(accessToken);
  const response = await apiRequest<ResponsePayload<Banner[]>>(
    `/users/${encodeURIComponent(decodedAccessToken.sub)}/banners`,
    'GET',
    accessToken
  );

  return response.data;
};

export const getInitialBannerSelection = (
  bannersAndUserGroups: Banner[]
): { bannerId: number; groupId: number; error?: boolean } => {
  const defaultSelection = {
    bannerId: bannersAndUserGroups[0].id,
    groupId: bannersAndUserGroups[0].user_groups[0].id,
  };
  const sessionBannerAndUserGroup = JSON.parse(
    sessionStorage.getItem(BANNER_AND_USER_GROUP)
  );

  const params = new URLSearchParams(window.location.search);
  const banner = Number(params.get(BANNER_QUERY_PARAM));
  const userGroup = Number(params.get(USER_GROUP_QUERY_PARAM));

  if (banner && userGroup) {
    const bannerAndUserGroup = {
      bannerId: banner,
      groupId: userGroup,
    };
    if (
      !bannersAndUserGroups
        .find((b) => b.id === banner)
        ?.user_groups.find((ug) => ug.id === userGroup)
    ) {
      return { ...defaultSelection, error: true };
    }

    sessionStorage.setItem(
      BANNER_AND_USER_GROUP,
      JSON.stringify(bannerAndUserGroup)
    );
    return bannerAndUserGroup;
  } else if (sessionBannerAndUserGroup) {
    return {
      bannerId: Number(sessionBannerAndUserGroup.bannerId),
      groupId: Number(sessionBannerAndUserGroup.groupId),
    };
  }

  return defaultSelection;
};

export const getAcceptanceStatus = async (accessToken: string) => {
  const decodedAccessToken: Auth0AccessToken = jwt_decode(accessToken);
  const response = await apiRequest<ResponsePayload<AcceptanceStatusResponse>>(
    `/users/${encodeURIComponent(decodedAccessToken.sub)}/terms-and-conditions`,
    'GET',
    accessToken
  );

  return response.data;
};

export const getProductFruitsHMAC = async (accessToken: string) => {
  const response = await apiRequest<ResponsePayload<TokenResponse>>(
    '/users/token',
    'GET',
    accessToken
  );

  return response.data;
};

export const getUserOptionalTerms = async (
  accessToken: string,
  userType: USER_TYPE
): Promise<TermsTab[]> => {
  const isSupplier = userType === USER_TYPE.SUPPLIER;

  const url = `/configs/client/terms/${
    isSupplier ? 'supplier-conditions' : 'customer-conditions'
  }`;

  const { data } = await apiRequest<ResponsePayload<UserTermsTabsResponse>>(
    url,
    'GET',
    accessToken
  );

  return typeof data === 'string' ? [] : data.tabs;
};
