/* eslint-disable indent */
import type { FunctionComponent } from 'react';
import { useState } from 'react';
import {
  Accordion,
  AccordionItem,
  InlineNotification,
  InlineLoading,
  OverflowMenu,
  OverflowMenuItem,
} from '@carbon/react';
import ParameterCollection from './ParameterCollection';
import {
  getExtendedName,
  transformParams,
} from '../../components/ReportBuilder/utils/reportBuilderUtils';
import SettingsSummary from './SettingsSummary';
import { useLocation } from 'react-router-dom';
import { reportBuilderPinClick } from '../../constants/posthog';
import usePosthog from '../../utils/posthog';
import type { HierarchyOverlap } from './ReportBuilder';

interface ParameterSummaryProps {
  parameters: ReportParameters;
  overlap?: HierarchyOverlap;
  showSubmit?: boolean;
  isEditable?: boolean;
  analyticEngineParams?: AnalyticEngineParams;
  isTitleHidden?: boolean;
  favouriteGroups?: FavouriteGroup[];
  editParameter?: (
    parameterKey: string,
    parameter: ReportParameter,
    index: number
  ) => void;
  removeParameter?: (parameterKey: string, parameter: ReportParameter) => void;
  pinGroup?: (
    hierarchyName: string,
    parameters: ReportParameter[],
    isGroupWithEqualNameExist?: boolean
  ) => Promise<void>;
  deleteFavourite?: (
    type: 'group' | 'item',
    hierarchyName: string,
    name: string,
    parentName?: string
  ) => Promise<void>;
}

const ParameterSummary: FunctionComponent<ParameterSummaryProps> = ({
  parameters,
  overlap,
  editParameter,
  removeParameter,
  showSubmit,
  isEditable,
  analyticEngineParams,
  isTitleHidden,
  pinGroup,
  deleteFavourite,
  favouriteGroups,
}) => {
  const [activeGroup, setActiveGroup] = useState<string[]>([]);
  const { pathname } = useLocation();
  const viewReportId = pathname.includes('view-report');
  const newParams = transformParams(parameters);
  const isNothingSelected: boolean = Object.entries(newParams).every(
    ([, value]) => Object.entries(value).every(([, value]) => !value.length)
  );

  const posthogEvent = usePosthog();
  const handlePinGroup = async (
    hierarchyName: string,
    paramArray: ReportParameter[],
    paramKey: string,
    favouriteGroup: FavouriteGroup
  ) => {
    const isGroupWithEqualNameExist = favouriteGroup?.favourite_groups.find(
      ({ group_name }) =>
        group_name === paramArray.map(({ name }) => name).join(' & ')
    );
    setActiveGroup([...activeGroup, paramKey]);
    posthogEvent(reportBuilderPinClick, {
      origin: 'pin-group',
    });
    await pinGroup(hierarchyName, paramArray, !!isGroupWithEqualNameExist);
    setActiveGroup([]);
  };

  const advSelections = viewReportId
    ? (parameters.analytic_engine_params as AnalyticEngineParams)
    : analyticEngineParams;

  return (
    <div>
      {!isTitleHidden && (
        <div className="summary-title">Insight report summary</div>
      )}
      {isNothingSelected ? (
        <InlineNotification
          subtitle="Nothing has been selected yet."
          hideCloseButton
          lowContrast
          kind="info"
        />
      ) : (
        <>
          {Object.keys(newParams).map((dimension) => {
            const parameters = newParams[dimension];
            const noParams = Object.entries(parameters).every(
              ([, value]) => Object.keys(value).length === 0
            );

            return (
              !noParams && (
                <Accordion key={dimension}>
                  <AccordionItem size="md" title={dimension} open>
                    {Object.keys(parameters).map((param) => {
                      const paramArray = newParams[dimension][param];
                      const hierarchyNames = [
                        ...new Set(
                          paramArray.map((parameter) =>
                            getExtendedName(parameter, dimension)
                          )
                        ),
                      ];
                      const paramKey =
                        `${dimension}_${param}` === 'time_groups'
                          ? `${dimension}_${param}`
                          : `${param}_${dimension}`;

                      return (
                        hierarchyNames.length > 0 &&
                        hierarchyNames.map((hierarchyName) => {
                          const filteredParams = paramArray.filter(
                            (parameter) =>
                              getExtendedName(parameter, dimension) ===
                              hierarchyName
                          );
                          const favouriteHierarchyGroups =
                            favouriteGroups?.find(
                              ({ hierarchy_name }) =>
                                hierarchy_name === hierarchyName
                            );
                          const isLoading = activeGroup.includes(
                            `${paramKey}_${hierarchyName}`
                          );
                          const isOverflowMenuHidden =
                            !isLoading &&
                            dimension !== 'time' &&
                            !filteredParams.some(
                              ({ hierarchy_element }) =>
                                hierarchy_element === 'basket_amt'
                            );

                          return (
                            <div
                              key={hierarchyName}
                              className="summary-parameter-wrapper"
                              data-testid="summary-parameter-wrapper"
                            >
                              <div className="SettingsSummary__type">
                                {hierarchyName}
                              </div>
                              <div className="summary-block-wrapper">
                                {!isEditable && (
                                  <div className="overflow-menu-container">
                                    {isOverflowMenuHidden && (
                                      <OverflowMenu
                                        ariaLabel="overflow-menu-pin-group"
                                        data-testid="overflow-menu-pin-group"
                                        focusTrap={false}
                                      >
                                        <OverflowMenuItem
                                          itemText="Add to Favourites"
                                          data-testid="pin-group-button"
                                          onClick={() =>
                                            handlePinGroup(
                                              hierarchyName,
                                              filteredParams,
                                              `${paramKey}_${hierarchyName}`,
                                              favouriteHierarchyGroups
                                            )
                                          }
                                        />
                                      </OverflowMenu>
                                    )}
                                    {isLoading && (
                                      <InlineLoading description="Adding..." />
                                    )}
                                  </div>
                                )}
                                <ParameterCollection
                                  overlap={overlap}
                                  editParameter={editParameter}
                                  isEditable={isEditable}
                                  parameters={filteredParams}
                                  parameterKey={paramKey}
                                  showSubmit={showSubmit}
                                  isSummary
                                  onDelete={(parameter) =>
                                    removeParameter(paramKey, parameter)
                                  }
                                  pinGroup={(parameters) =>
                                    pinGroup(hierarchyName, parameters)
                                  }
                                  deleteFavourite={(type, name, parentName) =>
                                    deleteFavourite(
                                      type,
                                      hierarchyName,
                                      name,
                                      parentName
                                    )
                                  }
                                  favouriteGroups={favouriteHierarchyGroups}
                                  isPinIconHidden={dimension === 'time'}
                                />
                              </div>
                            </div>
                          );
                        })
                      );
                    })}
                  </AccordionItem>
                </Accordion>
              )
            );
          })}
          {advSelections && <SettingsSummary settings={advSelections} />}
        </>
      )}
    </div>
  );
};

export default ParameterSummary;
