/* eslint-disable indent */

import {
  DataTable,
  TableContainer,
  Table,
  TableHead,
  TableHeader,
  TableBody,
  TableRow,
  TableCell,
  TableExpandHeader,
  TableExpandRow,
  TableExpandedRow,
  Toggle,
  NumberInput,
} from '@carbon/react';
import type { FunctionComponent, ReactNode } from 'react';
import { useContext, Fragment } from 'react';
import { OptimiserFormContext } from '../../providers/OptimiserFormProvider';
import type {
  PlanTargetRow,
  LocationPlanTargetRow,
} from '../../reducers/OptimiserFormReducer';
import PlanogramTable from './PlanogramTable';
import { isSkuTargetValid } from '../../utils/CDTUtils';
import { assortmentOptimiserPlanOptToggle } from '../../constants/posthog';
import usePosthog from '../../utils/posthog';

const PlanTargets: FunctionComponent = () => {
  const {
    formData: { planTargets },
    updatePlanInclusion,
    updatePlanPlanogram,
    updatePlanTarget,
  } = useContext(OptimiserFormContext);
  const posthogEvent = usePosthog();

  if (!planTargets) {
    return null;
  }

  const tableHeaders = [
    ...planTargets.headers,
    { id: 0, header: 'Plan Opt In/Out', key: 'include' },
  ];

  const { rows: tableRows } = planTargets;

  const updatePlanogram = ({
    planRowId: id,
    planogramNo,
  }: {
    planRowId: string;
    planogramNo: string;
  }): void => {
    updatePlanPlanogram({ id: id, planogramNo });
  };

  const getCellContent = (
    cell: CarbonCell<unknown>,
    row: PlanTargetRow | LocationPlanTargetRow
  ): ReactNode => {
    const { id } = row;

    switch (cell.info.header) {
      case 'include':
        return (
          <Toggle
            size="sm"
            labelText="Opt In"
            labelA="Out"
            labelB="In"
            toggled={row.include}
            id={`toggle_${id}`}
            onToggle={(include: boolean): void => {
              updatePlanInclusion({ id, include });
              posthogEvent(assortmentOptimiserPlanOptToggle, {
                id,
                isIncluded: include,
              });
            }}
          />
        );

      case 'skuTarget':
        return (
          <div className="number-picker">
            <NumberInput
              id={`target_${id}`}
              min={1}
              max={99999}
              value={cell.value}
              invalid={!isSkuTargetValid(cell.value as number)}
              onChange={(_, { value }) => {
                updatePlanTarget({ id, value: Number(value) || 0 });
              }}
            />
          </div>
        );

      default: {
        return typeof cell.value === 'object'
          ? (cell.value as { value: ReactNode }).value
          : (cell.value as ReactNode);
      }
    }
  };

  return (
    <div className="PlanTargets">
      <DataTable headers={tableHeaders} rows={tableRows}>
        {({
          rows,
          headers,
          getRowProps,
          getTableProps,
          getTableContainerProps,
        }: {
          rows: CarbonSelectedRow[];
          headers: Header[];
          getRowProps;
          getTableProps;
          getTableContainerProps;
        }) => (
          <TableContainer {...getTableContainerProps()}>
            <Table
              {...getTableProps()}
              data-testid="plan-targets"
              stickyHeader={true}
            >
              <TableHead>
                <TableRow>
                  <TableExpandHeader aria-label="expand row" />
                  {headers.map((header) => (
                    <TableHeader key={header.key}>{header.header}</TableHeader>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody>
                {rows.map((row, index) => {
                  const rowData = tableRows[index];

                  return (
                    <Fragment key={row.id}>
                      <TableExpandRow
                        {...getRowProps({ row })}
                        data-testid={`plan-row-${rowData.id}`}
                      >
                        {row.cells.map((cell) => (
                          <TableCell key={cell.id}>
                            {getCellContent(cell, rowData)}
                          </TableCell>
                        ))}
                      </TableExpandRow>
                      <TableExpandedRow
                        colSpan={headers.length + 2}
                        data-testid={`plan-expanded-row-${rowData.id}`}
                      >
                        <PlanogramTable
                          planRowId={rowData.id}
                          data={rowData.planograms}
                          value={rowData.referencePlanogramNo}
                          onChange={updatePlanogram}
                        />
                      </TableExpandedRow>
                    </Fragment>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </DataTable>
    </div>
  );
};

export default PlanTargets;
