import { Dropdown, Toggle } from '@carbon/react';
import { useContext, useEffect, useState } from 'react';
import { usePrevious } from '../../hooks';
import { AssortmentContext } from '../../providers/AssortmentProvider';

const COLLAPSED_ITEM_LIMIT = 4;
const HighlightControls = () => {
  const { highlightLegend, highlight, updateHighlight } =
    useContext(AssortmentContext);

  const [expanded, setExpanded] = useState(false);
  const selected = highlightLegend?.find((entry) => entry.key === highlight.by);
  const showDropdown = highlight.display && selected;
  const showLegend =
    showDropdown ||
    ('targetProductId' in highlight && highlight.targetProductId);

  const previousHighlight = usePrevious(highlight);

  useEffect(() => {
    if (highlight !== previousHighlight && selected?.type === 'index') {
      setExpanded(true);
    }
  }, [highlight, previousHighlight]);

  return (
    <>
      <div className="HighlightToggle">
        <span className="body-02">Highlight Attributes</span>
        <Toggle
          size="sm"
          labelA="Off"
          labelB="On"
          labelText=""
          id="highlight-assortment-atts"
          toggled={highlight.display}
          onToggle={() =>
            updateHighlight({
              display: !highlight.display,
              by: highlightLegend[0].key,
              label: highlightLegend[0].label,
              type: highlightLegend[0].type,
            })
          }
        />
      </div>
      <div className="HighlightControls" data-testid="highlight-controls">
        {showDropdown && (
          <Dropdown
            id="highlight-dropdown"
            data-testid="highlight-dropdown"
            label="Highlight by"
            hideLabel
            items={highlightLegend.filter((item) => !item.hidden)}
            selectedItem={selected}
            itemToString={(item) => item.label}
            onChange={({ selectedItem }) => {
              updateHighlight({
                ...highlight,
                type: selectedItem.type,
                by: selectedItem.key,
                label: selectedItem.label,
              });
            }}
          />
        )}
        <div className="HighlightControls__legend-anchor">
          {showLegend && (
            <div
              className={[
                'HighlightControls__legend',
                selected.type
                  ? `HighlightControls__legend--type-${selected.type}`
                  : '',
              ].join(' ')}
            >
              {'title' in highlight && highlight.title && (
                <div className="HighlightControls__legend-title">
                  {highlight.title}
                </div>
              )}

              {selected.items
                .filter((_, index) => expanded || index <= COLLAPSED_ITEM_LIMIT)
                .map(({ label, colour, info, showLabel = true }) => (
                  <div key={label} className="HighlightControls__legend-item">
                    <div
                      className={`HighlightControls__legend-item__label${
                        showLabel
                          ? ''
                          : ' HighlightControls__legend-item__label--hidden'
                      }`}
                    >
                      {label}
                    </div>
                    {info && (
                      <div className="HighlightControls__legend-item__info">
                        {info}
                      </div>
                    )}
                    <div
                      className="HighlightControls__legend-item__key"
                      style={{ backgroundColor: colour }}
                    />
                  </div>
                ))}
              <div
                className="HighlightControls__expand-btn"
                onClick={() => setExpanded(!expanded)}
                style={{
                  display:
                    selected.items.length <= COLLAPSED_ITEM_LIMIT + 1
                      ? 'none'
                      : 'inline-block',
                }}
              >
                {expanded ? 'Collapse Legend' : 'Expand Legend'}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default HighlightControls;
