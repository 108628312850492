export const formatTime = (time: number) => {
  const daysNr = Math.floor(time / 86400);
  const hoursNr = Math.floor((time % 86400) / 3600);
  const minutesNr = Math.round(Math.floor((time % 3600) / 60) / 5) * 5;
  const daysPlural = daysNr === 1 ? '' : 's';
  const hoursPlural = hoursNr === 1 ? '' : 's';
  const minutesPlural = minutesNr === 1 ? '' : 's';
  const formatDays = daysNr > 0 ? `${daysNr} day${daysPlural}` : '';
  const formatHours = hoursNr > 0 ? `${hoursNr} hour${hoursPlural}` : '';
  const formatMinutes =
    minutesNr > 0 ? `${minutesNr} minute${minutesPlural}` : '';

  const finalFormat = [formatDays, formatHours, formatMinutes].filter(
    (each) => each !== ''
  );

  return finalFormat.join(' ');
};
