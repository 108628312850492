import { useContext } from 'react';
import { DateTime } from 'luxon';
import posthog from 'posthog-js';
import { AppContext } from '../providers/AppProvider';
import {
  getBannerNameById,
  getFormattedUserRole,
  getFormattedUserType,
} from './adminPanelUtils';

interface PosthogPayload {
  [key: string]:
    | string
    | number
    | (string | number)[]
    | boolean
    | {
        [key: string]: string | number;
      }
    | AnalyticEngineParams;
}
const usePosthog = () => {
  const { bannerId, groupId, user, bannersAndUserGroups } =
    useContext(AppContext);
  const posthogEvent = (eventName: string, payload?: PosthogPayload) => {
    const { user_role, user_type, supplier_name, job_title, department, id } =
      user;
    posthog.capture(eventName, {
      user: {
        id,
        user_type: getFormattedUserType(user_type),
        user_role: getFormattedUserRole(user_role),
        supplier_name,
        job_title,
        department,
      },
      date: DateTime.now().toLocaleString(),
      month: DateTime.now().monthLong,
      banner: getBannerNameById(bannerId, bannersAndUserGroups),
      user_group: groupId,
      payload: payload || '',
    });
  };

  return posthogEvent;
};

export default usePosthog;
