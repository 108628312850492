import type { FunctionComponent } from 'react';
import { SkeletonText } from '@carbon/react';

interface Props {
  className?: string;
  width?: string;
}

const LoadingDropdown: FunctionComponent<Props> = ({ className, width }) => (
  <SkeletonText className={className} width={width} />
);

export default LoadingDropdown;
