import type { FormEvent, FunctionComponent } from 'react';
import { useContext, useMemo, useState } from 'react';
import {
  Column,
  Grid,
  Dropdown,
  Checkbox,
  NumberInput,
  InlineLoading,
  FormGroup,
  TextArea,
  TextInput,
  Form,
  Button,
  Toggle,
} from '@carbon/react';
import CardHeader from '../Cards/CardHeader';
import type { ResponsePayload } from '../Fetch';
import Fetch from '../Fetch';
import { CACHE_KEY } from '../../constants/api';
import '../../styles/components/genAi.scss';
import { useAuth0 } from '@auth0/auth0-react';
import { AppContext } from '../../providers/AppProvider';
import apiRequest from '../../api';
import { ModalContext } from '../../providers/ModalProvider';
import IconTitle from '../IconTitle';
import AIIcon from '../../assets/icons/AIIcon';
import {
  LINKED_FILES_DEFAULT_VALUE,
  LINKED_FILES_OPTIONS,
  MAX_TOKEN_DEFAULT_VALUE,
  MAX_TOKEN_MAX,
  MAX_TOKEN_MIN,
  PROMPT_DEFAULT_VALUE,
  TEMPERATURE_DEFAULT_VALUE,
  TEMPERATURE_MAX,
  TEMPERATURE_MIN,
  TOP_P_DEFAULT_VALUE,
  TOP_P_MAX,
  TOP_P_MIN,
} from './GenAiAdmin.constants';
import type {
  GenAIAnswer,
  GenAIAdminQuestion,
  LinkedFilesKeys,
  QuestionOption,
} from './GenAIAdmin.types';
import type { GenAIResponse } from '../../types/apiTypes';
import {
  compareCheckboxConfigs,
  getLinkedFilesConfig,
  getLinkedFiles,
} from './GenAIAdmin.utils';

const GenAIAdmin: FunctionComponent = () => {
  const [questions, setQuestions] = useState<QuestionOption[]>([]);
  const { getAccessTokenSilently } = useAuth0();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState<QuestionOption | null>(
    null
  );
  const [questionText, setQuestionText] = useState('');
  const [selectedStatus, setSelectedStatus] = useState(false);
  const [linkedFilesCollection, setLinkedFilesCollection] = useState<{
    [key in LinkedFilesKeys]: boolean;
  }>(LINKED_FILES_DEFAULT_VALUE);
  const [configFiles, setConfigFiles] = useState('');
  const [questionCategory, setQuestionCategory] = useState('');
  const [selectedFileName, setSelectedFileName] =
    useState(PROMPT_DEFAULT_VALUE);
  const [maxTokens, setMaxTokens] = useState(MAX_TOKEN_DEFAULT_VALUE);
  const [temperature, setTemperature] = useState(TEMPERATURE_DEFAULT_VALUE);
  const [topP, setTopP] = useState(TOP_P_DEFAULT_VALUE);
  const [answer, setAnswer] = useState<GenAIAnswer>({});

  const { bannerId, clearCacheForKey } = useContext(AppContext);
  const { toggleModal, updateModal } = useContext(ModalContext);

  const handleDropdownChange = (option: { id: string; question: string }) => {
    const chosenOption = questions.find(
      (question) => question.id === option.id
    );
    setSelectedOption(chosenOption);
    setQuestionText(option.question);
    setSelectedFileName(chosenOption.prompt_file_name);
    setLinkedFilesCollection(getLinkedFilesConfig(chosenOption.linked_files));
    setMaxTokens(chosenOption?.max_tokens ?? MAX_TOKEN_DEFAULT_VALUE);
    setTemperature(chosenOption?.temperature ?? TEMPERATURE_DEFAULT_VALUE);
    setTopP(chosenOption?.top_p ?? TOP_P_DEFAULT_VALUE);
    setSelectedStatus(chosenOption.is_active);
  };

  const resetQuestionsForm = () => {
    setSelectedOption(null);
    setQuestionText('');
    setSelectedFileName(PROMPT_DEFAULT_VALUE);
    setLinkedFilesCollection(LINKED_FILES_DEFAULT_VALUE);
    setMaxTokens(MAX_TOKEN_DEFAULT_VALUE);
    setTemperature(TEMPERATURE_DEFAULT_VALUE);
    setTopP(TOP_P_DEFAULT_VALUE);
    setSelectedStatus(false);
  };

  const resetTestForm = () => {
    setConfigFiles('');
    setQuestionCategory('');
  };

  const isLinkedFilesOptionSelected = useMemo(
    () => Object.values(linkedFilesCollection).some((file) => file),
    [linkedFilesCollection]
  );

  const ValidateNumberInputs = (): boolean => {
    const isMaxTokenValid =
      Number.isInteger(maxTokens) &&
      maxTokens >= MAX_TOKEN_MIN &&
      maxTokens <= MAX_TOKEN_MAX;
    const isTemperatureValid =
      temperature >= TEMPERATURE_MIN && temperature <= TEMPERATURE_MAX;
    const isTopPValid = topP >= TOP_P_MIN && topP <= TOP_P_MAX;

    return isMaxTokenValid && isTemperatureValid && isTopPValid;
  };

  const isDataChanged = useMemo(() => {
    const selectedCheckboxesConfig = getLinkedFilesConfig(
      selectedOption?.linked_files
    );

    if (selectedOption) {
      return (
        selectedOption.question !== questionText ||
        selectedOption.is_active !== selectedStatus ||
        selectedOption.prompt_file_name !== selectedFileName ||
        selectedOption.max_tokens !== maxTokens ||
        selectedOption.top_p !== topP ||
        selectedOption.temperature !== temperature ||
        !compareCheckboxConfigs(selectedCheckboxesConfig, linkedFilesCollection)
      );
    } else {
      return (
        questionText !== '' ||
        selectedStatus !== false ||
        selectedFileName !== PROMPT_DEFAULT_VALUE ||
        maxTokens !== MAX_TOKEN_DEFAULT_VALUE ||
        topP !== TOP_P_DEFAULT_VALUE ||
        temperature !== TEMPERATURE_DEFAULT_VALUE ||
        isLinkedFilesOptionSelected
      );
    }
  }, [
    selectedOption,
    questionText,
    selectedStatus,
    selectedFileName,
    linkedFilesCollection,
    maxTokens,
    temperature,
    topP,
  ]);

  const submitForm = async () => {
    setIsLoading(true);

    const payload: Omit<QuestionOption, 'id'> = {
      question: questionText,
      is_active: selectedStatus,
      prompt_file_name: selectedFileName,
      max_tokens: maxTokens,
      linked_files: getLinkedFiles(linkedFilesCollection),
      temperature,
      top_p: topP,
      tdp: bannerId.toString(),
    };

    try {
      const token = await getAccessTokenSilently();
      if (selectedOption?.id) {
        await apiRequest(
          `/genai/questions/${selectedOption.id}`,
          'PATCH',
          token,
          payload
        );
      } else {
        await apiRequest('/genai/questions', 'POST', token, payload);
      }
      resetQuestionsForm();
    } catch {
      updateModal({
        type: 'error',
        title: 'Something went wrong',
        body: 'There was an error. Please try refreshing your browser. If the issue persists, please contact the helpdesk.',
      });
    } finally {
      clearCacheForKey(CACHE_KEY.GENAI_QUESTIONS);
      setIsLoading(false);
    }
  };

  const submitQuestion = async () => {
    const payload: GenAIAdminQuestion = {
      max_tokens: maxTokens,
      req_question_category: questionCategory,
      req_question: questionText,
      config_files: configFiles,
      prompt_file_name: selectedFileName,
      req_question_linked_files: getLinkedFiles(linkedFilesCollection),
      temperature,
      top_p: topP,
      tdp: bannerId.toString(),
    };
    setIsLoading(true);
    setAnswer({});

    try {
      const token = await getAccessTokenSilently();
      const response = await apiRequest<ResponsePayload<GenAIResponse>>(
        '/admin/genai',
        'POST',
        token,
        payload
      );

      setAnswer({
        type: response.data.status_code === 200 ? 'success' : 'error',
        message: response.data.body.message,
      });
    } catch {
      updateModal({
        type: 'error',
        title: 'Something went wrong',
        body: 'There was an error sending question request. Please try refreshing your browser. If the issue persists, please contact the helpdesk.',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const confirmDelete = () => {
    updateModal({
      type: 'warning',
      title: 'Do you want to delete this question?',
      primaryCTAText: 'Delete',
      onPrimaryCTAClick: handleDelete,
      secondaryCTAText: 'Cancel',
    });
  };
  const handleDelete = async () => {
    try {
      setIsLoading(true);
      const token = await getAccessTokenSilently();
      await apiRequest(
        `/genai/questions/${selectedOption.id}`,
        'DELETE',
        token
      );
      toggleModal(false);
      resetQuestionsForm();
    } catch {
      updateModal({
        type: 'error',
        title: 'Something went wrong',
        body: 'There was an error deleting question. Please try refreshing your browser. If the issue persists, please contact the helpdesk.',
      });
    } finally {
      clearCacheForKey(CACHE_KEY.GENAI_QUESTIONS);
      setIsLoading(false);
    }
  };

  return (
    <div data-testid="flex direction-column gap--medium" className="GenAI">
      <IconTitle
        icon={<AIIcon size={20} />}
        title="Oneviu AI Question"
        kind="primary"
      />
      <Fetch
        apiUrl={`/genai/questions?tdp=${bannerId}`}
        cacheKey={CACHE_KEY.GENAI_QUESTIONS}
        initialData={questions}
        loadingMessage="Loading Oneviu AI questions form..."
        alwaysFetchOnMount={!questions.length}
        onReceiveData={(data: QuestionOption[]) => {
          if (data) {
            setQuestions(data);
          }
        }}
      >
        <Form className="GenAI-form" data-testid="genai-form">
          {isLoading && (
            <InlineLoading
              status="active"
              iconDescription="Loading"
              description="Loading form data..."
            />
          )}
          <Grid>
            <Column lg={8} md={8} sm={4}>
              <div className="card flex direction-column gap--medium">
                <CardHeader title="Edit Oneviu AI Questions" />
                <Dropdown
                  id="genai-questions"
                  data-testid="questions-dropdown"
                  label="Select a Oneviu AI question"
                  titleText="Oneviu AI question"
                  disabled={isLoading || !questions.length}
                  className="GenAI-form__dropdown"
                  itemToString={(item: QuestionOption) =>
                    item ? item.question : ''
                  }
                  items={questions}
                  onChange={({ selectedItem }) =>
                    handleDropdownChange(selectedItem)
                  }
                  selectedItem={selectedOption}
                />
                <TextInput
                  id="question-text"
                  data-testid="question-text-input"
                  type="text"
                  disabled={isLoading}
                  labelText="Question text"
                  value={questionText}
                  onChange={(e: FormEvent<HTMLInputElement>) =>
                    setQuestionText(e.currentTarget.value)
                  }
                  placeholder="Question text"
                />
                <TextArea
                  id="question-prompt"
                  data-testid="question-prompt-textarea"
                  type="text"
                  rows={6}
                  disabled={isLoading}
                  labelText="Question prompt"
                  value={selectedFileName}
                  onChange={(e: FormEvent<HTMLInputElement>) =>
                    setSelectedFileName(e.currentTarget.value)
                  }
                  placeholder="Question prompt"
                />
                <NumberInput
                  id="max-tokens"
                  data-testid="max-tokens-input"
                  allowEmpty={true}
                  min={MAX_TOKEN_MIN}
                  max={MAX_TOKEN_MAX}
                  hideSteppers
                  label="Please enter max token length of response"
                  invalidText="Value is not valid. Please enter integer number from 0 to 1000"
                  invalid={!Number.isInteger(maxTokens)}
                  value={maxTokens}
                  disabled={isLoading}
                  onChange={(e: FormEvent<HTMLInputElement>) =>
                    setMaxTokens(Number(e.currentTarget.value))
                  }
                />
                <NumberInput
                  id="temperature"
                  data-testid="temperature-input"
                  allowEmpty={true}
                  min={TEMPERATURE_MIN}
                  max={TEMPERATURE_MAX}
                  helperText="Number between 0 and 1.0 (ex. 0.5). Adjusts the diversity of responses. Higher values increase variety."
                  hideSteppers
                  label="Temperature: Output Variety"
                  invalidText="Value is not valid"
                  value={temperature}
                  disabled={isLoading}
                  onChange={(e: FormEvent<HTMLInputElement>) =>
                    setTemperature(+e.currentTarget.value)
                  }
                />
                <NumberInput
                  id="top-p"
                  data-testid="top-p-input"
                  allowEmpty={true}
                  min={TOP_P_MIN}
                  max={TOP_P_MAX}
                  helperText="Number between 0 and 1.0. Limits responses to top predictions. Lower values reduce variety"
                  hideSteppers
                  label="Top_P: Response Focus"
                  invalidText="Value is not valid"
                  value={topP}
                  disabled={isLoading}
                  onChange={(e: FormEvent<HTMLInputElement>) =>
                    setTopP(+e.currentTarget.value)
                  }
                />
                <FormGroup
                  legendText="Question linked files"
                  data-testid="linked-files-formgroup"
                >
                  {Object.entries(LINKED_FILES_OPTIONS).map(([key, value]) => (
                    <Checkbox
                      key={key}
                      id={key}
                      labelText={`${value.file} - ${value.type}`}
                      disabled={isLoading}
                      checked={linkedFilesCollection[key]}
                      onChange={(_, { checked }) =>
                        setLinkedFilesCollection((prev) => ({
                          ...prev,
                          [key]: checked,
                        }))
                      }
                    />
                  ))}
                </FormGroup>
                <Toggle
                  id="is-question-active"
                  labelText="Activate question"
                  labelA="Inactive"
                  labelB="Active"
                  size="sm"
                  disabled={isLoading}
                  toggled={selectedStatus}
                  onToggle={() =>
                    setSelectedStatus((prevStatus) => !prevStatus)
                  }
                />
                <div className="GenAI-form__actions">
                  <Button
                    size="sm"
                    kind="primary"
                    onClick={submitForm}
                    disabled={[
                      isLoading,
                      !isDataChanged,
                      !questionText,
                      !isLinkedFilesOptionSelected,
                      !ValidateNumberInputs(),
                    ].some(Boolean)}
                  >
                    {selectedOption?.id ? 'Update' : 'Add'} Question
                  </Button>
                  <Button
                    size="sm"
                    kind="secondary"
                    onClick={resetQuestionsForm}
                    disabled={
                      isLoading ||
                      (!selectedOption?.id &&
                        !questionText &&
                        !isLinkedFilesOptionSelected &&
                        maxTokens === MAX_TOKEN_DEFAULT_VALUE &&
                        temperature === TEMPERATURE_DEFAULT_VALUE &&
                        topP === TOP_P_DEFAULT_VALUE)
                    }
                  >
                    Clear Form
                  </Button>
                  <Button
                    size="sm"
                    kind="danger"
                    onClick={confirmDelete}
                    disabled={isLoading || !selectedOption?.id}
                  >
                    Delete Question
                  </Button>
                </div>
              </div>
            </Column>
            <Column lg={8} md={8} sm={4}>
              <div className="card flex direction-column gap--medium">
                <CardHeader title="Test question" />
                <TextInput
                  id="question-config-files"
                  data-testid="question-config-files-input"
                  type="text"
                  disabled={isLoading}
                  labelText="Question config files"
                  helperText="Example comma separated: 101/homepage_101/1011_0/kpi-tree-grid/1-16867618132419703444,101/homepage_101/1011_0/kpi-tree-grid/1-16867618132419703444"
                  value={configFiles}
                  onChange={(e: FormEvent<HTMLInputElement>) =>
                    setConfigFiles(e.currentTarget.value)
                  }
                />
                <TextInput
                  id="question-category"
                  data-testid="question-category-input"
                  type="text"
                  disabled={isLoading}
                  labelText="Question category"
                  value={questionCategory}
                  onChange={(e: FormEvent<HTMLInputElement>) =>
                    setQuestionCategory(e.currentTarget.value)
                  }
                />
                <div className="GenAI-form__actions">
                  <Button
                    size="sm"
                    kind="primary"
                    onClick={submitQuestion}
                    disabled={[
                      isLoading,
                      !configFiles,
                      !questionCategory,
                      !questionText,
                      !isLinkedFilesOptionSelected,
                      !ValidateNumberInputs(),
                    ].some(Boolean)}
                  >
                    Send Question Query
                  </Button>
                  <Button
                    size="sm"
                    kind="secondary"
                    disabled={
                      isLoading ||
                      [!configFiles, !questionCategory].every(Boolean)
                    }
                    onClick={resetTestForm}
                  >
                    Clear
                  </Button>
                </div>

                {answer?.message && (
                  <p
                    className={`GenAI-answer GenAI-answer__${answer.type}`}
                    data-testid="genai-answer"
                  >
                    {answer.message}
                  </p>
                )}
              </div>
            </Column>
          </Grid>
        </Form>
      </Fetch>
    </div>
  );
};

export default GenAIAdmin;
