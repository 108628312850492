/* eslint-disable indent */
import type { FunctionComponent } from 'react';
import { useState, useContext, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  DataTable,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableHeader,
  TableCell,
  TableRow as CarbonTableRow,
  Pagination,
  Breadcrumb,
  BreadcrumbItem,
  Button,
  TableSelectRow,
  IconButton,
  TableSelectAll,
  TableBatchAction,
  TableToolbar,
  TableToolbarContent,
  TableBatchActions,
  InlineLoading,
} from '@carbon/react';
import { TrashCan, Locked, AddAlt } from '@carbon/icons-react';
import CustomGroupsEdit from '../CustomGroupsEdit/CustomGroupsEdit';
import { CustomGroupsContext } from '../../../providers/CustomGroupsProvider';
import { AppContext } from '../../../providers/AppProvider';
import Fetch from '../../Fetch';
import {
  CustomGroupAccess,
  custGroupTableHeaders,
} from '../../../constants/metadata';
import {
  renderCustomGroupsMetadata,
  type UpdateCustomGroupParams,
} from './CustomGroups';
import { usePagination } from '../../../hooks';
import { ModalContext } from '../../../providers/ModalProvider';
import { useAuth0 } from '@auth0/auth0-react';
import apiRequest from '../../../api';
import {
  custGroupsAccessFailure,
  custGroupsAccessSuccess,
  editCustomGroupClick,
} from '../../../constants/posthog';
import usePosthog from '../../../utils/posthog';
import '../../../styles/components/CustomGroups.scss';
import '../../../styles/components/viewReport.scss';
import { CACHE_KEY } from '../../../constants/api';

export interface CustGroupsData {
  customGroup: string;
  entity_no: number;
  id: number;
  label: string;
}

const CustomGroupTable: FunctionComponent = () => {
  const navigate = useNavigate();
  const { getAccessTokenSilently } = useAuth0();
  const posthogEvent = usePosthog();
  const { bannerId, groupId } = useContext(AppContext);
  const {
    selectedCustomGroupId,
    existingCustGroups,
    selectedDimGroup,
    updateExistingCustGroups,
  } = useContext(CustomGroupsContext);
  const { toggleModal, updateModal } = useContext(ModalContext);
  const [tableTitle, setTableTitle] = useState<string>();
  const [custGroupsData, setCustGroupsData] = useState<CustGroupsData[]>();
  const [hoveredGroup, setHoveredGroup] = useState(false);
  const [deletingItems, setDeletingItems] = useState([]);
  const [isUpdating, setIsUpdating] = useState(false);
  const [access, setAccess] = useState('');
  const { custGroupId } = useParams();

  const textInputRef = useRef<HTMLInputElement>(null);
  const {
    page,
    pageSizes,
    pageSize,
    isShowAll,
    getItemRangeText,
    setPagination,
  } = usePagination();

  const deleteCustomGroupItems = async (
    ids: string[],
    selection: { label: string; entity_no: number }[]
  ) => {
    const allSelection = custGroupsData.every((gr) =>
      ids.includes(String(gr.id))
    );
    const itemPlural = ids.length === 1 ? '' : 's';
    const groupText = itemPlural ? 'these' : 'this';
    updateModal({
      type: 'warning',
      title: allSelection
        ? 'Are you sure you want to delete all of the selected items from this group?'
        : `Are you sure you want to delete ${groupText} item${itemPlural} from the group?`,
      body: allSelection
        ? 'If you delete all the items in a group, the group will be deleted'
        : '',
      primaryCTAText: allSelection
        ? 'Delete Group'
        : `Delete Item${itemPlural}`,
      secondaryCTAText: 'Cancel',
      onPrimaryCTAClick: async () => {
        toggleModal(false);
        setDeletingItems(ids);
        const token = await getAccessTokenSilently();
        const payload = allSelection
          ? {
              ids: [custGroupId],
            }
          : {
              id: custGroupId,
              entities: selection,
            };
        try {
          await apiRequest(
            `/configs/${bannerId}/user-groups/${groupId}/hierarchies`,
            'DELETE',
            token,
            payload
          );
        } catch {
          updateModal({
            type: 'error',
            title: 'Something went wrong',
            body: 'There was an error deleting custom groups. Please try refreshing your browser and try again. If the issue persists, please contact the helpdesk.',
          });
        } finally {
          if (allSelection) {
            const deletedGroups = existingCustGroups.filter(
              (gr) => ![custGroupId].includes(gr.id)
            );
            updateExistingCustGroups(deletedGroups);
            navigate('/custom-groups');
          } else {
            const updatedGroups = custGroupsData.filter(
              (gr) => !ids.includes(String(gr.id))
            );
            setCustGroupsData(updatedGroups);
          }

          setDeletingItems([]);
        }
      },
    });
  };

  const updateCustomGroupTable = async ({
    customGroupIds,
    targetValue,
    currentAccess,
  }: UpdateCustomGroupParams): Promise<void> => {
    const newAccess =
      currentAccess === CustomGroupAccess.PUBLIC
        ? CustomGroupAccess.PRIVATE
        : CustomGroupAccess.PUBLIC;
    const updatedGroupProperty = currentAccess ? 'access' : 'name';
    const updatedPropertyValue = currentAccess ? newAccess : targetValue;

    const updateGroup = async () => {
      setIsUpdating(true);
      const token = await getAccessTokenSilently();
      const HIERARCHIES_URL = `/configs/${bannerId}/user-groups/${groupId}/hierarchies`;
      const requestUrl = currentAccess
        ? HIERARCHIES_URL
        : `${HIERARCHIES_URL}/${customGroupIds[0]}`;
      const payload = currentAccess
        ? { access: newAccess, ids: customGroupIds }
        : { group_name: targetValue };
      try {
        await apiRequest(requestUrl, 'PATCH', token, payload);
        posthogEvent(custGroupsAccessSuccess, {
          access: currentAccess,
        });
      } catch {
        posthogEvent(custGroupsAccessFailure);
        updateModal({
          type: 'error',
          title: 'Something went wrong',
          body: 'There was an error updating custom groups. Please try refreshing your browser and try again. If the issue persists, please contact the helpdesk.',
        });
      } finally {
        setIsUpdating(false);
        currentAccess ? setAccess(newAccess) : setTableTitle(targetValue);
        const updatedGroups = existingCustGroups.map((group) =>
          group.id === custGroupId
            ? { ...group, [updatedGroupProperty]: updatedPropertyValue }
            : group
        );
        updateExistingCustGroups(updatedGroups);
      }
    };

    const modalBody =
      newAccess === CustomGroupAccess.PRIVATE
        ? 'Making your group public allows all users in the user group to see and use it in the report builder. Do you want to proceed?'
        : 'This action will make your group private so only you can view and manage it. Do you want to proceed?';

    if (currentAccess) {
      updateModal({
        type: 'warning',
        title: `Set Group to ${newAccess}`,
        body: modalBody,
        primaryCTAText: `Make ${newAccess}`,
        secondaryCTAText: 'Cancel',
        onPrimaryCTAClick: async () => {
          toggleModal(false);
          await updateGroup();
        },
        onSecondaryCTAClick: () => toggleModal(false),
      });
    } else {
      await updateGroup();
    }
  };

  const handleDelete = (row: CarbonSelectedRow, cell: CarbonCell<string>) => {
    const entityNoValue = row.cells.find(
      (cell) => cell.info.header === 'entity_no'
    )?.value;

    const selection = custGroupsData
      ?.filter((item) => item.entity_no === entityNoValue)
      .map(({ label, entity_no }) => ({ label, entity_no }));

    const groupId = cell.id.split(':')[0];
    deleteCustomGroupItems([groupId], selection);
  };

  const customGroupInfo =
    tableTitle && existingCustGroups.find((group) => group.name === tableTitle);

  const custDataAvailable = custGroupsData?.length > 0 && tableTitle;

  return (
    <>
      {custDataAvailable && (
        <div className="CustGroupTable__header">
          <div className="CustGroupTable__title-wrapper">
            {isUpdating ? (
              <div className="CustomGroups__item-loader">
                <InlineLoading description="Updating..." />
              </div>
            ) : (
              <>
                <div
                  onMouseEnter={() => setHoveredGroup(true)}
                  onMouseLeave={() => setHoveredGroup(false)}
                >
                  <div className="breadcrumb-container">
                    <Breadcrumb noTrailingSlash>
                      <BreadcrumbItem>
                        <div
                          className="cds--link"
                          onClick={() => navigate('/custom-groups')}
                        >
                          Custom Groups
                        </div>
                      </BreadcrumbItem>
                      <BreadcrumbItem isCurrentPage>
                        {tableTitle}
                      </BreadcrumbItem>
                    </Breadcrumb>
                  </div>
                  <CustomGroupsEdit
                    textInputRef={textInputRef}
                    groupId={custGroupId}
                    groupName={tableTitle}
                    hoveredGroup={hoveredGroup}
                    existingCustGroups={existingCustGroups}
                    updateCustomGroup={updateCustomGroupTable}
                    itemsCount={custGroupsData?.length}
                  />
                </div>

                <Button
                  as="div"
                  kind="secondary"
                  size="sm"
                  renderIcon={Locked}
                  className={`CustomGroups__access-btn ${access} has-icon ${
                    access === CustomGroupAccess.PUBLIC
                      ? 'cds--btn--secondary__neutral'
                      : ''
                  }`}
                  onClick={() => {
                    updateCustomGroupTable({
                      customGroupIds: [custGroupId],
                      targetValue: '',
                      currentAccess: access,
                    });
                  }}
                  data-testid="access-custom-group-btn"
                >
                  {access}
                </Button>
              </>
            )}
          </div>
          <div className="CustomGroupTable__metadata-wrapper">
            {customGroupInfo && renderCustomGroupsMetadata(customGroupInfo)}
            <div className="CustomGroupTable__edit-btn-wrapper">
              <Button
                kind="tertiary"
                size="md"
                className="has-icon"
                renderIcon={AddAlt}
                onClick={() => {
                  posthogEvent(editCustomGroupClick);
                  navigate(
                    `/custom-groups/upload?mode=edit&id=${custGroupId}&dimension=${selectedDimGroup}&name=${tableTitle}`
                  );
                }}
                data-testid="add-new-items-to-group-btn"
              >
                Add New Items to Group
              </Button>
            </div>
          </div>
        </div>
      )}
      <div className="CustomGroupsTable">
        <Fetch
          alwaysFetchOnMount
          key="uploaded-custom-groups"
          apiUrl={`/metadata?path=${bannerId}/user-groups/${groupId}/custom/${
            custGroupId || selectedCustomGroupId
          }`}
          initialData={null}
          loadingMessage="Loading custom group..."
          cacheKey={CACHE_KEY.UPLOADED_CUSTOM_GROUPS}
          onReceiveData={(data) => {
            if (data) {
              const updatedCustGroupsData = data?.entities.map((gr) => ({
                ...gr,
                customGroup: data.group_name,
                id: gr.entity_no,
              }));
              setTableTitle(data.group_name);
              setCustGroupsData(updatedCustGroupsData);
              setAccess(data.access);
            }
          }}
        >
          {custDataAvailable && (
            <>
              <DataTable
                headers={custGroupTableHeaders.map((header) =>
                  header.key === 'customGroup'
                    ? { ...header, header: 'Actions' }
                    : header
                )}
                rows={custGroupsData}
                data-testid="custom-groups-data-table"
                isSortable
              >
                {({
                  rows,
                  headers,
                  getHeaderProps,
                  getRowProps,
                  getTableProps,
                  getBatchActionProps,
                  getSelectionProps,
                  getToolbarProps,
                  selectedRows,
                  getTableContainerProps,
                }) => {
                  const rowStartIndex = (page - 1) * pageSize;

                  const visibleRows = isShowAll
                    ? rows
                    : rows.slice(rowStartIndex, rowStartIndex + pageSize);

                  const getCellContent = (
                    row: CarbonSelectedRow,
                    cell: CarbonCell<string>
                  ): TableCell => {
                    return (
                      <TableCell key={cell.id}>
                        {cell.info.header === 'customGroup' &&
                        deletingItems.includes(cell.id.split(':')[0]) ? (
                          <InlineLoading description="Deleting..." />
                        ) : (
                          cell.info.header === 'customGroup' && (
                            <IconButton
                              label="Delete item"
                              kind="ghost"
                              onClick={(e) => {
                                e.stopPropagation();
                                handleDelete(row, cell);
                              }}
                              size="sm"
                              data-testid="custom-table-del-icon"
                            >
                              <TrashCan />
                            </IconButton>
                          )
                        )}
                        {cell.info.header !== 'customGroup' && cell.value}
                      </TableCell>
                    );
                  };

                  return (
                    <>
                      <TableContainer
                        {...getTableContainerProps()}
                        className="CustGroupTable__table"
                      >
                        <TableToolbarContent>
                          <TableToolbar {...getToolbarProps()}>
                            <TableBatchActions
                              {...getBatchActionProps()}
                              className="toolbar-buttons"
                              data-testid="delete-custom-groups-table"
                            >
                              <TableBatchAction
                                key="delete-custom-groups"
                                renderIcon={TrashCan}
                                onClick={() => {
                                  const filteredData = custGroupsData.filter(
                                    ({ id }) =>
                                      selectedRows.some((row) => row.id === id)
                                  );
                                  deleteCustomGroupItems(
                                    filteredData.map(({ id }) => String(id)),
                                    filteredData.map(
                                      ({ label, entity_no }) => ({
                                        label,
                                        entity_no,
                                      })
                                    )
                                  );
                                }}
                              >
                                Delete
                              </TableBatchAction>
                            </TableBatchActions>
                          </TableToolbar>
                        </TableToolbarContent>
                        <Table {...getTableProps()}>
                          <TableHead>
                            <CarbonTableRow>
                              <TableSelectAll
                                {...getSelectionProps()}
                                className="header-checkbox"
                              />
                              {headers.map((header) => (
                                <TableHeader
                                  key={header.key}
                                  {...getHeaderProps({ header })}
                                >
                                  {header.header}
                                </TableHeader>
                              ))}
                            </CarbonTableRow>
                          </TableHead>
                          <TableBody>
                            {visibleRows.map((row) => {
                              return (
                                <CarbonTableRow
                                  key={row.id}
                                  {...getRowProps({ row })}
                                >
                                  <TableSelectRow
                                    {...getSelectionProps({ row })}
                                  />
                                  {row.cells.map((cell) =>
                                    getCellContent(row, cell)
                                  )}
                                </CarbonTableRow>
                              );
                            })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </>
                  );
                }}
              </DataTable>
            </>
          )}
          {custDataAvailable && (
            <Pagination
              backwardText="Previous page"
              forwardText="Next page"
              itemsPerPageText="Items per page:"
              onChange={setPagination}
              page={page}
              pageSize={pageSize}
              pageSizes={pageSizes}
              itemRangeText={getItemRangeText}
              totalItems={custGroupsData.length}
            />
          )}
        </Fetch>
      </div>
    </>
  );
};
export default CustomGroupTable;
