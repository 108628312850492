/* eslint-disable indent */
import type { ElementRef, FunctionComponent, ReactNode } from 'react';
import { useContext, useEffect, useMemo, useRef } from 'react';
import { Repeat } from '@carbon/icons-react';
import { AssortmentContext } from '../../providers/AssortmentProvider';
import { ReportContext } from '../../providers/ReportProvider';
import { addAndRemoveItems } from '../../utils/reportUtils';
import type { HighlightedCell, RowData } from './DataGrid';
import DataGrid from './DataGrid';
import { AppContext } from '../../providers/AppProvider';
import { CONTINUOUS_SCALE } from '../../constants/palettes';
import type {
  AssortmentData,
  HighlightLegendItem,
} from '../../reducers/AssortmentReducer';
import { SUBS_HEADER_KEYS, USER_TYPE } from '../../constants/metadata';
import {
  getContinuousHighlightedCells,
  getDiscreteHighlightedCells,
  getSubstituteHighlightedCells,
  getSubstituteProducts,
} from '../../utils/CDTUtils';

export const SUBSTITUTE_HIGHLIGHT_KEY = 'substitutes';

type ExtendedAssortmentData = AssortmentData & {
  readonly PRODUCT:
    | AssortmentData['PRODUCT']
    | {
        readonly value: string;
        readonly action: {
          readonly onClick: (cellId: string) => void | Promise<void>;
          readonly icon: ReactNode;
          readonly tooltip: string;
        };
      };
};

interface AssortmentGridProps {
  dropdownKey: string;
  reportTemplateId: string;
  reportTemplateIndex: number;
}

const AssortmentGrid: FunctionComponent<AssortmentGridProps> = ({
  dropdownKey,
  reportTemplateId,
  reportTemplateIndex,
}) => {
  const { user } = useContext(AppContext);
  const { visualsData, reportConfig, patchVisualsData } =
    useContext(ReportContext);
  const {
    assortmentData,
    selectedProducts,
    highlight,
    highlightLegend,
    resetSelection,
    updateSelectedProducts,
    saveAssortmentData,
    updateHighlightLegend,
    updateHighlight,
    clearHighlight,
  } = useContext(AssortmentContext);
  const isAuthor = reportConfig.user_id === user.id;
  const isSupplier = user.user_type === USER_TYPE.SUPPLIER;

  const dataGridRef = useRef<ElementRef<typeof DataGrid>>();

  const rows: RowData<ExtendedAssortmentData>[] = useMemo(() => {
    if (highlight.display) {
      return assortmentData;
    }

    return assortmentData.map((row) => ({
      ...row,
      PRODUCT: {
        value: row.PRODUCT as string,
        action: {
          onClick: async (cellId: string) => {
            if (
              !('targetProductId' in highlight) ||
              highlight.targetProductId !== row.PRODUCT_ID
            ) {
              updateHighlight({
                cellId,
                display: false,
                title: 'Substitutability Strength',
                type: 'continuous',
                by: SUBSTITUTE_HIGHLIGHT_KEY,
                label: SUBSTITUTE_HIGHLIGHT_KEY,
                targetProductId: row.PRODUCT_ID,
              });
            } else {
              clearHighlight();
            }

            await dataGridRef.current.showHeaders(SUBS_HEADER_KEYS);

            dataGridRef.current.scrollHeaderIntoView();
          },
          icon: <Repeat />,
          tooltip: 'View substitutes',
        },
      },
    }));
  }, [highlight, assortmentData]);

  const substituteProducts:
    | {
        productName: string;
        substitutes: [name: string, transfer: NumberPoint][];
      }
    | undefined = useMemo(() => {
    if (
      !('targetProductId' in highlight) ||
      highlight.by !== SUBSTITUTE_HIGHLIGHT_KEY
    ) {
      return undefined;
    }

    const selectedProduct = assortmentData.find(
      (row) => row.PRODUCT_ID === highlight.targetProductId
    );

    return {
      productName: selectedProduct.PRODUCT as string,
      substitutes: getSubstituteProducts(selectedProduct),
    };
  }, [highlight, assortmentData]);

  const highlightedCells: Record<string, HighlightedCell> | undefined =
    useMemo(() => {
      if (highlight.display) {
        const legend = highlightLegend?.find(
          (entry) => entry.key === highlight.by
        );

        if (!legend) {
          return undefined;
        }

        switch (highlight.type) {
          case 'discrete':
            return getDiscreteHighlightedCells({ assortmentData, legend });

          case 'continuous':
          case 'index':
            return getContinuousHighlightedCells({ assortmentData, legend });

          default:
            return undefined;
        }
      }

      if (highlight.by === SUBSTITUTE_HIGHLIGHT_KEY) {
        return getSubstituteHighlightedCells({
          ...substituteProducts,
          assortmentData,
        });
      }

      return undefined;
    }, [
      assortmentData,
      highlight.by,
      highlight.display,
      highlight.type,
      highlightLegend,
      substituteProducts,
    ]);

  const orderedData = useMemo(() => {
    if (!highlight.display) {
      return visualsData[1];
    }
    const data = visualsData[1];
    const headers = data.headers[1];
    const highlightedId = headers.find((h) => h.key === highlight.by).id;

    const headerIndex = data.isVisible.indexOf(highlightedId);

    const columnOrder = [...data.isVisible];
    const firstColIndex = data.stickyColumns.length;
    columnOrder.splice(headerIndex, 1);
    columnOrder.splice(firstColIndex, 0, highlightedId);

    return {
      ...data,
      isVisible: columnOrder,
      stickyColumns: [...visualsData[1].stickyColumns, highlightedId],
    };
  }, [highlight, visualsData[1]]);

  const customSortHeaders = (a: Header, b: Header): number => {
    return (
      orderedData.isVisible.indexOf(a.id) - orderedData.isVisible.indexOf(b.id)
    );
  };

  useEffect(() => {
    if (!substituteProducts) {
      return;
    }

    const { substitutes } = substituteProducts;

    const legendItems: HighlightLegendItem[] =
      substitutes.length === 0
        ? [{ label: 'No substitutable products', colour: '#fff' }]
        : substitutes.map(([label, { value }], index) => {
            const colour = CONTINUOUS_SCALE[index * 2];
            const range = { min: value, max: Infinity };
            let info;

            if (index === 0) {
              info = '(Most)';
            } else if (index === substitutes.length - 1) {
              info = '(Least)';
            }

            return { label, colour, range, info };
          });

    updateHighlightLegend({
      key: SUBSTITUTE_HIGHLIGHT_KEY,
      items: legendItems,
    });
  }, [substituteProducts]);

  const selectRows = (indexes: number[], add?: boolean) => {
    resetSelection();
    const productIds = assortmentData
      .filter((_, idx) => indexes.includes(idx))
      .map((product) => product.PRODUCT_ID);

    updateSelectedProducts(
      add ? addAndRemoveItems(selectedProducts, productIds) : productIds
    );
  };

  return (
    <div className="assortment-grid">
      <DataGrid
        ref={dataGridRef}
        headerData={orderedData.headers[dropdownKey]}
        rowData={rows}
        stickyColumns={orderedData.stickyColumns}
        visibleColumns={orderedData.isVisible}
        collapsed
        showExport={false}
        isSortable={false}
        showPagination={false}
        columnWidths={orderedData.columnWidths}
        visualData={visualsData[1]}
        id={1}
        reportTemplateId={reportTemplateId}
        reportTemplateIndex={reportTemplateIndex}
        resizable
        isAuthor={isAuthor}
        reportConfig={reportConfig}
        patchVisualsData={patchVisualsData}
        onSave={isAuthor && !isSupplier && saveAssortmentData}
        clickedCellId={'cellId' in highlight ? highlight.cellId : undefined}
        highlightedRows={selectedProducts.map((sku) =>
          assortmentData.map((row) => row.PRODUCT_ID).indexOf(sku)
        )}
        highlightedCells={highlightedCells}
        onSelectRows={(rowIndexes, add) => selectRows(rowIndexes, add)}
        customSortHeaders={customSortHeaders}
      />
    </div>
  );
};

export default AssortmentGrid;
