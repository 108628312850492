import type { FunctionComponent } from 'react';
import { useRef, useState } from 'react';
import {
  Checkmark,
  Close,
  Copy,
  ThumbsUp,
  ThumbsUpFilled,
  ThumbsDown,
  ThumbsDownFilled,
} from '@carbon/icons-react';
import Fetch from '../../Fetch';
import { CACHE_KEY } from '../../../constants/api';
import type {
  GenAIAnswerActionsProps,
  GenAIFeedbackOption,
} from '../GenAIPanel.types';
import usePosthog from '../../../utils/posthog';
import {
  aiAnswerCopyClick,
  aiAnswerDislikeClick,
  aiAnswerDislikeOptionClick,
  aiAnswerLikeClick,
  aiAnswerDislikeClosedWithoutOption,
} from '../../../constants/posthog';

const BUTTON_ACTIVE_DURATION = 2000;

const GenAIAnswerActions: FunctionComponent<GenAIAnswerActionsProps> = ({
  message,
  question,
}) => {
  const [isLikeActive, setIsLikeActive] = useState(false);
  const [isDislikeActive, setIsDislikeActive] = useState(false);
  const [isDislikeOptionsVisible, setIsDislikeOptionsVisible] = useState(false);
  const [isSavingFeedback, setIsSavingFeedback] = useState(false);
  const [feedbackItems, setFeedbackItems] = useState<string[]>([]);
  const [activeItem, setActiveItem] = useState<string | null>(null);
  const [isCopied, setIsCopied] = useState(false);
  const copyTimeoutRef = useRef<ReturnType<typeof setTimeout>>();
  const likeTimeoutRef = useRef<ReturnType<typeof setTimeout>>();
  const dislikeTimeoutRef = useRef<ReturnType<typeof setTimeout>>();
  const dislikeSubmitTimeoutRef = useRef<ReturnType<typeof setTimeout>>();

  const posthogEvent = usePosthog();

  const handleCopy = (): void => {
    clearTimeout(copyTimeoutRef.current);
    navigator.clipboard.writeText(message);
    setIsCopied(true);
    posthogEvent(aiAnswerCopyClick);
    copyTimeoutRef.current = setTimeout(
      () => setIsCopied(false),
      BUTTON_ACTIVE_DURATION
    );
  };

  const clearFeedbackButton = (): void => {
    clearTimeout(likeTimeoutRef.current);
    clearTimeout(dislikeTimeoutRef.current);
    clearTimeout(dislikeSubmitTimeoutRef.current);

    setIsLikeActive(false);
    setIsDislikeActive(false);
    setIsDislikeOptionsVisible(false);
    setActiveItem(null);
  };

  const handleLikeClick = (): void => {
    clearFeedbackButton();
    setIsLikeActive(true);

    likeTimeoutRef.current = setTimeout(
      () => setIsLikeActive(false),
      BUTTON_ACTIVE_DURATION
    );

    posthogEvent(aiAnswerLikeClick, {
      questionText: question,
    });
  };

  const handleDislikeClick = (): void => {
    clearFeedbackButton();
    setIsDislikeActive(true);
    setIsDislikeOptionsVisible(true);

    posthogEvent(aiAnswerDislikeClick, {
      questionText: question,
    });

    dislikeTimeoutRef.current = setTimeout(
      () => setIsDislikeActive(false),
      BUTTON_ACTIVE_DURATION
    );
  };

  const handleDislikeOptionClick = (option: string | null): void => {
    setActiveItem(option);
    setIsSavingFeedback(true);

    if (!option) {
      setIsDislikeOptionsVisible(false);
      posthogEvent(aiAnswerDislikeClosedWithoutOption, {
        questionText: question,
      });
      setIsSavingFeedback(false);
      return;
    }

    posthogEvent(aiAnswerDislikeOptionClick, {
      questionText: question,
      dislikeOption: option,
    });

    dislikeSubmitTimeoutRef.current = setTimeout(
      () => setIsDislikeOptionsVisible(false),
      BUTTON_ACTIVE_DURATION
    );
    setIsSavingFeedback(false);
  };

  return (
    <div className="GenAIPanel__actions">
      <button
        type="button"
        aria-label="Copy"
        title="Click to copy"
        className={`GenAIPanel__actions-button ${isCopied ? 'active' : ''}`}
        onClick={handleCopy}
      >
        Copy {isCopied ? <Checkmark /> : <Copy />}
      </button>
      <div className="GenAIPanel__actions-container">
        <button
          data-testid="genai-like"
          type="button"
          aria-label="Like answer"
          title="Click to like"
          className="GenAIPanel__actions-feedback"
          onClick={handleLikeClick}
          disabled={isSavingFeedback}
        >
          {isLikeActive ? <ThumbsUpFilled /> : <ThumbsUp />}
        </button>
        <button
          data-testid="genai-dislike"
          type="button"
          aria-label="Dislike answer"
          title="Click to dislike"
          className="GenAIPanel__actions-feedback"
          onClick={handleDislikeClick}
          disabled={isSavingFeedback}
        >
          {isDislikeActive ? <ThumbsDownFilled /> : <ThumbsDown />}
        </button>
      </div>
      {isDislikeOptionsVisible && (
        <div className="GenAIPanel__actions-options">
          <Fetch
            initialData={feedbackItems}
            alwaysFetchOnMount={
              !feedbackItems?.length || !CACHE_KEY.GENAI_FEEDBACK_ITEMS
            }
            onReceiveData={(data: GenAIFeedbackOption) => {
              if (data) {
                setFeedbackItems(data.options);
              }
            }}
            apiUrl="/genai/questions/dislike-options"
            cacheKey={CACHE_KEY.GENAI_FEEDBACK_ITEMS}
            loadingMessage="Loading OneViuAI feedback options..."
          >
            <div className="GenAIPanel__actions-options-header">
              <p className="GenAIPanel__actions-options-title">
                <ThumbsDownFilled /> Can you provide some feedback please:
              </p>
              {!activeItem && (
                <button
                  data-testid="genai-close-dislike-options"
                  className="GenAIPanel__actions-options-close"
                  onClick={() => handleDislikeOptionClick(null)}
                  disabled={isSavingFeedback}
                >
                  <Close />
                </button>
              )}
            </div>
            {activeItem ? (
              <>
                <div className="GenAIPanel__message">{activeItem}</div>
                <p className="GenAIPanel__actions-options-text">
                  Thank you for the feedback
                </p>
              </>
            ) : (
              feedbackItems?.length > 0 && (
                <div className="flex direction-column align-start gap--medium">
                  {feedbackItems.map((feedbackItem) => (
                    <button
                      key={feedbackItem}
                      className="GenAIPanel__button"
                      onClick={() => handleDislikeOptionClick(feedbackItem)}
                      disabled={isSavingFeedback}
                    >
                      {feedbackItem}
                    </button>
                  ))}
                </div>
              )
            )}
          </Fetch>
        </div>
      )}
    </div>
  );
};

export default GenAIAnswerActions;
