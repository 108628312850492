import { Outlet } from 'react-router-dom';
import { SecurityServices } from '@carbon/icons-react';
import IconTitle from '../IconTitle';
import '../../styles/components/adminPanel.scss';
import { useContext } from 'react';
import { AppContext } from '../../providers/AppProvider';
import NotFound from '../NotFound';

const AdminPanel = () => {
  const { showAdminControls } = useContext(AppContext);

  return showAdminControls ? (
    <>
      <IconTitle
        icon={<SecurityServices size={20} />}
        title="Admin Panel"
        kind="primary"
      />
      <Outlet />
    </>
  ) : (
    <NotFound />
  );
};

export default AdminPanel;
