import type { FunctionComponent, ReactNode } from 'react';
import { createContext, useMemo, useReducer } from 'react';

import {
  UPDATE_UPLOAD_STEP,
  UPDATE_UPLOAD_DATA,
  UPDATE_FILE_SPECS,
  UPDATE_FILTER_BY_CUSTOM_GROUP,
  UPDATE_UPLOAD_CUSTOM_GROUPS,
  UPDATE_HAS_ENTITY_ERRORS,
  UPDATE_EXISTING_CUSTOM_GROUPS,
  UPDATE_SELECTED_DIMENSION_GROUP,
  UPDATE_SELECTED_CUSTOM_GROUP_ID,
  RESET_UPLOAD_DATA,
} from '../constants/reducers';
import type {
  CustGroup,
  ExistingCustGroup,
  FileSpecs,
  CustomGroupsState,
  UploadData,
} from '../reducers/CustomGroupsReducer';
import {
  initialCustomGroupsState,
  customGroupsReducer,
} from '../reducers/CustomGroupsReducer';

export interface CustomGroupsContextType extends CustomGroupsState {
  updateUploadStep: (uploadStep: number) => void;
  updateUploadData: (uploadData: UploadData[]) => void;
  updateFileSpecs: (fileSpecs: FileSpecs[]) => void;
  updateFilterByCustGroup: (filterByCustGroup: string) => void;
  updateCustomGroups: (customGroups: CustGroup[]) => void;
  updateExistingCustGroups: (existingCustGroups: ExistingCustGroup[]) => void;
  updateHasEntityErrors: (hasEntityErrors: boolean) => void;
  updateSelectedDimGroup: (selectedDimGroup: string) => void;
  updateSelectedCustomGroupId: (selectedCustomGroupId: string) => void;
  resetUploadData: () => void;
}

interface CustomGroupsProviderProps {
  children?: ReactNode;
}

export const CustomGroupsContext =
  createContext<CustomGroupsContextType | null>(null);

const CustomGroupsProvider: FunctionComponent<CustomGroupsProviderProps> = ({
  children,
}) => {
  const [state, dispatch] = useReducer(
    customGroupsReducer,
    initialCustomGroupsState
  );

  const updateUploadStep = (uploadStep: number) =>
    dispatch({ type: UPDATE_UPLOAD_STEP, uploadStep });

  const updateUploadData = (uploadData: UploadData[]) =>
    dispatch({ type: UPDATE_UPLOAD_DATA, uploadData });

  const updateFileSpecs = (fileSpecs: FileSpecs[]) =>
    dispatch({ type: UPDATE_FILE_SPECS, fileSpecs });

  const updateFilterByCustGroup = (filterByCustGroup: string) =>
    dispatch({ type: UPDATE_FILTER_BY_CUSTOM_GROUP, filterByCustGroup });

  const updateCustomGroups = (customGroups: CustGroup[]) =>
    dispatch({ type: UPDATE_UPLOAD_CUSTOM_GROUPS, customGroups });

  const updateExistingCustGroups = (existingCustGroups: ExistingCustGroup[]) =>
    dispatch({ type: UPDATE_EXISTING_CUSTOM_GROUPS, existingCustGroups });

  const updateHasEntityErrors = (hasEntityErrors: boolean) =>
    dispatch({ type: UPDATE_HAS_ENTITY_ERRORS, hasEntityErrors });

  const updateSelectedDimGroup = (selectedDimGroup: string) =>
    dispatch({ type: UPDATE_SELECTED_DIMENSION_GROUP, selectedDimGroup });

  const updateSelectedCustomGroupId = (selectedCustomGroupId: string) =>
    dispatch({ type: UPDATE_SELECTED_CUSTOM_GROUP_ID, selectedCustomGroupId });

  const resetUploadData = () => dispatch({ type: RESET_UPLOAD_DATA });

  const initialCustomGroupsContext: CustomGroupsContextType = useMemo(() => {
    return {
      uploadStep: state.uploadStep,
      uploadData: state.uploadData,
      fileSpecs: state.fileSpecs,
      filterByCustGroup: state.filterByCustGroup,
      customGroups: state.customGroups,
      existingCustGroups: state.existingCustGroups,
      hasEntityErrors: state.hasEntityErrors,
      selectedDimGroup: state.selectedDimGroup,
      selectedCustomGroupId: state.selectedCustomGroupId,
      updateUploadStep,
      updateUploadData,
      updateFileSpecs,
      updateFilterByCustGroup,
      updateCustomGroups,
      updateHasEntityErrors,
      updateExistingCustGroups,
      updateSelectedDimGroup,
      updateSelectedCustomGroupId,
      resetUploadData,
    };
  }, [state]);

  return (
    <CustomGroupsContext.Provider value={initialCustomGroupsContext}>
      {children}
    </CustomGroupsContext.Provider>
  );
};

export default CustomGroupsProvider;
