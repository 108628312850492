import type { FunctionComponent } from 'react';
import { useContext, useEffect } from 'react';
import { ModalContext } from '../providers/ModalProvider';

const Fallback: FunctionComponent = () => {
  const { updateModal } = useContext(ModalContext);

  useEffect(() => {
    updateModal({
      type: 'error',
      title: 'Something went wrong',
      body: 'An unexpected error occurred, please refresh the browser and try again. If the issue persists please contact our helpdesk.',
    });
  }, []);
  return (
    <div data-testid="fallback-error">
      <div className="body-02">
        An unexpected error occurred, please refresh the browser and try again.
        If the issue persists please contact our helpdesk.
      </div>
    </div>
  );
};

export default Fallback;
