import type { FunctionComponent } from 'react';
import { useContext, useEffect, useState } from 'react';
import {
  Checkbox,
  Dropdown,
  Form,
  FormGroup,
  ModalBody,
  ModalHeader,
  Stack,
  Button,
  ComboBox,
  NumberInput,
  TextInput,
} from '@carbon/react';
import { AddAlt } from '@carbon/icons-react';
import {
  getProductsInCdGroup,
  getUniqueCDGroups,
  marginRateValid,
  nameAlreadyExists,
  newProductFormValid,
  pricePointValid,
} from '../../utils/CDTUtils';
import type { OptimiserContextType } from '../../providers/OptimiserFormProvider';
import '../../styles/components/newLinesForm.scss';
import { ModalContext } from '../../providers/ModalProvider';
import type {
  NewProduct,
  Performance,
} from '../../reducers/OptimiserFormReducer';
import { OptimiserMode } from '../../reducers/OptimiserFormReducer';
import { AppContext } from '../../providers/AppProvider';
import {
  assortmentOptimiserAddProduct,
  assortmentOptimiserForceInOutCheckbox,
} from '../../constants/posthog';
import usePosthog from '../../utils/posthog';

interface NewLinesFormProps {
  optimiserContext: OptimiserContextType;
  editProduct?: NewProduct;
}

const defaultNewProduct = {
  id: '0',
  name: '',
  performance: 'Medium' as Performance,
  price_point: 1,
  lookalike: { id: '', value: '' },
  cd1: { id: '', value: '' },
  cd2: { id: '', value: '' },
  cd3: { id: '', value: '' },
  locations: [],
  force_in: false,
};

const NewLinesForm: FunctionComponent<NewLinesFormProps> = ({
  optimiserContext,
  editProduct,
}) => {
  const { user } = useContext(AppContext);
  const { open, toggleModal } = useContext(ModalContext);
  const posthogEvent = usePosthog();
  const { formData, optimiserData, clusters, mode, updateNewProducts } =
    optimiserContext;
  const newProductTemplate: NewProduct = {
    ...defaultNewProduct,
    ...(user.show_extension_measure ? { margin_rate: 0.3 } : {}),
    locations: clusters.map(({ key }) => key),
  };
  const [newProduct, setNewProduct] = useState<NewProduct>(newProductTemplate);

  const totalLevelId = optimiserData[0] ? 0 : 1;

  const comparisonProducts = formData.newProducts.filter(
    (np) => np.id !== editProduct?.id
  );

  useEffect(() => {
    if (open) {
      setNewProduct(editProduct || newProductTemplate);
    }
  }, [open]);

  const {
    name,
    performance,
    price_point,
    margin_rate,
    cd1,
    cd2,
    cd3,
    force_in,
  } = newProduct;

  const lookalikeProducts = getProductsInCdGroup(optimiserData[totalLevelId], {
    cd1,
    cd2,
    cd3,
  }).map((prod) => {
    return {
      id: prod.PRODUCT_ID,
      value: prod.PRODUCT,
    };
  });

  const nameValid = (name: string) => {
    const trimmedName = name.trim();

    return (
      name.length === 0 ||
      (!nameAlreadyExists(trimmedName, comparisonProducts) &&
        trimmedName.length > 0)
    );
  };

  const closeModal = () => {
    toggleModal(false);
  };

  return (
    <>
      <ModalHeader buttonOnClick={closeModal} />
      <ModalBody>
        <Form className="NewLinesForm">
          <Stack gap={5}>
            <TextInput
              id="new-product-name"
              data-testid="new-product-name"
              labelText="New product"
              value={name}
              invalid={!nameValid(name)}
              invalidText="This name cannot be the same as an existing new product and must contain at least one character"
              placeholder="Enter a name for your product"
              onChange={({ target: { value: name } }) =>
                setNewProduct((existingProduct) => ({
                  ...existingProduct,
                  name,
                }))
              }
            />

            <ComboBox
              id="decision-group3-dropdown"
              data-testid="decision-group3-dropdown"
              selectedItem={cd3}
              titleText="Decision group"
              label="Decision level options"
              placeholder="Select a decision group from the list"
              items={getUniqueCDGroups(optimiserData[totalLevelId], 'cd3')}
              itemToString={(item) => (item ? item.value : '')}
              onChange={({ selectedItem }) => {
                setNewProduct((existingProduct) => ({
                  ...existingProduct,
                  cd3: selectedItem,
                  cd2: { id: '', value: '' },
                  cd1: { id: '', value: '' },
                  lookalike: { id: '', value: '' },
                }));
              }}
            />

            {cd3?.id && (
              <ComboBox
                id="decision-group2-dropdown"
                data-testid="decision-group2-dropdown"
                selectedItem={cd2}
                titleText=""
                label="Decision level options"
                placeholder="Select a decision group from the list"
                items={getUniqueCDGroups(optimiserData[totalLevelId], 'cd2', {
                  cd1,
                  cd2,
                  cd3,
                })}
                itemToString={(item) => (item ? item.value : '')}
                onChange={({ selectedItem }) => {
                  setNewProduct((existingProduct) => ({
                    ...existingProduct,
                    cd2: selectedItem,
                    cd1: { id: '', value: '' },
                    lookalike: { id: '', value: '' },
                  }));
                }}
              />
            )}

            {cd2?.id && (
              <ComboBox
                id="decision-group1-dropdown"
                data-testid="decision-group1-dropdown"
                selectedItem={cd1}
                titleText=""
                label="Decision level options"
                placeholder="Select a decision group from the list"
                items={getUniqueCDGroups(optimiserData[totalLevelId], 'cd1', {
                  cd1,
                  cd2,
                  cd3,
                })}
                itemToString={(item) => (item ? item.value : '')}
                onChange={({ selectedItem }) => {
                  setNewProduct((existingProduct) => ({
                    ...existingProduct,
                    cd1: selectedItem,
                    lookalike: { id: '', value: '' },
                  }));
                }}
              />
            )}

            {cd1?.id && (
              <Dropdown
                id="lookalike-product-dropdown"
                titleText="Lookalike product"
                items={lookalikeProducts}
                className="NewLinesForm__lookalike"
                label="Select a lookalike product"
                itemToString={(prod) =>
                  prod.value || 'Select a lookalike product'
                }
                selectedItem={newProduct.lookalike}
                onChange={({ selectedItem: { id, value } }) => {
                  setNewProduct((existingProduct) => ({
                    ...existingProduct,
                    lookalike: { id, value },
                  }));
                }}
              />
            )}
            <FormGroup
              legendText={
                user.show_extension_measure
                  ? 'Performance Level, Price Point & Margin Rate'
                  : 'Performance Level & Price Point'
              }
            >
              <div className="flex gap--large">
                <Dropdown
                  id="performance-level-dropdown"
                  data-testid="performance-level-dropdown"
                  className="NewLinesForm__performance"
                  selectedItem={performance}
                  titleText=""
                  direction="top"
                  label="Select Performance Level"
                  items={['High', 'Medium', 'Low']}
                  onChange={({ selectedItem: performance }) => {
                    setNewProduct((existingProduct) => ({
                      ...existingProduct,
                      performance,
                    }));
                  }}
                />

                <NumberInput
                  id="price-point"
                  data-testid="price-point-input"
                  className="NewLinesForm__price-point"
                  min={0}
                  max={100000}
                  hideSteppers
                  helperText="Please enter format $xx.xx"
                  value={price_point.toFixed(2)}
                  allowEmpty={false}
                  invalidText={pricePointValid(price_point).message}
                  invalid={!pricePointValid(price_point).valid}
                  onBlur={({ target: { value: pricePoint } }) => {
                    setNewProduct((existingProduct) => ({
                      ...existingProduct,
                      price_point: Number(pricePoint),
                    }));
                  }}
                />
                {!!user.show_extension_measure && (
                  <NumberInput
                    id="margin-rate"
                    data-testid="margin-rate-input"
                    className="NewLinesForm__margin-rate"
                    min={0}
                    max={100}
                    hideSteppers
                    helperText="Please enter format xx.xx%"
                    value={(margin_rate * 100).toFixed(2)}
                    allowEmpty={false}
                    invalidText="Margin rate is not valid"
                    invalid={
                      !marginRateValid(
                        margin_rate,
                        !!user.show_extension_measure
                      )
                    }
                    onBlur={({ target: { value: marginRate } }) => {
                      setNewProduct((existingProduct) => ({
                        ...existingProduct,
                        margin_rate: Number(
                          (Number(marginRate) / 100).toString()
                        ),
                      }));
                    }}
                  />
                )}
              </div>
            </FormGroup>
            {mode !== OptimiserMode.Plan && (
              <FormGroup legendText="Force in">
                <Checkbox
                  id="force-in-checkbox"
                  data-testid="force-in-checkbox"
                  labelText="Force this product in (This product will be forced in to all selected clusters but can be overridden later)"
                  checked={force_in}
                  onChange={(_, { checked: forceIn }) => {
                    setNewProduct((existingProduct) => ({
                      ...existingProduct,
                      force_in: forceIn,
                    }));
                  }}
                  onClick={() => {
                    posthogEvent(assortmentOptimiserForceInOutCheckbox, {
                      checked: force_in,
                    });
                  }}
                />
              </FormGroup>
            )}
            <Button
              className="has-icon"
              iconDescription="Add product"
              size="sm"
              renderIcon={AddAlt}
              disabled={
                !newProductFormValid(
                  newProduct,
                  comparisonProducts,
                  !!user.show_extension_measure
                )
              }
              onClick={() => {
                const ids = formData.newProducts.map((p) => Number(p.id));
                const newId =
                  editProduct?.id || (Math.max(...ids, 0) + 1).toString();
                const finalProduct: NewProduct = {
                  ...newProduct,
                  name: newProduct.name.trim(),
                  id: newId,
                };

                posthogEvent(assortmentOptimiserAddProduct, {
                  product: finalProduct.name,
                });
                const newProducts = formData.newProducts.map((np) => {
                  return np.id === editProduct?.id ? finalProduct : np;
                });

                if (!editProduct) {
                  newProducts.push(finalProduct);
                }
                updateNewProducts(newProducts);
                setNewProduct(newProductTemplate);
                closeModal();
              }}
            >
              {editProduct ? 'Update' : 'Add'} Product
            </Button>
          </Stack>
        </Form>
      </ModalBody>
    </>
  );
};

export default NewLinesForm;
