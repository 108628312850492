import type { FunctionComponent } from 'react';
import { Accordion, AccordionItem } from '@carbon/react';
import { advancedOptionsLabels } from '../../constants/metadata';
import { renderAdvOptionsValue } from './utils/reportBuilderUtils';

interface ParameterSummaryProps {
  settings: AnalyticEngineParams;
}

interface AdvancedSettingsValue {
  label: string;
  labelFormat?: NumberPoint;
  value: string | number | string[];
}

const SettingsSummary: FunctionComponent<ParameterSummaryProps> = ({
  settings,
}) => {
  const advancedOptionsList: AdvancedSettingsValue[] = Object.entries(
    settings
  ).reduce(
    (items, [key, value]) =>
      typeof value === 'string' ||
      typeof value === 'number' ||
      Array.isArray(value)
        ? [...items, { label: key, ...advancedOptionsLabels[key], value }]
        : items,
    [] as AdvancedSettingsValue[]
  );
  return (
    <Accordion>
      <AccordionItem title="Advanced Settings" open>
        <div className="SettingsSummary__wrapper">
          <ul
            className="SettingsSummary"
            data-testid="summary-parameter-wrapper"
          >
            {advancedOptionsList.map(({ label, value, labelFormat }) => (
              <li key={label}>
                <div className="SettingsSummary__type">
                  <span className="SettingsSummary__label">{label}: </span>
                  <span>
                    {renderAdvOptionsValue(label, value, labelFormat)}
                  </span>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </AccordionItem>
    </Accordion>
  );
};

export default SettingsSummary;
