import { useNavigate } from 'react-router-dom';
import { Button } from '@carbon/react';
import '../styles/components/notfound.scss';

const NotFound = () => {
  const navigate = useNavigate();

  return (
    <div className="not-found">
      <p>The requested page cannot be found. Try one of the links below.</p>
      <div>
        <Button onClick={() => navigate('/home')}>Home</Button>
        <Button onClick={() => navigate('/workspace/my-reports')}>
          Workspace
        </Button>
        <Button onClick={() => navigate('/modules')}>Modules</Button>
      </div>
    </div>
  );
};

export default NotFound;
