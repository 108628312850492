import type { FunctionComponent } from 'react';
import { useContext } from 'react';
import { Close } from '@carbon/icons-react';
import { SidePanelContext } from '../../providers/SidePanelProvider';
import '../../styles/components/side-panel.scss';

interface SidePanelHeaderProps {
  title: string;
  subtitle?: string;
}

const SidePanelHeader: FunctionComponent<SidePanelHeaderProps> = ({
  title,
  subtitle,
}) => {
  const { toggleSidePanelExpand } = useContext(SidePanelContext);
  return (
    <div className="SidePanelHeader">
      <div
        className={`SidePanelHeader__wrapper${
          !title && !subtitle ? '--hidden' : '--visible'
        }`}
      >
        {title && (
          <div className="SidePanelHeader__title-wrapper">
            <div className="SidePanelHeader__title">{title}</div>
            {subtitle && <div>{subtitle}</div>}
          </div>
        )}
        <div data-testid="side-panel-close-button">
          <Close
            size={24}
            className="hoverable clickable"
            onClick={() => toggleSidePanelExpand(false)}
          />
        </div>
      </div>
    </div>
  );
};

export default SidePanelHeader;
