import type { FunctionComponent } from 'react';
import {
  DataTableSkeleton,
  SkeletonPlaceholder,
  SkeletonText,
} from '@carbon/react';

interface LoadingSkeletonProps {
  type: string;
}

const LoadingSkeleton: FunctionComponent<LoadingSkeletonProps> = ({ type }) => {
  const renderloadingCards = (key: number) => (
    <div className="skeleton-loading" key={key}>
      <div className="loading-action-card-container">
        <SkeletonPlaceholder className={`loading-action-card ${type}`} />
        <SkeletonPlaceholder className={`loading-action-card ${type}`} />
        <SkeletonPlaceholder className={`loading-action-card ${type}`} />
        <SkeletonPlaceholder className={`loading-action-card ${type}`} />
      </div>
    </div>
  );
  return (
    <>
      <SkeletonText className="home-section-heading" />
      {[...Array(3)].map((_, i) => renderloadingCards(i))}
      <SkeletonText className="home-section-heading" />
      {type === 'home' && <DataTableSkeleton />}
    </>
  );
};

export default LoadingSkeleton;
