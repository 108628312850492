/* eslint-disable indent */
import type { FunctionComponent } from 'react';
import { useContext, useEffect, useState } from 'react';
import {
  EventSchedule,
  TrashCan,
  Share,
  Edit,
  ShareKnowledge,
  Star,
  StarFilled,
  ReportData,
  Replicate,
} from '@carbon/icons-react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { AppContext } from '../../providers/AppProvider';
import Fetch from '../Fetch';
import { REPORT_STATUS } from '../Workspace';
import apiRequest from '../../api';
import { formatUTCDate } from '../../utils/DateUtils';
import { CACHE_KEY } from '../../constants/api';
import '../../styles/components/workspace.scss';
import { SidePanelContext } from '../../providers/SidePanelProvider';
import ScheduleReport from '../ReportBuilder/ScheduleReport/ScheduleReport';
import {
  getScheduleOptionsPayload,
  getScheduleReportOptions,
} from '../ReportBuilder/utils/reportBuilderUtils';
import {
  cancelScheduledReportFailure,
  cancelScheduledReportSuccess,
  duplicateReportClick,
  deleteReportFailure,
  deleteReportSuccess,
  editReportClick,
  scheduleReportFailure,
  scheduleReportSuccess,
  shareReportClick,
  transferReportClick,
  addToFavouritesClick,
} from '../../constants/posthog';
import { ModalContext } from '../../providers/ModalProvider';
import ReportTable from './ReportTable';
import type { ActionOption, RowStatus } from './ReportTableRow';
import {
  getFilterParam,
  getReportTypes,
  isRowMatchCondition,
} from '../../utils/filterUtils';
import { WebsocketContext } from '../../providers/WebsocketProvider';
import { ReportFilterContext } from '../../providers/ReportFilterProvider';
import ShareReport from '../ShareReport/ShareReport';
import TransferReport from '../ShareReport/TransferReport';
import usePosthog from '../../utils/posthog';
import ReportTableFilters from './ReportTableFilters';
import { WebsocketAction } from '../../constants/websocketMessages';
import NoDataPlaceholder from './NoDataPlaceholder';
import {
  reportTableTabOptions,
  workspaceSharingTabs,
} from '../../constants/metadata';
import { CategoryTabs } from './CategoryTabs';
import { useDebounce } from '../../hooks';
import type { ReportMessage, UpdateReportMessage } from './types/websocket';

export interface CheckedReports {
  id: string;
  status?: string;
}

export type JustUpdatedStatus = 'Completed' | 'Failed';
export interface JustUpdated {
  [key: string]: JustUpdatedStatus;
}

export interface DeleteHandlerParameters {
  reports: CarbonSelectedRow[];
  isFirstHistoricScheduledReport?: boolean;
  callback?: () => void;
}

const headerData = [
  { key: 'report_name', header: 'Name' },
  { key: 'report_type', header: 'Type' },
  { key: 'end_time', header: 'Submitted' },
  { key: 'shared_with_users', header: 'Shared With' },
  { key: 'user_name', header: 'Owner' },
  { key: 'status', header: 'Status' },
  { key: 'schedule_data', header: 'Schedule' },
];

export enum WorkspaceTab {
  MyReports = 'my-reports',
  Favourites = 'favourites',
  Shared = 'shared',
  Scheduled = 'scheduled',
}

interface MyReportsTableProps {
  // TODO: String conversion is a temporary measure until the enum in fully implemented
  tab: `${WorkspaceTab}`;
}

const isCurrentTabReportData = (tab: WorkspaceTab, row: ReportMetaData) => {
  switch (tab) {
    case WorkspaceTab.MyReports:
      return true;

    case WorkspaceTab.Favourites:
      return row.is_favourite;

    case WorkspaceTab.Shared:
      return row.is_shared;

    case WorkspaceTab.Scheduled:
      return (
        row.schedule_data &&
        row.schedule_data.status === REPORT_STATUS.SCHEDULED
      );
  }
};

const MyReportsTable: FunctionComponent<MyReportsTableProps> = (props) => {
  // TODO: Temporary measure until the enum in fully implemented
  const tab = props.tab as WorkspaceTab;

  const { bannerId, groupId, user, queryCache, clearCacheForKey } =
    useContext(AppContext);
  const { renderSidePanelContent, toggleSidePanelExpand } =
    useContext(SidePanelContext);
  const { getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const posthogEvent = usePosthog();
  const { broadcastChannel } = useContext(WebsocketContext);
  const { updateModal, toggleModal } = useContext(ModalContext);
  const { tabs, changeTabFilter } = useContext(ReportFilterContext);
  const [allReports, setAllReports] = useState<ReportMetaData[]>(null);
  const [selectedReports, setSelectedReports] = useState<CheckedReports[]>([]);
  const [reportTypesOptions, setReportTypesOptions] = useState<string[] | null>(
    null
  );
  const [rowStatus, setRowStatus] = useState<RowStatus>(null);
  const [justUpdated, setJustUpdated] = useState<JustUpdated>({});
  const [isLoading, setIsLoading] = useState(true);
  const [deletedIds, setDeletedIds] = useState<string[]>([]);
  const deferredDeletedIds = useDebounce(deletedIds, 500);

  const pageConfig = reportTableTabOptions[tab];

  const activeFilters = tabs[pageConfig.tabKey];

  useEffect(() => {
    if (deferredDeletedIds.length === 0) {
      return;
    }
    setAllReports((reportsList) => {
      if (!reportsList) {
        return reportsList;
      }

      return reportsList.filter(
        ({ run_id: runId }) => !deferredDeletedIds.includes(runId)
      );
    });
    setDeletedIds((deletedIdsList) =>
      deletedIdsList.filter((runId) => !deferredDeletedIds.includes(runId))
    );
    setSelectedReports((selectedReportsList) =>
      selectedReportsList.filter(({ id }) => !deferredDeletedIds.includes(id))
    );
  }, [deferredDeletedIds]);

  useEffect(() => {
    if (selectedReports.length === 0) {
      setRowStatus(null);
    }
  }, [selectedReports]);

  const pushDeletedId = (id: string) => {
    setDeletedIds((deletedIdsList) => [...deletedIdsList, id]);
  };

  const updateReports = (data: UpdateReportMessage['data']) => {
    const { run_id, type, ...changedValues } = data;

    if (type === 'execution_status' || type === 'report_name') {
      setAllReports((reportsList) => {
        if (!reportsList) {
          return reportsList;
        }

        return reportsList.map((report) => {
          if (report.run_id !== run_id) {
            return report;
          }
          const endTime =
            changedValues?.end_time &&
            formatUTCDate(changedValues?.end_time, false);
          return { ...report, ...changedValues, end_time: endTime };
        });
      });
    }

    if (changedValues?.status) {
      setJustUpdated((existingJustUpdated) => ({
        ...existingJustUpdated,
        [run_id]:
          changedValues?.status === 'Completed' ? 'Completed' : 'Failed',
      }));
    }
  };

  useEffect(() => {
    const interval = setInterval(() => setJustUpdated({}), 3000);

    return () => {
      clearInterval(interval);
    };
  }, [justUpdated]);

  const currentTabReportData =
    allReports?.filter((row) => isCurrentTabReportData(tab, row)) ?? [];

  const filteredData =
    currentTabReportData?.filter((row) =>
      isRowMatchCondition(row, activeFilters, user.id)
    ) ?? [];

  const receiveReportData = (data: ReportMetaData[]) => {
    const reportTableData = data.map((row) => {
      const endDate = formatUTCDate(row.end_time, false);
      return {
        ...row,
        id: row.run_id,
        end_time: row.end_time ? endDate : 'Pending',
        end_time_formatted: row.end_time,
      };
    });
    const reportTypes = getReportTypes(reportTableData);
    setAllReports(reportTableData);
    setReportTypesOptions(reportTypes);
    setRowStatus(null);
    setSelectedReports([]);
    setIsLoading(false);
  };

  const onMessage = (event: MessageEvent) => {
    const response: ReportMessage = JSON.parse(event.data);

    const { action } = response;
    switch (action) {
      case WebsocketAction.Refresh: {
        clearCacheForKey(CACHE_KEY.REPORTS);
        break;
      }
      case WebsocketAction.Update: {
        updateReports(response.data);
        break;
      }
      case WebsocketAction.Delete: {
        const { run_id } = response.data;
        pushDeletedId(run_id);
        break;
      }
    }
  };

  useEffect(() => {
    if (broadcastChannel) {
      broadcastChannel.addEventListener('message', onMessage);
    }

    return () => broadcastChannel.removeEventListener('message', onMessage);
  }, [broadcastChannel, currentTabReportData]);

  const deleteReport = async (
    checkedReports: CheckedReports[],
    callback?: () => void
  ) => {
    const hasInProgressReport = checkedReports.some(
      ({ status }) => status === REPORT_STATUS.PROCESSING
    );
    const itemPlural = checkedReports.length > 1 ? 's' : '';
    if (hasInProgressReport) {
      updateModal({
        type: 'info',
        title: 'Unable to delete',
        body: `You are unable to delete the report${itemPlural} as a report is still in progress`,
      });
      return;
    }
    setRowStatus('Deleting');
    toggleModal(false);
    try {
      const token = await getAccessTokenSilently();
      await apiRequest(
        '/reports?action=archive',
        'POST',
        token,
        JSON.stringify({ run_ids: checkedReports.map(({ id }) => id) })
      );
      callback?.();
      posthogEvent(deleteReportSuccess, {
        origin: pageConfig.posthogEventOrigin,
        deletedReports: checkedReports.length,
      });
    } catch (error) {
      updateModal({
        type: 'error',
        title: 'Something went wrong',
        body: `There was an issue deleting your report${itemPlural}. Please try again.`,
      });
      posthogEvent(deleteReportFailure, {
        origin: pageConfig.posthogEventOrigin,
        deletedReports: checkedReports.length,
      });
      setRowStatus(null);
    }
  };

  const handleDelete = ({
    reports,
    isFirstHistoricScheduledReport,
    callback,
  }: DeleteHandlerParameters) => {
    const selected = reports.map(({ id, cells }) => {
      const status = cells.find((cell) => cell.id.match('status')).value;
      return { id, status };
    });
    const itemPlural = reports.length > 1 ? 's' : '';
    const isShared = reports.some(({ id }) => {
      const reportData = filteredData.find((report) => report.run_id === id);
      return reportData && reportData.user_id !== user.id;
    });
    setSelectedReports(selected);
    if (isShared) {
      updateModal({
        type: 'warning',
        title: 'Reports to be deleted',
        body: 'Are you sure you want to delete this report? The deletion will only affect your access to the report, as the owner will retain their access. Only the report owner has the authority to completely remove a report.',
        primaryCTAText: 'Continue',
        secondaryCTAText: 'Cancel',
        onPrimaryCTAClick: () => deleteReport(selected),
      });
    } else {
      const reportCopy = {
        title: 'Reports to be deleted',
        body: (
          <>
            You have selected{' '}
            <strong>
              {selected.length} report{itemPlural}
            </strong>{' '}
            to be deleted. Do you want to continue?
          </>
        ),
        primaryCTAText: 'Continue',
      };

      const reportName = reports[0].cells.find(
        ({ id }) => id === 'report_name'
      )?.value;
      const firstHistoricScheduledReportCopy = {
        title: `Are you sure you want to delete the scheduled report ${reportName}?`,
        body: 'Please be aware that deleting this schedued report will also remove all saved versions of the report.',
        primaryCTAText: 'Delete',
      };

      updateModal({
        ...(isFirstHistoricScheduledReport
          ? firstHistoricScheduledReportCopy
          : reportCopy),
        type: 'warning',
        secondaryCTAText: 'Cancel',
        onPrimaryCTAClick: () => deleteReport(selected, callback),
      });
    }
  };

  const handleEdit = (row: CarbonSelectedRow) => {
    const report = currentTabReportData.find((report) => report.id === row.id);
    posthogEvent(editReportClick, {
      origin: pageConfig.posthogEventOrigin,
      id: row.id,
      reportType: report.url_route,
    });
    const rowId = row.id;
    navigate(`/modules/${report.section}/${report.url_route}?edit=${rowId}`);
  };

  const handleDuplicate = (row: CarbonSelectedRow) => {
    const report = currentTabReportData.find((report) => report.id === row.id);
    posthogEvent(duplicateReportClick, {
      origin: pageConfig.posthogEventOrigin,
      id: row.id,
      reportType: report.url_route,
    });
    const rowId = row.id;
    navigate(
      `/modules/${report.section}/${report.url_route}?duplicate=${rowId}`
    );
  };

  const sendScheduleRequest = async (payload, method, callback?) => {
    try {
      const token = await getAccessTokenSilently();
      await apiRequest('/schedules', method, token, payload);
      updateModal({
        type: 'success',
        title: 'Scheduling successful',
        body: "Your report has been successfully scheduled. Check back here on or after the selected date to view the report's status",
        onPrimaryCTAClick: () => {
          toggleSidePanelExpand(false);
          toggleModal(false);
        },
      });
      const scheduledReports = payload.run_ids ? payload.run_ids : [payload.id];
      posthogEvent(scheduleReportSuccess, {
        origin: pageConfig.posthogEventOrigin,
        scheduledReports: scheduledReports.length,
      });
    } catch (error) {
      updateModal({
        type: 'error',
        title: 'Something went wrong',
        body: 'There was an issue scheduling your report. Please try again.',
      });
      const scheduledReports = payload.run_ids ? payload.run_ids : [payload.id];
      posthogEvent(scheduleReportFailure, {
        origin: pageConfig.posthogEventOrigin,
        scheduledReports: scheduledReports.length,
      });
    } finally {
      callback?.();
      clearCacheForKey(CACHE_KEY.REPORTS);
    }
  };

  const handleSaveReport = async (
    scheduleOptions: ScheduleReportOptions,
    ids: string[],
    callback: () => void
  ) => {
    const schedulePayload = getScheduleOptionsPayload(scheduleOptions);
    const payload = {
      run_ids: ids,
      schedule: schedulePayload,
    };
    sendScheduleRequest(payload, 'POST', callback);
  };

  const handleScheduleReport = (rows: CarbonSelectedRow[]) => {
    const hasScheduledReport = rows.some(({ cells }) =>
      cells.some(
        (cell) =>
          typeof cell.value === 'object' && cell.value.status === 'Scheduled'
      )
    );

    const hasCancelledReport = rows.some(({ cells }) =>
      cells.some(
        (cell) =>
          typeof cell.value === 'object' && cell.value.status === 'Cancelled'
      )
    );

    const hasFailedReport = rows.some((row) => {
      const status = row.cells.find(
        (cell) => cell.info.header === 'status'
      )?.value;
      return (
        status === REPORT_STATUS.FAILED ||
        status === REPORT_STATUS.CANCELLED ||
        status === REPORT_STATUS.NO_DATA
      );
    });

    const message = () => {
      if (hasFailedReport) {
        return 'You are unable to set a schedule as one or more of the reports selected has a failed status';
      }
      if (hasCancelledReport) {
        return 'You are unable to set a schedule as one or more of the reports selected has had a previous schedule cancelled';
      }
      if (hasScheduledReport) {
        return 'You are unable to set a schedule as one or more of the reports is already scheduled';
      }
      return null;
    };
    if (message()) {
      updateModal({
        type: 'warning',
        title: 'Unable to schedule',
        body: message(),
      });
    } else {
      renderSidePanelContent(
        <ScheduleReport
          onSave={(options, callback) =>
            handleSaveReport(
              options,
              rows.map(({ id }) => id),
              callback
            )
          }
        />,
        { title: 'Schedule Report' },
        false
      );
    }
  };

  const handleUpdateSchedule = (
    scheduleOptions: ScheduleReportOptions,
    id: string,
    callback: () => void
  ) => {
    const schedulePayload = getScheduleOptionsPayload(scheduleOptions);
    const payload = {
      id,
      schedule: schedulePayload,
    };
    sendScheduleRequest(payload, 'PATCH', callback);
  };

  const cancelSchedule = async (row: CarbonSelectedRow) => {
    setRowStatus('Cancelling');
    toggleSidePanelExpand(false);
    try {
      const token = await getAccessTokenSilently();
      await apiRequest('/schedules', 'DELETE', token, { run_ids: [row.id] });
      updateModal({
        type: 'success',
        title: 'Report cancelled',
        body: 'Your schedule has been successfully cancelled.',
      });
      posthogEvent(cancelScheduledReportSuccess, {
        origin: pageConfig.posthogEventOrigin,
      });
    } catch (error) {
      updateModal({
        type: 'error',
        title: 'Something went wrong',
        body: 'There was an issue cancelling this scheduled report. Please try again.',
      });
      posthogEvent(cancelScheduledReportFailure, {
        origin: pageConfig.posthogEventOrigin,
      });
    } finally {
      clearCacheForKey(CACHE_KEY.REPORTS);
      clearCacheForKey(`${CACHE_KEY.REPORT}-${row.id}`);
      setSelectedReports([]);
    }
  };

  const handleCancelSchedule = (row: CarbonSelectedRow) => {
    updateModal({
      type: 'warning',
      title: 'Schedule report to be cancelled',
      body: (
        <>
          You have selected <strong>1 report</strong> to be cancelled. Do you
          want to continue?
        </>
      ),
      onPrimaryCTAClick: () => {
        setSelectedReports([
          {
            id: row.id,
            status: row.cells.find(({ info }) => info.header === 'status')
              .value,
          },
        ]);
        cancelSchedule(row);
        toggleModal(false);
      },
      primaryCTAText: 'Continue',
      secondaryCTAText: 'Cancel',
    });
  };

  const handleUpdateScheduleReport = (row: CarbonSelectedRow) => {
    renderSidePanelContent(
      <Fetch
        apiUrl={`/reports/${bannerId}/user-groups/${groupId}/data?run_id=${row.id}`}
        initialData={null}
        cacheKey={`${CACHE_KEY.REPORT}-${row.id}`}
        loadingMessage="Loading report metadata..."
        alwaysFetchOnMount
        onReceiveData={(data) => {
          if (data) {
            const options = getScheduleReportOptions(
              data[0].schedule_info.schedule
            );
            const report = currentTabReportData.find(
              (r) => r.run_id === row.id
            );
            renderSidePanelContent(
              <ScheduleReport
                initialOptions={options}
                onCancel={() => handleCancelSchedule(row)}
                onSave={(options, onSaveCallback) =>
                  handleUpdateSchedule(
                    options,
                    data[0].schedule_info.schedule_id,
                    () => {
                      clearCacheForKey(`${CACHE_KEY.REPORT}-${row.id}`);
                      onSaveCallback();
                    }
                  )
                }
              />,
              {
                title: 'Schedule Report',
                subtitle: report.report_name,
              },
              false
            );
          }
        }}
      />,
      null,
      false
    );
  };

  const sendFavouritesRequest = async (row, method) => {
    setSelectedReports([{ id: row.id }]);
    try {
      const token = await getAccessTokenSilently();
      const action =
        method === 'PUT' ? 'Adding to Favourites' : 'Removing from Favourites';
      setRowStatus(action);
      await apiRequest(
        `/users/${encodeURIComponent(user.id)}/reports/favourites`,
        method,
        token,
        {
          run_ids: [row.id],
        }
      );
      const report = currentTabReportData.find((r) => r.run_id === row.id);
      posthogEvent(addToFavouritesClick, {
        reportType: report.url_route,
        type: action,
      });
    } catch (error) {
      updateModal({
        type: 'error',
        title: 'Something went wrong',
        body: 'There was an issue adding your report to favourites. Please try again.',
      });
    } finally {
      clearCacheForKey(CACHE_KEY.REPORTS);
    }
  };

  const hasId = (rowId: string) =>
    selectedReports.some(({ id }) => id === rowId);

  const handleTransferReports = (rows: CarbonSelectedRow[]) => {
    const run_ids = rows.map((r) => r.id);
    const reports = currentTabReportData.filter((report) =>
      run_ids.includes(report.run_id)
    );
    posthogEvent(transferReportClick, {
      origin: pageConfig.posthogEventOrigin,
      reportType: reports.map(({ url_route }) => url_route),
    });
    const itemPlural = reports.length === 1 ? '' : 's';
    renderSidePanelContent(
      <TransferReport
        reports={reports}
        groupId={groupId}
        bannerId={bannerId}
      />,
      {
        title: `Transfer report${itemPlural}`,
        subtitle: `Transfer ownership of selected report${itemPlural}`,
      },
      true
    );
  };

  const toolbarActions = ({
    isSharedReportSelected,
  }: {
    isSharedReportSelected?: boolean;
  }) =>
    [
      {
        renderIcon: TrashCan,
        onClick: (rows, callback) => handleDelete({ reports: rows, callback }),
        dataTestid: 'report-table-delete-btn',
        label: 'Delete',
        hidden: false,
      },
      {
        renderIcon: EventSchedule,
        onClick: handleScheduleReport,
        dataTestid: 'report-table-schedule-btn',
        label: 'Schedule',
        hidden: isSharedReportSelected,
      },
    ].filter(({ hidden }) => !hidden);

  const handleShareReport = (row) => {
    const report = currentTabReportData.find((r) => r.run_id === row.id);
    posthogEvent(shareReportClick, {
      origin: pageConfig.posthogEventOrigin,
      reportType: report.url_route,
    });
    renderSidePanelContent(
      <ShareReport
        key={report.run_id}
        report={report}
        bannerId={bannerId}
        groupId={groupId}
      />,
      {
        title: 'Share report',
        subtitle: report.report_name,
      },

      true
    );
  };

  const actionOptions = ({
    scheduledStatus,
    isScheduleOptionDisabled,
    isAuthor,
    isFavourite,
  }: {
    scheduledStatus?: string;
    isScheduleOptionDisabled: boolean;
    isAuthor: boolean;
    isFavourite: boolean;
  }): ActionOption[] => {
    const scheduleAction = !scheduledStatus
      ? {
          dataTestid: (id) => `schedule-${id}`,
          itemText: 'Schedule',
          hidden: isScheduleOptionDisabled || !isAuthor,
          onClick: (row) => handleScheduleReport([row]),
          isScheduled: !scheduledStatus,
          icon: EventSchedule,
        }
      : {
          dataTestid: (id) => `update-${id}`,
          itemText: 'Update schedule',
          onClick: (row) => handleUpdateScheduleReport(row),
          isScheduled: scheduledStatus === 'Scheduled',
          hidden: !isAuthor || scheduledStatus === 'Cancelled',
          icon: EventSchedule,
        };

    const favouriteAction = !isFavourite
      ? {
          dataTestid: (id) => `favourite-${id}`,
          itemText: 'Add to Favourites',
          onClick: (row) => sendFavouritesRequest(row, 'PUT'),
          icon: Star,
        }
      : {
          dataTestid: (id) => `unfavourite-${id}`,
          onClick: (row) => sendFavouritesRequest(row, 'DELETE'),
          itemText: 'Remove From Favourites',
          iconClassName: 'icon-filled',
          icon: StarFilled,
        };

    return [
      favouriteAction,
      {
        dataTestid: (id) => `edit-${id}`,
        itemText: 'Edit',
        onClick: (row) => handleEdit(row),
        icon: Edit,
        hidden: !isAuthor || scheduledStatus === REPORT_STATUS.SCHEDULED,
      },
      {
        dataTestid: (id) => `delete-${id}`,
        itemText: 'Delete',
        hidden: rowStatus === 'Deleting',
        onClick: (row) => handleDelete({ reports: [row] }),
        icon: TrashCan,
      },
      {
        dataTestid: (id) => `duplicate-${id}`,
        itemText: 'Duplicate',
        onClick: (row) => handleDuplicate(row),
        icon: Replicate,
      },
      {
        dataTestid: (id) => `share-${id}`,
        itemText: 'Share',
        onClick: (row) => handleShareReport(row),
        hidden: !isAuthor,
        icon: Share,
      },
      scheduleAction,
      {
        dataTestid: (id) => `transfer-${id}`,
        itemText: 'Transfer Ownership',
        onClick: (row) => handleTransferReports([row]),
        hidden: !isAuthor,
        icon: ShareKnowledge,
      },
    ];
  };

  const getHeaderData = () => {
    if (pageConfig.tabKey === 'shared') {
      return activeFilters.sharing === 'my-shares'
        ? headerData.filter((item) => item.key !== 'user_name')
        : headerData.filter(
            (item) => !['shared_with_users', 'schedule_data'].includes(item.key)
          );
    } else {
      return headerData.filter((item) => item.key !== 'shared_with_users');
    }
  };

  return (
    <>
      <Fetch
        apiUrl={`/reports/${bannerId}/user-groups/${groupId}/data?filter=workspace`}
        cacheKey={CACHE_KEY.REPORTS}
        initialData={null}
        alwaysFetchOnMount={!allReports}
        hideChildrenUntilFetched={!queryCache[CACHE_KEY.REPORTS] && !allReports}
        loadingMessage="Loading reports..."
        requestCanBeAborted
        onReceiveData={(data) => {
          if (data) {
            receiveReportData(data);
          }
        }}
      >
        {currentTabReportData.length > 0 && (
          <>
            {tab === WorkspaceTab.Shared && (
              <CategoryTabs
                label="List of sharing categories"
                tabs={workspaceSharingTabs}
                activeKey={activeFilters.sharing}
                onChange={(key) => {
                  changeTabFilter(pageConfig.tabKey, 'sharing', key);

                  setSearchParams({
                    ...Object.fromEntries(searchParams),
                    sharing: getFilterParam('sharing', key),
                  });
                }}
              />
            )}
            <ReportTableFilters
              reportTypesOptions={reportTypesOptions}
              rowCount={currentTabReportData.length}
              tabKey={pageConfig.tabKey}
              isTableEmpty={filteredData.length === 0}
              isStatusFilterHidden={tab === WorkspaceTab.Scheduled}
            />
            <ReportTable
              headerData={getHeaderData()}
              rowData={currentTabReportData}
              filteredData={filteredData}
              tableId="report-table-container"
              toolbarActions={toolbarActions}
              actionOptions={actionOptions}
              rowStatus={rowStatus}
              hasId={hasId}
              handleDelete={handleDelete}
              isEditable
              justUpdated={justUpdated}
              title="Number of reports requested"
              handleUpdateScheduleReport={handleUpdateScheduleReport}
            />
          </>
        )}
        {currentTabReportData.length === 0 && !isLoading && (
          <NoDataPlaceholder
            title={pageConfig.emptyNotification}
            icon={pageConfig.icon}
            description={{
              info: pageConfig.emptyMessage,
            }}
            buttonLabel={pageConfig.buttonText}
            onClick={() => navigate(pageConfig.navigateTo)}
            buttonIcon={ReportData}
          />
        )}
      </Fetch>
    </>
  );
};

export default MyReportsTable;
