/* eslint-disable indent */

import { type ComponentProps, type FunctionComponent, useMemo } from 'react';
import { useContext, useEffect, useState } from 'react';
// TODO - assortment optimiser - uncomment
import { /*ChartCustom,*/ CenterCircle, Product } from '@carbon/icons-react';
import { OptimiserFormContext } from '../../providers/OptimiserFormProvider';
import FormStepper from '../FormStepper/FormStepper';
// TODO - assortment optimiser - uncomment
// import RangeStrategy from './RangeStrategy';
import NewProducts from './NewProducts';
import ClusterGoals from './ClusterGoals';
import PlanTargets from './PlanTargets';
import { useAuth0 } from '@auth0/auth0-react';
import apiRequest from '../../api';
import { ModalContext } from '../../providers/ModalProvider';
import { useNavigate, useParams } from 'react-router';
import { AppContext } from '../../providers/AppProvider';
import { OPTIMISER_TAB_INDEX } from '../../constants/values';
import { ProgressBar } from '@carbon/react';
import { WebsocketContext } from '../../providers/WebsocketProvider';
import { OPTIMISER_STEP } from '../../providers/AssortmentProvider';
import type {
  LocationPlanTargetRow,
  PlanTargetRow,
} from '../../reducers/OptimiserFormReducer';
import {
  OptimiserMode,
  OptimiserStrategy,
} from '../../reducers/OptimiserFormReducer';
import { pick } from '../../utils/object';
import {
  getClusterFilters,
  getPlanFilters,
  isSkuTargetValid,
} from '../../utils/CDTUtils';
import usePosthog from '../../utils/posthog';
import { assortmentOptimiserDataSubmit } from '../../constants/posthog';


export interface UpdatePayload {
  readonly action: 'UPDATE';
  readonly data: {
    readonly type: 'process_step';
    readonly message?: string;
    readonly progress: number;
    readonly run_id: string;
    readonly next_step?: string;
    readonly error?: unknown;
    readonly complete?: true;
  };
}

interface SubmittingState {
  progress: number;
  message: string;
  status: 'active' | 'error';
}

const OptimiserForm: FunctionComponent = () => {
  const { bannerId, groupId } = useContext(AppContext);
  const { getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();
  const { updateModal } = useContext(ModalContext);
  const {
    cdtPath,
    mode,
    formData,
    clusters,
    optimiserData,
    updateClusterGoals,
  } = useContext(OptimiserFormContext);
  const posthogEvent = usePosthog();
  const { strategy, newProducts, clusterGoals, planTargets } = formData;
  const { reportId } = useParams();
  const { broadcastChannel } = useContext(WebsocketContext);
  const [submitting, setSubmitting] = useState<SubmittingState>(null);

  const cdtPathPayload = {
    path: cdtPath,
  };

  const filtersPayload =
    mode === OptimiserMode.NonPlan
      ? getClusterFilters(clusterGoals)
      : getPlanFilters(planTargets);

  const optimiserPayload = {
    ...cdtPathPayload,
    ...filtersPayload,
    ...(mode === OptimiserMode.Plan && { mode: 'plan' }),
    strategy,
  };

  const aggregatePlansPayload = {
    ...cdtPathPayload,
    payload: planTargets?.rows.map(
      (row: PlanTargetRow | LocationPlanTargetRow) =>
        pick(row, ['id', 'include', 'referencePlanogramNo', 'skuTarget'])
    ),
  };

  const payloads = {
    [OPTIMISER_STEP.OPTIMISE]: optimiserPayload,
    [OPTIMISER_STEP.PRODUCT_COUNTS]: cdtPathPayload,
    // Non-Plan Based
    [OPTIMISER_STEP.IMPACT_ANALYSIS]: cdtPathPayload,
    // Plan Based
    [OPTIMISER_STEP.AGGREGATE_PLANS]: aggregatePlansPayload,
  };

  const isSkuTargetInvalid: boolean = useMemo(
    () => !!planTargets?.rows.find((row) => !isSkuTargetValid(row.skuTarget)),
    [planTargets]
  );

  const showErrorModal = () => {
    updateModal({
      type: 'error',
      title: 'Something went wrong',
      body: 'There was an error in the optimisation request. Please try again. If the issue persists, please contact the helpdesk.',
    });
  };

  const submitForm = async () => {
    setSubmitting({
      progress: 10,
      message: 'Updating new products...',
      status: 'active',
    });

    try {
      const payload = {
        path: cdtPath,
        payload: { new_products: newProducts },
      };

      const token = await getAccessTokenSilently();
      apiRequest('/assortments/new-lines', 'POST', token, payload);
    } catch {
      showErrorModal();
      setSubmitting(null);
    }
  };

  useEffect(() => {
    updateClusterGoals(
      clusterGoals.map((clusterGoal) => {
        const { key } = clusterGoal;
        const maxProducts =
          optimiserData[key].length +
          newProducts.filter(
            (prod) =>
              prod.locations.includes(Number(key)) ||
              prod.locations.length === 0
          ).length;

        return {
          ...clusterGoal,
          max: maxProducts,
        };
      })
    );
  }, [formData.newProducts]);

  const strategyValid = () => {
    return [OptimiserStrategy.Breadth, OptimiserStrategy.Depth].includes(
      strategy
    );
  };

  const newProductsValid = () =>
    clusters.length === 1 ||
    newProducts.every((prod) => prod.locations.length > 0);

  const clusterGoalsValid = () =>
    clusterGoals.every((cg) => cg.goal >= 0 && cg.goal <= cg.max);

  const formValid =
    strategyValid() && newProductsValid() && clusterGoalsValid();

  const onMessage = async (event: MessageEvent) => {
    const token = await getAccessTokenSilently();
    const response = JSON.parse(event.data);

    if (
      !submitting ||
      response.action !== 'UPDATE' ||
      response.data.type !== 'process_step'
    ) {
      return;
    }

    const {
      data: {
        run_id: runId,
        progress,
        message,
        next_step: nextStep,
        complete,
        error,
      },
    } = response as UpdatePayload;

    if (runId !== reportId) {
      return;
    }

    setSubmitting({
      progress,
      message,
      status: error ? 'error' : 'active',
    });

    if (error) {
      showErrorModal();

      return;
    }

    if (nextStep) {
      apiRequest(`/assortments/${nextStep}`, 'POST', token, payloads[nextStep]);
    }

    if (complete) {
      posthogEvent(assortmentOptimiserDataSubmit);
      navigate(
        `/workspace/view-report/${reportId}?b=${bannerId}&ug=${groupId}&tabIndex=${OPTIMISER_TAB_INDEX}`
      );
    }
  };

  useEffect(() => {
    if (broadcastChannel) {
      broadcastChannel.addEventListener('message', onMessage);
    }

    return () => broadcastChannel.removeEventListener('message', onMessage);
  }, [broadcastChannel, payloads]);

  if (submitting) {
    return (
      <div className="AssortmentLoader">
        <div className="heading-04">Optimising your range</div>
        <div className="body-02">This may take up to 1 minute.</div>
        <ProgressBar
          label="Optimisation progress"
          hideLabel
          helperText={submitting.message}
          value={submitting.progress}
          status={submitting.status}
        />
      </div>
    );
  }

  const steps: ComponentProps<typeof FormStepper>['steps'] =
    mode === OptimiserMode.NonPlan
      ? [
          // TODO - assortment optimiser - uncomment
          // {
          //   label: '1. Set Global Ranging Strategy',
          //   title: 'Global Ranging Strategy',
          //   component: <RangeStrategy />,
          //   icon: <ChartCustom />,
          //   isValid: strategyValid(),
          // },
          {
            label: '1. Add New Products (optional)',
            title: 'New Products',
            component: <NewProducts />,
            icon: <Product />,
            nextDisabled: !newProductsValid(),
            isValid: newProductsValid(),
          },
          {
            label: '2. Set Cluster Goals',
            title: 'Cluster Goals',
            component: <ClusterGoals />,
            icon: <CenterCircle />,
            isSubmitStep: true,
            isValid: clusterGoalsValid(),
          },
        ]
      : [
          {
            label: '1. Review & Enter Plan Targets',
            title: 'Plan Targets',
            component: <PlanTargets />,
            icon: <CenterCircle />,
            nextDisabled: false,
            isValid: !isSkuTargetInvalid,
          },
          {
            label: '2. Add New Products (optional)',
            title: 'New Products',
            component: <NewProducts />,
            icon: <Product />,
            isSubmitStep: true,
            isValid: newProductsValid(),
          },
          // TODO - assortment optimiser - uncomment
          // {
          //   label: '3. Set Global Ranging Strategy',
          //   title: 'Global Ranging Strategy',
          //   component: <RangeStrategy />,
          //   icon: <ChartCustom />,
          //   isValid: strategyValid(),
          // }
        ];

  return (
    <FormStepper
      steps={steps}
      isFormValid={formValid}
      submitting={!!submitting}
      onSubmit={submitForm}
      submitText="Create report"
    />
  );
};

export default OptimiserForm;
