import type { FunctionComponent } from 'react';
import { InlineNotification, Toggle } from '@carbon/react';
import { Information } from '@carbon/icons-react';
import ParameterBlock from './ParameterBlock';
import { datesSameDuration } from './utils/reportBuilderUtils';
import type { HierarchyOverlap } from './ReportBuilder';
import Tooltip from '../Tooltip';

interface ParameterCollectionProps {
  editParameter: (
    parameterKey: string,
    parameter: ReportParameter,
    index: number
  ) => void;
  isEditable?: boolean;
  parameters: ReportParameter[];
  parameterKey: string;
  onDelete: (parameter: ReportParameter) => void;
  showSubmit?: boolean;
  isSummary?: boolean;
  pinGroup?: (parameters: ReportParameter[]) => Promise<void>;
  deleteFavourite?: (
    type: 'group' | 'item',
    name: string,
    parentName?: string
  ) => Promise<void>;
  favouriteGroups?: FavouriteGroup;
  isPinIconHidden?: boolean;
  overlap?: HierarchyOverlap;
  isFilters?: boolean;
}

const showDateWarning = (params, paramKey: string) =>
  paramKey.includes('time') && params && !datesSameDuration(params);

const ParameterCollection: FunctionComponent<ParameterCollectionProps> = ({
  editParameter,
  isEditable,
  onDelete,
  parameters,
  parameterKey,
  showSubmit,
  overlap,
  pinGroup,
  deleteFavourite,
  favouriteGroups,
  isPinIconHidden,
  isFilters,
}) => {
  const dimension = parameterKey.split('_')[0];
  const getParameterPinnedGroup = (parameter: ReportParameter) => {
    const itemIds = parameter.items?.map(({ id }) => id);
    const pinnedParameter = favouriteGroups?.favourite_groups.find(
      ({ group_items }) => {
        return group_items.some(
          ({ item_ids, item_name }) =>
            item_name === parameter.name &&
            item_ids.every((id) => itemIds.includes(id))
        );
      }
    );
    return pinnedParameter;
  };

  const handleDeleteFavourite = async (
    name: string,
    parentGroup: {
      group_name: string;
      group_items: {
        item_name: string;
        item_ids: string[];
      }[];
    }
  ) => {
    if (parentGroup.group_items.length === 1) {
      await deleteFavourite('group', parentGroup.group_name);
    } else {
      await deleteFavourite('item', name, parentGroup.group_name);
    }
  };

  return (
    <div className="parameter-collection">
      <div>
        {parameters?.map((parameter, index) => {
          return (
            <div
              key={parameter.name + '-' + index}
              className="flex gap--medium"
            >
              <ParameterBlock
                isEditable={isEditable}
                showSubmit={showSubmit}
                parameter={parameter}
                isParameterPinned={!!getParameterPinnedGroup(parameter)}
                onEdit={(parameter) =>
                  editParameter(parameterKey, parameter, index)
                }
                onDelete={onDelete}
                pinGroup={pinGroup}
                deleteFavourite={(name) =>
                  handleDeleteFavourite(
                    name,
                    getParameterPinnedGroup(parameter)
                  )
                }
                isPinIconHidden={
                  isPinIconHidden ||
                  parameter.hierarchy_element === 'basket_amt'
                }
              />
              {isFilters && (
                <div className="ParameterBlock__toggle">
                  <Toggle
                    toggled={!parameter.exclude}
                    onToggle={(checked) => {
                      editParameter(
                        parameterKey,
                        { ...parameter, exclude: !checked },
                        index
                      );
                    }}
                    defaultToggled
                    labelText=""
                    size="sm"
                    id={`${parameter.name}-toggle-${index}`}
                    data-testid={`${parameter.name}-toggle-${index}`}
                    labelA="Exclude"
                    labelB="Include"
                  />
                  <div className="ParameterBlock__toggle-info-tooltip">
                    <Tooltip
                      align="bottom-right"
                      description={
                        parameter.exclude
                          ? 'Item selected will be excluded from results'
                          : 'Item selected will be included as a filter'
                      }
                      useButtonWrapper
                    >
                      <Information size={13} />
                    </Tooltip>
                  </div>
                </div>
              )}
            </div>
          );
        })}
      </div>

      {(overlap?.[dimension]?.hasOverlap ||
        overlap?.[`${dimension}_filters`]?.hasFiltersOverlap) && (
        <InlineNotification
          subtitle={
            overlap?.[dimension]?.reason ||
            overlap?.[`${dimension}_filters`]?.reason
          }
          kind="info"
          hideCloseButton
          lowContrast
        />
      )}
      {showDateWarning(parameters, parameterKey) && (
        <InlineNotification
          title="Warning:"
          subtitle="These time groups are not of equal duration."
          kind="warning"
          hideCloseButton
          lowContrast
        />
      )}
    </div>
  );
};

export default ParameterCollection;
