import type { FunctionComponent } from 'react';
import { Button } from '@carbon/react';
import { DataCategorical } from '@carbon/icons-react';
import '../../styles/components/attributeManager.scss';

interface AttributePlaceholderProps {
  readonly onCreateAttributeClick: () => void;
}

export const AttributePlaceholder: FunctionComponent<
  AttributePlaceholderProps
> = (props) => {
  const { onCreateAttributeClick } = props;

  return (
    <div className="AttributePlaceholder" data-testid="attribute-placeholder">
      <DataCategorical size={48} />
      <div className="AttributePlaceholder__text">
        <div className="heading-02">You don&apos;t have any attributes yet</div>
        <div className="body-03">Let&apos;s start with creating one</div>
      </div>
      <Button
        kind="primary"
        size="md"
        renderIcon={DataCategorical}
        className="has-icon"
        onClick={onCreateAttributeClick}
      >
        Create Attribute
      </Button>
    </div>
  );
};
