import type { FunctionComponent, ReactNode } from 'react';
import { useEffect, useRef, useState } from 'react';
import {
  Popover as CarbonPopover,
  PopoverContent as CarbonPopoverContent,
} from '@carbon/react';
import '../styles/components/popover.scss';

type RenderFunction = (props: { closePopover: () => void }) => ReactNode;

interface PopoverProps {
  readonly target: JSX.Element;
  readonly children: ReactNode | RenderFunction;
  readonly align?:
    | 'top'
    | 'top-left'
    | 'top-right'
    | 'bottom'
    | 'bottom-left'
    | 'bottom-right'
    | 'left'
    | 'left-bottom'
    | 'left-top'
    | 'right'
    | 'right-bottom'
    | 'right-top';
  readonly as?: string;
  readonly autoAlign?: boolean;
  readonly caret?: boolean;
  readonly className?: string;
  readonly dropShadow?: boolean;
  readonly highContrast?: boolean;
  readonly shaded?: boolean;
  readonly stopPropagation?: boolean;
  readonly onOpenChange?: (open: boolean) => void;
}

const Popover: FunctionComponent<PopoverProps> = ({
  target,
  children,
  align,
  as,
  autoAlign,
  caret,
  className,
  dropShadow,
  highContrast,
  shaded,
  stopPropagation,
  onOpenChange,
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const popoverRef = useRef(null);
  const previousOpen = useRef(false);

  const classNames = ['Popover', className].join(' ').trim();

  const onOutsideClick = (e: MouseEvent) => {
    if (popoverRef.current && !popoverRef.current.contains(e.target)) {
      setOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', onOutsideClick);

    return () => {
      document.removeEventListener('click', onOutsideClick);
    };
  }, []);

  useEffect(() => {
    if (onOpenChange && previousOpen.current !== open) {
      onOpenChange(open);
    }

    previousOpen.current = open;
  }, [open]);

  return (
    <CarbonPopover
      open={open}
      align={align}
      as={as}
      autoAlign={autoAlign}
      caret={caret}
      className={classNames}
      dropShadow={dropShadow}
      highContrast={highContrast}
      ref={popoverRef}
      role="dialog"
      onKeyDown={({ code }) => code === 'Escape' && setOpen(false)}
    >
      <span
        onClick={(e) => {
          if (stopPropagation) {
            e.preventDefault();
            e.stopPropagation();
          }
          setOpen((open) => !open);
        }}
      >
        {target}
      </span>
      <CarbonPopoverContent
        className={`Popover--content ${
          shaded ? 'Popover--content__shaded' : ''
        }`.trim()}
        onClick={(e) => {
          if (stopPropagation) {
            e.preventDefault();
            e.stopPropagation();
          }
        }}
      >
        {typeof children === 'function'
          ? children({ closePopover: () => setOpen(false) })
          : children}
      </CarbonPopoverContent>
    </CarbonPopover>
  );
};

export default Popover;
