/* eslint-disable indent */
import type {
  PointLabelObject,
  TooltipFormatterContextObject,
} from 'highcharts';
import { formatNumber } from '../../utils/reportUtils';
import { REPORT_OUTPUT_CHARACTER_LIMIT } from '../../constants/values';
import { getMeasure } from '../../utils/chartUtils';
import type { VisualData } from '../../reducers/ReportReducer';

export const truncateParameterName = (name: string) => {
  const truncatedName =
    name.length > REPORT_OUTPUT_CHARACTER_LIMIT
      ? {
          text: `${name.substring(0, REPORT_OUTPUT_CHARACTER_LIMIT)}...`,
          tooltip: name,
        }
      : { text: name, tooltip: '' };
  return truncatedName;
};

export const stackedColumnTooltip = function ({
  measure,
  points,
  title,
  secondaryMeasure,
  measureFormats,
}: {
  measure: Measure;
  points: TooltipFormatterContextObject[];
  title: string | number;
  secondaryMeasure?: Measure;
  measureFormats?: VisualData['measureFormats'];
}) {
  let formatStr = `<div class="tooltip-container" data-testid="stacked-column-tooltip">
  <table>
    <thead>
      ${
        title
          ? `<tr height="24">
        <th/>
        <th colspan="2">${title}</th>
      </tr>`
          : ''
      }
    </thead>
    <tbody>`;
  points.forEach((dataPoint) => {
    const pointMeasure = getMeasure({
      point: dataPoint,
      measure,
      secondaryMeasure,
      measureFormats,
    });
    const formatPoint: NumberPoint = {
      value: dataPoint.y,
      format: pointMeasure.format,
      currency: pointMeasure.currency,
    };
    const formattedPoint = formatNumber(formatPoint);
    formatStr += `
    <tr height="24" style="margin-bottom: 4px;">
      <td>
        <div style="height: 8px; width: 8px; border-radius: 4px; background-color: ${
          dataPoint.color
        }; margin-right: 8px;" />
      </td>
      <td>${truncateParameterName(dataPoint.series.name).text}</td>
      <td style="text-align:right; padding-left: 16px;">${formattedPoint}</td>
    </tr>
  `;
  });
  formatStr += `</tbody>
    </table>
  </div>`;

  return formatStr;
};

export const columnChartTooltip = function ({
  measure,
  point,
  title,
  secondaryMeasure,
  measureFormats,
}: {
  measure: Measure;
  point: PointLabelObject;
  title: string | number;
  secondaryMeasure?: Measure;
  measureFormats?: VisualData['measureFormats'];
}) {
  const pointMeasure = getMeasure({
    point,
    measure,
    secondaryMeasure,
    measureFormats,
  });
  const dataPoint: NumberPoint = {
    value: point.y,
    format: pointMeasure.format,
    currency: pointMeasure.currency,
  };
  let formatStr = `<table data-testid="column-chart-tooltip">
  <thead>
    <tr height="24">
      <th />
      <th colspan="2">${title}</th>
    </tr>
  </thead>
  <tbody>`;

  const formattedPoint = formatNumber(dataPoint);
  formatStr += `
  <tr height="24" style="margin-bottom: 4px;">
    <td>
      <div style="height: 8px; width: 8px; border-radius: 4px; background-color: ${
        point.color
      }; margin-right: 8px;" />
    </td>
    <td>${truncateParameterName(point.series.name).text}</td>
    <td style="text-align:right; padding-left: 16px;">${formattedPoint}</td>
  </tr>
  `;

  formatStr += `</tbody>
</table>`;
  return formatStr;
};

export const scatterTooltip = (
  name: string,
  x: number,
  y: number,
  sales: NumberPoint
): string => {
  return `Product: <strong>${name}</strong>
    <br />
    Sales Rank: <strong>${x}</strong>
    <br />
    Customer Rank: <strong>${y}</strong>
    <br />
    Total sales: <strong>${formatNumber(sales)}</strong>
  `;
};

export const legendFormatter = function (name: string) {
  const { text, tooltip } = truncateParameterName(name);
  const formatStr = `<span title="${tooltip}">${text}</span>`;
  return formatStr;
};

export const customChartTooltip = ({
  tooltipType,
  measure,
  context,
  secondaryMeasure,
  measureFormats,
}: {
  tooltipType: 'stackedColumn' | 'column';
  measure: Measure;
  context: TooltipFormatterContextObject;
  secondaryMeasure?: Measure;
  measureFormats?: VisualData['measureFormats'];
}) => {
  return tooltipType === 'stackedColumn'
    ? stackedColumnTooltip({
        measure,
        points: context.points,
        title: context.x,
        secondaryMeasure,
        measureFormats,
      })
    : columnChartTooltip({
        measure,
        point: context,
        title: context.x,
        secondaryMeasure,
        measureFormats,
      });
};
