export const pear = {
  50: '#f3f9ec',
  100: '#e9f4dd',
  200: '#d0e7b6',
  300: '#badb94',
  400: '#a1cf6d',
  500: '#8bc34a',
  600: '#7cb342',
  700: '#689f38',
  800: '#558b2f',
  900: '#33691e',
  A100: '#ccff90',
  A200: '#b2ff59',
  A400: '#76ff03',
  A700: '#64dd17',
} as const;
