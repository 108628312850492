import asrt_rank from '../modules_frames/asrt_rank.svg';
import attog from '../modules_frames/attog.svg';
import bskt_szbnd from '../modules_frames/bskt-szbnd.svg';
import cdt from '../modules_frames/cdt.svg';
import ckpi from '../modules_frames/ckpi.svg';
import cpr from '../modules_frames/cpr.svg';
import custProf from '../modules_frames/custProf.svg';
import delist_transf from '../modules_frames/delist-transf.svg';
import dendro from '../modules_frames/dendro.svg';
import dim_att from '../modules_frames/dim-att.svg';
import gla from '../modules_frames/gla.svg';
import kpi from '../modules_frames/kpi.svg';
import kpi_tree from '../modules_frames/kpi-tree.svg';
import npd_fndr from '../modules_frames/npd-fndr.svg';
import npd_prfmc from '../modules_frames/npd-prfmc.svg';
import pas from '../modules_frames/pas.svg';
import promo_perf from '../modules_frames/promo-perf.svg';
import rpb from '../modules_frames/rpb.svg';
import trrp from '../modules_frames/trrp.svg';
import UnderConstruction from '../modules_frames/Under_Construction.svg';
import xover from '../modules_frames/xover.svg';

const images = {
  asrt_rank,
  attog,
  bskt_szbnd,
  cdt,
  ckpi,
  cpr,
  custProf,
  delist_transf,
  dendro,
  dim_att,
  gla,
  kpi_tree,
  kpi,
  npd_fndr,
  npd_prfmc,
  pas,
  promo_perf,
  rpb,
  trrp,
  UnderConstruction,
  xover,
};

export default images;
