import { useState, useEffect, useRef, useContext } from 'react';
import { InlineLoading, TextInput } from '@carbon/react';
import { WatsonHealthTextAnnotationToggle, Save } from '@carbon/icons-react';
import apiRequest from '../api';
import { useAuth0 } from '@auth0/auth0-react';
import Tooltip from '../components/Tooltip';
import '../styles/components/workspace.scss';
import { ModalContext } from '../providers/ModalProvider';
import {
  editReportNameFailure,
  editReportNameSuccess,
} from '../constants/posthog';
import usePosthog from '../utils/posthog';

interface EditReportNameProps {
  cell: CarbonCell<string>;
}

const EditReportName = ({ cell }: EditReportNameProps) => {
  const { getAccessTokenSilently } = useAuth0();
  const posthogEvent = usePosthog();
  const { updateModal } = useContext(ModalContext);
  const [reportName, setReportName] = useState<string>(cell.value);
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const textInputRef = useRef<HTMLInputElement>(null);
  const [editing, setEditing] = useState(false);

  const handleOutsideClick = (e) => {
    if (textInputRef.current && !textInputRef.current.contains(e.target)) {
      setEditing(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [setEditing]);

  useEffect(() => {
    setIsUpdating(false);
  }, [cell.value]);

  const changeReportName = async (runId: string, newName: string) => {
    setIsUpdating(true);
    const body = {
      run_id: runId,
      report_name: newName.trim(),
    };
    try {
      const token = await getAccessTokenSilently();
      await apiRequest('/reports', 'PATCH', token, body);
      posthogEvent(editReportNameSuccess, { newName });
    } catch (error) {
      posthogEvent(editReportNameFailure);
      updateModal({
        type: 'error',
        title: 'Something went wrong',
        body: 'There was an issue updating the report name. Please try again, or refresh your browser.',
      });
      return;
    }
  };

  const saveChanges = () => {
    if (reportName.trim().length > 0 && cell.value !== reportName) {
      changeReportName(cell.id.replace(':report_name', ''), reportName);
      setEditing(false);
    }
  };

  return (
    <div>
      {editing ? (
        <div ref={textInputRef} className="report-table-edit-name-wrapper">
          <TextInput
            id="report-name-input"
            labelText="Edit report"
            data-testid="report-name-input"
            hideLabel
            value={reportName}
            onClick={(e) => {
              e.stopPropagation();
            }}
            autoFocus
            onChange={({ target }) => {
              setReportName(target.value);
            }}
            onKeyDown={({ code }) => {
              if (['Enter', 'NumpadEnter'].includes(code)) {
                saveChanges();
              }
            }}
            invalid={!reportName || reportName.trim().length === 0}
            invalidText="Report must have a name"
          />
          <Tooltip description="Save report name" align="bottom-right">
            <Save
              className={`report-table-save-icon ${
                reportName.trim().length === 0
                  ? 'report-table-save-icon--disabled'
                  : ''
              }`}
              data-testid="report-name-save-icon"
              onClick={(e) => {
                e.stopPropagation();
                saveChanges();
              }}
            />
          </Tooltip>
        </div>
      ) : (
        <>
          {isUpdating ? (
            <InlineLoading description="Updating..." />
          ) : (
            <div className="report-name-cell">
              <div>{cell.value}</div>
              <div className="report-name-cell--edit">
                <Tooltip
                  description="Edit report name"
                  align="left"
                  useButtonWrapper
                >
                  <WatsonHealthTextAnnotationToggle
                    className="report-table-edit-icon"
                    data-testid="report-name-edit-icon"
                    onClick={(e) => {
                      e.stopPropagation();
                      setEditing(true);
                      reportName === '' && setReportName(cell.value);
                    }}
                  />
                </Tooltip>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default EditReportName;
