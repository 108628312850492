import { useState } from 'react';
import { PAGINATION_PAGE_SIZES } from '../constants/metadata';

const SHOW_ALL = -1 as const;

interface Pagination {
  readonly page: number;
  readonly pageSize: number;
}

interface PageSize {
  readonly text: string;
  readonly value: number;
}

type GetItemRangeText = (min: number, max: number, total: number) => string;

interface HookParams {
  readonly pageSizes?: ReadonlyArray<number>;
  readonly showAll?: boolean;
  readonly initialPage?: number;
  readonly initialPageSize?: number;
}

interface HookResult extends Pagination {
  readonly pageSizes: PageSize[];
  readonly getItemRangeText: GetItemRangeText;
  readonly isShowAll: boolean;
  readonly setPagination: (
    pagination: Pagination | ((state: Pagination) => Pagination)
  ) => void;
  readonly setPage: (page: number) => void;
  readonly setPageSize: (pageSize: number) => void;
}

export const usePagination = ({
  pageSizes = PAGINATION_PAGE_SIZES,
  showAll = true,
  initialPage,
  initialPageSize,
}: HookParams = {}): HookResult => {
  const [{ page, pageSize }, setPagination] = useState<{
    page: number;
    pageSize: number;
  }>({
    page: initialPage ?? 1,
    pageSize: initialPageSize ?? pageSizes[0],
  });

  const isShowAll = pageSize === SHOW_ALL;

  const extendedPageSizes: PageSize[] = pageSizes
    .map((value) => ({ text: String(value), value }))
    .concat(showAll ? [{ text: 'Show All', value: SHOW_ALL }] : []);

  const getItemRangeText: GetItemRangeText = (min, max, total) =>
    `${min}–${isShowAll ? total : max} of ${total} ${
      total === 1 ? 'item' : 'items'
    }`;

  return {
    page,
    pageSizes: extendedPageSizes,
    pageSize,
    isShowAll,
    setPagination: (pagination) =>
      setPagination((state) => ({
        ...state,
        ...(typeof pagination === 'function' ? pagination(state) : pagination),
      })),
    setPage: (page) => setPagination((state) => ({ ...state, page })),
    setPageSize: (pageSize) =>
      setPagination((state) => ({ ...state, pageSize })),
    getItemRangeText,
  };
};
