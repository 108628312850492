import type { FunctionComponent } from 'react';
import { useContext, useState } from 'react';
import {
  Button,
  Form,
  TextInput,
  InlineLoading,
  InlineNotification,
} from '@carbon/react';
import { useAuth0 } from '@auth0/auth0-react';
import {
  getAutoGeneratedName,
  getTemplateIdForReport,
  isValidReportName,
} from '../../../utils/reportUtils';
import type { ReportConfig } from '../../../reducers/ReportReducer';
import apiRequest from '../../../api';
import { useNavigate } from 'react-router-dom';
import { SidePanelContext } from '../../../providers/SidePanelProvider';
import { ModalContext } from '../../../providers/ModalProvider';
import { OptimiserMode } from '../../../reducers/OptimiserFormReducer';
import usePosthog from '../../../utils/posthog';
import { DelistTransferAnalysisSubmit } from '../../../constants/posthog';

interface ImpactAnalysisFormProps {
  reportModules: ReportSection[];
  groupId: number;
  reportConfig: ReportConfig;
  reportTaskId: string;
  reportTemplateIndex: number;
  optimiserMode: OptimiserMode;
  saveOptimiserGrid: () => Promise<void>;
}

const ImpactAnalysisForm: FunctionComponent<ImpactAnalysisFormProps> = ({
  reportModules,
  groupId,
  reportConfig,
  reportTaskId,
  reportTemplateIndex,
  optimiserMode,
  saveOptimiserGrid,
}) => {
  const navigate = useNavigate();
  const posthogEvent = usePosthog();
  const [name, setName] = useState(
    getAutoGeneratedName('Delist Transfer Analysis')
  );
  const [loadingMessage, setLoadingMessage] = useState('');
  const { updateModal, toggleModal } = useContext(ModalContext);

  const { getAccessTokenSilently } = useAuth0();
  const { toggleSidePanelExpand } = useContext(SidePanelContext);
  const redirectToWorkspace = () => {
    toggleModal(false);
    navigate('/workspace/my-reports');
  };

  const submitReport = async () => {
    setLoadingMessage('Saving optimiser data...');
    try {
      await saveOptimiserGrid();
      const token = await getAccessTokenSilently();
      const template_id = getTemplateIdForReport(
        reportModules,
        'delist-transf'
      );
      const payload = {
        report_name: name,
        template_id,
        template_requests: [
          {
            ...reportConfig.parameters.template_requests[reportTemplateIndex],
            analytic_engine_params: {
              ...reportConfig.parameters.template_requests[reportTemplateIndex]
                .analytic_engine_params,
              followOutId: reportTaskId,
              optimiserMode:
                optimiserMode === OptimiserMode.Plan ? 'plan' : 'non_plan',
            },
            template_id,
            user_group: groupId,
          },
        ],
      };
      setLoadingMessage('Submitting report...');
      await apiRequest('/convert', 'POST', token, payload);
      posthogEvent(DelistTransferAnalysisSubmit, {
        reportName: name,
      });
      toggleSidePanelExpand(false);
      updateModal({
        type: 'success',
        title: 'Report submitted',
        body: 'Your report has been successfully submitted. View it in your workspace, or create another.',
        primaryCTAText: 'Create another',
        onSecondaryCTAClick: () => redirectToWorkspace(),
        secondaryCTAText: 'View workspace',
      });
    } catch {
      updateModal({
        type: 'error',
        title: 'Something went wrong',
        body: 'There was an issue submitting your report. Please make sure your parameters are correct and try again.',
      });
    } finally {
      setLoadingMessage('');
    }
  };

  return (
    <Form>
      <InlineNotification
        subtitle="Please note that unsupported characters (') in parameter names will be automatically removed in the report output."
        hideCloseButton
        lowContrast
        kind="info"
      />
      <div className="assortment-side-panel-title">
        Enter a name for your report:
      </div>
      <TextInput
        id="IA-report-name"
        labelText="Report name"
        value={name}
        invalid={!isValidReportName(name)}
        invalidText="Report must have a name"
        onChange={({ target }) => setName(target.value)}
      />
      <div className="assortment-side-panel-controls">
        {loadingMessage && <InlineLoading description={loadingMessage} />}
        <Button
          disabled={!name || !!loadingMessage || !isValidReportName(name)}
          onClick={submitReport}
        >
          Submit
        </Button>
      </div>
    </Form>
  );
};

export default ImpactAnalysisForm;
