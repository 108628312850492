import type { FunctionComponent, SyntheticEvent } from 'react';
import { useContext } from 'react';
import { Help } from '@carbon/icons-react';
import { Link } from 'react-router-dom';
import { InlineNotification } from '@carbon/react';
import { PosthogContext } from '../providers/PosthogProvider';
import { SidePanelContext } from '../providers/SidePanelProvider';
import images from '../assets/modules_frames/';
import BetaBadge from '../assets/badges/BetaBadge.svg';
import '../styles/components/modules.scss';
import { moduleInfoClick, moduleTypeClick } from '../constants/posthog';
import usePosthog from '../utils/posthog';
interface ModuleProps {
  module: ReportModule;
  section: string;
}

const Module: FunctionComponent<ModuleProps> = ({ module, section }) => {
  const { renderSidePanelContent } = useContext(SidePanelContext);
  const { featureFlags } = useContext(PosthogContext);
  const posthogEvent = usePosthog();
  const handleInfoClick = (e: SyntheticEvent) => {
    posthogEvent(moduleInfoClick, {
      moduleName: module.name,
    });
    e.preventDefault();
    e.stopPropagation();
    renderSidePanelContent(
      <>
        <div className="module-box--text">{module.help_text}</div>
      </>,
      { title: `${module.name}` }
    );
  };
  const getImageUrl = (url_route: string): string =>
    images[url_route.replace(/-/g, '_')] || images.UnderConstruction;

  const reportFlag = featureFlags.find(
    (flag) => flag.name === 'report-maintenance'
  );

  const moduleFlag =
    reportFlag && (reportFlag.payload as string[]).includes(module.url_route);

  return (
    <Link
      to={moduleFlag ? '#' : `${section}/${module.url_route}`}
      onClick={() => {
        const moduleType = `${section
          .toLowerCase()
          .replace(/ /g, '_')}_${module.name.toLowerCase().replace(/ /g, '_')}`;
        posthogEvent(moduleTypeClick, {
          moduleType,
        });
      }}
    >
      <div className="Module__container-wrapper">
        {moduleFlag && (
          <div className="notification">
            <InlineNotification
              title="Report is currently unavailable due to ongoing maintenance."
              hideCloseButton
              lowContrast
              kind="info"
            />
          </div>
        )}

        <div className={`Module__box${moduleFlag ? ' maintenance' : ''}`}>
          <div className="Module__image-container">
            <div
              style={{
                backgroundImage: `url(${getImageUrl(module.url_route)})`,
              }}
              className={`Module__box--image${
                moduleFlag ? 'maintenance ' : ''
              }`}
            ></div>
          </div>
          <div className="Module__box--content">
            <h5>{module.name}</h5>
            <div
              data-testid={`info-button-${module.name}`}
              className="Module__box--info clickable hoverable"
              onClick={handleInfoClick}
            >
              <Help size={20} />
            </div>
          </div>
          {module.is_beta && (
            <img className="Module__badge" src={BetaBadge} alt="Beta badge" />
          )}
        </div>
      </div>
    </Link>
  );
};

export default Module;
