import type { DurationLike, LocaleOptions } from 'luxon';
import { DateTime } from 'luxon';

export const daysToMonthStart = (date: Date): number => {
  return DateTime.fromJSDate(date).day - 1;
};

export const daysToYearStart = (date: Date): number => {
  return DateTime.fromJSDate(date).ordinal - 1;
};

export const getHistoricalDate = (duration: DurationLike, date: Date): Date => {
  return DateTime.fromJSDate(date).minus(duration).toJSDate();
};

export const formatUTCDate = (date: string, includeTime = true): string => {
  const newDate = new Date(date);
  const toFormat = includeTime ? 'dd/MM/yyyy HH:mm' : 'dd/MM/yyyy';
  return DateTime.fromJSDate(newDate).toFormat(toFormat);
};

export const timePeriodOverlaps = (
  item: ReportParameter,
  otherItems: ReportParameter[]
) => {
  let overlappingTime = false;
  otherItems.forEach((otherItem) => {
    const from1 = new Date(item.from);
    const from2 = new Date(otherItem.from);
    const to1 = new Date(item.to);
    const to2 = new Date(otherItem.to);

    if ((from1 <= to2 && from1 >= from2) || (to1 <= to2 && to1 >= from2)) {
      overlappingTime = true;
    }
  });

  return overlappingTime;
};

export const getRemainingArchivingDays = (date: DateTime) => {
  const ARCHIVING_PERIOD = 14;
  const difference = Math.ceil(date.diffNow('days').days);
  return difference + ARCHIVING_PERIOD;
};

export const formatDateRange = ({
  startDate,
  endDate,
  formatToken,
  options,
}: {
  startDate: string;
  endDate: string;
  formatToken: string;
  options?: LocaleOptions;
}): string => {
  const formattedStartDate = DateTime.fromJSDate(new Date(startDate)).toFormat(
    formatToken,
    options
  );
  const formattedEndDate = DateTime.fromJSDate(new Date(endDate)).toFormat(
    formatToken,
    options
  );

  return `${formattedStartDate} - ${formattedEndDate}`;
};
