import type { FunctionComponent, ReactNode } from 'react';
import { Help } from '@carbon/icons-react';
import '../styles/components/iconTitle.scss';
import Tooltip from './Tooltip';
interface IconTitleProps {
  title: string;
  icon: JSX.Element;
  kind: 'primary' | 'secondary';
  description?: string;
  help?: ReactNode;
  sticky?: boolean;
  faded?: boolean;
  titleSize?: '01' | '02' | '03' | '04' | '05' | 'body-02';
  highlightIcon?: boolean;
}

const IconTitle: FunctionComponent<IconTitleProps> = ({
  icon,
  title,
  kind,
  description,
  help,
  faded,
  titleSize = '05',
  highlightIcon,
}) => {
  return (
    <div
      className={`IconTitle ${
        faded ? 'IconTitle--faded' : ''
      } IconTitle--${kind} IconTitle--${titleSize}`.trim()}
    >
      <div
        className={`IconTitle__icon ${
          highlightIcon ? 'IconTitle__icon--highlighted' : ''
        } ${description ? 'IconTitle--with-description' : ''}`}
      >
        {icon}
      </div>
      <div className="IconTitle__content">
        <div className="IconTitle__title">
          {title}
          {help && (
            <Tooltip
              useButtonWrapper
              align="bottom-left"
              className="IconTitle__tooltip"
              description={help}
            >
              <Help size={16} />
            </Tooltip>
          )}
        </div>
        {!!description && (
          <div className="IconTitle__description">{description}</div>
        )}
      </div>
    </div>
  );
};

export default IconTitle;
