import type { FunctionComponent } from 'react';
import { Dropdown, TextInput, FormLabel } from '@carbon/react';
import { USER_TYPE } from '../../../constants/metadata';
import LabelWithTooltip from '../LabelWithTooltip';
import { validateEmail } from '../../../utils/adminPanelUtils';
import type { UserDataConfig } from '../../../reducers/AdminPanelReducer';
import type {
  UserForm,
  UserFormWithError,
} from '../../../reducers/UserFormReducer';

interface PersonalDetailsStepProps {
  readonly userData: Pick<
    UserFormWithError,
    | 'name'
    | 'email'
    | 'userRole'
    | 'userType'
    | 'authType'
    | 'supplierName'
    | 'jobTitle'
    | 'department'
    | 'formErrors'
  >;
  readonly dataConfig: UserDataConfig;
  readonly isEditMode?: boolean;
  readonly updateUserForm: <T extends keyof UserForm>(
    key: T,
    value: UserFormWithError[T]
  ) => void;
}

const PersonalDetailsStep: FunctionComponent<PersonalDetailsStepProps> = ({
  userData,
  dataConfig,
  isEditMode,
  updateUserForm,
}) => {
  const { userRoles, userTypes, authTypes } = dataConfig;
  const {
    name,
    email,
    userRole,
    userType,
    authType,
    supplierName,
    jobTitle,
    department,
    formErrors,
  } = userData;
  const { name: nameError, email: emailError } = formErrors;

  return (
    <>
      {!isEditMode ? (
        <>
          <TextInput
            id="user-full-name"
            data-testid="user-full-name"
            labelText="Full Name"
            value={name}
            invalid={!!nameError}
            invalidText={nameError}
            placeholder="Please enter first and second name"
            className="InviteTeamMemberModal__text-input"
            onChange={(e) => {
              updateUserForm('name', e.target.value);
            }}
          />
          <TextInput
            id="user-email"
            data-testid="user-email"
            labelText="Email"
            value={email}
            invalid={!!emailError || (!!email && !validateEmail(email))}
            invalidText={emailError || 'Please enter a valid email address'}
            placeholder="Please enter email address"
            className="InviteTeamMemberModal__text-input"
            onChange={(e) => {
              updateUserForm('email', e.target.value);
            }}
          />
          <Dropdown
            id="user-type"
            data-testid="user-type"
            titleText="User Type"
            label={
              <div className="InviteTeamMemberModal__dropdown-placeholder">
                Select Option
              </div>
            }
            className="InviteTeamMemberModal__dropdown-container"
            items={userTypes}
            selectedItem={userType}
            itemToString={(item) => item.name}
            onChange={({ selectedItem }) =>
              updateUserForm('userType', selectedItem)
            }
          />
        </>
      ) : (
        <>
          <div>
            <FormLabel data-testid="user-auth-type">
              <LabelWithTooltip
                title="Authentication Type"
                subtitle="The type of method used to verify a user before allowing access to access the system."
              />
            </FormLabel>
            <div className="PersonalDetailsStep__read-only-value">
              {authType?.name ?? '-'}
            </div>
          </div>
          <Dropdown
            id="user-role"
            data-testid="user-role"
            titleText={
              <LabelWithTooltip
                title="Role"
                subtitle="The type of permissions assigned to a user based on their responsibility within the platform."
              />
            }
            label={
              <div className="InviteTeamMemberModal__dropdown-placeholder">
                Select Option
              </div>
            }
            className="InviteTeamMemberModal__dropdown-container"
            items={userRoles}
            selectedItem={userRole}
            itemToString={(item) => item.name}
            onChange={({ selectedItem }) =>
              updateUserForm('userRole', selectedItem)
            }
          />
        </>
      )}
      {userType?.type === USER_TYPE.SUPPLIER && (
        <TextInput
          id="user-supplier-name"
          data-testid="user-supplier-name"
          labelText="Supplier Name"
          value={supplierName}
          className="InviteTeamMemberModal__text-input"
          placeholder="Please enter supplier name"
          onChange={(e) => updateUserForm('supplierName', e.target.value)}
        />
      )}
      {!isEditMode && (
        <>
          <Dropdown
            id="user-role"
            data-testid="user-role"
            titleText={
              <LabelWithTooltip
                title="Role"
                subtitle="The type of permissions assigned to a user based on their responsibility within the platform."
              />
            }
            label={
              <div className="InviteTeamMemberModal__dropdown-placeholder">
                Select Option
              </div>
            }
            className="InviteTeamMemberModal__dropdown-container"
            items={userRoles}
            selectedItem={userRole}
            itemToString={(item) => item.name}
            onChange={({ selectedItem }) =>
              updateUserForm('userRole', selectedItem)
            }
          />
          <Dropdown
            id="user-auth-type"
            data-testid="user-auth-type"
            titleText={
              <LabelWithTooltip
                title="Authentication Type"
                subtitle="The type of method used to verify a user before allowing access to access the system."
              />
            }
            label={
              <div className="InviteTeamMemberModal__dropdown-placeholder">
                Select Option
              </div>
            }
            className="InviteTeamMemberModal__dropdown-container"
            items={authTypes}
            selectedItem={authType}
            itemToString={(item) => item.name}
            onChange={({ selectedItem }) =>
              updateUserForm('authType', selectedItem)
            }
          />
        </>
      )}
      {userType?.type !== USER_TYPE.SUPPLIER && (
        <div className="flex gap--medium">
          <TextInput
            id="user-job-title"
            data-testid="user-job-title"
            labelText="Job Title (Optional)"
            value={jobTitle}
            placeholder="Please enter job title"
            onChange={(e) => updateUserForm('jobTitle', e.target.value)}
          />
          <TextInput
            id="user-department"
            data-testid="user-department"
            labelText="Department (Optional)"
            value={department}
            placeholder="Please enter description"
            onChange={(e) => updateUserForm('department', e.target.value)}
          />
        </div>
      )}
    </>
  );
};

export default PersonalDetailsStep;
