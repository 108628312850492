import type { FunctionComponent } from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Tag } from '@carbon/react';
import { View, Close } from '@carbon/icons-react';
import { createPortal } from 'react-dom';
import { useWindowDimensions } from '../../../hooks';

interface BannerPopoverProps {
  readonly rowId: string;
  readonly title: string;
  readonly listItems: { id: number; name: string }[];
  readonly popoverHeader: string;
  readonly bannerTagColour: {
    backgroundColor: string;
    color: string;
  };
  readonly scrollbarRef: React.MutableRefObject<HTMLDivElement>;
}

export const BannerPopover: FunctionComponent<BannerPopoverProps> = ({
  rowId,
  title,
  listItems,
  popoverHeader,
  bannerTagColour,
  scrollbarRef,
}) => {
  const [open, setOpen] = useState(false);
  const targetRef = useRef(null);
  const [popoverRef, setPopoverRef] = useState<HTMLDivElement>(null);
  const { documentWidth } = useWindowDimensions();

  const onOutsideClick = (e: MouseEvent) => {
    if (
      popoverRef &&
      !popoverRef?.contains(e.target as Node) &&
      targetRef.current &&
      !targetRef.current.contains(e.target)
    ) {
      setOpen(false);
    }
  };

  const getPopoverPosition = useCallback(() => {
    const { height, width } = popoverRef.getBoundingClientRect();
    const {
      height: buttonHeight,
      bottom: buttonBottom,
      top: buttonTop,
      right: buttonRight,
      left: buttonLeft,
    } = targetRef.current.getBoundingClientRect();
    const { bottom: paginationBottom } =
      scrollbarRef.current.getBoundingClientRect();
    const top =
      height + buttonBottom > paginationBottom
        ? buttonTop - height + buttonHeight
        : buttonTop;
    const left =
      width + buttonLeft > documentWidth ? buttonLeft - width : buttonRight;

    return { top, left };
  }, [popoverRef, targetRef, documentWidth]);

  useEffect(() => {
    document.addEventListener('click', onOutsideClick);

    return () => {
      document.removeEventListener('click', onOutsideClick);
    };
  }, [popoverRef]);

  useEffect(() => {
    const closeMenu = () => {
      setOpen(false);
    };
    const appContainer = document.querySelector('#root > .app');
    appContainer?.addEventListener('scroll', closeMenu);
    scrollbarRef.current?.addEventListener('scroll', closeMenu);
    return () => {
      appContainer?.removeEventListener('scroll', closeMenu);
      scrollbarRef.current?.removeEventListener('scroll', closeMenu);
    };
  }, []);

  return (
    <div
      className="BannerPopover__wrapper"
      data-testid={`banner-popover-${rowId}-${title}`}
    >
      <div
        onClick={() => setOpen(!open)}
        ref={targetRef}
        style={{ width: 'fit-content' }}
      >
        <Tag
          size="md"
          className={`BannerPopover__target ${
            open ? 'BannerPopover__target--open' : ''
          }`}
          style={bannerTagColour}
          renderIcon={View}
          data-testid={`banner-popover-target-${rowId}-${title}`}
        >
          {title}
        </Tag>
      </div>
      {open &&
        createPortal(
          <div
            className="BannerPopover__content"
            ref={(ref) => setPopoverRef(ref)}
            style={popoverRef ? getPopoverPosition() : null}
            data-testid={`banner-popover-content-${rowId}-${title}`}
          >
            <div className="BannerPopover__content-header">
              <span>{popoverHeader}</span>
              <div
                className="BannerPopover__close-icon"
                onClick={() => setOpen(false)}
              >
                <Close />
              </div>
            </div>
            {listItems.map(({ id, name }) => (
              <div className="BannerPopover__content-item" key={id}>
                {name}
              </div>
            ))}
          </div>,
          document.body,
          `banner-popover-${rowId}-${title}`
        )}
    </div>
  );
};
