import { IconButton, Tooltip } from '@carbon/react';
import { Restart } from '@carbon/icons-react';
import '../styles/components/refresh.scss';
interface RefreshProps {
  message: string;
  subtle?: boolean;
  onRetry: () => void;
}

const Refresh = ({ message, subtle = false, onRetry }: RefreshProps) => {
  return (
    <div className={`refresh-component ${subtle ? 'subtle' : ''}`}>
      {subtle ? (
        <Tooltip label="Refresh">
          <button style={{ all: 'unset' }}>
            <Restart onClick={onRetry} />
          </button>
        </Tooltip>
      ) : (
        <IconButton label="Retry" onClick={onRetry} size="sm">
          <Restart />
        </IconButton>
      )}
      <span className="refresh-message">{message}</span>
    </div>
  );
};

export default Refresh;
