import type { ReactNode } from 'react';
import {
  RENDER_SIDE_PANEL_CONTENT,
  RESIZE_SIDE_PANEL,
  TOGGLE_SIDE_PANEL_EXPAND,
} from '../constants/reducers';

export interface SidePanelState {
  expanded: boolean;
  content: ReactNode;
  autoclose?: boolean;
  header?: { title: string; subtitle?: string };
  width?: number;
}

export const initialSidePanelState: SidePanelState = {
  header: null,
  expanded: false,
  content: null,
  autoclose: true,
  width: 550,
};

interface ToggleSidePanelExpand {
  type: typeof TOGGLE_SIDE_PANEL_EXPAND;
  expanded: boolean;
}
interface RenderSidebarContent {
  type: typeof RENDER_SIDE_PANEL_CONTENT;
  content: ReactNode;
  header?: { title: string; subtitle?: string };
  autoclose: boolean;
}
interface ResizeSidePanel {
  type: typeof RESIZE_SIDE_PANEL;
  width: number;
}

export type SidepanelAction =
  | ToggleSidePanelExpand
  | RenderSidebarContent
  | ResizeSidePanel;

const sidePanelReducer = (
  state: SidePanelState,
  action: SidepanelAction
): SidePanelState => {
  switch (action.type) {
    case TOGGLE_SIDE_PANEL_EXPAND:
      return {
        ...state,
        expanded: action.expanded,
      };
    case RENDER_SIDE_PANEL_CONTENT:
      return {
        ...state,
        header: action.header,
        expanded: true,
        content: action.content,
        autoclose: action.autoclose,
      };

    case RESIZE_SIDE_PANEL:
      return {
        ...state,
        width: action.width,
      };
  }
};

export default sidePanelReducer;
