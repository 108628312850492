/* eslint-disable indent */
import type { FunctionComponent } from 'react';
import { useContext, useEffect, useReducer, useState } from 'react';
import {
  Button,
  Dropdown,
  ModalBody,
  ModalHeader,
  ModalFooter,
  InlineLoading,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@carbon/react';
import type { UserDataConfig } from '../../../reducers/AdminPanelReducer';
import { ModalContext } from '../../../providers/ModalProvider';
import Gravatar from '../../Gravatar';
import type {
  BannerFormData,
  UserForm,
  UserFormWithError,
} from '../../../reducers/UserFormReducer';
import {
  userFormInitState,
  userFormReducer,
} from '../../../reducers/UserFormReducer';
import { editExistingUser } from '../../../utils/adminPanelUtils';
import PersonalDetailsStep from './PersonalDetailsStep';
import UserAccessStep from './UserAccessStep';
import {
  ADD_NEW_EMPTY_BANNER,
  REMOVE_BANNER_FROM_LIST,
  RESET_USER_FORM,
  UPDATE_USER_FORM,
  UPDATE_USER_FORM_BANNERS,
} from '../../../constants/reducers';
import { useAuth0 } from '@auth0/auth0-react';
import { CACHE_KEY } from '../../../constants/api';
import { AppContext } from '../../../providers/AppProvider';

interface EditUserModalProps {
  readonly dataConfig: UserDataConfig;
  readonly userId: string;
  readonly initialFormState: UserFormWithError;
  readonly currentAction: {
    title: string;
    action: (id: string) => void;
  };
}

const EditUserModal: FunctionComponent<EditUserModalProps> = ({
  dataConfig,
  userId,
  initialFormState,
  currentAction,
}) => {
  const { clearCacheForKey } = useContext(AppContext);
  const [formState, dispatch] = useReducer(userFormReducer, userFormInitState);
  const { userTypes } = dataConfig;
  const { updateModal, toggleModal, renderComponent } =
    useContext(ModalContext);
  const { getAccessTokenSilently } = useAuth0();
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const validateForm = () => {
    const { banners, userRole, userType, measureAccess } = formState;
    const bannerData = banners.filter(({ banner }) => banner);
    const tabValidation =
      userType &&
      userRole &&
      bannerData.length > 0 &&
      bannerData.every(
        ({ banner, userGroups }) => banner && userGroups.length > 0
      ) &&
      measureAccess;
    return tabValidation;
  };

  const updateUserData = async () => {
    setIsLoading(true);
    try {
      const token = await getAccessTokenSilently();
      await editExistingUser(formState, userId, token);
      clearCacheForKey(CACHE_KEY.USER_LIST);
      setIsEditing(false);
      toggleModal(false);
    } catch (error) {
      updateModal({
        type: 'error',
        title: 'Something went wrong',
        body: 'There was a problem editing a user. Please try again, or refresh your browser.',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const updateUserForm = <T extends keyof UserForm>(
    key: T,
    value: UserFormWithError[T]
  ) => {
    dispatch({
      type: UPDATE_USER_FORM,
      key,
      value,
    });
    setIsEditing(true);
  };

  const addNewEmptyBannerItem = () => {
    dispatch({ type: ADD_NEW_EMPTY_BANNER });
  };

  const updateBanners = (id: number, value: Partial<BannerFormData>) => {
    dispatch({ type: UPDATE_USER_FORM_BANNERS, value, id });
    setIsEditing(true);
  };

  const removeBanner = (id: number) => {
    dispatch({ type: REMOVE_BANNER_FROM_LIST, id });
    setIsEditing(true);
  };

  const handleModalCloseClick = () => {
    const onPrimaryCTAClick = validateForm()
      ? updateUserData
      : () =>
          renderComponent(
            <EditUserModal
              dataConfig={dataConfig}
              userId={userId}
              currentAction={currentAction}
              initialFormState={formState}
            />,
            'EditUserModal__container'
          );

    if (isEditing) {
      updateModal({
        type: 'warning',
        title: 'You have unsaved changes',
        body: 'Do you want to discard or save the changes made?',
        primaryCTAText: validateForm() ? 'Save' : 'Continue Editing',
        onPrimaryCTAClick,
        secondaryCTAText: 'Discard',
        onSecondaryCTAClick: () => toggleModal(false),
      });
    } else {
      toggleModal(false);
    }
  };

  useEffect(() => {
    dispatch({ type: RESET_USER_FORM, userData: initialFormState });
    setIsEditing(false);
    setSelectedTabIndex(0);
  }, [initialFormState, userId]);

  return (
    <>
      <ModalHeader buttonOnClick={handleModalCloseClick}>
        <div className="EditUserModal__header">
          <Gravatar name={formState.name} size={44} showName={false} />
          <div className="heading-06">{formState.name}</div>
          <div className="body-03">ID: {userId}</div>
          <Dropdown
            id="user-type"
            data-testid="user-type"
            titleText=""
            hideLabel
            label="Select Option"
            items={userTypes}
            selectedItem={formState.userType}
            itemToString={(item) => item.name}
            onChange={({ selectedItem }) =>
              updateUserForm('userType', selectedItem)
            }
          />
          <div className="EditUserModal__tabs-container">
            <Tabs selectedIndex={selectedTabIndex}>
              <TabList aria-label="List of visual tabs">
                <Tab onClick={() => setSelectedTabIndex(0)}>
                  Personal Details
                </Tab>
                <Tab onClick={() => setSelectedTabIndex(1)}>User Access</Tab>
              </TabList>
            </Tabs>
            <Button
              kind="tertiary"
              size="md"
              onClick={() => {
                currentAction.action(userId);
              }}
            >
              {currentAction.title}
            </Button>
          </div>
        </div>
      </ModalHeader>
      <ModalBody>
        <Tabs selectedIndex={selectedTabIndex}>
          <TabPanels>
            <TabPanel>
              <div className="EditUserModal__content">
                <PersonalDetailsStep
                  userData={formState}
                  dataConfig={dataConfig}
                  isEditMode
                  updateUserForm={updateUserForm}
                />
              </div>
            </TabPanel>
            <TabPanel>
              <div
                className={`EditUserModal__content ${
                  selectedTabIndex === 1 &&
                  !formState.banners.every(({ banner }) => !!banner)
                    ? 'EditUserModal__content--with-bottom-space'
                    : ''
                }`}
              >
                <UserAccessStep
                  userData={formState}
                  dataConfig={dataConfig}
                  updateUserForm={updateUserForm}
                  updateBanners={updateBanners}
                  addNewEmptyBannerItem={addNewEmptyBannerItem}
                  removeBanner={removeBanner}
                />
              </div>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </ModalBody>
      <ModalFooter className="InviteTeamMemberModal__buttons">
        <Button
          size="md"
          onClick={updateUserData}
          disabled={!isEditing || !validateForm() || isLoading}
          className="InviteTeamMemberModal__save-button"
        >
          Save {isLoading && <InlineLoading />}
        </Button>
      </ModalFooter>
    </>
  );
};

export default EditUserModal;
