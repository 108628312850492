import type { FunctionComponent, ReactNode } from 'react';
import { createContext, useMemo, useReducer } from 'react';
import {
  RENDER_COMPONENT,
  TOGGLE_MODAL,
  UPDATE_MODAL_OPTIONS,
} from '../constants/reducers';
import '../styles/components/side-panel.scss';
import type { ModalState } from '../reducers/ModalReducer';
import modalReducer, { initialModalState } from '../reducers/ModalReducer';
import Modal from '../components/Modal';

interface ModalProviderProps {
  children?: ReactNode;
}

export interface ModalContextType extends ModalState {
  toggleModal: (open: boolean) => void;
  updateModal: (options: ModalOptions) => void;
  renderComponent: (
    component: JSX.Element,
    containerClassName?: string
  ) => void;
}

export const ModalContext = createContext<ModalContextType | null>(null);

const ModalProvider: FunctionComponent<ModalProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(modalReducer, initialModalState);

  const toggleModal = (open: boolean) => dispatch({ type: TOGGLE_MODAL, open });

  const updateModal = (options: ModalOptions) =>
    dispatch({ type: UPDATE_MODAL_OPTIONS, options });

  const renderComponent = (
    component: JSX.Element,
    containerClassName?: string
  ) => dispatch({ type: RENDER_COMPONENT, component, containerClassName });

  const modalContext: ModalContextType = useMemo(() => {
    return {
      ...state,
      toggleModal,
      updateModal,
      renderComponent,
    };
  }, [state]);

  return (
    <ModalContext.Provider value={modalContext}>
      {children}
      <Modal />
    </ModalContext.Provider>
  );
};

export default ModalProvider;
