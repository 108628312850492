import type { FunctionComponent } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@carbon/react';
import { Upload } from '@carbon/icons-react';
import { custGroupsUploadBtnClick } from '../../../constants/posthog';
import usePosthog from '../../../utils/posthog';
import CustomGroupsEmptyList from '../../../assets/custom-groups/custom_groups_empty_list.svg';
import '../../../styles/components/CustomGroups.scss';

const NoCustomGroupsPlaceholder: FunctionComponent = () => {
  const navigate = useNavigate();
  const posthogEvent = usePosthog();

  const handleUploadClick = () => {
    posthogEvent(custGroupsUploadBtnClick);
    navigate('/custom-groups/upload', { replace: true });
  };
  return (
    <div className="NoCustomGroupsPlaceholder">
      <img
        className="NoCustomGroupsPlaceholder__image"
        src={CustomGroupsEmptyList}
        alt="Custom Group Empty List"
      />
      <div className="heading-02">
        You don&apos;t have any custom groups yet
      </div>
      <div className="body-03">Let&apos;s start with creating one</div>
      <div className="NoCustomGroupsPlaceholder__button-wrapper">
        <Button
          kind="primary"
          size="md"
          renderIcon={Upload}
          className="has-icon"
          onClick={() => handleUploadClick()}
        >
          Upload Group
        </Button>
      </div>
    </div>
  );
};

export default NoCustomGroupsPlaceholder;
