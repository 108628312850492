import type { FunctionComponent } from 'react';
import { Button, Dropdown, InlineNotification } from '@carbon/react';
import { Add, TrashCan } from '@carbon/icons-react';
import MultiSelect from '../../MultiSelect/MultiSelect';
import LabelWithTooltip from '../LabelWithTooltip';
import type { UserDataConfig } from '../../../reducers/AdminPanelReducer';
import type {
  UserForm,
  BannerFormData,
  UserFormWithError,
} from '../../../reducers/UserFormReducer';

interface UserAccessStepProps {
  readonly userData: Pick<UserForm, 'banners' | 'measureAccess'>;
  readonly dataConfig: UserDataConfig;
  readonly updateUserForm: <T extends keyof UserForm>(
    key: T,
    value: UserFormWithError[T]
  ) => void;
  readonly updateBanners: (
    index: number,
    value: Partial<BannerFormData>
  ) => void;
  readonly addNewEmptyBannerItem: () => void;
  readonly removeBanner: (id: number) => void;
}

const UserAccessStep: FunctionComponent<UserAccessStepProps> = ({
  userData,
  dataConfig,
  updateUserForm,
  updateBanners,
  addNewEmptyBannerItem,
  removeBanner,
}) => {
  const { banners: bannerList, measureRestrictions } = dataConfig;
  const { banners, measureAccess } = userData;
  const showNotification = bannerList.every(({ id }) =>
    banners.find(({ banner }) => id === banner?.id)
  );

  return (
    <>
      <Dropdown
        id="user-measure-access"
        data-testid="user-measure-access"
        titleText={
          <LabelWithTooltip
            title="Measure Access"
            subtitle="This access type refers to the users viewing access to either all metrics across the platform or restricted ones."
          />
        }
        label={
          <div className="InviteTeamMemberModal__dropdown-placeholder">
            Select Option
          </div>
        }
        className="InviteTeamMemberModal__dropdown-container"
        items={measureRestrictions}
        itemToString={(item) => item.name}
        selectedItem={measureAccess}
        onChange={({ selectedItem }) =>
          updateUserForm('measureAccess', selectedItem)
        }
      />
      {banners.map(({ banner, userGroups, id: listItemId }, index) => {
        const bannerItems = bannerList.filter(
          ({ id }) =>
            !banners.find((bannerData) => id === bannerData.banner?.id) ||
            banner?.id === id
        );
        const userGroupItems = bannerList.find(
          ({ id }) => banner?.id === id
        )?.user_groups;
        return (
          <div className="InviteTeamMemberModal__banner-row" key={listItemId}>
            <Dropdown
              id={`user-banner-access-${listItemId}`}
              data-testid={`user-banner-access-${listItemId}`}
              titleText="Banner Access"
              label={
                <div className="InviteTeamMemberModal__dropdown-placeholder">
                  Select Option
                </div>
              }
              itemToString={(item) => item.name}
              items={bannerItems}
              selectedItem={banner}
              onChange={({ selectedItem }) => {
                const { id, name } = selectedItem;
                updateBanners(listItemId, {
                  banner: { id, name },
                  userGroups: [],
                });
              }}
            />
            {banner && (
              <>
                <div className="InviteTeamMemberModal__multi-select-container">
                  <MultiSelect
                    label="Select Options"
                    id={`user-group-access-${banner.id}`}
                    titleText="User Group Access"
                    items={userGroupItems.map(({ name }) => name)}
                    invalid={userGroups.length < 1}
                    invalidText="Please select at least 1 option"
                    onChange={({ selectedItems }) => {
                      const newItems = userGroupItems.filter(({ name }) =>
                        selectedItems.includes(name)
                      );
                      updateBanners(listItemId, {
                        userGroups: newItems,
                      });
                    }}
                    selectedItems={userGroups.map(({ name }) => name)}
                  />
                </div>
                {index > 0 && (
                  <Button
                    kind="tertiary"
                    className="InviteTeamMemberModal__delete-banner-button"
                    onClick={() => {
                      removeBanner(listItemId);
                    }}
                  >
                    <TrashCan />
                  </Button>
                )}
              </>
            )}
          </div>
        );
      })}
      {banners.every(({ banner }) => !!banner) && !showNotification && (
        <Button
          kind="secondary"
          onClick={() => {
            addNewEmptyBannerItem();
          }}
        >
          Add Banner <Add />
        </Button>
      )}
      {showNotification && (
        <InlineNotification
          title="No more banners available"
          lowContrast
          kind="info"
          className="UserAccessStep__notification"
        />
      )}
    </>
  );
};

export default UserAccessStep;
