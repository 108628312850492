import type { FunctionComponent } from 'react';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import * as d3 from 'd3';
import { InlineNotification } from '@carbon/react';
import { getSVGPath } from '../../utils/reportUtils';
import { AssortmentContext } from '../../providers/AssortmentProvider';
import { SidePanelContext } from '../../providers/SidePanelProvider';
import type { DendrogramProps } from './Dendrogram';
import { getNodes, clearSVG } from '../../utils/dendroUtils';

const AssortmentDendrogram: FunctionComponent<DendrogramProps> = ({
  data,
  numSkus,
}) => {
  const topMargin = 48;
  const height = numSkus * 36;
  const ref = useRef(null);
  const [width, setWidth] = useState(0);
  const [selectedPaths, setSelectedPaths] = useState([]);
  const {
    selectedProducts,
    selectedNode,
    updateSelectedProducts,
    updateSelectedNode,
    resetSelection,
  } = useContext(AssortmentContext);

  const nodes = useMemo(() => getNodes(data, height, width), [data, width]);

  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      setWidth(entries[0].contentRect.width);
    });
    observer.observe(ref.current);
    return () => {
      resetTree();
      ref.current && observer.unobserve(ref.current);
    };
  }, []);

  useEffect(() => {
    clearSVG();
    drawSVG();
  }, [width, selectedNode, selectedPaths]);

  const drawSVG = () => {
    const svg = d3
      .select('#dendrogram')
      .append('svg')
      .attr('width', width)
      .attr('height', height);

    svg
      .selectAll('path')
      .data(nodes)
      .enter()
      .append('path')
      .attr('d', (d) => getSVGPath(d, width))
      .style('fill', 'none')
      .style('stroke', (d) =>
        selectedPaths.map((n) => n.data.node_id).includes(d.data.node_id)
          ? '#f25829'
          : '#1b71d5'
      )
      .attr('stroke-width', '1px');

    svg
      .selectAll('circle')
      .data(nodes)
      .enter()
      .append('circle')
      .attr('cx', (d) => (d.data.dist === 1 ? 4 : width - d.data.dist * width))
      .attr('cy', (d) => d.x)
      .attr('r', 4)
      .style('fill', (d) =>
        selectedPaths.map((n) => n.data.node_id).includes(d.data.node_id) ||
        d.data.node_id === selectedNode?.data.node_id
          ? '#f25829'
          : '#1b71d5'
      )
      .style('cursor', 'pointer')
      .on('click', function (_, node) {
        selectedNode?.data.node_id === node.data.node_id
          ? resetTree()
          : updateSelectedNode(node);
      });
  };

  const { toggleSidePanelExpand } = useContext(SidePanelContext);

  useEffect(() => {
    if (selectedNode) {
      updateSelection();
    }
  }, [selectedNode]);

  const updateSelection = () => {
    const productIds = selectedNode.data.name.split('-');
    updateSelectedProducts(productIds);
  };

  useEffect(() => {
    if (selectedNode && selectedProducts) {
      const parents = selectedNode
        .descendants()
        .filter(
          (n) => n.depth > selectedNode.depth && n.data.name.includes('-')
        );

      const descendants = nodes.filter((n) =>
        selectedProducts.includes(n.data.name)
      );

      setSelectedPaths([...parents, ...descendants]);
    } else {
      setSelectedPaths([]);
    }
  }, [selectedNode, selectedProducts]);

  const resetTree = () => {
    resetSelection();
    setSelectedPaths([]);
    toggleSidePanelExpand(false);
  };

  return (
    <div id="dendrogram" style={{ marginTop: topMargin }} ref={ref}>
      {!data.children && (
        <InlineNotification
          title="This dendrogram has no valid data to display. Please try re-running the
          report with different parameters."
          hideCloseButton
          lowContrast
          kind="warning"
        />
      )}
    </div>
  );
};

export default AssortmentDendrogram;
