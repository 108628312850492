import type { FunctionComponent } from 'react';
import '../../styles/components/checkmark.scss';

interface CheckmarkProps {
  animated?: boolean;
}

export const Checkmark: FunctionComponent<CheckmarkProps> = ({
  animated = true,
}) => {
  return (
    <svg
      className={`checkmark ${animated ? 'animated' : 'static'}`}
      data-testid="completed-checkmark"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 52 52"
    >
      <circle
        className="checkmark__circle"
        cx="24"
        cy="24"
        r="24"
        fill="none"
      />
      <path
        className="checkmark__check"
        fill="none"
        d="M14.1 27.2l7.1 7.2 16.7-16.8"
      />
    </svg>
  );
};
