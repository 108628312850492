/* eslint-disable indent */
import {
  UPDATE_USER_FORM,
  UPDATE_USER_FORM_ERROR,
  UPDATE_USER_FORM_BANNERS,
  ADD_NEW_EMPTY_BANNER,
  REMOVE_BANNER_FROM_LIST,
  RESET_USER_FORM,
} from '../constants/reducers';
import type {
  UserRoleData,
  UserTypeData,
  UserAuthData,
  UserMeasureAccessData,
} from './AdminPanelReducer';

export interface BannerFormData {
  id: number;
  banner: {
    id: number;
    name: string;
  } | null;
  userGroups: {
    id: number;
    name: string;
  }[];
}

export interface UserForm {
  name: string;
  email: string;
  userRole: UserRoleData;
  userType: UserTypeData;
  authType: UserAuthData;
  jobTitle?: string;
  department?: string;
  supplierName?: string;
  measureAccess: UserMeasureAccessData;
  banners: BannerFormData[];
}

export type UserFormError = {
  [key in keyof UserForm]?: string[];
};

export type UserFormWithError = UserForm & {
  formErrors: UserFormError;
};

type UpdateUserForm<T extends keyof UserForm> = {
  type: typeof UPDATE_USER_FORM;
  key: keyof UserForm;
  value: UserForm[T];
};

type UpdateUserFormErrors = {
  type: typeof UPDATE_USER_FORM_ERROR;
  value: UserFormError;
};

type UpdateBannerData = {
  type: typeof UPDATE_USER_FORM_BANNERS;
  id: number;
  value: Partial<BannerFormData>;
};

type AddNewEmptyBanner = {
  type: typeof ADD_NEW_EMPTY_BANNER;
};

type RemoveBannerFromList = {
  type: typeof REMOVE_BANNER_FROM_LIST;
  id: number;
};

type ResetUserForm = {
  type: typeof RESET_USER_FORM;
  userData?: UserForm;
};

export type FormAction =
  | UpdateUserForm<keyof UserForm>
  | ResetUserForm
  | UpdateBannerData
  | UpdateUserFormErrors
  | AddNewEmptyBanner
  | RemoveBannerFromList;

export const userFormInitState: UserFormWithError = {
  name: '',
  email: '',
  userRole: null,
  userType: null,
  authType: null,
  measureAccess: null,
  banners: [
    {
      id: 1,
      banner: null,
      userGroups: [],
    },
  ],
  jobTitle: '',
  department: '',
  formErrors: {},
};

export const userFormReducer = (
  state: UserFormWithError,
  action: FormAction
): UserFormWithError => {
  switch (action.type) {
    case UPDATE_USER_FORM:
      return {
        ...state,
        [action.key]: action.value,
        formErrors: {
          ...state.formErrors,
          [action.key]: null,
        },
      };
    case ADD_NEW_EMPTY_BANNER: {
      const newBanners = [
        ...state.banners,
        {
          id: state.banners.at(-1).id + 1,
          banner: null,
          userGroups: [],
        },
      ];
      return {
        ...state,
        banners: newBanners,
      };
    }
    case UPDATE_USER_FORM_BANNERS: {
      const { value, id } = action;
      const newBanners = state.banners.map((bannerData) => {
        return bannerData.id === id
          ? {
              ...bannerData,
              ...value,
            }
          : bannerData;
      });
      return {
        ...state,
        banners: newBanners,
      };
    }
    case REMOVE_BANNER_FROM_LIST: {
      const { id } = action;
      const newBanners = state.banners.filter((banner) => banner.id !== id);
      return {
        ...state,
        banners: newBanners,
      };
    }
    case UPDATE_USER_FORM_ERROR: {
      return {
        ...state,
        formErrors: {
          ...state.formErrors,
          ...action.value,
        },
      };
    }
    case RESET_USER_FORM:
      return { ...userFormInitState, ...action.userData };
  }
};
