import type { CSSProperties, FunctionComponent, ReactNode } from 'react';
import { ChevronLeft, ChevronRight } from '@carbon/icons-react';

interface CustomArrowWrapperProps {
  className?: string;
  style?: CSSProperties;
  direction: 'left' | 'right';
  onClick?: (e) => void;
}

const CustomSliderArrow: FunctionComponent<CustomArrowWrapperProps> = ({
  className,
  style,
  direction,
  onClick,
}) => {
  const arrow: Record<CustomArrowWrapperProps['direction'], ReactNode> = {
    left: <ChevronLeft />,
    right: <ChevronRight />,
  };
  return (
    <div className={className} style={style} onClick={onClick}>
      {arrow[direction]}
    </div>
  );
};

export default CustomSliderArrow;
