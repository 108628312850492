import type { FunctionComponent } from 'react';

interface GridRectangleProps {
  rowStart: number;
  rowEnd: number;
  columnStart: number;
  columnEnd: number;
  colour: string;
}

const Rectangle: FunctionComponent<GridRectangleProps> = ({
  rowStart,
  rowEnd,
  columnStart,
  columnEnd,
  colour,
}) => {
  return (
    <div
      data-testid="venn-rectangle"
      className="venn-rectangle"
      style={{
        gridRowStart: rowStart,
        gridRowEnd: rowEnd,
        gridColumnStart: columnStart,
        gridColumnEnd: columnEnd,
        backgroundColor: colour,
      }}
    />
  );
};

export default Rectangle;
