/* eslint-disable indent */
import type { FunctionComponent } from 'react';
import {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useReducer,
} from 'react';
import { Button, InlineLoading, ProgressBar } from '@carbon/react';
import { Edit, SoftwareResourceCluster } from '@carbon/icons-react';
import {
  CLEAR_HIGHLIGHT,
  INITIAL_LOAD,
  RESET_SELECTION,
  TOGGLE_SAVE_STATE,
  UPDATE_ALL_OPTIMISER_DATA,
  UPDATE_ASSORTMENT_DATA,
  UPDATE_CDT_NODES,
  UPDATE_CDT_ROOT_NAME,
  UPDATE_HIGHLIGHT,
  UPDATE_HIGHLIGHT_LEGEND,
  UPDATE_LAST_SAVED_DATA,
  UPDATE_OPTIMISER_ROWS,
  UPDATE_OPTIMISER_STRATEGIES,
  UPDATE_SAVING_MESSAGE,
  UPDATE_SELECTED_NODE,
  UPDATE_SELECTED_PRODUCTS,
  UPDATING_OPTIMISATION,
  UPDATING_OPTIMISATION_STATUS,
  VALIDATE_OPTIMISER,
} from '../constants/reducers';
import type {
  AssortmentData,
  AssortmentState,
  CDTNode,
  ClusterOptimiserStrategy,
  Highlight,
  HighlightLegendEntry,
  AssortmentOptimisationPayload,
  OptimisationStatus,
  AssortmentDendroPayload,
} from '../reducers/AssortmentReducer';
import assortmentReducer, {
  initialAssortmentState,
} from '../reducers/AssortmentReducer';
import { ReportContext } from './ReportProvider';
import { useAuth0 } from '@auth0/auth0-react';
import apiRequest from '../api';
import '../styles/components/assortment.scss';
import type { HierarchyNode } from 'd3';
import DendroNodeInfo from '../components/Report/Assortment/DendroNodeInfo';
import { SidePanelContext } from './SidePanelProvider';
import { AppContext } from './AppProvider';
import { CACHE_KEY } from '../constants/api';
import type { ResponsePayload } from '../components/Fetch';
import Fetch from '../components/Fetch';
import {
  findVisual,
  getReportApiUrl,
  getTaskIdFromUrl,
} from '../utils/reportUtils';
import type { SourceDestination } from '../constants/assortmentFileSync';
import {
  attributes1,
  attributes2,
  attributes3,
  attributes4,
  idKey1,
  idKey2,
  idKey3,
  idKey4,
} from '../constants/assortmentFileSync';
import { useNavigate, useSearchParams } from 'react-router-dom';
import type { ReportConfig, VisualData } from '../reducers/ReportReducer';
import { VisualType } from '../reducers/ReportReducer';
import {
  CDT_TAB_INDEX,
  DENDRO_TAB_INDEX,
  OPTIMISER_TAB_INDEX,
} from '../constants/values';
import CDT from '../components/Charts/CDT';
import Tooltip from '../components/Tooltip';
import ClusterStrategy from '../components/Optimise/ClusterStrategy';
import { ModalContext } from './ModalProvider';
import HighlightControls from '../components/Optimise/HighlightControls';
import {
  assortmentOptimiserOpenSuccess,
  assortmentOptimiserOpenFailure,
  assortmentOptimiserModalCancelClick,
} from '../constants/posthog';
import usePosthog from '../utils/posthog';
import { WebsocketContext } from './WebsocketProvider';
import ImpactAnalysisForm from '../components/Report/Assortment/ImpactAnalysisForm';
import {
  getFileUpdateReportPath,
  getOptimiserDataUpdates,
  getTotalLevelKey,
  getOptimiserMode,
  getLargestPlanHeader,
} from '../utils/CDTUtils';
import {
  OptimiserMode,
  OptimiserStrategy,
} from '../reducers/OptimiserFormReducer';
import type { AssortmentVisualDataResponse } from '../types/apiTypes';
import SharedDropdowns from '../components/Report/Dropdowns/SharedDropdowns';

type InitialLoad = (
  assortmentData: AssortmentData[],
  cdtRootName: string,
  headers: Header[]
) => void;
type UpdateAssortmentData = (assortmentData: AssortmentData[]) => void;
type UpdateLastSavedData = (assortmentData: AssortmentData[]) => void;
type UpdateAllOptimiserData = (optimiserData: {
  [key: string]: AssortmentData[];
}) => void;
type UpdateOptimiserStrategies = (optimiserStrategies: {
  [key: string]: ClusterOptimiserStrategy;
}) => void;
type UpdateOptimiserRows = (
  optimiserData: AssortmentData[],
  dropdownKey: string
) => void;
type UpdateCDTNodes = (cdtNodes: CDTNode[]) => void;
type UpdateCDTRootName = (cdtRootName: string) => void;
type SaveAssortmentData = () => void;
type SaveOptimiserData = () => void;
type ToggleSaveState = (saving: boolean) => void;
type UpdateSelectedProducts = (productIds: string[]) => void;
type UpdateSelectedNode = (node: HierarchyNode<DendrogramNode>) => void;
type ResetSelection = () => void;
type UpdateSavingMessage = (savingMessage: string) => void;
type RefreshVisual = (apiUrl: string) => void;
type ToggleOptimising = (updating: boolean) => void;
type UpdateOptimisationStatus = (status: OptimisationStatus) => void;
type ValidateOptimiser = (valid: boolean) => void;
type SaveOptimiserGrid = () => Promise<void>;
type UpdateHighlightLegend = (
  legend: Pick<HighlightLegendEntry, 'key' | 'items'>
) => void;
type UpdateHighlight = (highlight: Highlight) => void;
type ClearHighlight = () => void;

export interface AssortmentContextType extends AssortmentState {
  dendroGridId: number;
  optimiserId: number;
  initialLoad: InitialLoad;
  updateAssortmentData: UpdateAssortmentData;
  updateLastSavedData: UpdateLastSavedData;
  updateAllOptimiserData: UpdateAllOptimiserData;
  updateOptimiserRows: UpdateOptimiserRows;
  updateCDTNodes: UpdateCDTNodes;
  updateCDTRootName: UpdateCDTRootName;
  saveAssortmentData: SaveAssortmentData;
  saveOptimiserData: SaveOptimiserData;
  toggleSaveState: ToggleSaveState;
  updateSelectedProducts: UpdateSelectedProducts;
  updateSelectedNode: UpdateSelectedNode;
  resetSelection: ResetSelection;
  updateSavingMessage: UpdateSavingMessage;
  refreshVisual: RefreshVisual;
  toggleOptimising: ToggleOptimising;
  updateOptimisationStatus: UpdateOptimisationStatus;
  validateOptimiser: ValidateOptimiser;
  saveOptimiserGrid: SaveOptimiserGrid;
  updateHighlightLegend: UpdateHighlightLegend;
  updateHighlight: UpdateHighlight;
  clearHighlight: ClearHighlight;
}

interface BaseSynchronisePayload {
  readonly from_file: string;
  readonly to_file: string;
  readonly row_value: string;
  readonly id_key: SourceDestination;
  readonly attributes_to_copy: SourceDestination[];
}

interface BasicSynchronisePayload extends BaseSynchronisePayload {
  readonly row_key: string;
}

interface AdvancedSynchronisePayload extends BaseSynchronisePayload {
  readonly from_row_key: string;
  readonly to_row_key: string;
}

type SynchronisePayload = BasicSynchronisePayload | AdvancedSynchronisePayload;

interface AssortmentProviderProps {
  children?: JSX.Element | JSX.Element[];
  reportTemplateId: string;
}

export enum OPTIMISER_STEP {
  OPTIMISE = 'optimise',
  IMPACT_ANALYSIS = 'impact-analysis',
  PRODUCT_COUNTS = 'product-counts',
  AGGREGATE_PLANS = 'aggregate-plans',
}

export const AssortmentContext = createContext<AssortmentContextType | null>(
  null
);

const AssortmentProvider: FunctionComponent<AssortmentProviderProps> = ({
  children,
  reportTemplateId,
}) => {
  const {
    queryCache,
    bannerId,
    groupId,
    user,
    reportModules,
    updateReportModules,
  } = useContext(AppContext);
  const { updateModal, toggleModal } = useContext(ModalContext);
  const [searchParams] = useSearchParams();
  const posthogEvent = usePosthog();
  const {
    visualsData,
    reportConfig,
    tabIndex,
    dropdownSelections,
    updateVisualsData,
    updateReportConfig,
    updateTabIndex,
  } = useContext(ReportContext);
  const { broadcastChannel } = useContext(WebsocketContext);
  const navigate = useNavigate();
  const [state, dispatch] = useReducer(
    assortmentReducer,
    initialAssortmentState
  );
  const { visuals } = reportConfig.configuration;

  const totalLevelKey = getTotalLevelKey(reportConfig);
  const dendroGridVisualConfig = findVisual(visuals[reportTemplateId], (vis) =>
    vis.apiUrl.includes('cdt-dendro-grid')
  );
  const optimiserVisualConfig = findVisual(visuals[reportTemplateId], (vis) =>
    [VisualType.OPTIMISER_GRID, VisualType.OPTIMISER_PLAN_GRID].includes(
      vis.type
    )
  );

  const { id: dendroGridId, apiUrl: dendroGridUrl } = dendroGridVisualConfig;
  const { id: optimiserId, apiUrl: optimiserUrl } = optimiserVisualConfig ?? {};

  const reportTaskId = getTaskIdFromUrl(dendroGridUrl);

  const showOptimiserButton =
    reportConfig.user_id === user.id && user.user_type !== 'supplier';
  const { getAccessTokenSilently } = useAuth0();
  const { renderSidePanelContent, toggleSidePanelExpand } =
    useContext(SidePanelContext);

  const { user_group } = reportConfig;

  const dendroVisual = visualsData[dendroGridId];

  const optimiserVisual = visualsData[optimiserId] as
    | VisualData<AssortmentData>
    | undefined;

  const clusterStrategy =
    state.optimiserStrategies[
      dropdownSelections?.[OPTIMISER_TAB_INDEX]?.[optimiserId]
    ];

  const showErrorModal = () => {
    updateModal({
      type: 'error',
      title: 'Something went wrong',
      body: 'There was an error saving the assortment data. Please try again. If the issue persists, please contact the helpdesk.',
    });
  };

  const reportApiUrl = useMemo(
    () => getReportApiUrl(visuals[reportTemplateId][0].apiUrl),
    [visuals[reportTemplateId][0].apiUrl]
  );

  const cdtPathPayload = {
    path: `${bannerId}/user-groups/${groupId}/${reportApiUrl}/`,
  };
  const payloads = {
    [OPTIMISER_STEP.IMPACT_ANALYSIS]: cdtPathPayload,
    [OPTIMISER_STEP.PRODUCT_COUNTS]: cdtPathPayload,
  };

  const showOptimiser = async ({
    isEditing,
    origin,
    isFirstOptimisation = false,
  }: {
    isEditing: boolean;
    origin: string;
    isFirstOptimisation?: boolean;
  }) => {
    try {
      if (state.unsavedChanges) {
        await saveAssortmentData(false);
      }
      posthogEvent(assortmentOptimiserOpenSuccess, {
        isFirstOptimisation,
        isEditing,
        reportType: reportConfig.url_route,
        origin,
      });
      navigate(`optimise${isEditing ? '?mode=edit' : ''}`);
    } catch {
      showErrorModal();
      posthogEvent(assortmentOptimiserOpenFailure, {
        reportType: reportConfig.url_route,
        origin,
      });
    }
  };

  const saveAssortmentData = async (update?: boolean) => {
    toggleSaveState(true);
    try {
      const hasPlanOptimisationGrid =
        getOptimiserMode(reportConfig, reportTemplateId) === OptimiserMode.Plan;

      const token = await getAccessTokenSilently();

      const savePayload: AssortmentDendroPayload = {
        path: getFileUpdateReportPath(dendroGridUrl),
        payload: {
          rows: {
            '1': state.assortmentData.map(({ PRODUCT_ID, cd1, cd2, cd3 }) => ({
              PRODUCT_ID,
              cd1,
              cd2,
              cd3,
            })),
          },
          cdtRootName: state.cdtRootName,
        },
      };

      const reportUrl = `${bannerId}/user-groups/${user_group}/${reportApiUrl}`;

      const synchronisationPayloads: SynchronisePayload[] = [
        {
          from_file: `${reportUrl}/cdt-dendro-grid`,
          to_file: `${reportUrl}/cdt-optimisation-grid`,
          row_key: 'rows',
          row_value: '1',
          id_key: idKey1,
          attributes_to_copy: attributes1,
        },
        {
          from_file: `${reportUrl}/cdt-dendro-grid`,
          to_file: `${reportUrl}/cdt-optimisation-grid-read-only`,
          row_key: 'rows',
          row_value: '1',
          id_key: idKey1,
          attributes_to_copy: attributes1,
        },
        hasPlanOptimisationGrid && {
          from_file: `${reportUrl}/cdt-dendro-grid`,
          to_file: `${reportUrl}/cdt-plan-optimisation-grid`,
          row_key: 'rows',
          row_value: '1',
          id_key: idKey1,
          attributes_to_copy: attributes1,
        },
        {
          from_file: `${reportUrl}/cdt-dendro-grid`,
          to_file: `${reportUrl}/cdt-rank-grid`,
          row_key: 'rows',
          row_value: '1',
          id_key: idKey1,
          attributes_to_copy: attributes1,
        },
        {
          from_file: `${reportUrl}/cdt-dendro-grid`,
          to_file: `${reportUrl}/cdt-compscore-data-read-only`,
          row_key: 'rows',
          row_value: '1',
          id_key: idKey2,
          attributes_to_copy: attributes2,
        },
        {
          from_file: `${reportUrl}/cdt-dendro-grid`,
          to_file: `${reportUrl}/cdt-subs-data-read-only`,
          row_key: 'rows',
          row_value: '1',
          id_key: idKey3,
          attributes_to_copy: attributes3,
        },
        {
          from_file: `${reportUrl}/cdt-dendro-grid`,
          to_file: `${reportUrl}/cdt-payload`,
          from_row_key: 'rows',
          to_row_key: 'new_products',
          row_value: '1',
          id_key: idKey4,
          attributes_to_copy: attributes4,
        },
      ].filter(Boolean);

      updateSavingMessage('Saving changes...');

      await apiRequest<AssortmentDendroPayload>(
        '/assortments/file-updates',
        'POST',
        token,
        savePayload
      );

      updateSavingMessage('Synchronising data...');

      await Promise.all(
        synchronisationPayloads.map((payload) =>
          apiRequest('/assortments/synchronise', 'POST', token, payload)
        )
      );

      if (update) {
        updateSavingMessage('Updating data...');

        await Promise.all([
          refreshVisual('cdt-dendro-grid'),
          ...(hasPlanOptimisationGrid
            ? [refreshVisual('cdt-plan-optimisation-grid')]
            : [refreshVisual('cdt-optimisation-grid')]),
          refreshVisual('cdt-rank-grid'),
        ]);
      }

      updateLastSavedData(state.assortmentData);
    } catch (err) {
      throw new Error('Something went wrong saving the assortment data.');
    } finally {
      toggleSaveState(false);
    }
  };

  const saveOptimiserFileUpdates = async ({
    data,
    includeActions,
  }: {
    data: VisualData<AssortmentData>;
    includeActions: boolean;
  }) => {
    const token = await getAccessTokenSilently();

    const savePayload: AssortmentOptimisationPayload = {
      path: getFileUpdateReportPath(optimiserUrl),
      payload: {
        rows: getOptimiserDataUpdates({ data, includeActions }),
      },
    };

    return apiRequest<AssortmentOptimisationPayload>(
      '/assortments/file-updates',
      'POST',
      token,
      savePayload
    );
  };

  const saveOptimiserGrid = async () => {
    try {
      toggleSaveState(true);
      updateSavingMessage('Saving data...');

      await saveOptimiserFileUpdates({
        data: { ...visualsData[optimiserId], rows: state.optimiserData },
        includeActions: false,
      });
    } finally {
      toggleSaveState(false);
    }
  };

  const onMessage = async (event: MessageEvent) => {
    const token = await getAccessTokenSilently();
    const response = JSON.parse(event.data);
    const { data } = response;

    const { progress, message, next_step, complete, error } = data;

    updateOptimisationStatus({
      progress,
      message,
    });

    if (error) {
      showErrorModal();
    } else {
      next_step &&
        apiRequest(
          `/assortments/${next_step}`,
          'POST',
          token,
          payloads[next_step]
        );

      if (complete) {
        const visualUrls = [
          'cdt-impanal-counts',
          'cdt-impanal-forecasts',
          'cdt-optimisation-grid',
        ];
        const promises = visualUrls
          .map((url) =>
            findVisual(
              visuals[reportTemplateId],
              (vis) => !!vis.apiUrl.match(url)
            )
          )
          .filter(Boolean)
          .map(async (visual) => {
            const { data } = await apiRequest<
              ResponsePayload<AssortmentVisualDataResponse>
            >('/reports' + visual.apiUrl, 'GET', token, null);
            return { visualId: visual.id, data };
          });
        const responses = await Promise.all(promises);

        responses.forEach((response) => {
          const { visualId, data } = response;
          updateVisualsData(visualId, data);
          if (visualId === optimiserId) {
            updateAllOptimiserData(
              data.rows as { [key: string]: AssortmentData[] }
            );
          }
        });

        validateOptimiser(true);
        toggleOptimising(false);
      }
    }
  };

  useEffect(() => {
    if (broadcastChannel) {
      broadcastChannel.addEventListener('message', onMessage);
    }

    return () => broadcastChannel.removeEventListener('message', onMessage);
  }, [broadcastChannel, payloads]);

  const saveOptimiserData = async () => {
    toggleOptimising(true);
    try {
      const token = await getAccessTokenSilently();

      updateOptimisationStatus({
        progress: 10,
        message: 'Saving optimiser data...',
      });

      await saveOptimiserFileUpdates({
        data: { ...visualsData[optimiserId], rows: state.optimiserData },
        includeActions: true,
      });

      updateOptimisationStatus({
        progress: 30,
        message: 'Running impact analysis...',
      });
      apiRequest(
        '/assortments/impact-analysis',
        'POST',
        token,
        payloads[OPTIMISER_STEP.IMPACT_ANALYSIS]
      );
    } catch {
      showErrorModal();
    }
  };

  const refreshVisual = async (apiUrl: string) => {
    try {
      const visual = findVisual(
        visuals[reportTemplateId],
        (vis) => !!vis.apiUrl.match(apiUrl)
      );
      const token = await getAccessTokenSilently();
      const response = await apiRequest<
        ResponsePayload<AssortmentVisualDataResponse>
      >('/reports' + visual.apiUrl, 'GET', token);
      updateVisualsData(visual.id, response.data);
    } catch {
      return new Error('Visual data failed to refresh');
    }
  };

  const openReportPanel = () => {
    renderSidePanelContent(
      <ImpactAnalysisForm
        reportModules={reportModules}
        groupId={groupId}
        reportConfig={reportConfig}
        saveOptimiserGrid={saveOptimiserGrid}
        reportTaskId={reportTaskId}
        optimiserMode={getOptimiserMode(reportConfig, reportTemplateId)}
        reportTemplateIndex={0}
      />,
      {
        title: 'Delist Transfer Analysis request',
      },
      true
    );
  };

  const initialLoad: InitialLoad = (assortmentData, cdtRootName, headers) =>
    dispatch({ type: INITIAL_LOAD, assortmentData, cdtRootName, headers });
  const updateAssortmentData: UpdateAssortmentData = (assortmentData) =>
    dispatch({ type: UPDATE_ASSORTMENT_DATA, assortmentData });
  const updateLastSavedData: UpdateLastSavedData = (lastSavedData) =>
    dispatch({ type: UPDATE_LAST_SAVED_DATA, lastSavedData });
  const updateAllOptimiserData: UpdateAllOptimiserData = (optimiserData) =>
    dispatch({ type: UPDATE_ALL_OPTIMISER_DATA, optimiserData });
  const updateOptimiserStrategies: UpdateOptimiserStrategies = (
    optimiserStrategies
  ) => dispatch({ type: UPDATE_OPTIMISER_STRATEGIES, optimiserStrategies });
  const updateOptimiserRows: UpdateOptimiserRows = (
    optimiserData,
    dropdownKey
  ) => dispatch({ type: UPDATE_OPTIMISER_ROWS, optimiserData, dropdownKey });
  const updateCDTNodes: UpdateCDTNodes = (cdtNodes) =>
    dispatch({ type: UPDATE_CDT_NODES, cdtNodes });
  const updateCDTRootName: UpdateCDTRootName = (cdtRootName) =>
    dispatch({ type: UPDATE_CDT_ROOT_NAME, cdtRootName });
  const toggleSaveState: ToggleSaveState = (saving) =>
    dispatch({ type: TOGGLE_SAVE_STATE, saving });
  const updateSelectedProducts: UpdateSelectedProducts = (productIds) =>
    dispatch({ type: UPDATE_SELECTED_PRODUCTS, productIds });
  const updateSelectedNode: UpdateSelectedNode = (node) =>
    dispatch({ type: UPDATE_SELECTED_NODE, node });
  const resetSelection: ResetSelection = () =>
    dispatch({ type: RESET_SELECTION });
  const updateSavingMessage: UpdateSavingMessage = (savingMessage) =>
    dispatch({ type: UPDATE_SAVING_MESSAGE, savingMessage });
  const toggleOptimising: ToggleOptimising = (updating) =>
    dispatch({ type: UPDATING_OPTIMISATION, updating });
  const updateOptimisationStatus: UpdateOptimisationStatus = (status) =>
    dispatch({ type: UPDATING_OPTIMISATION_STATUS, status });
  const validateOptimiser: ValidateOptimiser = (valid) =>
    dispatch({ type: VALIDATE_OPTIMISER, valid });
  const updateHighlightLegend: UpdateHighlightLegend = ({ key, items }) =>
    dispatch({ type: UPDATE_HIGHLIGHT_LEGEND, key, items });
  const updateHighlight: UpdateHighlight = (highlight) =>
    dispatch({ type: UPDATE_HIGHLIGHT, highlight });
  const clearHighlight: ClearHighlight = () =>
    dispatch({ type: CLEAR_HIGHLIGHT });

  useEffect(() => {
    const interval = setInterval(() => {
      if (state.unsavedChanges && !state.saving) {
        saveAssortmentData();
      }
    }, 15000);
    return () => clearInterval(interval);
  }, [state.unsavedChanges, state.assortmentData]);

  useEffect(() => {
    if (
      !reportConfig.configuration.switchers[reportTemplateId].find(
        (s) => s.name === 'CDT'
      )
    ) {
      const newSwitchers = [
        ...reportConfig.configuration.switchers[reportTemplateId],
      ];
      newSwitchers.splice(CDT_TAB_INDEX, 0, {
        name: 'CDT',
        visualContent: [],
      });
      const configWithCDTTab: ReportConfig = {
        ...reportConfig,
        configuration: {
          ...reportConfig.configuration,
          switchers: {
            ...reportConfig.configuration.switchers,
            [reportTemplateId]: newSwitchers,
          },
        },
      };
      updateReportConfig(configWithCDTTab);
    }
  }, [reportConfig]);

  useEffect(() => {
    const tabIndex = Number(searchParams.get('tabIndex'));
    if (reportConfig.configuration.switchers[reportTemplateId][tabIndex]) {
      updateTabIndex(Number(tabIndex));
    }
  }, [reportConfig.configuration.switchers]);

  useEffect(() => {
    if (dendroVisual) {
      const { rows, cdtRootName, headers } = dendroVisual;
      if (state.assortmentData.length === 0 && rows[1]) {
        initialLoad(rows[1] as AssortmentData[], cdtRootName, headers[1]);
      }
    }
  }, [dendroVisual]);

  useEffect(() => {
    if (
      optimiserVisual &&
      state.optimiserData === initialAssortmentState.optimiserData
    ) {
      const { rows, headers, info } = optimiserVisual;

      updateAllOptimiserData(rows);

      const strategies = Object.entries(headers).reduce(
        (strategyAccumulator, [key, headerList]) => {
          const actionColumn = headerList.find((h) => h.key === 'action');
          const strategyMatches = actionColumn?.header.match(/([B|D])\/(\d*)/);

          const planHeader = strategyMatches
            ? undefined
            : getLargestPlanHeader(headerList);

          const strategy = (info?.strategy ??
            strategyMatches?.[1].toLowerCase() ??
            OptimiserStrategy.Depth) as OptimiserStrategy;

          return {
            ...strategyAccumulator,
            [key]: {
              strategy,
              ...(strategyMatches && {
                targetSkuGoal: rows[key].length - Number(strategyMatches[2]),
              }),
              ...(planHeader && {
                totalSkuCount: planHeader.info.skuTarget as number,
              }),
            },
          };
        },
        {} as { [key: string]: ClusterOptimiserStrategy }
      );

      updateOptimiserStrategies(strategies);
    }
  }, [optimiserVisual]);

  useEffect(() => {
    if (state.optimiserData !== initialAssortmentState.optimiserData) {
      updateVisualsData(optimiserId, {
        ...optimiserVisual,
        rows: state.optimiserData,
      });
    }
  }, [state.optimiserData]);

  const assortmentContext: AssortmentContextType = useMemo(
    () => ({
      ...state,
      dendroGridId,
      optimiserId,
      initialLoad,
      updateAssortmentData,
      updateLastSavedData,
      updateAllOptimiserData,
      updateOptimiserRows,
      updateCDTNodes,
      updateCDTRootName,
      saveAssortmentData,
      toggleSaveState,
      updateSelectedProducts,
      updateSelectedNode,
      resetSelection,
      updateSavingMessage,
      saveOptimiserData,
      refreshVisual,
      toggleOptimising,
      updateOptimisationStatus,
      validateOptimiser,
      saveOptimiserGrid,
      updateHighlightLegend,
      updateHighlight,
      clearHighlight,
    }),
    [state]
  );

  useEffect(() => {
    if (state.selectedProducts.length > 0) {
      renderSidePanelContent(
        <DendroNodeInfo
          selectedProducts={state.selectedProducts}
          assortmentData={state.assortmentData}
          updateSelectedProducts={updateSelectedProducts}
          updateAssortmentData={updateAssortmentData}
          toggleSidePanelExpand={toggleSidePanelExpand}
          isAuthor={reportConfig.user_id === user.id}
        />,
        null,
        false
      );
    }

    if (state.selectedProducts.length === 0) {
      updateSelectedNode(null);
    }
  }, [state.selectedProducts, state.assortmentData]);

  const getContent = () => {
    if (state.updatingOptimisation) {
      return (
        <div className="AssortmentLoader">
          <div className="heading-04">Re-calculating your updated range</div>
          <div className="body-02">This may take up to 1 minute.</div>
          <ProgressBar
            helperText={state.optimisationStatus.message}
            value={state.optimisationStatus.progress}
          />
        </div>
      );
    } else {
      return tabIndex === CDT_TAB_INDEX ? <CDT /> : children;
    }
  };

  const runNewAssortmentOptimiser = ({
    isFirstOptimisation,
  }: {
    isFirstOptimisation: boolean;
  }) =>
    showOptimiser({
      isEditing: false,
      origin: 'Assortment Optimiser',
      isFirstOptimisation,
    });

  const confirmNewAssortmentOptimiser = () => {
    updateModal({
      type: 'warning',
      title:
        'Are you sure you want to run a brand new Assortment Optimisation?',
      body: 'The existing assortment optimisation will be replaced with the new one.',
      primaryCTAText: 'New Assortment Optimisation',
      secondaryCTAText: 'Cancel',
      onSecondaryCTAClick: () => {
        toggleModal(false);
        posthogEvent(assortmentOptimiserModalCancelClick);
      },
      onPrimaryCTAClick: () => {
        toggleModal(false);
        runNewAssortmentOptimiser({ isFirstOptimisation: false });
      },
    });
  };

  const showStrategy =
    !state.saving &&
    !state.updatingOptimisation &&
    clusterStrategy &&
    tabIndex === OPTIMISER_TAB_INDEX;

  if (!queryCache[CACHE_KEY.MODULES]) {
    return (
      <Fetch
        apiUrl={`/configs/${bannerId}/user-groups/${groupId}/modules`}
        cacheKey={CACHE_KEY.MODULES}
        initialData={[]}
        loadingMessage="Loading Modules..."
        onReceiveData={(data) => updateReportModules(data)}
      />
    );
  }

  return (
    <AssortmentContext.Provider value={assortmentContext}>
      {showOptimiserButton && (
        <>
          <div className="cdt-controls">
            {state.unsavedChanges && !state.saving && (
              <div className="subtle-text unsaved-changes">
                <Tooltip
                  description="Changes will be autosaved after 15 seconds."
                  style={{ display: 'flex' }}
                >
                  <>You have unsaved changes</>
                </Tooltip>
              </div>
            )}
            {state.saving && (
              <InlineLoading description={state.savingMessage} state="active" />
            )}
          </div>
          <div className="flex justify-space-between align-center">
            <SharedDropdowns
              tab={tabIndex}
              visualIds={
                reportConfig?.configuration?.switchers?.[reportTemplateId][
                  tabIndex
                ]?.visualContent
              }
              hideLabels
            />
            <div className="flex-spacer" />
            {tabIndex === OPTIMISER_TAB_INDEX ? (
              <>
                {showStrategy && (
                  <ClusterStrategy
                    strategy={clusterStrategy.strategy}
                    targetSkuGoal={clusterStrategy.targetSkuGoal}
                    totalSkuCount={clusterStrategy.totalSkuCount}
                  />
                )}
                <div className="optimisation-controls">
                  {dropdownSelections?.[OPTIMISER_TAB_INDEX]?.[optimiserId] ===
                    totalLevelKey.toString() && (
                    <Button
                      className="hide-in-export"
                      disabled={state.saving || state.updatingOptimisation}
                      kind="primary"
                      size="sm"
                      onClick={openReportPanel}
                    >
                      Run Delist Transfer Analysis
                    </Button>
                  )}
                  <Button
                    hasIconOnly
                    onClick={() =>
                      showOptimiser({
                        isEditing: true,
                        origin: 'Edit Optimisation',
                      })
                    }
                    className="AssortmentProvider__edit-button hide-in-export"
                    renderIcon={
                      state.saving || state.updatingOptimisation
                        ? InlineLoading
                        : Edit
                    }
                    disabled={state.saving || state.updatingOptimisation}
                    kind="secondary"
                    size="sm"
                    align="top-right"
                    iconDescription="Edit Optimisation"
                  />
                </div>
              </>
            ) : (
              <Button
                onClick={
                  getOptimiserMode(reportConfig, reportTemplateId)
                    ? confirmNewAssortmentOptimiser
                    : () =>
                        runNewAssortmentOptimiser({ isFirstOptimisation: true })
                }
                className="has-icon hide-in-export"
                renderIcon={
                  state.saving ? InlineLoading : SoftwareResourceCluster
                }
                disabled={state.saving}
                size="sm"
              >
                New Assortment Optimiser
              </Button>
            )}
          </div>
        </>
      )}
      {tabIndex === DENDRO_TAB_INDEX && <HighlightControls />}

      {getContent()}
    </AssortmentContext.Provider>
  );
};

export const assortmentWrapper = (
  children: JSX.Element[],
  reportTemplateId: string
) => (
  <AssortmentProvider reportTemplateId={reportTemplateId}>
    {children}
  </AssortmentProvider>
);

export default AssortmentProvider;
