import { InlineLoading } from '@carbon/react';
import { useAuth0 } from '@auth0/auth0-react';
import LoggedInComponent from './routes/LoggedInComponent';
import LoggedOutComponent from './routes/LoggedOutComponent';

const App = () => {
  const { isAuthenticated, isLoading } = useAuth0();

  if (!isLoading) {
    return isAuthenticated ? <LoggedInComponent /> : <LoggedOutComponent />;
  } else {
    return (
      <div className="app-spinner-container">
        <span>
          <InlineLoading />
        </span>
      </div>
    );
  }
};

export default App;
