import type { FunctionComponent, ReactNode } from 'react';
import { useState } from 'react';
import { Column, ContentSwitcher, Switch } from '@carbon/react';
import { DEFAULT_COLUMN_SPAN } from '../constants/values';

interface NavigationTabsProps {
  readonly tabs: {
    icon?: ReactNode;
    label: string;
    id: string;
  }[];
  readonly onTabClick: (id: string) => void;
}

const ReportContentNavigationTabs: FunctionComponent<NavigationTabsProps> = ({
  tabs,
  onTabClick,
}) => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  return (
    <Column
      lg={DEFAULT_COLUMN_SPAN}
      md={DEFAULT_COLUMN_SPAN / 2}
      sm={DEFAULT_COLUMN_SPAN / 4}
      className="ReportContentNavigationTabs__column"
    >
      <ContentSwitcher
        selectedIndex={selectedIndex}
        className="ReportContentNavigationTabs__switcher"
        onChange={({ index }) => {
          setSelectedIndex(index);
        }}
      >
        {tabs.map(({ id, label, icon }) => (
          <Switch
            key={id}
            name={id}
            className="ReportContentNavigationTabs__tab"
            onClick={() => onTabClick(id)}
          >
            {icon} {label}
          </Switch>
        ))}
      </ContentSwitcher>
    </Column>
  );
};

export default ReportContentNavigationTabs;
