import type { FunctionComponent } from 'react';
import { Droppable } from 'react-beautiful-dnd';

interface DroppableProps {
  id: string;
  children?: JSX.Element | JSX.Element[];
}
const DroppableColumn: FunctionComponent<DroppableProps> = ({
  id,
  children,
}) => {
  return (
    <Droppable droppableId={id}>
      {(provided) => (
        <div
          ref={provided.innerRef}
          {...provided.droppableProps}
          className="item-order__droppable-column"
        >
          {children}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
};

export default DroppableColumn;
