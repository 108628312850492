import type { FunctionComponent, ReactNode } from 'react';
import { createContext, useMemo, useReducer } from 'react';
import {
  attributeValuesReducer,
  initialAttributeValuesState,
  type UploadData,
  type AttributeValuesState,
  type UploadMetadata,
} from '../reducers/AttributeValuesReducer';

import {
  UPDATE_ATTRIBUTE_VALUES_FILE_SPECS,
  UPDATE_ATTRIBUTE_VALUES_UPLOAD_DATA,
  UPDATE_ATTRIBUTE_VALUES_UPLOAD_METADATA,
  RESET_ATTRIBUTE_VALUES_UPLOAD_DATA,
} from '../constants/reducers';
import type { FileSpecs } from '../reducers/CustomGroupsReducer';

export interface AttributeValuesContextType extends AttributeValuesState {
  updateFileSpecs: (fileSpecs: Partial<FileSpecs>) => void;
  updateUploadData: (uploadData: UploadData) => void;
  updateUploadMetadata: (uploadMetadata: UploadMetadata) => void;
  resetUploadData: () => void;
}

interface AttributeValuesProviderProps {
  children?: ReactNode;
}

export const AttributeValuesContext =
  createContext<AttributeValuesContextType | null>(null);

const AttributeValuesProvider: FunctionComponent<
  AttributeValuesProviderProps
> = ({ children }) => {
  const [state, dispatch] = useReducer(
    attributeValuesReducer,
    initialAttributeValuesState
  );

  const updateFileSpecs = (fileSpecs: Partial<FileSpecs>) => {
    dispatch({ type: UPDATE_ATTRIBUTE_VALUES_FILE_SPECS, fileSpecs });
  };

  const updateUploadData = (uploadData: UploadData) =>
    dispatch({ type: UPDATE_ATTRIBUTE_VALUES_UPLOAD_DATA, uploadData });

  const updateUploadMetadata = (uploadMetadata: UploadMetadata) =>
    dispatch({ type: UPDATE_ATTRIBUTE_VALUES_UPLOAD_METADATA, uploadMetadata });

  const resetUploadData = () =>
    dispatch({ type: RESET_ATTRIBUTE_VALUES_UPLOAD_DATA });

  const attributeValuesContext: AttributeValuesContextType = useMemo(() => {
    return {
      uploadData: state.uploadData,
      fileSpecs: state.fileSpecs,
      uploadMetadata: state.uploadMetadata,
      updateFileSpecs,
      updateUploadData,
      updateUploadMetadata,
      resetUploadData,
    };
  }, [state]);

  return (
    <AttributeValuesContext.Provider value={attributeValuesContext}>
      {children}
    </AttributeValuesContext.Provider>
  );
};

export default AttributeValuesProvider;
