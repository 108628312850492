/* eslint-disable indent */
import {
  Button,
  Checkbox,
  DataTable,
  TableContainer,
  Table,
  TableHead,
  TableHeader,
  TableBody,
  TableRow as CarbonTableRow,
  TableCell,
  TableToolbar,
  TableToolbarContent,
  TableBatchActions,
  TableBatchAction,
  TableSelectRow,
  TableSelectAll,
  Tag,
} from '@carbon/react';
import {
  AddAlt,
  Close,
  CloseOutline,
  Edit,
  Store,
  TrashCan,
  Bookmark,
  BookmarkFilled,
} from '@carbon/icons-react';
import type { FunctionComponent } from 'react';
import { useContext, useEffect, useRef, useState } from 'react';
import { ModalContext } from '../../providers/ModalProvider';
import NewLinesForm from './NewLinesForm';
import { OptimiserFormContext } from '../../providers/OptimiserFormProvider';
import type { NewProduct } from '../../reducers/OptimiserFormReducer';
import { OptimiserMode } from '../../reducers/OptimiserFormReducer';
import { AppContext } from '../../providers/AppProvider';
import { formatNumber } from '../../utils/reportUtils';
import {
  assortmentOptimiserAddClusterBtnClick,
  assortmentOptimiserAddProductBtnClick,
  assortmentOptimiserDeleteBtnClick,
  assortmentOptimiserEditBtnClick,
  assortmentOptimiserForceInOutBtnClick,
  assortmentOptimiserApplyClusters,
} from '../../constants/posthog';
import usePosthog from '../../utils/posthog';

const MARGIN_RATE = 'margin_rate';
const newProductHeaders = [
  { key: 'name', header: 'Name' },
  { key: 'cd1', header: 'Decision group' },
  { key: 'locations', header: 'Cluster' },
  { key: 'lookalike', header: 'Lookalike' },
  { key: 'price_point', header: 'Price point' },
  { key: MARGIN_RATE, header: 'Margin rate' },
  { key: 'performance', header: 'Performance' },
];

const NewProducts: FunctionComponent = () => {
  const { user } = useContext(AppContext);
  const posthogEvent = usePosthog();
  const { renderComponent, open, toggleModal } = useContext(ModalContext);
  const optimiserContext = useContext(OptimiserFormContext);
  const { formData, clusters, mode, updateNewProducts } = optimiserContext;
  const { newProducts } = formData;
  const renderModal = (editProduct?: NewProduct) => {
    posthogEvent(assortmentOptimiserAddProductBtnClick, {
      action: 'renders modal',
    });
    renderComponent(
      <NewLinesForm
        optimiserContext={optimiserContext}
        editProduct={editProduct}
      />
    );
  };

  const tableRef = useRef(null);
  const [applyClusters, setApplyClusters] = useState<NewProduct[]>([]);

  const applyingClusters = applyClusters.length > 0;
  const showClusterSelection = clusters.length > 1;
  const headers = newProductHeaders.filter(
    ({ key }) => showClusterSelection || key !== 'locations'
  );

  useEffect(() => {
    if (open) {
      renderModal();
    }

    return () => toggleModal(false);
  }, [optimiserContext]);

  useEffect(() => {
    if (applyClusters.length === 0) {
      if (tableRef.current?.getSelectedRows().length > 0) {
        tableRef.current.handleSelectAll();
      }
    }
  }, [applyClusters]);

  const removeCluster = (clusterKey: number, newProduct: NewProduct) => {
    const updatedProducts = newProducts.map((product) =>
      product.id === newProduct.id
        ? {
            ...product,
            locations: product.locations.filter(
              (cluster) => cluster !== clusterKey
            ),
          }
        : product
    );

    updateNewProducts(updatedProducts);
  };

  const removeAllClusters = (newProduct: NewProduct) => {
    const updatedProducts = newProducts.map((product) =>
      product.id === newProduct.id ? { ...product, locations: [] } : product
    );

    updateNewProducts(updatedProducts);
  };

  const clearSelection = () => {
    setApplyClusters([]);
  };

  const getCellContent = (cell, newProduct: NewProduct) => {
    if (!newProduct) {
      return null;
    }

    switch (cell.info.header) {
      case 'locations': {
        if (cell.value.length === clusters.length) {
          return (
            <TableCell key={cell.id}>
              <div
                className="NewProducts__cluster-group"
                data-testid="cluster-tag-group"
              >
                <Tag
                  className="NewProducts__cluster-tag"
                  filter
                  onClose={() => {
                    removeAllClusters(newProduct);
                    posthogEvent(assortmentOptimiserAddClusterBtnClick);
                    setApplyClusters([{ ...newProduct, locations: [] }]);
                  }}
                >
                  All Clusters
                </Tag>
              </div>
            </TableCell>
          );
        }

        return (
          <TableCell key={cell.id}>
            <div
              className="NewProducts__cluster-group"
              data-testid="cluster-tag-group"
            >
              {cell.value.map((clusterKey) => {
                const label = clusters.find(
                  ({ key }) => key === clusterKey
                ).label;

                return (
                  <Tag
                    key={clusterKey}
                    className="NewProducts__cluster-tag"
                    filter
                    onClose={() => {
                      removeCluster(clusterKey, newProduct);

                      if (cell.value.length > 1) {
                        return;
                      }

                      posthogEvent(assortmentOptimiserAddClusterBtnClick);
                      setApplyClusters([{ ...newProduct, locations: [] }]);
                    }}
                  >
                    {label}
                  </Tag>
                );
              })}
            </div>
          </TableCell>
        );
      }

      case 'price_point':
        return (
          <TableCell key={cell.id}>
            {formatNumber({ value: cell.value, format: 'currency2' })}
          </TableCell>
        );

      case 'margin_rate':
        return (
          <TableCell key={cell.id}>
            {formatNumber({ value: cell.value, format: 'percent2' })}
          </TableCell>
        );

      default:
        return (
          <TableCell key={cell.id}>
            {typeof cell.value === 'object' ? cell.value.value : cell.value}
          </TableCell>
        );
    }
  };

  const handleClusterAllocation = (applyClusters: NewProduct[]) => {
    const updateProducts = newProducts.map((prod) => {
      const newProd = applyClusters.find((newProd) => newProd.id === prod.id);
      return newProd || prod;
    });

    const changedClusters: {
      [key: string]: string;
    } = applyClusters.reduce((acc, cluster) => {
      return {
        ...acc,
        [cluster.name]: cluster.locations
          .map((location) => clusters.find(({ key }) => key === location).label)
          .join(', '),
      };
    }, {});

    posthogEvent(assortmentOptimiserApplyClusters, changedClusters);
    updateNewProducts(updateProducts);
    clearSelection();
  };

  const getIsChecked = (clusterKey: string | number): boolean =>
    applyClusters.some((prod) => prod.locations.includes(clusterKey));

  const getIsIndeterminate = (clusterKey: string | number): boolean =>
    getIsChecked(clusterKey) &&
    !applyClusters.every((prod) => prod.locations.includes(clusterKey));

  const handleCheck = (clusterKey: string | number) =>
    setApplyClusters((existingApplyClusters) => {
      const keyIsChecked = existingApplyClusters.some((prod) =>
        prod.locations.includes(clusterKey)
      );

      return existingApplyClusters.map((product) => ({
        ...product,
        locations: keyIsChecked
          ? product.locations.filter((key) => key !== clusterKey)
          : Array.from(new Set([...product.locations, clusterKey])).sort(
              (a, b) => (a > b ? 1 : -1)
            ),
      }));
    });

  const selectAllClusters = () =>
    setApplyClusters((existingApplyClusters) =>
      existingApplyClusters.map((product) => ({
        ...product,
        locations: clusters.map(({ key }) => key),
      }))
    );

  const clearAllClusters = () =>
    setApplyClusters((existingApplyClusters) =>
      existingApplyClusters.map((product) => ({ ...product, locations: [] }))
    );

  const tabIndex = applyingClusters ? -1 : 1;

  const activeClusters = Array.from(
    new Set(applyClusters.flatMap(({ locations }) => locations ?? []))
  );

  return (
    <div className="NewProducts">
      <DataTable
        headers={headers.filter(
          ({ key }) => !!user.show_extension_measure || key !== MARGIN_RATE
        )}
        rows={newProducts}
        ref={tableRef}
        data-testid="added-new-products"
      >
        {({
          rows,
          headers,
          getRowProps,
          getSelectionProps,
          getBatchActionProps,
          selectedRows,
          getTableProps,
          getTableContainerProps,
          getToolbarProps,
        }) => (
          <TableContainer
            {...getTableContainerProps()}
            style={{ position: 'relative' }}
          >
            {newProducts.length > 0 && (
              <TableToolbarContent>
                <TableToolbar {...getToolbarProps()}>
                  <TableBatchActions
                    {...getBatchActionProps()}
                    className="toolbar-buttons"
                    data-testid="table-toolbar-buttons"
                  >
                    {showClusterSelection && (
                      <TableBatchAction
                        tabIndex={tabIndex}
                        renderIcon={Store}
                        onClick={() => {
                          const selectedIds = selectedRows.map((row) => row.id);
                          setApplyClusters(
                            newProducts.filter((prod) =>
                              selectedIds.includes(prod.id)
                            )
                          );
                        }}
                      >
                        Add cluster
                      </TableBatchAction>
                    )}
                    <TableBatchAction
                      data-testid="multi-delete-product"
                      renderIcon={TrashCan}
                      onClick={() => {
                        const selectedIds = selectedRows.map((row) => row.id);
                        updateNewProducts(
                          newProducts.filter(
                            (prod) => !selectedIds.includes(prod.id)
                          )
                        );
                      }}
                    >
                      Delete
                    </TableBatchAction>
                  </TableBatchActions>
                </TableToolbar>
              </TableToolbarContent>
            )}
            <Table {...getTableProps()} className="table-content">
              <TableHead>
                <CarbonTableRow>
                  <TableSelectAll
                    {...getSelectionProps()}
                    disabled={applyingClusters}
                  />
                  {headers.map((header) => (
                    <TableHeader key={header.key}>{header.header}</TableHeader>
                  ))}
                  <TableHeader />
                </CarbonTableRow>
              </TableHead>
              <TableBody style={{ position: 'relative' }} tabIndex={tabIndex}>
                {applyingClusters && (
                  <CarbonTableRow className="NewProducts__table-overlay" />
                )}
                {rows.map((row) => {
                  const newProduct = newProducts.find(
                    ({ id }) => id === row.id
                  );

                  return (
                    <CarbonTableRow key={row.id} {...getRowProps({ row })}>
                      <TableSelectRow
                        {...getSelectionProps({ row })}
                        disabled={applyingClusters}
                      />
                      {row.cells.map((cell) =>
                        getCellContent(cell, newProduct)
                      )}
                      <TableCell data-testid="actions-cell">
                        <div className="NewProducts__actions-cell">
                          {showClusterSelection && (
                            <Button
                              tabIndex={tabIndex}
                              size="sm"
                              kind="primary"
                              renderIcon={Store}
                              hasIconOnly
                              iconDescription="Add cluster"
                              onClick={() => {
                                posthogEvent(
                                  assortmentOptimiserAddClusterBtnClick
                                );
                                setApplyClusters([newProduct]);
                              }}
                            />
                          )}
                          <Button
                            tabIndex={tabIndex}
                            size="sm"
                            kind="ghost"
                            renderIcon={Edit}
                            hasIconOnly
                            iconDescription="Edit"
                            onClick={() => {
                              posthogEvent(assortmentOptimiserEditBtnClick);
                              renderModal(newProduct);
                            }}
                          />
                          {mode !== OptimiserMode.Plan && (
                            <Button
                              tabIndex={tabIndex}
                              size="sm"
                              kind="ghost"
                              renderIcon={
                                newProduct?.force_in ? BookmarkFilled : Bookmark
                              }
                              hasIconOnly
                              iconDescription={
                                newProduct?.force_in ? 'Auto' : 'Force In'
                              }
                              className={`NewProducts__table-force-btn--${
                                newProduct?.force_in ? 'in' : 'auto'
                              }`}
                              onClick={() => {
                                posthogEvent(
                                  assortmentOptimiserForceInOutBtnClick
                                );
                                updateNewProducts(
                                  newProducts.map((prod) =>
                                    row.id === prod.id
                                      ? { ...prod, force_in: !prod.force_in }
                                      : prod
                                  )
                                );
                              }}
                            />
                          )}
                          <Button
                            tabIndex={tabIndex}
                            size="sm"
                            kind="ghost"
                            renderIcon={TrashCan}
                            hasIconOnly
                            iconDescription="Delete"
                            onClick={() => {
                              posthogEvent(assortmentOptimiserDeleteBtnClick);
                              updateNewProducts(
                                newProducts.filter(
                                  (prod) => prod.name !== row.cells[0].value
                                )
                              );
                            }}
                          />
                        </div>
                      </TableCell>
                    </CarbonTableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </DataTable>
      <div
        data-testid="new-product-cluster-selection"
        className={`NewProducts__cluster-selection ${
          applyingClusters
            ? 'NewProducts__cluster-selection--show'
            : 'NewProducts__cluster-selection--hidden'
        }`}
      >
        <div className="NewProducts__clusters-container">
          <div className="NewProducts__clusters">
            {clusters.map(({ label, key }) => (
              <div
                data-testid={`cluster-selection-${label}`}
                key={`${label}-${getIsChecked(key)}-${getIsIndeterminate(key)}`}
                className="NewProducts__cluster"
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handleCheck(key);
                  }
                }}
                onClick={() => {
                  handleCheck(key);
                }}
              >
                {label}
                <Checkbox
                  labelText={label}
                  hideLabel
                  id={`cluster-checkbox-${label}`}
                  checked={getIsChecked(key)}
                  indeterminate={getIsIndeterminate(key)}
                />
              </div>
            ))}
          </div>
        </div>

        <div className="NewProducts__action-bar">
          <Button
            size="sm"
            kind="ghost"
            renderIcon={CloseOutline}
            disabled={activeClusters.length === 0}
            onClick={clearAllClusters}
          >
            Clear All
          </Button>
          <div className="NewProducts__action-bar__spacer" />
          <Button
            size="sm"
            kind="secondary"
            disabled={activeClusters.length === clusters.length}
            onClick={selectAllClusters}
          >
            Select All
          </Button>
          <Button
            size="sm"
            onClick={() => {
              handleClusterAllocation(applyClusters);
            }}
          >
            Apply
          </Button>
        </div>

        <Button
          className="NewProducts__cancel-button"
          kind="ghost"
          size="sm"
          onClick={() => {
            clearSelection();
          }}
        >
          <Close />
        </Button>
      </div>

      <Button
        tabIndex={tabIndex}
        className="NewProducts__add-product has-icon"
        kind="secondary"
        size="sm"
        renderIcon={AddAlt}
        onClick={() => {
          renderModal();
        }}
      >
        Add product
      </Button>
    </div>
  );
};

export default NewProducts;
