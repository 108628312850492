import { useEffect, useState } from 'react';

interface Dimension {
  readonly documentWidth: Element['clientWidth'];
  readonly documentHeight: Element['clientHeight'];
}

const getWindowDimensions = (): Dimension => ({
  documentWidth: document.documentElement.clientWidth,
  documentHeight: document.documentElement.clientHeight,
});

export const useWindowDimensions = (): Dimension => {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions);

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
};
