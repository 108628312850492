/* eslint-disable indent */

export const pick = <T extends object, K extends keyof T, R = Pick<T, K>>(
  data: T,
  keys: K[]
): R =>
  keys.reduce(
    (selectedData, key) =>
      key in data ? { ...selectedData, [key]: data[key] } : selectedData,
    {} as R
  );

export const omit = <T extends object, K extends keyof T, R = Omit<T, K>>(
  data: T,
  keys: K[]
): R => {
  const newFields = Object.entries(data ?? {}).filter(
    ([key]) => !keys.includes(key as K)
  );
  return Object.fromEntries(newFields) as R;
};
