import type { FunctionComponent } from 'react';
import {
  useContext,
  useEffect,
  useLayoutEffect,
  useReducer,
  useRef,
  useState,
} from 'react';
import {
  Button,
  DatePicker,
  DatePickerInput,
  InlineLoading,
  TextArea,
  Toggle,
} from '@carbon/react';
import {
  EventSchedule,
  Calendar,
  TimePlot,
  Notebook,
  CalendarAdd,
} from '@carbon/icons-react';
import RecurrenceOptions from './RecurrenceOptions';
import {
  generateScheduleSummary,
  validateSchedulingOptions,
} from '../utils/reportBuilderUtils';
import '../../../styles/components/scheduleReport.scss';
import { SidePanelContext } from '../../../providers/SidePanelProvider';
import {
  CHANGE_SCHEDULE_OPTION,
  CLEAR_SCHEDULE_STATE,
  SET_INITIAL_SCHEDULE_STATE,
} from '../../../constants/reducers';
import { DateTime } from 'luxon';

type ChangeOption<T extends keyof ScheduleReportOptions> = {
  type: typeof CHANGE_SCHEDULE_OPTION;
  option: keyof ScheduleReportOptions;
  value: ScheduleReportOptions[T];
};

interface ClearState {
  type: typeof CLEAR_SCHEDULE_STATE;
}

interface SetInitialState {
  type: typeof SET_INITIAL_SCHEDULE_STATE;
  initialState: ScheduleReportOptions;
}

export type OptionAction =
  | ChangeOption<keyof ScheduleReportOptions>
  | ClearState
  | SetInitialState;

const initialData: ScheduleReportOptions = {
  startDate: null,
  isRecurrence: false,
  repeatEvery: 1,
  endsAfterOccurrences: 1,
  recurrenceType: 'Slide',
  recurrenceEnds: 'never',
  notes: '',
  recurrencePeriod: null,
  endDate: null,
};

const reducer = (
  state: ScheduleReportOptions,
  action: OptionAction
): ScheduleReportOptions => {
  switch (action.type) {
    case CHANGE_SCHEDULE_OPTION:
      return { ...state, [action.option]: action.value };
    case SET_INITIAL_SCHEDULE_STATE:
      return action.initialState;
    case CLEAR_SCHEDULE_STATE:
      return initialData;
  }
};

interface ScheduleReportProps {
  initialOptions?: ScheduleReportOptions;
  onSave?: (options: ScheduleReportOptions, callback?: () => void) => void;
  onCancel?: () => void;
}

const ScheduleReport: FunctionComponent<ScheduleReportProps> = ({
  initialOptions,
  onSave,
  onCancel,
}) => {
  const [state, dispatch] = useReducer(reducer, initialOptions ?? initialData);
  const [isRefReady, setIsRefReady] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const startDateRef = useRef();
  const { expanded } = useContext(SidePanelContext);
  const { isRecurrence, startDate, notes } = state;

  const handleChangeOption = <T extends keyof ScheduleReportOptions>(
    option: T,
    value: ScheduleReportOptions[T]
  ) => {
    dispatch({
      type: CHANGE_SCHEDULE_OPTION,
      value,
      option,
    });
  };

  useEffect(() => {
    if (!expanded) {
      dispatch({
        type: CLEAR_SCHEDULE_STATE,
      });
    }
  }, [expanded]);

  useEffect(() => {
    if (initialOptions && expanded) {
      dispatch({
        type: SET_INITIAL_SCHEDULE_STATE,
        initialState: initialOptions,
      });
    }
  }, [initialOptions, expanded]);

  const handleSave = () => {
    setSubmitting(true);
    onSave(state, () => setSubmitting(false));
  };

  useLayoutEffect(() => {
    setIsRefReady(true);
  }, []);

  const minDate = new Date(
    DateTime.now().plus({ days: 1 }).startOf('day').toJSDate()
  ).toISOString();

  return (
    <div className="ScheduleReport">
      <div className="ScheduleReport__content">
        {startDate ? (
          <div className="ScheduleReport__summary">
            <div className="ScheduleReport__summary-title">
              <Calendar /> <span>Schedule Summary</span>
            </div>
            <div className="ScheduleReport__summary-content">
              <span data-testid="schedule-report-summary">
                {generateScheduleSummary(state)}
              </span>
            </div>
            <div className="ScheduleReport__summary-cancel-btn-container">
              {onCancel && (
                <Button
                  kind="secondary"
                  data-testid="cancel-report-schedule-button"
                  onClick={onCancel}
                  size="sm"
                  className="has-icon"
                  renderIcon={EventSchedule}
                >
                  Cancel Schedule
                </Button>
              )}
            </div>
          </div>
        ) : null}
        <div
          className="ScheduleReport__date-picker-container"
          ref={startDateRef}
        >
          <div className="ScheduleReport__section-title">
            <CalendarAdd />
            <span>Select date for the report to run</span>
          </div>
          <div className="ScheduleReport__date-picker">
            {isRefReady && (
              <DatePicker
                value={startDate}
                datePickerType="single"
                onChange={(date) => handleChangeOption('startDate', date[0])}
                appendTo={startDateRef.current}
                data-testid="schedule-report-start-date"
                minDate={minDate}
                dateFormat="d/m/Y"
                labeltext=""
              >
                <DatePickerInput
                  placeholder="dd/mm/yyyy"
                  id="schedule-report-start-date"
                  data-testid="schedule-report-start-date-input"
                  size="md"
                  autoComplete="off"
                  labeltext=""
                />
              </DatePicker>
            )}

            <Toggle
              labelA=""
              labelB=""
              size="sm"
              id="recurrence-flag"
              toggled={isRecurrence}
              onToggle={(checked) =>
                handleChangeOption('isRecurrence', checked)
              }
              data-testid="schedule-recurrence-toggle"
            />
            <span>Recurrence</span>
          </div>
        </div>
        <div className="sidebar-divider" />

        {isRecurrence ? (
          <>
            <div className="ScheduleReport__section-title">
              <TimePlot />
              <span>Recurrence Type</span>
            </div>
            <RecurrenceOptions
              state={state}
              handleChangeOption={handleChangeOption}
            />
          </>
        ) : null}

        <div className="ScheduleReport__notes">
          <TextArea
            labelText={
              <div className="ScheduleReport__section-title">
                <Notebook /> <span>Notes</span>
              </div>
            }
            rows={2}
            value={notes}
            onChange={(e) => handleChangeOption('notes', e.target.value)}
            id="notes-option"
            enableCounter
            maxCount={100}
          />
        </div>
      </div>
      <div className="ScheduleReport__footer">
        <Button
          disabled={!validateSchedulingOptions(state) || submitting}
          data-testid="save-report-button"
          renderIcon={submitting ? InlineLoading : null}
          className={`${submitting ? 'has-icon' : ''}`}
          onClick={handleSave}
          size="md"
        >
          {submitting ? 'Submitting...' : 'Save'}
        </Button>
      </div>
    </div>
  );
};

export default ScheduleReport;
