import type { FunctionComponent } from 'react';
import { Button } from '@carbon/react';
import type { CarbonIconType } from '@carbon/icons-react';

interface ActionButtonProps {
  readonly renderIcon: CarbonIconType;
  readonly iconDescription: string;
  readonly disabled: boolean;
  readonly onClick: () => void;
}

export const ActionButton: FunctionComponent<ActionButtonProps> = (props) => (
  <Button size="sm" kind="ghost" hasIconOnly {...props} />
);
