export const mustard = {
  50: '#fff6db',
  100: '#ffeebd',
  200: '#ffde7a',
  300: '#ffcd38',
  400: '#f5b800',
  500: '#b28600',
  600: '#8f6b00',
  700: '#6b5000',
  800: '#473600',
  900: '#241b00',
} as const;
