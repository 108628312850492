export const apiRequestFailure = 'api_request_failure';
export const moduleInfoClick = 'module_info_click';
export const moduleTypeClick = 'module_type_click';
export const sidebarLinkClick = 'sidebar_link_click';
export const editReportClick = 'edit_report_click';
export const duplicateReportClick = 'duplicate_report_click';
export const reportDetailsClick = 'report_details_click';
export const shareReportClick = 'share_report_click';
export const transferReportClick = 'transfer_report_click';
export const tableRowClick = 'table_row_click';
export const tableExportClick = 'table_export_click';
export const tableExportAllTableOptionsClick =
  'table_export_all_table_options_click';
export const tableServerExportAllTableOptionsClick =
  'table_server_export_all_table_options_click';
export const reportsTableSortBy = 'reports_table_sort_by';
export const chartExportClick = 'chart_export_click';
export const submitReportSuccess = 'report_submission_success';
export const addToFavouritesClick = 'add_to_favourites_click';
export const submitReportFailure = 'report_submission_failure';
export const submitEditReportSuccess = 'report_edit_submission_success';
export const submitEditReportFailure = 'report_edit_submission_failure';
export const scheduleReportSuccess = 'report_schedule_success';
export const scheduleReportFailure = 'report_schedule_failure';
export const cancelScheduledReportSuccess = 'cancel_scheduled_report_success';
export const cancelScheduledReportFailure = 'cancel_scheduled_report_failure';
export const deleteReportSuccess = 'report_delete_success';
export const deleteReportFailure = 'report_delete_failure';
export const shareReportSuccess = 'report_share_success';
export const shareReportFailure = 'report_share_failure';
export const transferReportSuccess = 'report_transfer_success';
export const transferReportFailure = 'report_transfer_failure';
export const submitModalCreateAnotherClick =
  'submit_modal_create_another_click';
export const submitModalViewWorkspaceClick =
  'submit_modal_view_workspace_click';
export const editReportNameSuccess = 'edit_report_name_success';
export const editReportNameFailure = 'edit_report_name_failure';
export const homepageCategorytDropdownClick =
  'homepage_category_dropdown_click';
export const homepageSearchClick = 'homepage_search_click';
export const homepageSharedDropdownClick = 'homepage_shared_dropdown_click';
export const homepageFiltersDropdownClick = 'homepage_filters_dropdown_click';
export const notificationIconClick = 'notification_icon_click';
export const notificationShowUnreadToggle = 'notification_show_unread_toggle';
export const notificationMarkAllAsReadSuccess =
  'notification_mark_all_as_read_success';
export const notificationMarkAllAsReadFailure =
  'notification_mark_all_as_read_failure';
export const notificationMarkAsReadSuccess =
  'notification_mark_as_read_success';
export const notificationMarkAsReadFailure =
  'notification_mark_as_read_failure';
export const notificationMarkAsSeenSuccess =
  'notification_mark_as_seen_success';
export const notificationMarkAsSeenFailure =
  'notification_mark_as_seen_failure';
export const notificationClick = 'notification_click';
export const notificationToastCloseClick = 'notification_toast_close_click';
export const dimensionTabClick = 'dimension_tab_click';
export const reportBuilderHierarchyDropdown =
  'report_builder_hierarchy_dropdown';
export const reportBuilderHierarchyNodeClick =
  'report_builder_hierarchy_node_click';
export const reportBuilderHierarchyNodeSelection =
  'report_builder_hierarchy_node_selection';
export const reportBuilderHierarchyEditName =
  'report_builder_hierarchy_edit_name';
export const reportBuilderAddGroup = 'report_builder_add_group';
export const reportBuilderRemoveGroup = 'report_builder_remove_group';
export const reportBuilderAddSplitGroup = 'report_builder_add_split_group';
export const reportBuilderAddTransactionSpendGroup =
  'report_builder_add_transaction_spend_group';
export const reportBuilderAddSplitTransactionSpendGroup =
  'report_builder_add_split_transaction_spend_group';
export const reportBuilderAddTDateRange = 'report_builder_add_date_range';
export const reportBuilderSplitAddDateRange =
  'report_builder_split_add_date_range';
export const reportBuilderLatestDateClick = 'report_builder_latest_date_click';
export const reportBuilderWeeksSelectedClick =
  'report_builder_weeks_selected_click';
export const reportBuilderMonthSelectedClick =
  'report_builder_month_selected_click';
export const reportBuilderYearSelectedClick =
  'report_builder_year_selected_click';
export const reportBuilderYearOnYearClick = 'report_builder_year_on_year_click';
export const reportBuilderDeleteFavouriteSuccess =
  'report_builder_delete_favourite_success';
export const reportBuilderDeleteFavouriteFailure =
  'report_builder_delete_favourite_failure';
export const reportBuilderPinGroupSuccess = 'report_builder_pin_group_success';
export const reportBuilderPinGroupFailure = 'report_builder_pin_group_failure';
export const reportBuilderPinClick = 'report_builder_pin_click';
export const reportBuilderOverflowOneLevelClick =
  'report_builder_overflow_one_level_click';
export const reportBuilderOverflowLevelClick =
  'report_builder_overflow_level_click';
export const reportBuilderSearchClick = 'report_builder_search_click';
export const termsAndConditionsClick = 'terms_and_conditions_click';
export const termsAndConditionsTabClick = 'terms_and_conditions_tab_click';
export const reportBuilderClearAllNodes = 'report_builder_clear_all_nodes';
export const reportBuilderClearAllParams = 'report_builder_clear_all_params';
export const productFruitsTokenError = 'product_fruits_token_error';
export const existingCustGroupClick = 'existing_cust_group_click';
export const deleteExistingCustGroupSuccess =
  'delete_existing_cust_group_success';
export const deleteExistingCustGroupFailure =
  'delete_existing_cust_group_failure';
export const editCustomGroupClick = 'edit_custom_group_click';
export const searchCustomGroupClick = 'search_custom_group_click';
export const customGroupDropdownSelection = 'custom_group_dropdown_selection';
export const updateCustomGroupSuccess = 'update_custom_group_success';
export const updateCustomGroupFailure = 'update_custom_group_failure';
export const custGroupsUploadBtnClick = 'cust_groups_upload_btn_click';
export const custGroupsUploadFile = 'cust_groups_upload_file';
export const custGroupsDeleteUploadFile = 'cust_groups_delete_upload_file';
export const custGroupsUploadValidateSuccess =
  'cust_groups_upload_validate_success';
export const custGroupsUploadValidateFailure =
  'cust_groups_upload_validate_failure';
export const custGroupsUploadRevalidateSuccess =
  'cust_groups_upload_revalidate_success';
export const custGroupsUploadRevalidateFailure =
  'cust_groups_upload_revalidate_failure';
export const custGroupsSeeItemsBtnClick = 'cust_groups_see_items_btn_click';
export const custGroupsCreateSuccess = 'cust_groups_create_success';
export const custGroupsCreateFailure = 'cust_groups_create_failure';
export const custGroupsAccessSuccess = 'cust_groups_access_success';
export const custGroupsAccessFailure = 'cust_groups_access_failure';
export const custGroupsCreateNewGroupBtnClick =
  'cust_groups_create_new_group_btn_click';
export const custGroupsViewMyGroupsBtnClick =
  'cust_groups_view_my_groups_btn_click';
export const gridColumnHide = 'grid_column_hide';
export const gridColumnUnhide = 'grid_column_unhide';
export const gridColumnReorder = 'grid_column_reorder';
export const dataLabelsToggle = 'data_labels_toggle';

export const workspacePageTabClick = 'workspace_page_tab_click';
export const workspacePageSearchClick = 'workspace_page_search_click';
export const workspacePageFiltersBtnClick = 'workspace_page_filters_btn_click';
export const workspacePageFiltersCheckboxClick =
  'workspace_page_filters_checkbox_click';
export const workspaceDeleteInfoPopoverClick =
  'workspace_delete_info_popover_click';
export const reportContentSwitcherClick = 'report_content_switcher_click';
export const columnsCTAClick = 'columns_cta_clicked';
export const tableSortedBy = 'table_sorted_by';
export const treeChartExpandLevelClick = 'tree_chart_expand_level_click';
export const treeChartZoomClick = 'tree_chart_zoom_click';
export const treeChartExpandTreeClick = 'tree_chart_expand_tree_click';
export const treeChartCollapseTreeClick = 'tree_chart_collapse_tree_click';
export const treeChartFitToScreenClick = 'tree_chart_fit_to_screen_click';
export const treeChartReturnToDefaultClick =
  'tree_chart_return_to_default_click';
export const treeChartExpandFullScreenClick =
  'tree_chart_expand_full_screen_click';
export const chartShowSecondaryAxisClick = 'chart_show_secondary_axis_click';
export const skuGridBtnClick = 'sku_grid_btn_click';

export const cdtDraggableNodeClick = 'cdt_draggable_node_click';
export const cdtExpandMetricsRowClick = 'cdt_expand_metrics_row_click';
export const cdtRenameGroupInputClick = 'cdt_rename_group_input_click';
export const cdtNodeClick = 'cdt_node_click';

export const assortmentOptimiserOpenSuccess =
  'assortment_optimiser_open_success';
export const assortmentOptimiserOpenFailure =
  'assortment_optimiser_open_failure';
export const assortmentOptimiserDataSubmit = 'assortment_optimiser_data_submit';
export const assortmentOptimiserSelectedPlan =
  'assortment_optimiser_selected_plan';
export const assortmentOptimiserModalCancelClick =
  'assortment_optimiser_modal_cancel_click';
export const DelistTransferAnalysisSubmit = 'delist_transfer_analysis_submit';
export const assortmentOptimiserAddProductBtnClick =
  'assortment_optimiser_add_product_btn_click';
export const assortmentOptimiserAddProduct = 'assortment_optimiser_add_product';
export const assortmentOptimiserEditBtnClick =
  'assortment_optimiser_edit_btn_click';
export const assortmentOptimiserDeleteBtnClick =
  'assortment_optimiser_delete_btn_click';
export const assortmentOptimiserForceInOutBtnClick =
  'assortment_optimiser_force_in_out_btn_click';
export const assortmentOptimiserForceInOutCheckbox =
  'assortment_optimiser_force_in_out_checkbox';
export const assortmentOptimiserAddClusterBtnClick =
  'assortment_optimiser_add_cluster_btn_click';
export const assortmentOptimiserPOGRadioClick =
  'assortment_optimiser_POG_radio_click';
export const assortmentOptimiserPlanOptToggle =
  'assortment_optimiser_plan_opt_toggle';
export const assortmentOptimiserApplyClusters =
  'assortment_optimiser_apply_clusters';
export const assortmentOptimiserClusterGoalChange =
  'assortment_optimiser_cluster_goal_change';

export const slideChangeClick = 'slide_change_click';

export const visualDropdownOptionSelected = 'visual_dropdown_option_selected';

export const visualSearchInputText = 'visual_search_input_text';

export const aiQuestionItemClickSuccess = 'ai_question_item_click_success';
export const aiQuestionItemClickError = 'ai_question_item_click_error';
export const aiAnswerCopyClick = 'ai_answer_copy_click';
export const aiAnswerLikeClick = 'ai_answer_like_click';
export const aiAnswerDislikeClick = 'ai_answer_dislike_click';
export const aiAnswerDislikeOptionClick = 'ai_answer_dislike_option_click';
export const aiAnswerDislikeClosedWithoutOption =
  'ai_answer_dislike_closed_without_option';
