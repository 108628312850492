import type { FunctionComponent } from 'react';
import { useContext, useEffect, useState } from 'react';
import { Redo, TrashCan, Edit, Archive, ReportData } from '@carbon/icons-react';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { AppContext } from '../../providers/AppProvider';
import Fetch from '../Fetch';
import apiRequest from '../../api';
import { formatUTCDate } from '../../utils/DateUtils';
import { CACHE_KEY } from '../../constants/api';
import '../../styles/components/workspace.scss';
import { ModalContext } from '../../providers/ModalProvider';
import ReportTable from './ReportTable';
import type { ActionOption, RowStatus } from './ReportTableRow';
import { getReportTypes, isRowMatchCondition } from '../../utils/filterUtils';
import {
  deleteReportFailure,
  deleteReportSuccess,
  editReportClick,
} from '../../constants/posthog';
import usePosthog from '../../utils/posthog';
import { ReportFilterContext } from '../../providers/ReportFilterProvider';
import ReportTableFilters from './ReportTableFilters';
import NoDataPlaceholder from './NoDataPlaceholder';

interface ArchivedReportRowData extends ReportMetaData {
  days_before_delete: number;
  archive_date: string;
}

interface ExtendedArchivedReportRowData extends ArchivedReportRowData {
  id: ArchivedReportRowData['run_id'];
  end_time: string;
  archive_date: string;
  end_time_formatted: ArchivedReportRowData['end_time'];
}

const headerData = [
  { key: 'report_name', header: 'Name' },
  { key: 'report_type', header: 'Type' },
  { key: 'end_time', header: 'Submitted' },
  { key: 'archive_date', header: 'Archived' },
  { key: 'user_name', header: 'Owner' },
  { key: 'days_before_delete', header: 'Deletion In' },
  { key: 'status', header: 'Status' },
];

const ArchivedReportsTable: FunctionComponent = () => {
  const { user, bannerId, groupId, clearCacheForKey } = useContext(AppContext);
  const posthogEvent = usePosthog();
  const { getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();
  const { updateModal, toggleModal } = useContext(ModalContext);
  const { tabs } = useContext(ReportFilterContext);
  const [reportData, setReportData] =
    useState<ExtendedArchivedReportRowData[]>(null);
  const [reportTypesOptions, setReportTypesOptions] = useState<string[] | null>(
    null
  );
  const [rowStatus, setRowStatus] = useState<RowStatus>(null);
  const [selectedReports, setSelectedReports] = useState<{ id: string }[]>([]);

  const tabKey = 'archived';

  const filteredData = reportData?.filter((row) =>
    isRowMatchCondition(row, tabs[tabKey], user.id)
  );

  const receiveReportData = (data: ArchivedReportRowData[]): void => {
    const reportTableData: ExtendedArchivedReportRowData[] = data.map(
      (row) => ({
        ...row,
        id: row.run_id,
        end_time: formatUTCDate(row.end_time, false),
        archive_date: formatUTCDate(row.archive_date, false),
        end_time_formatted: row.end_time,
      })
    );

    const reportTypes = getReportTypes(reportTableData);
    setReportData(reportTableData);
    setReportTypesOptions(reportTypes);
    setRowStatus(null);
  };

  const handleEdit = (row: CarbonSelectedRow) => {
    posthogEvent(editReportClick, {
      origin: 'Archived Table',
    });
    const report = reportData.find((report) => report.id === row.id);
    const rowId = row.id;
    navigate(`/modules/${report.section}/${report.url_route}?edit=${rowId}`);
  };

  const handleRetrieve = async (reports: CarbonSelectedRow[]) => {
    const selected = reports.map(({ id }) => ({ id }));
    setSelectedReports(selected);
    try {
      setRowStatus('Retrieving');
      const token = await getAccessTokenSilently();
      await apiRequest(
        '/reports?action=retrieve',
        'POST',
        token,
        JSON.stringify({ run_ids: reports.map(({ id }) => id) })
      );
      updateModal({
        type: 'success',
        title: 'Reports retrieved',
        body: (
          <span>
            The selected reports will appear under the My&nbsp;Reports&nbsp;tab.
          </span>
        ),
        onPrimaryCTAClick: () => {
          navigate('/workspace/my-reports');
          toggleModal(false);
        },
        primaryCTAText: 'View My Reports',
        secondaryCTAText: 'Dismiss',
      });
    } catch (error) {
      updateModal({
        type: 'error',
        title: 'Something went wrong',
        body: 'There was an issue retrieving your reports. Please try again.',
      });
    } finally {
      clearCacheForKey(CACHE_KEY.REPORTS_ARCHIVED);
    }
  };

  const deleteReport = async (
    checkedReports: { id: string }[],
    callback?: () => void
  ) => {
    const itemPlural = checkedReports.length > 1 ? 's' : '';
    setRowStatus('Deleting');
    toggleModal(false);
    try {
      const token = await getAccessTokenSilently();
      await apiRequest(
        '/reports',
        'DELETE',
        token,
        JSON.stringify({ run_ids: checkedReports.map(({ id }) => id) })
      );
      clearCacheForKey(CACHE_KEY.REPORTS_ARCHIVED);
      callback?.();
      posthogEvent(deleteReportSuccess, {
        origin: 'Archived Table',
        deletedReports: checkedReports.length,
      });
    } catch (error) {
      updateModal({
        type: 'error',
        title: 'Something went wrong',
        body: `There was an issue deleting your report${itemPlural}. Please try again.`,
      });
      posthogEvent(deleteReportFailure, {
        origin: 'Archived Table',
        deletedReports: checkedReports.length,
      });
    } finally {
      clearCacheForKey(CACHE_KEY.REPORTS_ARCHIVED);
    }
  };

  const handleDelete = (
    reports: CarbonSelectedRow[],
    callback?: () => void
  ) => {
    const selected = reports.map(({ id }) => ({ id }));
    const itemPlural = reports.length > 1 ? 's' : '';
    setSelectedReports(selected);
    updateModal({
      type: 'warning',
      title: 'Reports to be deleted',
      body: (
        <>
          You have selected{' '}
          <strong>
            {selected.length} report{itemPlural}
          </strong>{' '}
          to be deleted permanently. This action cannot be undone. Do you want
          to continue?
        </>
      ),
      primaryCTAText: 'Continue',
      secondaryCTAText: 'Cancel',
      onPrimaryCTAClick: () => deleteReport(selected, callback),
    });
  };

  const hasId = (rowId: string) =>
    selectedReports.some(({ id }) => id === rowId);

  const toolbarActions = () => [
    {
      renderIcon: TrashCan,
      onClick: handleDelete,
      dataTestid: 'report-table-delete-btn',
      label: 'Delete',
    },
    {
      renderIcon: Redo,
      onClick: handleRetrieve,
      dataTestid: 'report-table-retrieve-btn',
      label: 'Retrieve',
    },
  ];

  const actionOptions = (): ActionOption[] => [
    {
      dataTestid: (id) => `edit-${id}`,
      itemText: 'Edit',
      onClick: (row) => handleEdit(row),
      icon: Edit,
    },
    {
      dataTestid: (id) => `retrieve-${id}`,
      itemText: 'Retrieve',
      onClick: (row) => handleRetrieve([row]),
      icon: Redo,
    },
    {
      dataTestid: (id) => `delete-${id}`,
      itemText: 'Delete',
      hidden: rowStatus === 'Deleting',
      onClick: (row) => handleDelete([row]),
      icon: TrashCan,
    },
  ];

  useEffect(() => {
    setReportData(null);
  }, [bannerId, groupId]);

  return (
    <Fetch
      apiUrl={`/reports/${bannerId}/user-groups/${groupId}/data?filter=archived`}
      cacheKey={CACHE_KEY.REPORTS_ARCHIVED}
      initialData={null}
      alwaysFetchOnMount={!reportData}
      hideChildrenUntilFetched={false}
      loadingMessage="Loading reports..."
      requestCanBeAborted
      onReceiveData={(data) => {
        if (data) {
          receiveReportData(data);
        }
      }}
    >
      {reportData !== null && reportData.length > 0 && (
        <>
          <ReportTableFilters
            reportTypesOptions={reportTypesOptions}
            rowCount={reportData.length}
            isStatusFilterHidden
            tabKey={tabKey}
            isTableEmpty={filteredData.length === 0}
          />
          <ReportTable
            headerData={headerData}
            rowData={reportData}
            filteredData={filteredData}
            tableId="archived-report-table-container"
            toolbarActions={toolbarActions}
            actionOptions={actionOptions}
            rowStatus={rowStatus}
            hasId={hasId}
            title="Number of reports to be archived"
          />
        </>
      )}
      {reportData !== null && reportData.length === 0 && (
        <NoDataPlaceholder
          title="No Reports Archived or Deleted"
          icon={Archive}
          description={{
            info: 'In this page you will find all the reports that have been archived or deleted.',
            list: [
              'Reports that are older than 90 day will be automatically archived.',
              'Reports that have been deleted will appear here in case you need to recover them.',
            ],
          }}
          notificationText="Remember that archived reports only stay archived for 14 days"
          buttonLabel="View My Reports"
          onClick={() => navigate('/workspace/my-reports')}
          buttonIcon={ReportData}
        />
      )}
    </Fetch>
  );
};

export default ArchivedReportsTable;
