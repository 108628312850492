import type { FunctionComponent } from 'react';
import IconTitle from '../IconTitle';

interface FormStepProps {
  icon: JSX.Element;
  title: string;
  children?: JSX.Element;
}
const FormStep: FunctionComponent<FormStepProps> = ({
  icon,
  title,
  children,
}) => {
  return (
    <div className="FormStep">
      <IconTitle
        icon={icon}
        title={title}
        kind="secondary"
        titleSize="02"
        faded
      />
      <div className="FormStep__content">{children}</div>
    </div>
  );
};

export default FormStep;
