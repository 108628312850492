/* eslint-disable react/no-unescaped-entities */
export const PLATFORM_ACCESS_TERMS = (
  <>
    <div className="TermsAndConditions__accept-terms-header">
      <h4>
        <strong>PLATFORM ACCESS TERMS</strong>
      </h4>
      <p>
        Tecsa Limited. Registered Office: Friar Gate Studios, Ford Street,
        Derby, England, DE1 1EE. Company Registration No: 11548711.
      </p>
      <p>
        Website:{' '}
        <a
          href="https://www.thetecsagroup.com/"
          target="_blank"
          rel="noreferrer"
        >
          https://www.thetecsagroup.com
        </a>
      </p>
    </div>
    <p>
      <strong>Effective Date: 18 October 2023</strong>
    </p>
    <h4>INTRODUCTION</h4>
    <p>
      This page or section informs you the user (<strong>“You”</strong> or{' '}
      <strong>“Your”</strong>) of the terms and conditions (collectively the{' '}
      <strong>“Platform Access Terms”</strong>) under which You from time to
      time may carry out any of the following activities (
      <strong>“Platform Activities”</strong>):
    </p>
    <ul>
      <li>access and use any and all pages or aspects of the Platform;</li>
      <li>
        upload or post any data, document or file of any kind onto the Platform;
      </li>
      <li> make any communication on or through the Platform;</li>
      <li>or do any combination of any of the above.</li>
    </ul>
    <p>
      With each Platform Activity You agree to be bound by these Platform Access
      Terms.
    </p>
    <p>
      You must not carry out any Platform Activity if You do not accept these
      Platform Access Terms and do not want Us to enforce these Platform Access
      Terms against You.
    </p>
    <p>
      The <strong>“Platform”</strong> is the retail analytics platform known as
      OneViu that can be accessed through the website set up by Us or through
      computer or mobile device software or application from time to time
      provided by Us or our suppliers.
    </p>
    <p>
      The Platform may from time to time be referred to as the{' '}
      <strong>“Retail Analytics Platform”</strong> in Our marketing materials,
      correspondence or other documents.
    </p>
    <p>
      The Platform is operated by Tecsa Limited, a company incorporated in
      England and Wales under company number 11548711 (also referred to in the
      Platform Access Terms as <strong>“Us”</strong>, <strong>“We”</strong> or{' '}
      <strong>“Our”</strong>)
    </p>
    <p>
      Please read these Platform Access Terms carefully before using or
      continuing to use the Platform in any way.
    </p>
    <p>
      Please also print a copy of these Platform Access Terms (and pages or
      sections linked within them) for future reference and also read Our
      privacy policy and our cookies policy found withing your user avatar
      profile menu regarding Your personal data. Those policies and other terms
      are also intended to bind You without prejudice to these Platform Access
      Terms.
    </p>
    <p>
      We may revise the Platform Access Terms at any time by updating this
      posting. Please see the section on “Revised Terms” below for more
      information.
    </p>
    <p>
      You should check the Platform from time to time to review the then current
      version of the Platform Access Terms because they are binding on You.
    </p>
    <p>
      Certain provisions in the Platform Access Terms may be superseded by
      expressly designated legal notices located on particular pages or sections
      of the Platform.
    </p>
    <p>
      Any rights not expressly granted in these Platform Access Terms are
      reserved to or by Us.
    </p>
    <p>
      If You are using this Platform as a consumer, Your statutory rights will
      not be affected in any event.
    </p>
    <p>
      If You have any questions regarding the Platform, please get in touch by
      email:{' '}
      <a href="mailto:helpdesk@oneviu.ai" target="_blank" rel="noreferrer">
        helpdesk@oneviu.ai
      </a>
    </p>
    <h4>Revised Terms</h4>
    <p>
      We may revise or replace the whole or any part of these Platform Access
      Terms at any time by updating this posting. Any such revisions or
      replacements will take effect when posted on the Platform (see date at the
      top of this page).
    </p>
    <p>
      It is Your responsibility to check the Platform with each Platform
      Activity to review the then current Platform Access Terms. This is because
      the Platform Access Terms, as they are in force from time to time, are
      binding on You.
    </p>
    <p>
      Certain provisions of these Platform Access Terms may be superseded by
      expressly designated legal notices or terms located on particular pages of
      the Platform. If You do not wish to accept any new terms after we have
      given any such notices or terms, You should not continue with any Platform
      Activity.
    </p>
    <p>
      In any event, Your continued Platform Activity will signify Your
      acceptance of and consent to be bound by the then latest version of the
      Platform Access Terms published on the Platform.
    </p>
    <p>
      Notwithstanding the foregoing, the resolution of any dispute that arises
      between You and Us will be governed by the version of the Platform Access
      Terms in effect at the time when such dispute arises.
    </p>
    <h4>DISCLAIMERS</h4>
    <p>
      Except where expressly provided otherwise, access to the Platform and
      documents downloaded from any webpage for or section of the Platform are
      not to be treated as amounting to or containing any form of financial,
      investment, tax, commercial or other advice. Further, we make no
      representation or warranty that any ongoing client data feed on the
      Platform will be accurate or reliable. Nor do we accept any responsibility
      arising from errors or omissions in any client data feed. We therefore
      disclaim all liability and responsibility arising from any reliance placed
      on such materials by any visitor to the Platform, or by anyone who may be
      informed of any of its contents. In the event that you are dissatisfied
      with the Platform or any of its Content, your sole remedy against us is to
      stop using the Platform.
    </p>
    <p>
      We may provide links on the Platform to the websites or applications of
      other persons, companies, businesses or entities, whether affiliated with
      Us or not. We cannot warrant, represent or undertake that products You
      purchase from third party suppliers (or the services that You receive from
      such third-party suppliers) through the Platform, or from third parties to
      whose website or application We have provided a link on the Platform, will
      be of satisfactory quality or fit for purpose which meets Your
      expectations – and, accordingly, any such warranties, representations or
      undertakings are disclaimed by Us absolutely. Nevertheless, this
      disclaimer does not affect Your statutory rights against the third-party
      supplier and is subject to any laws under which We are not permitted to
      limit or exclude Our liability.
    </p>
    <p>
      We may monitor Platform Activities from time to time for legal, security,
      service improvement, compliance verification or other reasonable reasons.
      However, we are not obliged to do so at any time.
    </p>
    <p>
      We do not make any promise, representation, warranty or assurance about
      You receiving any particular benefit or outcome from any Platform
      Activity.
    </p>
    <p>
      We are not obliged to provide any feedback about any registered user to
      any other registered user.
    </p>
    <p>
      It is prohibited to access the Platform from territories where its
      contents are illegal, unlawful or unenforceable for any other reason. If
      You access the Platform from locations outside your local territory, You
      do so at Your own risk and You may be liable for possible contravention of
      local laws.
    </p>
    <h4>REGISTRATION</h4>
    <p>
      You need to be a registered user in order to carry out certain Platform
      Activities.
    </p>
    <p>
      We reserve the right from time to time to designate a different or
      additional reason for registration in respect of the Platform.
    </p>
    <p>
      Each registered user must: (1) be at least 16 years old; and (2) provide
      valid, current, correct and complete contact details (including, but not
      limited to, a valid operational e-mail address) which are clear and not
      misleading.
    </p>
    <p>Each such registration is for a single user only.</p>
    <p>
      We do not permit You to share Your user name and password with any other
      person nor with multiple users on a network.
    </p>
    <p>
      Responsibility for the security of any username and/or password issued
      rests with You. If You know or suspect that someone else knows Your
      username and/or password, You should follow normal procedures with your
      local IT team.
    </p>
    <p>
      If You are registering on behalf of a business or any corporate body, by
      registering with Us You are confirming that You have the proper authority
      to bind the business/corporate body on whose behalf You are registering.
    </p>
    <p>
      If registration is for a body corporate, please provide the company or
      business registration number.
    </p>
    <p>
      We may reject, suspend or cancel Your registration for any reason and at
      any time at Our absolute discretion if You breach any of Your obligations
      under these Platform Access Terms. This is without prejudice to any of Our
      other rights and remedies.
    </p>
    <h4>ASSURANCES</h4>
    <p>With each Platform Activity You represent and warrant that:</p>
    <ol>
      <li>
        You are authorised to lawfully and properly carry out that Platform
        Activity.
      </li>
      <li>You are solvent.</li>
      <li>
        You are legally capable of entering into binding contracts with Us in
        accordance with the laws of your local territory.
      </li>
      <li>
        You are able and willing to comply with and observe Your obligations and
        duties under and/or in relation to these Platform Access Terms.
      </li>
      <li>
        You are able and willing to comply with all applicable laws in Your
        jurisdiction concerning the applicable Platform Activity.
      </li>
    </ol>
    <h4>PLATFORM ACCESS</h4>
    <p>
      You may from time to time be able to access and use some areas of the
      Platform without registering Your details with Us. However, certain areas
      of the Platform are only open to You if You have registered. In any event,
      the Platform Access Terms apply whether You are a guest or a registered
      user. We reserve the right, with or without notice and at Our sole
      discretion, to terminate Your ability to carry out any Platform Activity,
      and to block or prevent future Platform Activity in each case where We
      suspect or consider that:
    </p>
    <p>Access to the Platform is permitted on a temporary basis only.</p>
    <p>
      We reserve the right, with or without notice and at Our sole discretion,
      to terminate Your ability to carry out any Platform Activity, and to block
      or prevent future Platform Activity in each case where We suspect or
      consider that:
    </p>
    <ol>
      <li>
        You are not authorised to carry out the applicable Platform Activity;
      </li>
      <li>
        the Platform Activity You wish to undertake or are undertaking is not
        compliant with the Platform Access Terms or is a risk to the wellbeing
        of any person or property; or
      </li>
      <li>any combination of any of the above applies.</li>
    </ol>
    <p>
      We will not be liable if for any reason the Platform is unavailable at any
      time or for any period when we are not obliged to have it available under
      the applicable subscription services contract entered into by Us or one of
      Our authorised resellers/agents with You or Your employer under which You
      are directly or indirectly permitted to carry out Platform Activities.
    </p>
    <p>
      From time to time, We may restrict access to some parts of the Platform,
      or the entire Platform, for any and all users. We aim to update the
      Platform regularly, and may change the content at any time. If the need
      arises, We may suspend access to the Platform, or close it indefinitely.{' '}
    </p>
    <p>
      Any of the material on the Platform may be out of date at any given time,
      and We are under no obligation to update such material.{' '}
    </p>
    <p>
      If You are provided with a user identification code, password or any other
      piece of information as part of Our security procedures, You must treat
      such information as confidential, and You must not disclose it to any
      third party.
    </p>
    <p>
      We have the right to disable any user identification code or password,
      whether chosen by You or allocated by Us, at any time, if in Our opinion
      You have failed to comply with any of the provisions of these Platform
      Access Terms.
    </p>
    <p>
      You are responsible for making all arrangements necessary for You to have
      access to the Platform. You are also responsible for ensuring that all
      persons who access the Platform through Your internet connection are aware
      of these Platform Access Terms, and that they comply with them.
    </p>
    <p>
      We process information about You in accordance with Our privacy policy and
      our cookies policy. By using the Platform, You consent to such processing
      and You represent and warrant that all data provided by You is accurate.
    </p>
    <p>
      Whenever You make use of a feature that allows You to upload material or
      data to the Platform, or to make contact with other users of the Platform,
      You represent and warrant that any such contribution will be in compliance
      with these Platform Access Terms and all applicable laws.
    </p>
    <p>
      Without Prejudice to Our other rights, any data that is not covered by the
      Our privacy policy and our cookies policy that You upload to the Platform
      will be considered non-confidential and non-proprietary, and We have the
      right to use, copy, distribute and disclose to third parties any such
      material for any purpose.
    </p>
    <p>
      We also have the right to disclose Your identity to any third party who is
      claiming that any material posted or uploaded by You or on Your behalf to
      the Platform constitutes a violation of their intellectual property
      rights, or of their right to privacy.
    </p>
    <p>
      We will not be responsible, or liable to any third party, for the content
      or accuracy of any materials posted by You or any other user of the
      Platform.
    </p>
    <p>
      We have the right to block or remove any material or posting made by You
      or on Your behalf on the Platform:
    </p>
    <ol>
      <li>
        if, in Our opinion, such material or posting does not comply with these
        Platform Access Terms and/or any applicable laws, and/or
      </li>
      <li>for any other reasonable reason.</li>
    </ol>
    <p>
      We will notify You when a third party is involved in a transaction, and We
      may disclose Your customer information related to that transaction to the
      third- party supplier.
    </p>
    <h4>PLATFORM PROTECTION</h4>
    <p>
      You will not access, store, distribute, upload or transmit any Virus with,
      under or through any Platform Activity.
    </p>
    <p>
      A <strong>“Virus”</strong> is any thing or device (including any software,
      code, file or programme) which may:
    </p>
    <ol>
      <li>
        prevent, impair or otherwise adversely affect the operation of any
        computer software, hardware or network, any telecommunications service,
        equipment or network or any other service or device;
      </li>
      <li>
        prevent, impair or otherwise adversely affect access to or the operation
        of any programme or data, including the reliability of any programme or
        data (whether by re-arranging, altering or erasing the programme or data
        in whole or part or otherwise);
      </li>
      <li>
        adversely affect the user experience, including worms, trojan horses,
        viruses and other similar things or devices; or
      </li>
      <li>do any combination of any of the above.</li>
    </ol>
    <p>
      You will not access, store, distribute, upload or transmit any material
      when accessing or using Our services of any kind or the Platform that:
    </p>
    <ol>
      <li>
        is unlawful, harmful, threatening, defamatory, obscene, infringing,
        harassing or racially or ethnically offensive;
      </li>
      <li>facilitates illegal activity;</li>
      <li>depicts sexually explicit images;</li>
      <li>promotes unlawful violence;</li>
      <li>
        is discriminatory based on race, gender, colour, religious belief,
        sexual orientation, disability or any other protected characteristic
        under the Equality Act 2010;
      </li>
      <li>
        is otherwise illegal or causes damage or injury to any person or
        property;
      </li>
      <li>
        infringes any user’s or other person’s statutory, contractual, common
        law, equitable or other rights of any kind; or
      </li>
      <li>amounts to any combination of any of the above.</li>
    </ol>
    <p>
      You will not (except as expressly permitted in writing by Us or applicable
      laws) attempt to:
    </p>
    <ol>
      <li>
        copy, modify, duplicate, create derivative works from, frame, mirror,
        republish, download, display, transmit, or distribute all or any portion
        of the Platform (or any software comprised in the Platform or its
        related services) in any form or media or by any means;
      </li>
      <li>
        attempt to de-compile, reverse compile, disassemble, reverse engineer or
        otherwise reduce to human-perceivable form all or any part of the
        software comprised in the Platform; or
      </li>
      <li>do any combination of any of the above.</li>
    </ol>
    <p>
      You will not access all or any part of the Platform in order to build a
      product or service which competes with the Platform.
    </p>
    <p>
      You will not use the Platform to provide services to third parties that
      are not expressly approved in advance and in writing by Us.
    </p>
    <p>
      You will not license, sell, rent, lease, transfer, assign, distribute,
      display, disclose or otherwise commercially exploit (or make the Platform
      available to) any third party except other authorised users of the
      Platform.
    </p>
    <p>
      You will not attempt to obtain, or assist third parties in obtaining,
      access to the Platform, other than as expressly permitted by Us in
      writing.
    </p>
    <p>
      You will use all reasonable endeavours to prevent any unauthorised access
      to, or use of, the Platform (or software comprised in such Platform) and,
      in the event of any such unauthorised access or use, promptly notify Us in
      writing.
    </p>
    <p>
      You will ensure that Your network and systems (or the network and systems
      of the party with the subscription liecence from Us or One of our
      authorised resellers) comply with the relevant specifications (if any)
      provided by Us from time to time.
    </p>
    <p>
      <strong>
        The above terms under this Platform Protection section apply without
        prejudice to the terms under the Intellectual Property Rights section
        below.
      </strong>
    </p>
    <h4>Intellectual Property & limited Licence</h4>
    <p>
      You accept that as between You and Us all intellectual property rights in
      the word, logo or any related image for “Tecsa” belongs to Us.
    </p>
    <p>You may carry out Platform Activities for as long as:</p>
    <ol>
      <li>the Platform is available;</li>
      <li>
        the Platform Activities are compliant with these Platform Access Terms;
      </li>
      <li>
        We continue to receive in full the subscription and other fees
        applicable to Your Platform Activities; and
      </li>
      <li>
        the contract under which those fees are paid has not been cancelled or
        terminated.
      </li>
    </ol>
    <p>
      Except as noted above, You are not conveyed any right or license by
      implication, estoppel, or otherwise in or under any patent, trademark,
      copyright, or proprietary right belonging to Us or any of Our group
      companies or any third party whatsoever.
    </p>
    <p>
      You may not use, frame or utilise framing techniques to enclose any
      trademark, logo or other proprietary information (including the images
      found at or on the Platform, the content of any text or the layout/design
      of any page or form contained on a page) without Our express prior written
      consent. Further, You may not use any meta tags or any other "hidden text"
      utilising Our name, trademark, or product name without Our express written
      consent.
    </p>
    <p>
      Any unauthorised use of the Platform will terminate the permission or
      license granted by these Platform Access Terms and may violate applicable
      laws including copyright law, trademark law (including trade dress), and
      communications regulations and statutes. All violators will be prosecuted
      to the fullest extent of the law.
    </p>
    <p>
      As between You and Us, all copyrighted and copyrightable materials on the
      Platform (including, without limitation, Our logo, design, text, graphics,
      pictures, sound files and other files, and the selection and arrangement)
      (the <strong>"Copyright Materials"</strong>) thereof belong to Us and/or
      Our licensors. Except as stated herein, none of the Copyright Materials
      may be copied, reproduced, distributed, republished, downloaded,
      displayed, posted or transmitted in any form or by any means, including,
      but not limited to, electronic, mechanical, photocopying, recording, or
      otherwise, without the prior express written permission of Us and/or the
      respective copyright owner. Permission is granted to display, copy,
      distribute and download the Copyright Materials on this Website for
      personal, non-commercial, and informational use only; provided that, You
      may not, without the express prior written permission of Us and/or the
      respective copyright owner: (a) copy, publish, or post any Copyright
      Materials on any computer network or broadcast or publications media, (b)
      modify the Copyright Materials, or (c) remove or alter any copyright and
      other proprietary notices contained in the Copyright Materials.
    </p>
    <p>
      Without prejudice to the above, You agree not to infringe any intellectual
      property rights whatsoever of Us or any of our group of companies or any
      third party (whether or not registered) in each case concerning any aspect
      of the Platform.
    </p>
    <h4>LINKING BY YOU</h4>
    <p>
      Our express prior written consent is required to create a hyperlink to or
      on the Platform, and any such consent will be conditional (or deemed
      conditional) on Your full and proper compliance with these Platform Access
      Terms. If such a right is granted it will be non-exclusive and may be
      revoked by Us at any time and for any reason whatsoever.
    </p>
    <p>
      We make no claim or representation regarding, and accept no responsibility
      for, the quality, content, nature or reliability of sites accessible by
      hyper-link from the Platform, or sites linking to the Platform. The linked
      sites are or may not be under Our control and We are not responsible for
      the content of any linked site or any link contained in a linked site, or
      any review, changes or updates to such sites. We are providing or
      facilitating these links to You only as a convenience, and the inclusion
      of any link does not imply affiliation, endorsement, or adoption by Us of
      the site or any information contained therein.
    </p>
    <p>
      When leaving the Platform in order to access another site, You should be
      aware that these Platform Access Terms and Our privacy policy and our
      cookies policy, as well as Our general website terms at{' '}
      <a
        href="https://www.thetecsagroup.com/privacy-policy.html"
        target="_blank"
        rel="noreferrer"
      >
        https://www.thetecsagroup.com/privacy-policy.html
      </a>
      , do not govern other sites, and, therefore, You should review the
      applicable terms and policies, including privacy and data gathering
      practices, of that site.
    </p>
    <p>In Your linking You will not in any way:</p>
    <ol>
      <li>
        reproduce the Platform or any part of its contents other than to the
        extent permitted in these Platform Access Terms;
      </li>
      <li>
        suggest that We are endorsing any products or services other than Our
        own and in the manner that We expressly do ourselves on or through the
        Platform;
      </li>
      <li>
        misrepresent the relationship between You and Us, or present any other
        false information about Us;
      </li>
      <li>
        except as expressly provided for in these Terms, use any of Our trade
        marks without express prior written permission from Us;
      </li>
      <li>
        display or use a link in a manner that causes the Platform or any
        portion of its content to display within a frame, be associated with any
        advertising or sponsorship not part of the Platform, or otherwise
        incorporate Platform content into a third-party website;
      </li>
      <li>
        display or use a link to any information file contained in the Platform;
      </li>
      <li>
        alter, block or otherwise prevent display of any content of the
        Platform;
      </li>
      <li>
        alter, block or otherwise prevent display of any content of the
        Platform;
      </li>
      <li>
        link to the Platform if Your website or application may reasonably be
        considered to be obscene, defamatory, harassing, offensive or malicious,
        or if that website or application infringes any third-party rights or
        otherwise does not comply with all applicable laws or regulations; or
      </li>
      <li>do any combination of any of the above.</li>
    </ol>
    <h4>THIRD PARTY APPLICATIONS</h4>
    <p>
      The Platform from time to time may have software applications provided by
      third parties that are not in the same corporate group as Us.
    </p>
    <p>
      If you access, use or carry out any communication, contractual or other
      activity with or through such applications it will be treated as a{' '}
      <strong>“Third Party Application Activity”</strong> or{' '}
      <strong>“TPA Activity”</strong> under these Platform Access Terms.
    </p>
    <p>By carrying out any TPA Activity in respect of any such application:</p>
    <ol>
      <li>
        You accept that We do not endorse such applications or suggest or
        recommend that You carry out any TPA Activity at any time, for any
        reason or in any context;
      </li>
      <li>
        You accept that the application may not be available all the time, or at
        all, on or through the Platform for any reason (and We have no
        obligation to ensure that it remains available at any time and for any
        reason);
      </li>
      <li>
        You accept that any TPA Activity will be at your sole commercial and
        legal risk;
      </li>
      <li>
        You will comply with all terms and conditions of the provider of this
        application (on the condition that where there is a conflict or
        inconsistency between those terms and conditions and the Platform Access
        Terms, the Platform Access Terms will prevail and apply);
      </li>
      <li>
        without prejudice to the scope or enforceability of the Platform Access
        Terms, You accept that any binding agreement, arrangement, understanding
        or commitment formed between You or any third party on, through or in
        respect of the application or any TPA Activity does not involve Us as a
        party; and
      </li>
      <li>
        the provider of the application may enforce its rights under the terms
        and conditions (subject to paragraph d. above of this section of the
        Platform Access Terms) of the agreement, arrangement, understanding or
        commitments as set out in e. hereinabove under the contract law in the
        United Kingdom.
      </li>
    </ol>
    <h4>GOVERNING LAW AND JURISDICTION</h4>
    <p>
      These Platform Access Terms shall be governed by and construed in
      accordance with your local territory laws in any and all respects.
    </p>
    <p>
      United Kingdom courts will have exclusive jurisdiction to hear and
      determine any dispute arising out of or in connection with the formation,
      interpretation, scope, application, implementation and other aspects of
      the Platform Access Terms.
    </p>
  </>
);

export const COOKIES_POLICY = (
  <>
    <h4>Cookies Policy</h4>
    <p>
      This policy is effective as of 18 October 2023. Please note that this
      cookies policy may be updated from time to time and you are advised to
      review this policy to keep abreast of how we use cookies.
    </p>
    <p>
      Below is information about how Tecsa Limited and its subsidiaries
      (collectively “Tecsa Group” or “OneViu” or “we” or “us” or “our”) use
      cookies and other related technology.
    </p>
    <h4>WHAT ARE COOKIES?</h4>
    <p>
      Cookies are small files, typically of letters and numbers, downloaded onto
      your computer or mobile device when you visit certain websites. When you
      return to these websites or visit other websites that use the same
      cookies, the websites recognise these cookies and your browsing device. A
      cookie cannot read data off your hard drive or read cookie files created
      by other websites.
    </p>
    <p>
      Cookies set by the website operator are called “first-party cookies”.
      Cookies set by parties other than the website operator are called
      “third-party cookies”. The parties that set third-party cookies can
      recognise your web browser both when it visits the OneViu platform and
      when it visits certain other websites where the third party’s cookies are
      also present.
    </p>
    <p>
      Visit{' '}
      <a href="https://www.aboutcookies.org/" target="_blank" rel="noreferrer">
        aboutcookies.org
      </a>{' '}
      or{' '}
      <a
        href="https://www.allaboutcookies.org/"
        target="_blank"
        rel="noreferrer"
      >
        allaboutcookies.org
      </a>{' '}
      for more information on cookies.
    </p>
    <h4>WHY DO WE USE COOKIES?</h4>
    <p>
      When you access our Services, we, or the companies we work with, may place
      cookies on your computer or other device. These technologies help us
      better understand user behaviour and inform us about which parts of our
      websites people have visited.
    </p>
    <p>
      We use first-party and third-party cookies to recognise you as a OneViu
      customer, customise our services and content, and collect information
      about your computer or other access device to mitigate risk, help prevent
      fraud, and promote trust and safety.
    </p>
    <p>
      The following are some examples of information that we collect and how we
      may use it:
    </p>
    <ol>
      <li>
        We may collect information such as your language, inferred zip code or
        area code, unique device identifier, referrer URL, location, and time
        zone to understand your customer behaviour better and improve our
        Services.
      </li>
      <li>
        We may collect and store details of how you use our Services. Except in
        limited instances to ensure the quality of our Services over the
        Internet, such information will not be associated with your IP address.
      </li>
      <li>
        We may collect information regarding customer activities on our
        websites, which is used to understand which parts of our Services are of
        most interest. This type of data is aggregated and thus is not personal
        information.
      </li>
    </ol>
    <h4>WHAT TYPES OF COOKIES DO WE USE?</h4>
    <p>
      Below is a list of the types of cookies used and some entities that may be
      serving cookies, with links to those organisations’ privacy policies.
    </p>
    <table>
      <tbody>
        <tr>
          <th>TYPE</th>
          <th>DESCRIPTION</th>
          <th>SERVED BY</th>
        </tr>
        <tr>
          <td>Necessary Cookies</td>
          <td>
            These cookies are necessary for the Websites to function and cannot
            be switched off in our systems. They are usually only set in
            response to actions made by you that amount to a request for
            services, such as setting your privacy preferences, logging in, or
            filling in forms. These also include cookies we may rely on for
            fraud prevention. You can set your browser to block or alert you
            about these cookies, but some parts of the Websites will not then
            work.
          </td>
          <td>
            <a
              href="https://auth0.com/security"
              target="_blank"
              rel="noreferrer"
            >
              Auth0
            </a>
          </td>
        </tr>
        <tr>
          <td>Functional Cookies</td>
          <td>
            These cookies allow us to remember your choices and tailor our
            Services so we can provide relevant content to you. For example, a
            functionality cookie can remember your preferences (e.g., country or
            language selection) or your username.
          </td>
          <td>
            <div>
              <a
                href="https://productfruits.com/policies/privacy"
                target="_blank"
                rel="noreferrer"
              >
                ProductFruits
              </a>
              <a
                href="https://posthog.com/privacy"
                target="_blank"
                rel="noreferrer"
              >
                PostHog
              </a>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <h4>HOW LONG WILL COOKIES STAY ON YOUR BROWSING DEVICE?</h4>
    <p>
      The time a cookie will stay on your browsing device depends on whether it
      is a “persistent” or “session” cookie. Session cookies will only stay on
      your device until you close your browser. Persistent cookies stay on your
      browsing device until they expire or are deleted.
    </p>
    <h4>MANAGING COOKIES, SIMILAR TECHNOLOGIES</h4>
    <p>
      You can control and manage cookies in various ways. Removing or blocking
      cookies can negatively impact your user experience, and parts of our
      platform may no longer be fully accessible.
    </p>
    <p>
      Most browsers automatically accept cookies, but you can choose whether to
      accept cookies through your browser controls, often found in your
      browser’s “Tools” or “Preferences” menu.
    </p>
    <p>
      Questions about this privacy policy? Please email us at{' '}
      <a href="mailto:helpdesk@oneviu.ai" target="_blank" rel="noreferrer">
        helpdesk@oneviu.ai
      </a>
      , and we’ll be happy to answer them!
    </p>
  </>
);

export const PRIVACY_NOTICE = (
  <>
    <h4>Tecsa: Privacy Notice</h4>
    <p>
      This privacy policy sets out how Tecsa uses and protects any information
      that you give us when you use this website.
    </p>
    <p>
      Tecsa will always ensure that your privacy is protected. Should we ask you
      to provide certain information by which you can be identified when using
      this website, you can be certain that it will only be used in accordance
      with this privacy statement and the local Personal Data legislation (see
      appendix 1).
    </p>
    <p>
      Tecsa may change this policy from time to time by updating this page. You
      may wish to check this page to ensure that you keep abreast of any
      changes. This policy is effective from 18 October 2023.
    </p>
    <h4>Controller</h4>
    <p>
      Tecsa is the Data Processor and is in control of the collection, holding,
      processing or use of your personal data (collectively referred to as
      “Tecsa”, “we”, “us” or “our” in this privacy notice).
    </p>
    <p>
      If you have any questions about this privacy notice, including any
      questions about the protection of your personal data or suspected
      violation of your legal rights in respect of your personal data, please
      contact the us at{' '}
      <a href="mailto:helpdesk@oneviu.ai" target="_blank" rel="noreferrer">
        helpdesk@oneviu.ai
      </a>
      .
    </p>
    <p>
      If you have doubts or concerns about whether we handle your personal data
      in accordance with the local Personal Data legislation (see appendix 1)
      and wish to make a complaint, we would appreciate that you contact us in
      the first instance so that we can deal with it. However, if you are not
      satisfied with our reply, you have the right to lodge a complaint at any
      time with the necessary statutory body.
    </p>
    <p>We may collect the following information:</p>
    <ul>
      <li>Name, job title, customer name, department</li>
      <li>Contact information including email address</li>
    </ul>
    <h4>What we do with the information we gather</h4>
    <p>
      We may use the information to improve our products and service or other
      information which we think you may find interesting using the email
      address which you have provided.
    </p>
    <p>
      We may use the information to customise the website according to your
      interests.
    </p>
    <h4>Security</h4>
    <p>
      We are committed to ensuring that your information is secure. In order to
      prevent unauthorised or accidental access or disclosure, processing,
      erasure, use or loss, we have put in place suitable physical, electronic
      and managerial procedures to safeguard and secure the information we
      collect online.
    </p>
    <h4>Controlling your personal information</h4>
    <p>
      You may choose to restrict the collection or use of your personal
      information in the following ways:
    </p>
    <ul>
      <li>
        Whenever you are asked to fill in a form on the website, look for the
        box that you can click to indicate that you do not want the information
        to be used by anybody for direct marketing purposes.
      </li>
      <li>
        Even if you have previously given your consent to our use of your
        personal information, you may nevertheless change your mind at any time
        by writing to or emailing us at{' '}
        <a href="mailto:helpdesk@oneviu.ai" target="_blank" rel="noreferrer">
          helpdesk@oneviu.ai
        </a>
        .
      </li>
    </ul>
    <p>
      We will not sell, distribute or release your personal information to third
      parties unless we have obtained your permission or are required by law to
      do so.
    </p>
    <p>
      If you believe that we are in possession of any of your personal
      information which is incorrect or incomplete, please email or write to us
      as soon as possible at this address:
    </p>
    <p>
      Registered offices: Friar Gate Studios, Ford Street, Derby, DE1 1EE, UK
    </p>
    <p>We will promptly correct any information found to be incorrect.</p>
    <h4>Data retention- How long will you use my personal data for?</h4>
    <p>
      We will only retain your personal data for as long as necessary to fulfil
      the purposes we collected it for, including for the purposes of satisfying
      any legal, accounting or reporting requirements.
    </p>
    <p>
      To determine the appropriate retention period for personal data, we
      consider our regulatory obligations, the requirements of our insurers, the
      amount, nature and sensitivity of the personal data, the potential risk of
      harm from unauthorised use or disclosure of your personal data, the
      purposes for which we process your personal data, whether we can achieve
      those purposes through other means and the applicable legal requirements.
    </p>
    <p>
      In some circumstances, you can ask us to delete your data, to do so email
      us at <a href="mailto:helpdesk@oneviu.ai">helpdesk@oneviu.ai</a>.
    </p>
    <p>
      For research and/ or statistical purposes, we may anonymise your personal
      data to the extent that no one else will be able to directly or indirectly
      identify you or associate you with your personal data. In such a case, we
      may use this information indefinitely without giving further notice to
      you.
    </p>
    <p style={{ textDecoration: 'underline' }}>
      <strong>Appendix 1</strong>
    </p>
    <table>
      <tbody>
        <tr>
          <th>Territory</th>
          <th>Data protection legislation</th>
          <th>Statutory Body</th>
        </tr>
        <tr>
          <td>United Kingdom</td>
          <td>
            Data Protection Act 2018 which implements the EU’s General Data
            Protection Regulation.
          </td>
          <td>Information Commissioner’s Office</td>
        </tr>
        <tr>
          <td>Hong Kong</td>
          <td>Personal Data (Privacy) Ordinance (Cap. 486) (PDPO).</td>
          <td>Office of the Privacy Commissioner for Personal Data (PCPD)</td>
        </tr>
      </tbody>
    </table>
  </>
);
