import type { FunctionComponent } from 'react';
import type React from 'react';

interface DisableUserProps {
  onClick?: React.MouseEventHandler<SVGSVGElement>;
}

const DisableUser: FunctionComponent<DisableUserProps> = (props) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      enableBackground="new 0 0 20 20"
      height="20px"
      viewBox="0 0 20 20"
      width="20px"
      fill="currentColor"
    >
      <g>
        <rect fill="none" height="20" width="20" x="0" />
      </g>
      <g>
        <g>
          <path d="M2,13.86V16h12v-2.14c0-1.9-4-2.86-6-2.86C6,11,2,11.96,2,13.86z M3,13.86C3,13.04,5.8,12,8,12c2.2,0,5,1.04,5,1.86V15H3 V13.86z" />
          <path d="M11,7c0-1.66-1.34-3-3-3C6.34,4,5,5.34,5,7c0,1.66,1.34,3,3,3C9.66,10,11,8.66,11,7z M10,7c0,1.1-0.9,2-2,2S6,8.1,6,7 s0.9-2,2-2S10,5.9,10,7z" />
          <rect
            height="1"
            transform="matrix(-1 4.479249e-11 -4.479249e-11 -1 30 19)"
            width="4"
            x="13"
            y="9"
          />
        </g>
      </g>
    </svg>
  );
};

export default DisableUser;
