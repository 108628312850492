import { Accordion, AccordionItem, Tag } from '@carbon/react';
import Tooltip from '../../components/Tooltip';
import { LEGENDS } from '../../constants/metadata';

export const loadExtras = (reportType: ReportType) => {
  if (reportType === 'Product Association') {
    return (
      <Accordion data-testid="SOR-key">
        <AccordionItem title="Strength Of Relationship Colour Coding" open>
          {LEGENDS.map((association) => (
            <Tooltip
              key={association.label}
              description={association.label}
              align="top"
              useButtonWrapper
            >
              <Tag
                style={{
                  backgroundColor: `${association.background}`,
                  color: `${association.color}`,
                  padding: '12px',
                }}
              >
                {association.value}
              </Tag>
            </Tooltip>
          ))}
        </AccordionItem>
      </Accordion>
    );
  }
};
