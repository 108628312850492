import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Route,
  RouterProvider,
} from 'react-router-dom';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import AppContainer from '../components/AppContainer';
import Modules from '../components/Modules';
import NotFound from '../components/NotFound';
import ViewReport from '../components/Report/ViewReport';
import ReportBuilder from '../components/ReportBuilder/ReportBuilder';
import ReportProvider from '../providers/ReportProvider';
import Summary from '../components/Home/Home';
import Workspace from '../components/Workspace';
import SummaryProvider from '../providers/HomeProvider';
import Fallback from './Fallback';
import ModalProvider from '../providers/ModalProvider';
import SidePanelProvider from '../providers/SidePanelProvider';
import MyReportsTable from '../components/ReportTable/MyReportsTable';
import ArchivedReportsTable from '../components/ReportTable/ArchivedReportsTable';
import WebsocketProvider from '../providers/WebsocketProvider';
import Optimise from '../components/Optimise/Optimise';
import AdminPanel from '../components/AdminPanel/AdminPanel';
import UsersPanel from '../components/AdminPanel/UsersPanel/UsersPanel';
import AdminPanelProvider from '../providers/AdminPanelProvider';
import CustomGroupsLayout from '../components/CustomGroups/CustomGroupsLayout';
import CustomGroupsProvider from '../providers/CustomGroupsProvider';
import SettingsUpload from '../components/CustomGroups/Platform/Upload/CustomGroupsUpload';
import SettingsCustGroupsTable from '../components/CustomGroups/Platform/CustomGroupTable';
import PlatformSettings from '../components/CustomGroups/Platform/CustomGroups';
import GenAIAdmin from '../components/GenAIAdmin/GenAIAdmin';
import { useContext, useMemo } from 'react';
import { AppContext } from '../providers/AppProvider';
import { USER_ROLE, USER_TYPE } from '../constants/metadata';
import UserGroupsTable from '../components/AdminPanel/UsersPanel/UserGroupsTable';
import {
  AttributeLayout,
  AttributeManager,
  AttributeValuesForm,
} from '../components/AttributeManager';
import { useFeatureFlagEnabled } from '../context/posthog/useFeatureFlagEnabled';

const createRoute = (path: string, element: JSX.Element) => {
  return <Route path={path} element={element} errorElement={<Fallback />} />;
};

const routerBuilder = ({
  showAttributeManager,
  showExperimentalFeatures,
}: {
  showAttributeManager: boolean;
  showExperimentalFeatures: boolean;
}) =>
  createBrowserRouter(
    createRoutesFromElements(
      <Route
        path="/"
        element={
          <ModalProvider>
            <SidePanelProvider>
              <WebsocketProvider>
                <AppContainer />
              </WebsocketProvider>
            </SidePanelProvider>
          </ModalProvider>
        }
      >
        {createRoute(
          'home',
          <SummaryProvider>
            <ReportProvider>
              <Summary />
            </ReportProvider>
          </SummaryProvider>
        )}
        {createRoute('*', <NotFound />)}
        {createRoute('modules', <Modules />)}
        {createRoute('modules/:section/:moduleId', <ReportBuilder />)}
        <Route path="workspace" element={<Workspace />}>
          {createRoute('my-reports', <MyReportsTable tab="my-reports" />)}
          {createRoute('favourites', <MyReportsTable tab="favourites" />)}
          {createRoute('shared', <MyReportsTable tab="shared" />)}
          {createRoute('scheduled', <MyReportsTable tab="scheduled" />)}
          {createRoute('archived', <ArchivedReportsTable />)}
        </Route>
        {createRoute('workspace/view-report/:reportId', <ViewReport />)}

        {createRoute('workspace/view-report/:reportId/optimise', <Optimise />)}

        <Route
          path="custom-groups"
          element={
            <CustomGroupsProvider>
              <CustomGroupsLayout />
            </CustomGroupsProvider>
          }
        >
          {createRoute('', <PlatformSettings />)}
          {createRoute('upload', <SettingsUpload />)}
          {createRoute(
            'my-uploaded-groups/:custGroupId',
            <SettingsCustGroupsTable />
          )}
        </Route>
        <Route
          path="admin-panel"
          element={
            <AdminPanelProvider>
              <AdminPanel />
            </AdminPanelProvider>
          }
        >
          {createRoute('users', <UsersPanel />)}
          {createRoute('', <Navigate to="users" />)}
        </Route>
        {createRoute('admin-panel/users/user-groups', <UserGroupsTable />)}
        {showAttributeManager && (
          <Route path="attribute-manager" element={<AttributeLayout />}>
            {createRoute('', <AttributeManager />)}
            {createRoute('upload', <AttributeValuesForm />)}
          </Route>
        )}

        {showExperimentalFeatures && (
          <Route path="oneviuai-questions" element={<GenAIAdmin />} />
        )}
      </Route>
    )
  );

const LoggedInComponent = () => {
  const { user } = useContext(AppContext);
  const isCustomAttributesEnabled = useFeatureFlagEnabled('custom-attributes');

  const showAttributeManager =
    isCustomAttributesEnabled &&
    (user?.user_type === USER_TYPE.SUPPORT ||
      user?.user_role === USER_ROLE.SUPER_USER);

  const showExperimentalFeatures =
    user?.user_type === USER_TYPE.SUPPORT &&
    user?.user_role === USER_ROLE.SUPER_USER;

  const router = useMemo(
    () => routerBuilder({ showAttributeManager, showExperimentalFeatures }),
    [showAttributeManager, showExperimentalFeatures]
  );

  return <RouterProvider router={router} />;
};

export default withAuthenticationRequired(LoggedInComponent, {
  onRedirecting: () => <div>Redirecting you to the login page...</div>,
});
