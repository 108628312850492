import { useNavigate } from 'react-router-dom';
import { Button } from '@carbon/react';
import { TaskTools } from '@carbon/icons-react';
import type { FunctionComponent } from 'react';
import { useEffect, useState } from 'react';
import '../../styles/components/maintenance.scss';
import { formatTime } from '../../utils/maintenanceUtils';

interface MaintenanceProps {
  header: string;
  subheader: string;
  hasBackButton?: boolean;
  endTime?: string;
}

const Maintenance: FunctionComponent<MaintenanceProps> = ({
  header,
  subheader,
  hasBackButton = true,
  endTime,
}) => {
  const navigate = useNavigate();
  const [counter, setCounter] = useState<number>();
  useEffect(() => {
    if (endTime) {
      const endTimeMs = new Date(endTime).getTime();
      if (isNaN(endTimeMs) || !endTimeMs) {
        setCounter(0);
        return;
      }
      const interval = setInterval(() => {
        const remainingTime = Math.max(0, endTimeMs - Date.now());
        setCounter(Math.floor(remainingTime / 1000));
      }, 1000);

      const remainingTime = Math.max(0, endTimeMs - Date.now());
      setCounter(Math.floor(remainingTime / 1000));

      return () => clearInterval(interval);
    }
  }, [endTime]);

  return (
    <div data-testid="maintenance" className="maintenance-page">
      <div className="maintenance-page__header--wrapper">
        <h2 className="maintenance-page__header">{header}</h2>
        <TaskTools width="2rem" height="2rem" data-testid="maintenance-icon" />
      </div>
      <br />
      <h4 className="maintenance-page__subheader">{subheader}</h4>
      {endTime && counter !== 0 && (
        <div className="maintenance-page__counter">
          <p>
            The maintenance is expected to last approximately:{' '}
            {formatTime(counter)}
          </p>
        </div>
      )}

      {hasBackButton && (
        <Button
          data-testid="maintenance-button"
          onClick={() => navigate('/home')}
        >
          Go back
        </Button>
      )}
    </div>
  );
};

export default Maintenance;
