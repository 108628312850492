import type { DraggableLocation } from 'react-beautiful-dnd';
import { reorderList } from './CDTUtils';
import type { List } from '../components/ItemOrder/ItemOrder';
import {
  gridColumnHide,
  gridColumnReorder,
  gridColumnUnhide,
} from '../constants/posthog';

export const onDragEnd = (
  source: DraggableLocation,
  destination: DraggableLocation,
  lists: List[]
): List[] => {
  if (destination === null) {
    return lists;
  }

  const sourceListIndex = lists.findIndex(
    (list) => list.listId === source.droppableId
  );

  const destinationListIndex = lists.findIndex(
    (list) => list.listId === destination.droppableId
  );

  const updatedLists = [...lists];

  if (source.droppableId === destination.droppableId) {
    const newList = {
      ...lists[destinationListIndex],
      items: reorderList(
        lists[destinationListIndex].items,
        source.index,
        destination.index
      ),
    };

    updatedLists.splice(destinationListIndex, 1, newList);
  } else {
    const sourceList = updatedLists[sourceListIndex];
    const destinationList = updatedLists[destinationListIndex];
    const movedItem = sourceList.items.splice(source.index, 1)[0];
    destinationList.items.splice(destination.index, 0, movedItem);

    updatedLists[sourceListIndex] = sourceList;
    updatedLists[destinationListIndex] = destinationList;
  }

  return updatedLists;
};

export const getItemOrderPosthogEventParams = (
  itemId: string,
  lists: List[],
  source: DraggableLocation,
  destination: DraggableLocation
) => {
  const columnName = lists
    .flatMap((list) => list.items)
    .find(({ id }) => id.toString() === itemId)?.name;
  if (source.droppableId === destination.droppableId) {
    if (
      source.droppableId === 'visible-columns' &&
      source.index !== destination.index
    ) {
      const hasBeenMoved =
        source.index < destination.index ? 'lower' : 'higher';
      return {
        eventName: gridColumnReorder,
        payload: {
          columnName,
          hasBeenMoved,
        },
      };
    }
    return null;
  } else {
    const eventName =
      destination.droppableId === 'hidden-columns'
        ? gridColumnHide
        : gridColumnUnhide;
    return {
      eventName,
      payload: {
        columnName,
      },
    };
  }
};
