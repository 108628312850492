/* eslint-disable indent */

import { useContext, useState } from 'react';
import type { ResponsePayload } from '../Fetch';
import Fetch from '../Fetch';
import { AppContext } from '../../providers/AppProvider';
import { OptimiserFormContext } from '../../providers/OptimiserFormProvider';
import type { VisualData } from '../../reducers/ReportReducer';
import OptimiserForm from './OptimiserForm';
import type { AssortmentData } from '../../reducers/AssortmentReducer';
import type {
  LocationPlanTargetGrid,
  NewProduct,
  OptimiserStrategy,
} from '../../reducers/OptimiserFormReducer';
import { OptimiserMode } from '../../reducers/OptimiserFormReducer';
import { getClusterGoals } from '../../utils/CDTUtils';
import ModeSelection from './ModeSelection';

type CDTGridData = VisualData<AssortmentData>;

interface CDTPayloadData {
  readonly new_products: NewProduct[];
}

type CDTOptimisationPayload =
  | {
      readonly path: string;
      readonly strategy: OptimiserStrategy;
      readonly clusters:
        | { readonly id: string; readonly filter: number }[]
        | null;
      readonly filter: number | null;
    }
  | Record<string, never>;

interface OptimiserConfigurationProps {
  readonly reportApiUrl: string;
  readonly isEditing: boolean;
}

interface LoadDataResponse {
  readonly cdtRankGrid: ResponsePayload<CDTGridData>;
  readonly cdtPayload?: ResponsePayload<CDTPayloadData>;
  readonly cdtOptimisationPayload?: ResponsePayload<CDTOptimisationPayload>;
  readonly cdtPlanTargetsPayload?: ResponsePayload<LocationPlanTargetGrid>;
}

const OptimiserConfiguration = (props: OptimiserConfigurationProps) => {
  const { reportApiUrl, isEditing } = props;
  const { bannerId, groupId } = useContext(AppContext);
  const { mode, loadData: loadAssortmentData } =
    useContext(OptimiserFormContext);

  const [dataFetched, setDataFetched] = useState(false);

  const baseReportUrl = `/reports/${bannerId}/user-groups/${groupId}`;

  const loadData = (response: LoadDataResponse) => {
    const {
      cdtRankGrid: { data: optimiserVisualData },
      cdtPayload: { data: newProductsData } = {},
      cdtOptimisationPayload: { data: optimisationData = {} } = {},
      cdtPlanTargetsPayload: { data: planTargets } = {},
    } = response;

    const { rows: optimiserData } = optimiserVisualData;
    const { new_products: newProducts = [] } = newProductsData ?? {};

    const clusters = optimiserVisualData.sharedDropdown?.[0]?.options.filter(
      (cluster) => cluster.label !== 'Total'
    ) || [{ label: 'Total', key: 1 }];

    const existingClusterGoals =
      optimisationData?.clusters ??
      clusters.map(({ key }) => ({
        id: key.toString(),
        filter: optimisationData.filter || 0,
      }));

    const clusterGoals =
      mode === OptimiserMode.NonPlan
        ? getClusterGoals(
            optimiserData,
            clusters,
            newProducts,
            existingClusterGoals
          )
        : [];

    loadAssortmentData({
      optimiserData,
      newProducts,
      clusters,
      clusterGoals,
      planTargets,
    });

    setDataFetched(true);
  };

  if (!mode) {
    return <ModeSelection />;
  }

  return (
    <Fetch
      apiUrls={{
        cdtRankGrid: `${baseReportUrl}?path=${reportApiUrl}/cdt-rank-grid`,
        ...(mode === OptimiserMode.Plan && {
          cdtPlanTargetsPayload: `${baseReportUrl}?path=${reportApiUrl}/${
            isEditing
              ? 'cdt-plan-target-grid'
              : 'cdt-plan-target-grid-read-only'
          }`,
        }),
        ...(isEditing && {
          cdtPayload: `${baseReportUrl}?path=${reportApiUrl}/cdt-payload`,
          cdtOptimisationPayload: `${baseReportUrl}?path=${reportApiUrl}/cdt-optimisation-payload`,
        }),
      }}
      multiRequest
      onReceiveData={(data) => {
        if (data) {
          loadData(data);
        }
      }}
      loadingMessage="Loading assortment data..."
      initialData={null}
      alwaysFetchOnMount={!dataFetched}
      hideChildrenUntilFetched
    >
      <OptimiserForm />
    </Fetch>
  );
};

export default OptimiserConfiguration;
