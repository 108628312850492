import type { RefObject } from 'react';
import type { ScatterData } from '../components/Charts/ScatterWithSummary';
import {
  UPDATE_CHART_OPTIONS,
  UPDATE_MEASURE,
  UPDATE_SELECTED_POINTS,
} from '../constants/reducers';
export interface ChartState {
  ref: RefObject<HTMLDivElement> | null;
  selectedPoints: ScatterData[];
  chartOptions: Highcharts.Options;
  measure?: Measure;
}

export const initialChartState: ChartState = {
  ref: null,
  selectedPoints: [],
  chartOptions: {},
  measure: undefined,
};

interface UpdateSelectedPoints {
  type: typeof UPDATE_SELECTED_POINTS;
  selectedPoints: ScatterData[];
}

interface UpdateChartOptions {
  type: typeof UPDATE_CHART_OPTIONS;
  chartOptions: Highcharts.Options;
}

interface UpdateMeasure {
  type: typeof UPDATE_MEASURE;
  measure: Measure;
}

export type ChartAction =
  | UpdateSelectedPoints
  | UpdateChartOptions
  | UpdateMeasure;

const chartReducer = (state: ChartState, action: ChartAction): ChartState => {
  switch (action.type) {
    case UPDATE_SELECTED_POINTS:
      return {
        ...state,
        selectedPoints: action.selectedPoints,
      };

    case UPDATE_CHART_OPTIONS:
      return {
        ...state,
        chartOptions: action.chartOptions,
      };

    case UPDATE_MEASURE:
      return {
        ...state,
        measure: action.measure,
      };
  }
};

export default chartReducer;
