import type { FunctionComponent } from 'react';
import { useEffect, useState } from 'react';
import { ChevronDown, ChevronUp, Information } from '@carbon/icons-react';
import '../styles/components/infoOverlay.scss';
interface InfoOverlayProps {
  title: string;
  overlayContent: string[];
}

const InfoOverlay: FunctionComponent<InfoOverlayProps> = ({
  title,
  overlayContent,
}) => {
  const [open, setOpen] = useState(false);

  const close = () => setOpen(false);

  useEffect(() => {
    document.addEventListener('click', close);
    return () => document.removeEventListener('click', close);
  }, []);

  return (
    <div
      className="info-overlay"
      onClick={(e) => {
        e.stopPropagation();
        setOpen(!open);
      }}
    >
      <div className="info-overlay__content">
        <div className="info-overlay__title">
          <Information />
          {title} {open ? <ChevronUp /> : <ChevronDown />}
        </div>

        <div className={`info-overlay__items--${open ? 'open' : 'closed'}`}>
          {overlayContent.map((text) => {
            return (
              <div key={`info-item-${text}`} className="info-overlay__item">
                {text}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default InfoOverlay;
