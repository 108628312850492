import { Tabs, Tab, TabList } from '@carbon/react';

interface Tab<K> {
  readonly key: K;
  readonly label: string;
}

interface CategoryTabsProps<K, T> {
  readonly activeKey: K;
  readonly tabs: ReadonlyArray<T>;
  readonly label: string;
  readonly onChange: (key: K) => void;
}

export const CategoryTabs = <K extends string, T extends Tab<K>>(
  props: CategoryTabsProps<K, T>
): JSX.Element => {
  const { tabs, activeKey, label, onChange } = props;

  const selectedIndex = tabs.findIndex(({ key }) => key === activeKey);

  return (
    <Tabs
      selectedIndex={selectedIndex}
      onChange={({ selectedIndex }: { selectedIndex: number }) => {
        onChange(tabs[selectedIndex].key);
      }}
    >
      <TabList className="tabs-list-container" aria-label={label}>
        {tabs.map(({ key, label }) => (
          <Tab className="workspace-tab hoverable" key={key}>
            {label}
          </Tab>
        ))}
      </TabList>
    </Tabs>
  );
};
