/* eslint-disable indent */
import type {
  AxisOptions,
  PlotOptions,
  XAxisOptions,
  YAxisOptions,
} from 'highcharts';
import { DateTime } from 'luxon';
import type { FileData, VisualData, YAxis } from '../reducers/ReportReducer';
import { formatAxis, getSelectableOptions } from './reportUtils';
import type { SharedUser } from '../components/ShareReport/ShareReport';
import { omit } from './object';
import type { GlobalSelection } from '../components/Report/ReportContent';

export const getCSSColor = (value: number, colorIntensity: 1 | 2) => {
  if (value > 0) {
    return colorIntensity === 1 ? '#044317' : '#e6f4e9';
  } else if (value < 0) {
    return colorIntensity === 1 ? '#750E13' : '#ffebee';
  } else {
    return colorIntensity === 1 ? 'grey' : '#ededed';
  }
};

export const getTextStyleFromIndex = (
  index?: 'positive' | 'negative' | 'neutral'
): { color: string; backgroundColor: string } => {
  switch (index) {
    case 'positive':
      return {
        color: '#0E6027',
        backgroundColor: '#e6f4e9',
      };
    case 'negative':
      return { color: '#A2191F', backgroundColor: '#ffebee' };
    case 'neutral':
    default:
      return { color: '#4D4B62', backgroundColor: '#ededed' };
  }
};

export const getIndicatorStyleFromIndex = (
  index?: 'positive' | 'negative' | 'neutral'
): string => {
  switch (index) {
    case 'positive':
      return '#198038';
    case 'negative':
      return '#A2191F';
    case 'neutral':
    default:
      return '#B5B4C6';
  }
};

export const timeSince = (fromDate: Date, toDate?: Date) => {
  const seconds = Math.floor(
    DateTime.fromJSDate(toDate ? toDate : new Date(Date.now())).toSeconds() -
      DateTime.fromJSDate(fromDate).toSeconds()
  );

  let interval = seconds / 31536000;

  if (interval > 1) {
    return Math.floor(interval) + ' years ago';
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return Math.floor(interval) + ' months ago';
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return Math.floor(interval) + ' days ago';
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return Math.floor(interval) + ' hours ago';
  }
  interval = seconds / 60;
  if (interval > 1) {
    return Math.floor(interval) + ' min. ago';
  }
  return Math.floor(seconds) === 0
    ? 'just now'
    : `${Math.floor(seconds)} seconds ago`;
};

export const getMeasureInfo = (
  visualData: FileData,
  dropdownKey: string,
  isReportSplit?: boolean
) => {
  const { visualDropdownConfig, dropdownConfig, sharedDropdown } = visualData;
  const dropdowns = isReportSplit
    ? [...visualDropdownConfig, ...(sharedDropdown ?? [])]
    : [...dropdownConfig, ...(sharedDropdown ?? [])];
  const selectedKey = getSelectedKey(
    dropdowns,
    visualData.measureDropdownId,
    dropdownKey
  );

  const measureDropdown = dropdowns.find((dropdown) => {
    return dropdown.id === visualData.measureDropdownId;
  });

  const numberFormatKey = measureDropdown.options.find(
    (opt) => opt.key === selectedKey
  ).numberFormat;

  return visualData.measureFormats[numberFormatKey];
};

export const updateDropdownKey = (
  selectedItem: string,
  dropdownId: number,
  config: DropdownConfig[],
  dropdownSelection: string,
  globalSelections?: GlobalSelection[]
): string => {
  const dropdownIndex = config.findIndex((d) => d.id === dropdownId);
  const key = config[dropdownIndex].options.find(
    (opt) => opt.label === selectedItem
  ).key;
  const keys: string[] = dropdownSelection.split('_');
  keys[dropdownIndex] = key.toString();

  const tempKey = keys.join('_');
  config.forEach((dropdown, index) => {
    const globalSelectionItem = globalSelections?.find(
      ({ label }) => dropdown.label === label
    );

    if (globalSelectionItem) {
      keys[index] = globalSelectionItem.selectedKey.toString();
    } else {
      const selectable = getSelectableOptions(config, dropdown.id, tempKey);
      const selected = getSelectedItem(dropdown.id, tempKey, config);

      if (!selectable.includes(selected)) {
        keys[index] = dropdown.options
          .find((opt) => opt.label === selectable[0])
          .key.toString();
      }
    }
  });

  return keys.join('_');
};

export const getSelectedItem = (
  dropdownId: number,
  dropdownSelection: string,
  dropdownConfig: DropdownConfig[]
): string => {
  if (!dropdownSelection) {
    return '';
  }
  const keys: string[] = dropdownSelection.split('_');

  const dropdownIndex: number = dropdownConfig.findIndex(
    (d) => d.id === dropdownId
  );
  const selected = keys[dropdownIndex];
  const label = dropdownConfig[dropdownIndex].options.find(
    (opt) => opt.key == selected
  )?.label;
  return label;
};

export const getSelectedChildren = (node: HierNode) => {
  if (!node.children) return;
  const children = node.children.filter(
    ({ id, groupedItems }) => id || groupedItems
  );
  const nodeLevel = children[0]?.level;
  const selections = children.map(({ id, label, level, groupedItems }) => ({
    id,
    label,
    level,
    groupedItems,
  }));

  return { nodeLevel, selections };
};

export const getYAxes = ({
  visualData,
  measure,
  dropdownKey,
  isReportSplit,
}: {
  visualData: VisualData | FileData;
  measure: Measure | null;
  dropdownKey: string;
  isReportSplit: boolean;
}): AxisOptions | AxisOptions[] => {
  const { yAxis } = visualData as FileData;

  if (!yAxis) {
    return null;
  }

  if (Array.isArray(yAxis)) {
    return yAxis.map((axis) =>
      getYAxisFormat({
        yAxis: axis,
        visualData,
        measure,
        dropdownKey,
        isReportSplit,
      })
    );
  } else {
    return getYAxisFormat({
      yAxis,
      visualData,
      measure,
      dropdownKey,
      isReportSplit,
    });
  }
};

export const getYAxisFormat = ({
  yAxis,
  visualData,
  measure,
  dropdownKey,
  isReportSplit,
}: {
  yAxis: YAxis;
  visualData: VisualData | FileData;
  measure: Measure | null;
  dropdownKey: string;
  isReportSplit: boolean;
}) => {
  const { measureFormats } = visualData as FileData;
  const useCustomFormat = !measure && !!yAxis.custom?.format;

  return useCustomFormat
    ? applyYAxisFormat({
        yAxis: yAxis,
        measure: measureFormats?.[yAxis.custom?.format],
      })
    : applyYAxisFormatFromDropdown({
        yAxis: yAxis,
        visualData,
        measure,
        dropdownKey,
        isReportSplit,
        useCustomFormat,
      });
};

export const applyYAxisFormatFromDropdown = ({
  yAxis,
  visualData,
  measure,
  dropdownKey,
  isReportSplit,
  useCustomFormat,
}: {
  yAxis: YAxis;
  visualData: VisualData | FileData;
  measure: Measure | null;
  dropdownKey: string;
  isReportSplit: boolean;
  useCustomFormat?: boolean;
}): AxisOptions => {
  const {
    dropdownConfig,
    visualDropdownConfig,
    measureDropdownId,
    sharedDropdown,
  } = visualData as FileData;

  const dropdowns = isReportSplit
    ? [...visualDropdownConfig, ...(sharedDropdown ?? [])]
    : [...dropdownConfig, ...(sharedDropdown ?? [])];

  if ((!measure || !measureDropdownId) && !useCustomFormat) {
    return yAxis;
  }

  const selectedKey = getSelectedKey(dropdowns, measureDropdownId, dropdownKey);

  const measureDropdown = dropdowns.find((dd) => dd.id === measureDropdownId);
  const selectedMeasure = measureDropdown.options.find(
    (opt) => opt.key === selectedKey
  );

  const title =
    yAxis.title?.text === 'Measure' ? selectedMeasure.label : yAxis.title?.text;

  return applyYAxisFormat({ yAxis, title, measure });
};

export const applyYAxisFormat = ({
  yAxis,
  title,
  measure,
}: {
  yAxis: YAxis;
  measure: Measure | null;
  title?: string;
}): YAxisOptions => {
  return {
    ...omit(yAxis, ['custom']),
    title: {
      text: title ?? yAxis.title?.text,
    },
    labels: {
      formatter: function () {
        if (!measure) {
          return this.value.toString();
        }
        const point: NumberPoint = {
          format: measure.format,
          value: Number(this.value),
          currency: measure.currency,
        };
        const label = formatAxis(point);
        return label;
      },
    },
  };
};

export const getXAxis = (
  visualData: VisualData,
  dropdownKey: string,
  visualDropdownKey: string
): XAxisOptions => {
  const {
    categoryConfig,
    categoryDropdownId,
    dropdownConfig,
    visualDropdownConfig,
    xAxis,
  } = visualData;

  if (!categoryConfig || !categoryDropdownId) {
    return xAxis;
  }

  const dropdownIds = dropdownConfig?.map((dd) => dd.id);
  const categorySelection = dropdownIds.includes(categoryDropdownId)
    ? { categoryKey: visualDropdownKey, dropdowns: dropdownConfig }
    : { categoryKey: dropdownKey, dropdowns: visualDropdownConfig };

  const selectedKey = getSelectedKey(
    categorySelection.dropdowns,
    categoryDropdownId,
    categorySelection.categoryKey
  );

  return {
    ...xAxis,
    categories: categoryConfig[selectedKey],
  };
};

export const getPlotOptions = (visualData: VisualData): PlotOptions => {
  return visualData.plotOptions !== null &&
    typeof visualData.plotOptions === 'object'
    ? visualData.plotOptions
    : {};
};

export const getSelectedKey = (
  dropdownConfig: DropdownConfig[],
  dropdownId: number,
  dropdownKey: string
): number => {
  const dropdown = dropdownConfig.find((dropdown) => {
    return dropdown.id === dropdownId;
  });

  const keyIndex = dropdownConfig.indexOf(dropdown);
  const keys: string[] = dropdownKey.split('_');
  return Number(keys[keyIndex]);
};

export const getParameter = (
  selectedHierarchy: Hierarchy,
  selection: { [key: string]: HierNode[] },
  hierarchies: { [key: string]: HierarchyResponse },
  bannerId: number
): ReportParameter => {
  const items: HierarchyParam[] = [];
  const labels = [];
  const hierarchy = hierarchies[selectedHierarchy.id];
  Object.keys(selection).forEach((levelKey) => {
    selection[levelKey].forEach((node) => {
      if (
        node.groupedItems ||
        (selectedHierarchy.is_custom && node.children?.length)
      ) {
        const grouped = selectedHierarchy.is_custom
          ? node.children
          : node.groupedItems;
        grouped.forEach((item) => {
          const hierarchyLevelId =
            hierarchy.hierarchy_levels[item.levelId ?? item.level]?.id;
          items.push({ ...item, hierarchy_element: hierarchyLevelId });
        });
      } else {
        const hierarchyLevelId =
          hierarchy.hierarchy_levels[node.levelId ?? levelKey]?.id;
        items.push({ ...node, hierarchy_element: hierarchyLevelId });
      }
      const label = node.favouriteNodeType
        ? node.label
        : renderNodeLabel(node.label);
      labels.push(label);
    });
  });

  if (
    [
      'financial-week',
      'promo-week',
      'full-financial-week',
      'full-promo-week',
    ].includes(selectedHierarchy.id)
  ) {
    items.sort((a, b) => (a.id > b.id ? 1 : -1));
    labels.sort((a, b) => (a > b ? 1 : -1));
  }

  return {
    items,
    hierarchy_name: selectedHierarchy.name,
    name: labels.join(', '),
    tdp_id: [bannerId],
  };
};

export const getSplitParameters = (
  selectedHierarchy: Hierarchy,
  selection: { [key: string]: HierNode[] },
  hierarchies: { [key: string]: HierarchyResponse },
  bannerId: number
): ReportParameter[] => {
  const parameters: ReportParameter[] = [];
  const hierarchy = hierarchies[selectedHierarchy.id];

  Object.keys(selection).forEach((levelKey) => {
    selection[levelKey].forEach((node) => {
      const name = removeApostrophe(
        node.favouriteNodeType ? node.label : renderNodeLabel(node.label)
      );

      if (
        node.groupedItems ||
        (selectedHierarchy.is_custom && node.children?.length)
      ) {
        const grouped = selectedHierarchy.is_custom
          ? node.children
          : node.groupedItems;
        const items: HierarchyParam[] = grouped.map((item) => {
          const hierarchyLevelId =
            hierarchy.hierarchy_levels[item.levelId ?? item.level]?.id;
          return { ...item, hierarchy_element: hierarchyLevelId };
        });
        parameters.push({
          items,
          hierarchy_name: selectedHierarchy.name,
          name,
          tdp_id: [bannerId],
        });
      } else {
        const hierarchyLevelId =
          hierarchy.hierarchy_levels[node.levelId ?? levelKey]?.id;
        parameters.push({
          items: [{ ...node, hierarchy_element: hierarchyLevelId }],
          hierarchy_name: selectedHierarchy.name,
          name,
          tdp_id: [bannerId],
        });
      }
    });
  });

  return parameters;
};

const getDateParameter = (
  selectedDate: SelectedDate,
  bannerId: number,
  selectedHierarchyName: string
): ReportParameter => {
  const { startDate, endDate } = selectedDate;
  const from = DateTime.fromJSDate(startDate).toISODate();
  const to = DateTime.fromJSDate(endDate).toISODate();
  const name = from === to ? from : from + ' to ' + to;
  return {
    from,
    to,
    name,
    hierarchy_element: 'date_id',
    hierarchy_name: selectedHierarchyName,
    tdp_id: [bannerId],
  };
};

const datesSplitByDays = (
  dateParam: ReportParameter,
  splitBy: TimeIncrement,
  bannerId: number,
  selectedHierachyName: string
) => {
  const start = new Date(dateParam.from);
  const end = new Date(dateParam.to);
  let current = end;
  const dateGroups: ReportParameter[] = [];

  while (current >= start) {
    const dateWithSplit = DateTime.fromJSDate(current)
      .minus({
        days:
          splitBy.timeSplit === 'days'
            ? splitBy.duration - 1
            : splitBy.duration * 7 - 1,
      })
      .toJSDate();

    const selectedDate: SelectedDate = {
      endDate: current,
      startDate: dateWithSplit <= start ? start : dateWithSplit,
    };

    dateGroups.push(
      getDateParameter(selectedDate, bannerId, selectedHierachyName)
    );

    current = DateTime.fromJSDate(dateWithSplit).minus({ days: 1 }).toJSDate();
  }
  return dateGroups;
};

const datesSplitByMonths = (
  dateParam: ReportParameter,
  splitBy: TimeIncrement,
  bannerId: number,
  selectedHierachyName: string
) => {
  const start = new Date(dateParam.from);
  const end = new Date(dateParam.to);
  let current = end;
  const dateGroups: ReportParameter[] = [];

  while (current > start) {
    const xMonthsAgo = new Date(
      current.getFullYear(),
      current.getMonth() - (splitBy.duration - 1),
      1
    );

    const endOfPriorMonth = new Date(
      xMonthsAgo.getFullYear(),
      xMonthsAgo.getMonth(),
      0
    );

    const selectedDate: SelectedDate = {
      endDate: current,
      startDate: xMonthsAgo < start ? start : xMonthsAgo,
    };

    dateGroups.push(
      getDateParameter(selectedDate, bannerId, selectedHierachyName)
    );

    current = endOfPriorMonth;
  }

  return dateGroups;
};

export const getDateGroups = (
  selectedDate: SelectedDate[],
  bannerId: number,
  selectedHierachyName: string,
  splitBy?: TimeIncrement
): ReportParameter | ReportParameter[] => {
  const dateParam = getDateParameter(
    selectedDate[0],
    bannerId,
    selectedHierachyName
  );
  let dateGroups: ReportParameter[] = [];

  if (splitBy) {
    if (splitBy.timeSplit === 'days' || splitBy.timeSplit === 'weeks') {
      dateGroups = datesSplitByDays(
        dateParam,
        splitBy,
        bannerId,
        selectedHierachyName
      );
    } else {
      dateGroups = datesSplitByMonths(
        dateParam,
        splitBy,
        bannerId,
        selectedHierachyName
      );
    }

    return dateGroups.sort((a, b) => {
      return a.from > b.from ? 1 : -1;
    });
  } else {
    return dateParam;
  }
};

const getTransactionSpendBandGroup = (
  min: number,
  max: number,
  name: string,
  bannerId: number
): ReportParameter => {
  return {
    min: min,
    ...(max && { max }),
    name: max ? `$${min} - ${max}` : `$${min}+`,
    hierarchy_element: 'basket_amt',
    hierarchy_name: name,
    tdp_id: [bannerId],
  };
};

export const getTransactionSpendGroups = (
  spendBands: SpendBands,
  selectedHierarchy: Hierarchy,
  bannerId: number,
  includeUpperBand: boolean,
  spendIncrement?: number
): ReportParameter[] => {
  const { min, max } = spendBands;
  const params = [];

  if (spendIncrement && spendIncrement > 0) {
    const remainder = (max - min) % spendIncrement;
    const numIterations =
      Math.floor((max - min) / spendIncrement) + (remainder > 0 ? 1 : 0);
    for (let index = 0; index < numIterations; index++) {
      const newMin = min + index * spendIncrement;
      const newMax = Math.min(newMin + spendIncrement, max);
      params.push(
        getTransactionSpendBandGroup(
          newMin,
          includeUpperBand ? newMax : null,
          selectedHierarchy.name,
          bannerId
        )
      );
    }
  } else {
    params.push(
      getTransactionSpendBandGroup(
        min,
        includeUpperBand ? max : null,
        selectedHierarchy.name,
        bannerId
      )
    );
  }

  return params;
};

export const isDateUnique = (
  selectedDate: ReportParameter,
  existingDates: ReportParameter[]
) => {
  const duplicateDates = existingDates.filter(
    (d) => d.from === selectedDate.from && d.to === selectedDate.to
  );
  return duplicateDates.length === 0;
};

export const removeApostrophe = (label: string): string => {
  return label.replace(/'/g, '');
};

export const hasMatchingDescendant = (
  node: HierNode,
  searchTerm: string
): boolean => {
  if (node.label && node.label.toLowerCase().match(searchTerm.toLowerCase())) {
    return true;
  }

  if (!node.children) {
    return false;
  }

  return (
    node.children.filter((child) => hasMatchingDescendant(child, searchTerm))
      .length > 0
  );
};

export const renderNodeLabel = (label: string) =>
  label
    ? label.toLowerCase().replace(/(?:\b|_)\w/g, (x) => x.toUpperCase())
    : '-';

export const getSharedUserArray = (sharedUserObject: {
  [key: string]: string;
}): SharedUser[] => {
  return Object.keys(sharedUserObject).map((userId) => {
    return {
      userId,
      name: sharedUserObject[userId],
    };
  });
};

export const removeHexColourPrefix = (colour: string) =>
  colour?.replace('#', '');
