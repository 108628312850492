import type { FunctionComponent } from 'react';
import { useLayoutEffect, useRef } from 'react';
import { InlineLoading, Loading } from '@carbon/react';
import { useWindowDimensions } from '../../hooks';

interface DataVisualSkeletonProps {
  className?: string;
  displayDescription?: boolean;
}

const DataVisualSkeleton: FunctionComponent<DataVisualSkeletonProps> = ({
  className,
  displayDescription,
}) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const { documentHeight } = useWindowDimensions();

  useLayoutEffect(() => {
    if (containerRef.current) {
      const { top } = containerRef.current.getBoundingClientRect();
      const height = documentHeight - 30 - top;
      containerRef.current.style.height = `${height > 450 ? height : 450}px`;
    }
  }, [documentHeight]);

  return (
    <div
      className={`DataVisual__skeleton ${className ?? ''}`}
      ref={containerRef}
    >
      {displayDescription ? (
        <InlineLoading description="Loading report data..." />
      ) : (
        <Loading withOverlay={false} />
      )}
    </div>
  );
};

export default DataVisualSkeleton;
