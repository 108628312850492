import type { FunctionComponent } from 'react';
import { useContext, useEffect } from 'react';
import { DataError } from '@carbon/icons-react';
import { ReportContext } from '../../providers/ReportProvider';
import HomeReportContent from './HomeReportContent';
import { HomeContext } from '../../providers/HomeProvider';
import { filterVisuals, getDataFileKey } from '../../utils/reportUtils';
import NoDataPlaceholder from '../ReportTable/NoDataPlaceholder';
import HomepageLoadingSkeleton from '../Loading/HomepageLoadingSkeleton';

const HomeReportData: FunctionComponent = () => {
  const { reportConfig, toggleVisualFullscreen } = useContext(ReportContext);
  const { reportConfigMap, homeDropdownSelections, selectedCategoryKey } =
    useContext(HomeContext);

  const reportsToDisplay =
    (homeDropdownSelections &&
      reportConfigMap[getDataFileKey(homeDropdownSelections)]) ||
    [];

  useEffect(() => {
    const onFullscreenChange = () => {
      if (!document.fullscreenElement) {
        toggleVisualFullscreen(null);
      }
    };
    document.addEventListener('fullscreenchange', onFullscreenChange);
    return () =>
      document.removeEventListener('fullscreenchange', onFullscreenChange);
  }, []);

  if (!(homeDropdownSelections && reportConfig.configuration?.switchers)) {
    return <HomepageLoadingSkeleton />;
  }

  const reportData = reportsToDisplay.map((reportTemplateId) => ({
    reportTemplateId,
    switcherData: reportConfig.configuration.switchers[reportTemplateId],
  }));

  return selectedCategoryKey &&
    reportData.some(({ switcherData }) => switcherData) ? (
    <>
      {reportData.flatMap(
        ({ reportTemplateId, switcherData }, reportTemplateIndex) => {
          return switcherData?.map(({ visualContent, name }, visualsIndex) => {
            const visualsToDisplay = filterVisuals(
              reportConfig.configuration.visuals[reportTemplateId],
              (vis) => visualContent.includes(vis.id)
            );
            return (
              <HomeReportContent
                key={`${reportTemplateId}_${name}`}
                visualMetaData={visualsToDisplay}
                reportTemplateId={reportTemplateId}
                reportTemplateIndex={reportTemplateIndex}
                visualsIndex={visualsIndex}
                visualContent={visualContent}
              />
            );
          });
        }
      )}
    </>
  ) : (
    <NoDataPlaceholder
      title="No Data"
      icon={DataError}
      description={{
        info: (
          <div className="getVisual__no-data-info">
            The selected input combination does not have any associated data.
            <br />
            Try altering your selection to obtain updated results.
          </div>
        ),
      }}
    />
  );
};

export default HomeReportData;
