import type { FunctionComponent } from 'react';
import { useContext, useEffect, useRef, useState } from 'react';
import { InlineLoading, TextInput } from '@carbon/react';
import { Edit, Save } from '@carbon/icons-react';
import Tooltip from '../../Tooltip';
import { useAuth0 } from '@auth0/auth0-react';
import { ModalContext } from '../../../providers/ModalProvider';
import apiRequest from '../../../api';
import { AppContext } from '../../../providers/AppProvider';
import usePosthog from '../../../utils/posthog';
import { reportBuilderHierarchyEditName } from '../../../constants/posthog';
import type { ResponsePayload } from '../../Fetch';

interface EditNodeNameProps {
  node: HierNode;
  hierarchyName: string;
}

const EditNodeName: FunctionComponent<EditNodeNameProps> = ({
  node,
  hierarchyName,
}) => {
  const [nodeName, setNodeName] = useState<string>(node.label);
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [editing, setEditing] = useState(false);
  const textInputRef = useRef<HTMLInputElement>(null);
  const { getAccessTokenSilently } = useAuth0();
  const { updateModal } = useContext(ModalContext);
  const posthogEvent = usePosthog();
  const { groupId, bannerId, user, updateFavouriteGroups } =
    useContext(AppContext);

  const handleOutsideClick = (e) => {
    if (textInputRef.current && !textInputRef.current.contains(e.target)) {
      setEditing(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [setEditing]);

  const changeNodeName = async () => {
    setIsUpdating(true);
    const body = {
      hierarchy_name: hierarchyName,
      tdp_id: bannerId,
      user_group: groupId,
      group_name: node.label,
      new_group_name: nodeName,
    };
    try {
      const token = await getAccessTokenSilently();
      const response = await apiRequest<ResponsePayload<FavouriteGroup[]>>(
        `/users/${encodeURIComponent(user.id)}/report-params/favourites/rename`,
        'PATCH',
        token,
        body
      );
      updateFavouriteGroups(response.data);
    } catch (error) {
      updateModal({
        type: 'error',
        title: 'Something went wrong',
        body: 'There was an issue updating the favourite group name. Please try again, or refresh your browser.',
      });
    } finally {
      setIsUpdating(false);
    }
  };

  const saveChanges = () => {
    if (nodeName.length > 0 && node.label !== nodeName) {
      posthogEvent(reportBuilderHierarchyEditName, {
        origin: 'favourites',
        newName: nodeName,
      });
      changeNodeName();
    }
    setEditing(false);
  };

  return (
    <div className="node-table-edit-name-container">
      {editing ? (
        <div ref={textInputRef} className="node-table-edit-name-wrapper">
          <TextInput
            id={`node-name-input-${node.id}`}
            labelText="Edit group name"
            data-testid={`node-name-input-${node.id}`}
            hideLabel
            value={nodeName}
            onClick={(e) => {
              e.stopPropagation();
            }}
            autoFocus
            onChange={(e) => {
              setNodeName(e.target.value);
            }}
            onKeyDown={({ code }) => {
              if (code === 'Enter') {
                saveChanges();
              }
            }}
            invalid={!nodeName || nodeName.trim().length === 0}
          />
          <Tooltip description="Save group name" align="bottom-right">
            <Save
              data-testid={`node-name-save-icon-${node.id}`}
              onClick={(e) => {
                e.stopPropagation();
                saveChanges();
              }}
            />
          </Tooltip>
        </div>
      ) : (
        <>
          {isUpdating ? (
            <InlineLoading description="Updating..." />
          ) : (
            <div className="node-name">
              <div>{node.label}</div>
              <div className="node-name--edit">
                <Tooltip description="Edit group name" align="left">
                  <Edit
                    data-testid={`node-name-edit-icon-${node.id}`}
                    onClick={(e) => {
                      e.stopPropagation();
                      setEditing(true);
                      nodeName === '' && setNodeName(node.label);
                    }}
                  />
                </Tooltip>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default EditNodeName;
