import type { FunctionComponent } from 'react';
import { useEffect, useMemo, useRef, useState } from 'react';
import * as d3 from 'd3';
import { InlineNotification } from '@carbon/react';
import { getSVGPath } from '../../utils/reportUtils';
import { clearSVG, getNodes } from '../../utils/dendroUtils';

export interface DendrogramProps {
  data: DendrogramNode;
  numSkus: number;
}

const Dendrogram: FunctionComponent<DendrogramProps> = ({ data, numSkus }) => {
  const topMargin = 48;
  const height = numSkus * 36;
  const ref = useRef(null);
  const [width, setWidth] = useState(0);
  const nodes = useMemo(() => getNodes(data, height, width), [data, width]);

  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      setWidth(entries[0].contentRect.width);
    });
    observer.observe(ref.current);
    return () => {
      ref.current && observer.unobserve(ref.current);
    };
  }, []);

  useEffect(() => {
    clearSVG();
    drawSVG();
  }, [width]);

  const drawSVG = () => {
    const svg = d3
      .select('#dendrogram')
      .append('svg')
      .attr('width', width)
      .attr('height', height);

    svg
      .selectAll('path')
      .data(nodes)
      .enter()
      .append('path')
      .attr('d', (d) => getSVGPath(d, width))
      .style('fill', 'none')
      .style('stroke', '#2327bb')
      .attr('stroke-width', '1px');
  };

  return (
    <div id="dendrogram" style={{ marginTop: topMargin }} ref={ref}>
      {!data.children && (
        <InlineNotification
          title="This dendrogram has no valid data to display. Please try re-running the
          report with different parameters."
          hideCloseButton
          lowContrast
          kind="warning"
        />
      )}
    </div>
  );
};

export default Dendrogram;
