/* eslint-disable indent */
import type { ChangeEvent, ReactNode } from 'react';
import {
  Fragment,
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import {
  Button,
  ContentSwitcher,
  DataTable,
  Pagination,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeader,
  TableRow,
  TableToolbar,
  TableToolbarContent,
  TableToolbarMenu,
  TableToolbarSearch,
  InlineNotification,
  InlineLoading,
} from '@carbon/react';
import { Column, Download, Save, Warning } from '@carbon/icons-react';
import {
  findVisualById,
  getFilteredRows,
  getFormattedValue,
  getLeftOffset,
  getSearchableColumnIds,
  getSkuGridApiUrl,
  getVisibleColumns,
} from '../../utils/reportUtils';
import { useStickyTableHeader } from '../../utils/useStickyTableHeader';
import '../../styles/components/workspace.scss';
import {
  getCellIcon,
  getEditableCell,
  getIndexesBetween,
  sortHeaders as defaultSortHeaders,
} from '../../utils/DataGridUtils';
import apiRequest from '../../api';
import { useAuth0 } from '@auth0/auth0-react';
import type { ReportConfig, VisualData } from '../../reducers/ReportReducer';
import { ModalContext } from '../../providers/ModalProvider';
import {
  columnsCTAClick,
  tableSortedBy,
  skuGridBtnClick,
  visualSearchInputText,
} from '../../constants/posthog';
import usePosthog from '../../utils/posthog';
import ColumnOrder from '../ColumnOrder';
import { useDebounce, usePagination, useWindowDimensions } from '../../hooks';
import Tooltip from '../Tooltip';
import CardHeader from '../Cards/CardHeader';
import { PAGE_TITLE, subsKeyRegex } from '../../constants/metadata';
import { getPresentedColour } from '../../utils/colour';
import DownloadOptions from '../DownloadOptions/DownloadOptions';
import type { GlobalSelection } from '../Report/ReportContent';
import type { Decision } from '../../reducers/AssortmentReducer';

interface DataGridHandle {
  readonly showHeaders: (keys: Header['key'][]) => Promise<void>;
  readonly scrollHeaderIntoView: () => void;
}

type ActionExtension<T> = T & {
  readonly action?: {
    readonly onClick: (cellId: string) => void | Promise<void>;
    readonly icon: ReactNode;
    readonly tooltip: string;
  };
};

export type RowDataDefaultType = Record<
  string,
  | string
  | boolean
  | ActionExtension<Point<number | string> | NumberPoint | Decision>
>;

export type RowData<T = RowDataDefaultType, K extends keyof T = keyof T> = {
  [key in K]: T[K];
};

export enum CustomActionKey {
  VISUAL_DROPDOWNS_TABLE = 'visual_dropdowns_table',
  VISUAL_DROPDOWNS_TOOLBAR = 'visual_dropdowns_toolbar',
  SHARED_DROPDOWN = 'shared_dropdown',
  OPTIMISER_SAVE_BUTTON = 'Optimiser_save_button',
}

export interface CustomAction {
  key: CustomActionKey;
  element: JSX.Element;
}

export interface HighlightedCell {
  color?: string;
  backgroundColor?: string;
  showBorder?: boolean;
  tooltip?: string;
}

interface Props<T = RowDataDefaultType> {
  headerData: Header[];
  rowData: RowData<T>[];
  id?: number;
  showExport?: boolean;
  stickyColumns?: number[];
  visibleColumns?: number[];
  collapsed?: boolean;
  showPagination?: boolean;
  isSortable?: boolean;
  searchable?: boolean;
  isNestedConfiguration?: boolean;
  exportedFileName?: string;
  highlightedRows?: number[];
  searchableColumns?: number[];
  columnWidths?: { [key: string]: number };
  visualData?: VisualData;
  reportConfig?: ReportConfig;
  resizable?: boolean;
  dropdownKey?: string;
  isAuthor?: boolean;
  isSupplier?: boolean;
  isSkuSelected?: boolean;
  isSkuGridReady?: boolean;
  customToolbarActions?: CustomAction[];
  customTableActions?: CustomAction[];
  highlightedCells?: {
    [key: string]: HighlightedCell;
  };
  clickedCellId?: string;
  reportTemplateId?: string;
  reportTemplateIndex?: number;
  visualHeader?: VisualHeader;
  hasChildGrid?: boolean;
  persistHeaders?: boolean;
  globalSelections?: GlobalSelection[];
  onSelectRows?: (rows: number[], add?: boolean) => void;
  onEditRows?: (rows: object[], cellKey?: string) => void;
  onSave?: () => void;
  patchVisualsData?: (id: number | string, data: Partial<VisualData>) => void;
  toggleSkuGrid?: () => void;
  customSortHeaders?: (a: Header, b: Header) => number;
  handleGridServerExport?: (name: string) => Promise<void>;
}

const DataGrid = forwardRef<DataGridHandle, Props>(function DataGrid(
  props,
  ref
) {
  const {
    headerData,
    rowData,
    showExport = true,
    isSortable = true,
    showPagination = true,
    stickyColumns,
    visibleColumns,
    collapsed,
    searchable = false,
    isNestedConfiguration = false,
    highlightedRows = [],
    exportedFileName = PAGE_TITLE + ' export',
    searchableColumns,
    id,
    columnWidths,
    visualData,
    reportConfig,
    resizable,
    dropdownKey,
    isAuthor,
    isSupplier,
    isSkuSelected,
    isSkuGridReady,
    customToolbarActions,
    customTableActions,
    highlightedCells,
    clickedCellId,
    reportTemplateId,
    visualHeader,
    hasChildGrid,
    persistHeaders,
    globalSelections,
    onSelectRows,
    onEditRows,
    patchVisualsData,
    onSave,
    toggleSkuGrid,
    customSortHeaders,
    handleGridServerExport,
  } = props;

  const { getAccessTokenSilently } = useAuth0();
  const { updateModal, renderComponent } = useContext(ModalContext);
  const posthogEvent = usePosthog();
  const {
    page,
    pageSizes,
    pageSize,
    isShowAll,
    getItemRangeText,
    setPagination,
  } = usePagination();
  const { pathname } = useLocation();
  const [searchParameters, setSearchParameters] = useSearchParams();
  const [stickyRefs, setStickyRefs] = useState([]);
  const [showSubsColumnsInfo, setShowSubsColumnsInfo] = useState(false);
  const [cellTooltip, setCellTooltip] = useState(null);
  const { documentWidth } = useWindowDimensions();

  const dragBounds = useRef([]);
  const columnRefs = useRef([]);
  const initialX = useRef({
    startX: 0,
    endX: 0,
    columnId: -1,
    width: 0,
    index: -1,
  });

  const [searchText, setSearchText] = useState('');
  const debouncedSearchText: string = useDebounce<string>(searchText, 1000);

  useEffect(() => {
    if (debouncedSearchText) {
      posthogEvent(visualSearchInputText, {
        title: visualHeader?.title,
        text: debouncedSearchText,
      });
    }
  }, [debouncedSearchText]);

  const tableId = `data-table-container${id}`;
  const [sortState, setSortState] = useState('');

  const sortHeaders = (a: Header, b: Header): number => {
    return customSortHeaders
      ? customSortHeaders(a, b)
      : defaultSortHeaders(a, b, visibleColumns);
  };

  const sortableColumns = headerData
    .filter((header) => !stickyColumns?.includes(header.id))
    .sort((a, b) => sortHeaders(a, b))
    .map((h) => ({
      key: h.key,
      id: h.id,
      name: h.header,
      isDragDisabled: !isAuthor && !persistHeaders,
      visible: !visibleColumns || visibleColumns.includes(h.id),
    }));

  const visibleHeaders = headerData
    .filter((header) => {
      return !visibleColumns || visibleColumns.includes(header.id);
    })
    .sort((a, b) => sortHeaders(a, b));

  const frozenColumns = headerData
    .filter((h) => stickyColumns?.includes(h.id))
    .map((h) => ({
      key: h.key,
      id: h.id,
      name: h.header,
      isDragDisabled: true,
    }));

  useEffect(() => {
    if (stickyColumns?.length) {
      const stickys = columnRefs.current
        .filter((item) => item !== null)
        .slice(0, stickyColumns.length);
      setStickyRefs(stickys);
    }
  }, [
    columnRefs.current.filter((item) => item !== null).length,
    pageSize,
    page,
    sortState,
    dropdownKey,
    stickyColumns,
    columnWidths,
  ]);

  const saveData = async (data: { [key: string]: unknown }) => {
    let apiUrl = findVisualById(
      reportConfig.configuration.visuals[reportTemplateId],
      Number(id)
    ).apiUrl;
    let visualId: string | number = id;
    if (isSkuSelected) {
      apiUrl = getSkuGridApiUrl(apiUrl);
      visualId = `${id}_sku-grid`;
    }
    const isReportSplit = reportConfig.report_version === 'v2';
    try {
      if (!persistHeaders) {
        const token = await getAccessTokenSilently();
        apiRequest(
          `/reports${apiUrl}${isReportSplit ? '/config' : ''}`,
          'PATCH',
          token,
          data
        );
      }

      showSubsColumnsInfo && setShowSubsColumnsInfo(false);
      patchVisualsData(visualId, data);
    } catch {
      updateModal({
        type: 'error',
        title: 'Something went wrong',
        body: 'There was an issue saving the column layout. Please try again.',
      });
    }
  };

  useImperativeHandle(
    ref,
    () => ({
      showHeaders: async (keys) => {
        const subsColumnIds = keys.reduce((acc, key) => {
          const id = headerData.find((item) => item.key === key)?.id;
          if (typeof id === 'number') {
            acc.push(id);
          }
          return acc;
        }, []);

        const headersIdsToShow = headerData.reduce(
          (headerIds, { key, id }) =>
            keys.includes(key) && !visibleColumns.includes(id)
              ? [...headerIds, id]
              : headerIds,
          [] as number[]
        );

        const hasAllSubsColumns = subsColumnIds.every((id) =>
          visibleColumns.includes(id)
        );

        if (headersIdsToShow.length === 0) {
          return;
        }

        if (!hasAllSubsColumns) {
          const updatedIsVisible = [...visibleColumns, ...headersIdsToShow];

          await saveData({
            isVisible: isNestedConfiguration
              ? { ...visualData.isVisible, [dropdownKey]: updatedIsVisible }
              : updatedIsVisible,
          });
        }
        setShowSubsColumnsInfo(!hasAllSubsColumns);
      },
      scrollHeaderIntoView: () => {
        const targetHeaderIndex = visibleHeaders.findIndex((header) =>
          subsKeyRegex.test(header.key)
        );
        if (targetHeaderIndex < 0) {
          return;
        }
        const index = clickedCellId ? targetHeaderIndex : 1;
        columnRefs.current[index]?.scrollIntoView({
          block: 'center',
          inline: 'center',
        });
      },
    }),
    [headerData, visibleColumns, visibleHeaders, clickedCellId]
  );

  const getStyle = (
    cell,
    cellIndex: number,
    isHeader: boolean,
    headers?: Header[]
  ) => {
    const { id, editable } =
      headers.find((h) => h.key === cell.info.header) || {};

    const isSticky =
      stickyColumns?.includes(id) &&
      (!visibleColumns || visibleColumns.includes(id));

    if (isSticky && stickyRefs.length > 0) {
      return {
        position: 'sticky',
        left: getLeftOffset(stickyRefs, cellIndex) + 'px',
        borderCollapse: 'separate',
        zIndex: isHeader ? 2 : 1,
        whiteSpace: 'nowrap',
      };
    } else if (editable?.type === 'text' && !isHeader) {
      return {
        padding: 0,
      };
    }
  };

  const onEditCell = ({
    value,
    cellKey,
    rowId,
  }: {
    value: string | EditableDropdownOption;
    cellKey: string;
    rowId: string | number;
  }) => {
    const newRows = rowData.map((row) => {
      if (row.id !== rowId) {
        return row;
      }

      const existingValue = row[cellKey];

      return {
        ...row,
        [cellKey]:
          typeof existingValue !== 'object' || typeof value === 'string'
            ? value
            : { ...existingValue, ...value },
      };
    });

    onEditRows(newRows, cellKey);
  };

  const iconCell = (iconType) => {
    return iconType === 'dot' ? (
      <div data-testid="cross-shop-dot" className="cross-shop-circle" />
    ) : (
      <div data-testid="cross-shop-blank" />
    );
  };

  const formattedCell = (value) => {
    const formattedValue =
      typeof value !== 'string' && value.bold ? (
        <strong>{getFormattedValue(value)}</strong>
      ) : (
        getFormattedValue(value)
      );

    return (
      <>
        {getCellIcon(value?.icon, getPresentedColour(value?.icon_colour))}
        {formattedValue}
      </>
    );
  };

  const renderCell = ({
    cell,
    cellIndex,
    rowId,
    headers,
  }: {
    cell;
    cellIndex: number;
    rowId: string | number;
    headers: Header[];
  }) => {
    const { id, value } = cell;

    const headerId = headers.find((h) => h.key === cell.info.header)?.id;

    if (!value && value !== '') {
      return <TableCell key={id} />;
    }

    const renderContent = () => {
      if (headers[cellIndex]?.editable) {
        return getEditableCell({
          editable: headers[cellIndex].editable,
          cell,
          rowId,
          onEditCell,
          isAuthor,
          isSupplier,
        });
      }

      if (value.iconType) {
        return iconCell(value.iconType);
      }

      if (value.action) {
        const { icon, tooltip, onClick } = value.action;
        return (
          <div className="DataGrid__action-cell">
            <div className="DataGrid__action-cell--value">{value.value}</div>
            <div className="DataGrid__action-cell--action-container">
              <Button
                className="DataGrid__action-cell--action-button"
                size="sm"
                data-testid={`cell-action-${cellIndex}`}
                onMouseDown={(e) => {
                  e.stopPropagation();
                }}
                onMouseEnter={(e) => {
                  const cell = (
                    e.target as HTMLElement
                  ).getBoundingClientRect();
                  const { x, y, width } = cell;
                  setCellTooltip({
                    y: y + 36,
                    x: x,
                    width,
                    tooltip,
                  });
                }}
                onClick={() => {
                  const clicked = cell.id === clickedCellId ? null : cell.id;
                  onClick(clicked);
                }}
              >
                {icon}
              </Button>
            </div>
          </div>
        );
      }

      return formattedCell(value);
    };

    const classes = [];

    if (stickyColumns && cellIndex === stickyColumns.length - 1) {
      classes.push('shadow-cell');
    }

    if (cell.id === clickedCellId || highlightedCells?.[cell.id]?.showBorder) {
      classes.push('highlighted');
    }

    const cellWidth = columnWidths?.[headerId]
      ? columnWidths[headerId] + 'px'
      : '300px';

    return (
      <TableCell
        style={{
          backgroundColor: getPresentedColour(
            highlightedCells?.[id]?.backgroundColor ?? value.background_colour
          ),
          color: getPresentedColour(
            highlightedCells?.[id]?.color ?? value.colour
          ),
          textAlign: value.format && 'right',
          padding: 0,
          maxWidth: cellWidth,
          ...getStyle(cell, cellIndex, false, headers),
        }}
        className={classes.join(' ')}
        key={id + headerId}
        onMouseEnter={(e) => {
          if (highlightedCells?.[id]?.tooltip) {
            const cell = (e.target as HTMLElement).getBoundingClientRect();
            const { x, y, width } = cell;
            setCellTooltip({
              y: y + 42,
              x: x,
              width,
              tooltip: highlightedCells?.[id]?.tooltip,
            });
          }
        }}
        onMouseLeave={() => {
          setCellTooltip(null);
        }}
      >
        <div
          className="table-cell"
          style={{
            overflow:
              headers[cellIndex]?.editable?.type === 'dropdown'
                ? 'revert'
                : 'hidden',
          }}
        >
          {renderContent()}
        </div>
      </TableCell>
    );
  };

  const getSortState = (prevState: string, headerKey: string) => {
    const nextState = {
      ASC: 'DESC',
      DESC: 'UNSORTED',
      UNSORTED: 'ASC',
    };
    const [prevKey, prevStatus] = prevState.split('-');
    const status = nextState[prevStatus];
    if (status && prevKey === headerKey) {
      return `${headerKey}-${status}`;
    }
    return `${headerKey}-ASC`;
  };

  const sortRows = (cellA, cellB, { sortDirection, sortStates }) => {
    const cellAValue = typeof cellA === 'string' ? cellA : cellA?.value;
    const cellBValue = typeof cellB === 'string' ? cellB : cellB?.value;

    const [firstValue, secondValue] =
      sortDirection === sortStates.DESC
        ? [cellBValue, cellAValue]
        : [cellAValue, cellBValue];

    return typeof firstValue === 'string'
      ? firstValue.localeCompare(secondValue)
      : firstValue - secondValue;
  };

  const handleRowClick = (row: TableRow) => {
    if (visualData?.isClickable) {
      const cell = rowData.find(({ id }) => id === row.id)?.[
        visualData.isClickable.key
      ];
      const cellValue = typeof cell !== 'object' ? cell : cell?.value;
      if (cellValue) {
        searchParameters.set('sku_id', cellValue.toString());
        setSearchParameters(searchParameters);
        const appContainer = document.querySelector('#root > .app');
        appContainer?.scrollTo({
          top: 0,
        });
      }
    }
  };

  const getRows = (rows, headers: Header[]) => {
    const startIndex = (page - 1) * pageSize;

    const tableRows =
      showPagination && !isShowAll
        ? rows.slice(startIndex, startIndex + pageSize)
        : rows;

    return tableRows.map((row, rowIndex) => (
      <TableRow
        key={row.id}
        tabIndex={0}
        isSelected={highlightedRows.includes(rowIndex)}
        className={
          visualData?.isClickable ? 'DataGrid__table-row--clickable' : ''
        }
        onMouseDown={() => onMouseDown(rowIndex)}
        onMouseUp={({ shiftKey }) => onMouseUp(rowIndex, shiftKey)}
        style={{ height: collapsed ? '36px' : '48px' }}
        onClick={() => handleRowClick(row)}
      >
        {row.cells.map((cell, cellIndex) =>
          renderCell({ cell, cellIndex, rowId: row.id, headers })
        )}
      </TableRow>
    ));
  };

  const searchableColumnsIds = useMemo(
    () =>
      searchable && searchableColumns
        ? getSearchableColumnIds(headerData, searchableColumns)
        : null,
    [headerData, searchableColumns]
  );

  const filterRows = ({
    rowIds,
    cellsById,
    inputValue,
  }: {
    rowIds: string[];
    cellsById: Record<string, CarbonCell<string>>;
    inputValue: string;
  }) => {
    const newRowIds = getFilteredRows(
      cellsById,
      searchableColumnsIds,
      inputValue
    );

    return rowIds.filter((id) => newRowIds.includes(id));
  };

  const { isSticky, scrollbarRef } = useStickyTableHeader({
    tableId,
    rowsLength: rowData?.length,
    columns: visibleHeaders,
    columnWidths,
    documentWidth,
    resizable,
  });

  const onMouseDown = (index: number) => {
    if (onSelectRows) {
      dragBounds.current[0] = index;
    }
  };

  const onMouseUp = (index: number, shiftKey: boolean) => {
    if (onSelectRows) {
      dragBounds.current[1] = index;

      onSelectRows(
        getIndexesBetween(dragBounds.current[0], dragBounds.current[1]),
        shiftKey
      );

      dragBounds.current = [];
    }
  };

  const onResizeStart = (event, columnId, headers) => {
    const index = headers.findIndex((h) => h.id === columnId);
    const handle = document.getElementById(`resize-handle-${columnId}`);
    handle.classList.add('resizing');

    const allHeaders = document.querySelectorAll('th.table-header');
    allHeaders[index].classList.add('resizing');

    const sortableHeaders = document.querySelectorAll(
      '.table-header.cds--table-sort'
    );
    if (sortableHeaders.length) {
      sortableHeaders[index].classList.add('resizing');
    }

    initialX.current = {
      columnId,
      index,
      width: columnRefs.current.filter((item) => item !== null)[index]
        .clientWidth,
      startX: event.clientX,
      endX: 0,
    };

    document.addEventListener('mousemove', onColumnResize);
    document.addEventListener('mouseup', onResizeEnd);
  };

  const onColumnResize = (event) => {
    initialX.current = {
      ...initialX.current,
      endX: event.clientX,
    };

    const handle = document.getElementById(
      `resize-handle-${initialX.current.columnId}`
    );
    handle.setAttribute(
      'style',
      `right: ${initialX.current.startX - event.clientX}px;`
    );
  };

  const onResizeEnd = () => {
    const dx = initialX.current.endX - initialX.current.startX;
    const newWidth = Math.max(initialX.current.width + dx, 100);

    const newColumnWidths = {
      ...columnWidths,
      [initialX.current.columnId]: newWidth,
    };

    saveData({
      columnWidths: isNestedConfiguration
        ? { ...visualData.columnWidths, [dropdownKey]: newColumnWidths }
        : newColumnWidths,
    });

    const handle = document.getElementById(
      `resize-handle-${initialX.current.columnId}`
    );
    handle.classList.remove('resizing');

    const allHeaders = document.querySelectorAll('th.table-header');
    const allLabels = document.querySelectorAll('.cds--table-header-label');
    allHeaders[initialX.current.index].classList.remove('resizing');
    allHeaders[initialX.current.index].setAttribute(
      'style',
      `width: ${newWidth}px; min-width: ${newWidth}px;`
    );
    allLabels[initialX.current.index].setAttribute(
      'style',
      `max-width: ${newWidth - 32}px;`
    );

    const sortableHeaders = document.querySelectorAll(
      '.table-header.cds--table-sort'
    );
    if (sortableHeaders.length) {
      sortableHeaders[initialX.current.index].classList.remove('resizing');
      sortableHeaders[initialX.current.index].setAttribute(
        'style',
        `width: ${newWidth}px, min-width: ${newWidth}px;`
      );
    }

    handle.setAttribute('style', 'right: 0;');
    document.removeEventListener('mousemove', onColumnResize);
    document.removeEventListener('mouseup', onResizeEnd);
  };

  useEffect(() => {
    columnRefs.current.forEach((column, index) => {
      if (!column) {
        return;
      }

      const headerId = visibleColumns?.[index];

      if (columnWidths?.[headerId]) {
        column.style.width = `${columnWidths[headerId]}px`;
        column.style.minWidth = `${columnWidths[headerId]}px`;
        column.style.maxWidth = '';
      } else {
        column.style.width = 'initial';
        column.style.minWidth = 'initial';
        column.style.maxWidth = '300px';
      }

      if (stickyRefs.includes(column)) {
        column.style.position = 'sticky';
        column.style.left = getLeftOffset(stickyRefs, index) + 'px';
        column.style.zIndex = 2;
      } else {
        column.style.position = 'relative';
        column.style.left = 'unset';
        column.style.zIndex = 1;
      }
    });
  }, [columnWidths, documentWidth, visibleColumns, stickyColumns, stickyRefs]);

  useEffect(() => {
    if (dropdownKey) {
      columnRefs.current = [];
    }
  }, [dropdownKey]);

  const getHeaderClasses = (headerId: number, index: number) => {
    const classes = ['table-header'];
    const sticky = stickyColumns?.includes(headerId);
    if (sticky) {
      classes.push('sticky');
    }
    if (stickyColumns && index === stickyColumns.length - 1) {
      classes.push('shadow-cell');
    }
    return classes;
  };

  const showColumnOrder = () => {
    posthogEvent(columnsCTAClick, {
      reportType: reportConfig.url_route,
      origin,
    });
    renderComponent(
      <ColumnOrder
        columns={sortableColumns}
        frozenColumns={frozenColumns}
        isButtonsHidden={!isAuthor && !persistHeaders}
        updateVisibleColumns={(visibleIds) => {
          const frozenIds = frozenColumns.map((c) => c.id);
          const previousIds = sortableColumns.map((c) => c.id);
          const newIds = [...frozenIds, ...visibleIds];
          const oldIds = [...frozenIds, ...previousIds];

          const newVisibleColumns = getVisibleColumns(
            newIds,
            oldIds,
            visibleColumns
          );

          saveData({
            isVisible: isNestedConfiguration
              ? { ...visualData.isVisible, [dropdownKey]: newVisibleColumns }
              : newVisibleColumns,
          });
        }}
      />
    );
  };

  const hiddenColumns = sortableColumns.filter((c) => !c.visible);

  return (
    <>
      <DataTable
        headers={visibleHeaders}
        rows={rowData}
        sortRow={sortRows}
        filterRows={filterRows}
      >
        {({
          rows,
          headers,
          getHeaderProps,
          getTableProps,
          getToolbarProps,
          getTableContainerProps,
          onInputChange,
          sortBy,
        }) => (
          <TableContainer {...getTableContainerProps()}>
            {(searchable || showExport || resizable) && (
              <TableToolbar {...getToolbarProps()}>
                {visualHeader?.title && <CardHeader {...visualHeader} />}
                {showSubsColumnsInfo && (
                  <InlineNotification
                    kind="info"
                    lowContrast
                    title="All Substitute Columns Now Visible"
                    style={{ maxWidth: '400px' }}
                  />
                )}
                <TableToolbarContent
                  aria-hidden={!searchable}
                  className="DataGrid__table-toolbar"
                >
                  {hasChildGrid && (
                    <div className="DataGrid__grid-switching">
                      <ContentSwitcher
                        selectedIndex={Number(isSkuSelected)}
                        size="sm"
                        onChange={() => {
                          toggleSkuGrid();
                          posthogEvent(skuGridBtnClick, {
                            reportType: reportConfig.url_route,
                            origin,
                          });
                        }}
                      >
                        <Switch name="Group" text="Group" />
                        <Switch
                          name="SKUs"
                          data-testid="grid-switcher-sku-button"
                          disabled={
                            !isSkuGridReady || !!reportConfig.error_message
                          }
                          className={
                            !isSkuGridReady || reportConfig.error_message
                              ? 'DataGrid__grid-switcher-button-error'
                              : ''
                          }
                        >
                          {!isSkuGridReady && !reportConfig.error_message && (
                            <InlineLoading description="Loading SKUs" />
                          )}
                          {!!reportConfig.error_message && (
                            <div className="DataGrid__grid-switching-error">
                              <span>Failed SKUs</span>
                              <Tooltip
                                align="bottom"
                                description={`SKU level report failed due to a large volume of data.${
                                  isAuthor
                                    ? ' Edit the report to reset the SKU data.'
                                    : ''
                                }`}
                                useButtonWrapper
                              >
                                <Warning size="20" />
                              </Tooltip>
                            </div>
                          )}
                          {isSkuGridReady &&
                            !reportConfig.error_message &&
                            'SKUs'}
                        </Switch>
                      </ContentSwitcher>
                    </div>
                  )}
                  {searchable && (
                    <TableToolbarSearch
                      tabIndex={!searchable ? -1 : 0}
                      onChange={(event: ChangeEvent<HTMLInputElement>) => {
                        setSearchText(event.target.value);
                        onInputChange(event);
                      }}
                    />
                  )}
                  {(isAuthor || persistHeaders) &&
                    Array.isArray(visibleColumns) && (
                      <Button
                        data-testid="column-order-btn"
                        kind="ghost"
                        renderIcon={Column}
                        onClick={showColumnOrder}
                        size="md"
                        className={`notifications-icon hoverable ${
                          hiddenColumns.length > 0 && !pathname.includes('home')
                            ? 'has-unseen'
                            : ''
                        }`}
                      >
                        Columns
                      </Button>
                    )}
                  {customToolbarActions?.map((action) => (
                    <Fragment key={action.key}>{action.element}</Fragment>
                  ))}
                  {onSave && (
                    <Button
                      data-testid="save-btn"
                      kind="ghost"
                      renderIcon={Save}
                      onClick={onSave}
                      hasIconOnly
                      iconDescription="Save changes"
                    />
                  )}
                  {showExport && rowData.length > 0 && (
                    <TableToolbarMenu
                      title="Download"
                      renderIcon={Download}
                      data-testid="export-toolbar-menu"
                    >
                      <DownloadOptions
                        headerData={headerData}
                        id={id}
                        rowData={rowData}
                        visualData={visualData}
                        visibleHeaders={visibleHeaders}
                        visualHeader={visualHeader}
                        reportConfig={reportConfig}
                        reportTemplateId={reportTemplateId}
                        exportedFileName={exportedFileName}
                        isSkuSelected={isSkuSelected}
                        globalSelections={globalSelections}
                        handleGridServerExport={handleGridServerExport}
                      />
                    </TableToolbarMenu>
                  )}
                </TableToolbarContent>
              </TableToolbar>
            )}
            {customTableActions?.length > 0 && (
              <div className="DataGrid__dropdowns-container">
                {customTableActions.map((action) => (
                  <Fragment key={action.key}>{action.element}</Fragment>
                ))}
              </div>
            )}
            <div className="data-table-container" id={tableId}>
              <Table
                {...getTableProps()}
                data-testid="data-grid-table"
                id={`data-grid-table-${tableId}`}
              >
                <TableHead>
                  <TableRow
                    className={isSticky ? 'sticky-header' : 'visible-header'}
                    data-testid="data-grid-table-header-row"
                  >
                    {headers.map((headerData, index) => {
                      const { id, key, header } = headerData;
                      const classes = getHeaderClasses(id, index);
                      const cellWidth = columnWidths?.[id]
                        ? columnWidths[id] + 'px'
                        : '100%';
                      return (
                        <TableHeader
                          key={key}
                          data-testid={`column-header-${key}`}
                          {...getHeaderProps({ header: headerData })}
                          tabIndex={0}
                          isSortable={isSortable}
                          className={classes.join(' ')}
                          style={{ width: cellWidth }}
                          ref={(ref) => (columnRefs.current[index] = ref)}
                          onClick={() => {
                            sortBy(key);
                            setSortState((prev) => getSortState(prev, key));
                            posthogEvent(tableSortedBy, {
                              title: visualHeader?.title,
                              reportName: reportConfig?.report_name,
                              reportType: reportConfig?.report_type,
                              sortedBy: header,
                            });
                          }}
                        >
                          {header}
                          {resizable && (
                            <div
                              onMouseDown={(e) => {
                                onResizeStart(e, id, headers);
                              }}
                              className="header-resize-handle"
                              id={`resize-handle-${id}`}
                              data-testid={`resize-handle-${id}`}
                            />
                          )}
                        </TableHeader>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <TableBody data-testid="datagrid-body">
                  {getRows(rows, headers)}
                </TableBody>
              </Table>
            </div>
          </TableContainer>
        )}
      </DataTable>
      <div
        className={`data-grid-scrollbar-container ${
          !showPagination ? 'data-grid-scrollbar-container_dendrogram' : ''
        }`}
        data-testid="data-grid-scrollbar-container"
        ref={scrollbarRef}
      >
        <div />
      </div>
      {showPagination && (
        <Pagination
          data-testid="data-grid-pagination"
          backwardText="Previous page"
          forwardText="Next page"
          itemsPerPageText="Items per page:"
          onChange={setPagination}
          page={page}
          pageSizes={pageSizes}
          itemRangeText={getItemRangeText}
          totalItems={rowData.length}
        />
      )}
      {cellTooltip && (
        <div
          className="DataGrid__tooltip-container"
          style={{
            width: cellTooltip.width,
            top: cellTooltip.y,
            left: cellTooltip.x,
          }}
        >
          <div
            className="DataGrid__tooltip-pointer"
            style={{
              left: cellTooltip.width / 2 - 4,
            }}
          />
          <div className="DataGrid__tooltip-label">{cellTooltip.tooltip}</div>
        </div>
      )}
    </>
  );
});

export default DataGrid;
