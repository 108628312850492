import type { FunctionComponent } from 'react';
import { ArrowUp, ArrowDown, Subtract } from '@carbon/icons-react';
import { formatNumber } from '../../utils/reportUtils';
import '../../styles/components/indicator.scss';

type IndicatorSize = 'sm' | 'lg';
interface IndicatorProps {
  value: number;
  index: IndexType;
  ppt?: boolean;
  size?: IndicatorSize;
  format?: NumberFormat;
  showArrow?: boolean;
  currency?: string;
}

const getIcon = (index: IndexType, size: IndicatorSize): JSX.Element => {
  switch (index) {
    case 'positive':
      return (
        <ArrowUp size={size === 'sm' ? 14 : 18} data-testid="icon-positive" />
      );
    case 'negative':
      return (
        <ArrowDown size={size === 'sm' ? 14 : 18} data-testid="icon-negative" />
      );
    case 'neutral':
      return (
        <Subtract size={size === 'sm' ? 14 : 18} data-testid="icon-neutral" />
      );
  }
};

const Indicator: FunctionComponent<IndicatorProps> = ({
  value,
  index,
  ppt = false,
  size = 'sm',
  format = 'percent',
  showArrow = true,
  currency,
}) => (
  <div className={`performance-indicator ${index} ${size}`}>
    <div className="perforemance-indicator--value">
      {formatNumber({ value, format, currency })}
      {ppt && 'pt'}
    </div>
    {showArrow && getIcon(index, size)}
  </div>
);

export default Indicator;
