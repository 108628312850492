import type { FunctionComponent, ReactNode } from 'react';
import '../../styles/components/cards.scss';
import CardHeader from './CardHeader';

export interface BaseCardProps {
  title?: string;
  value?: number;
  pct?: number;
  index?: IndexType;
  format?: NumberFormat;
  colSpan?: number;
  currency?: string;
  children?: JSX.Element | JSX.Element[];
  icon?: ReactNode;
}

const BaseCard: FunctionComponent<BaseCardProps> = ({
  index,
  title,
  children,
  icon,
}) => {
  const indexColor = index ? index : 'neutral';
  return (
    <div className={`card card--${indexColor}`} data-testid="card">
      <CardHeader title={title} icon={icon} />
      {children}
    </div>
  );
};

export default BaseCard;
