import type { FunctionComponent } from 'react';
import BaseCard from './BaseCard';
import { formatNumber } from '../../utils/reportUtils';
import Indicator from './Indicator';
import Tooltip from '../Tooltip';

const ReportCard: FunctionComponent<Card> = ({ title, index, data, child }) => {
  return (
    <BaseCard title={title} index={index}>
      <div className="card--home-info">
        <div className="card--home-info__value" title={formatNumber(data)}>
          {formatNumber(data)}
        </div>
        {child && (
          <Tooltip label={child.title} useButtonWrapper>
            <Indicator
              value={child.data.value}
              format={child.data.format}
              currency={child.data.currency}
              index={child.index}
              showArrow={false}
            />
          </Tooltip>
        )}
      </div>
    </BaseCard>
  );
};

export default ReportCard;
