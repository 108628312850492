import type { FunctionComponent } from 'react';
import { useContext } from 'react';
import { ChartContext } from '../../providers/ChartProvider';
import LegendItem from './LegendItem';
import Rectangle from './Rectangle';
import { formatNumber } from '../../utils/reportUtils';
import { HIGHCHARTS_PALETTE } from '../../constants/palettes';
import VENN_SECTIONS from '../../constants/vennSections';

interface VennProps {
  vennData: number[];
  vennSections: number[];
  vennLegend: string[];
}

const Venn: FunctionComponent<VennProps> = ({
  vennData,
  vennSections,
  vennLegend,
}) => {
  const { measure } = useContext(ChartContext);

  return (
    <div className="venn-container" data-testid="venn-chart">
      <div className="venn-chart">
        <Rectangle
          rowStart={2}
          rowEnd={9}
          columnStart={1}
          columnEnd={5}
          colour={HIGHCHARTS_PALETTE[0]}
        />
        <Rectangle
          rowStart={1}
          rowEnd={5}
          columnStart={2}
          columnEnd={9}
          colour={HIGHCHARTS_PALETTE[1]}
        />
        {vennSections.length > 3 && (
          <Rectangle
            rowStart={3}
            rowEnd={10}
            columnStart={4}
            columnEnd={8}
            colour={HIGHCHARTS_PALETTE[2]}
          />
        )}
        {vennSections.length > 7 && (
          <Rectangle
            rowStart={4}
            rowEnd={8}
            columnStart={3}
            columnEnd={10}
            colour={HIGHCHARTS_PALETTE[10]}
          />
        )}
        {vennSections.map((section, index) => {
          return (
            <div
              key={section}
              className="venn-section"
              style={{
                ...VENN_SECTIONS[section],
              }}
            >
              {vennData &&
                measure &&
                formatNumber(
                  {
                    value: vennData[index],
                    format: measure.format,
                    currency: measure.currency,
                  },
                  { notation: 'compact' }
                )}
            </div>
          );
        })}
      </div>
      <div className="venn-legend">
        {vennLegend.map((item, index) => {
          return (
            <LegendItem
              key={item}
              text={item}
              colour={
                index < 3 ? HIGHCHARTS_PALETTE[index] : HIGHCHARTS_PALETTE[10]
              }
            />
          );
        })}
      </div>
    </div>
  );
};

export default Venn;
