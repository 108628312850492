import type { FunctionComponent } from 'react';
import { InlineNotification } from '@carbon/react';
import { Information } from '@carbon/icons-react';
import Popover from '../Popover';

interface LabelWithTooltipProps {
  title: string;
  subtitle: string;
}

const LabelWithTooltip: FunctionComponent<LabelWithTooltipProps> = ({
  title,
  subtitle,
}) => {
  return (
    <div className="flex gap--small">
      {title}
      <Popover
        align="bottom-left"
        caret={false}
        stopPropagation
        target={
          <span className="LabelWithTooltip__target">
            <Information />
          </span>
        }
      >
        <InlineNotification
          title={title}
          subtitle={subtitle}
          lowContrast
          kind="info"
          hideCloseButton
          className="LabelWithTooltip__container"
        />
      </Popover>
    </div>
  );
};

export default LabelWithTooltip;
