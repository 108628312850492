import type { FunctionComponent } from 'react';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import {
  Dropdown,
  ProgressIndicator,
  ProgressStep,
  Button,
  InlineLoading,
} from '@carbon/react';
import { ChevronRight, ChevronLeft } from '@carbon/icons-react';
import apiRequest from '../../../../api';
import { ModalContext } from '../../../../providers/ModalProvider';
import { AppContext } from '../../../../providers/AppProvider';
import { CustomGroupsContext } from '../../../../providers/CustomGroupsProvider';
import CustomGroupsUploadValidator from './CustomGroupsUploadValidator';
import Fetch from '../../../Fetch';
import { CACHE_KEY } from '../../../../constants/api';
import {
  custGroupsCreateFailure,
  custGroupsCreateSuccess,
} from '../../../../constants/posthog';
import usePosthog from '../../../../utils/posthog';
import { validateUploadData } from '../../../../utils/reportUtils';
import type {
  CustGroup,
  UploadData,
} from '../../../../reducers/CustomGroupsReducer';
import CustomGroupsUploadTable from './CustomGroupsUploadTable';
import CustomGroupsUploadSuccess from './CustomGroupsUploadSuccess';
import CustomGroupsUploadTransform from './CustomGroupsUploadTransform';

export interface EditCustomGroup {
  id: string | null;
  dim: string | null;
  name: string | null;
  isEditing: boolean;
}

const CustomGroupsUpload: FunctionComponent = () => {
  const {
    uploadStep,
    uploadData,
    customGroups,
    fileSpecs,
    hasEntityErrors,
    selectedDimGroup,
    filterByCustGroup,
    existingCustGroups,
    updateUploadStep,
    updateUploadData,
    updateHasEntityErrors,
    updateFileSpecs,
    updateExistingCustGroups,
    updateSelectedDimGroup,
  } = useContext(CustomGroupsContext);

  const navigate = useNavigate();
  const posthogEvent = usePosthog();
  const { updateModal, toggleModal } = useContext(ModalContext);
  const { getAccessTokenSilently } = useAuth0();
  const { bannerId, groupId, dimensions, updateDimensionsData } =
    useContext(AppContext);
  const [loadingCreate, setLoadingCreate] = useState<boolean>(false);
  const [createGroupLabel, setCreateGroupLabel] = useState('');
  const [createGroupError, setCreateGroupError] = useState(false);
  const [searchParams] = useSearchParams();

  const editCustomGroup = useMemo(() => {
    return {
      id: searchParams.get('id'),
      dim: searchParams.get('dimension'),
      name: searchParams.get('name'),
      isEditing: searchParams.get('mode') === 'edit',
    };
  }, [searchParams]);

  useEffect(() => {
    const hasEmptyCustGroupChecked = customGroups.some(
      (group) => group.customGroup === '' && group.isChecked
    );
    if (hasEmptyCustGroupChecked) {
      updateHasEntityErrors(hasEmptyCustGroupChecked);
    }
    let createGroupLabel;
    const checkedGroupCount = customGroups.filter((gr) => gr.isChecked).length;
    if (editCustomGroup.isEditing) {
      createGroupLabel = 'Update Group';
    } else if (checkedGroupCount <= 1) {
      createGroupLabel = 'Create Group';
    } else {
      createGroupLabel = 'Create Groups';
    }
    setCreateGroupLabel(createGroupLabel);
  }, [customGroups]);

  const renderStepsContent = () => {
    switch (uploadStep) {
      case 0:
        return (
          <CustomGroupsUploadValidator editCustomGroup={editCustomGroup} />
        );
      case 1:
        return <CustomGroupsUploadTable editCustomGroup={editCustomGroup} />;
      case 2:
        return (
          <CustomGroupsUploadTransform editCustomGroup={editCustomGroup} />
        );
      case 3:
        return !createGroupError && <CustomGroupsUploadSuccess />;
    }
  };

  useEffect(() => {
    if (dimensions.length > 0) {
      updateSelectedDimData(dimensions);
    }
  }, []);

  useEffect(() => {
    uploadStep === 2 && !loadingCreate && updateUploadStep(uploadStep + 1);
  }, [loadingCreate]);

  useEffect(() => {
    if (
      uploadData?.length === 0 ||
      fileSpecs?.length === 0 ||
      fileSpecs[0]?.invalid ||
      fileSpecs[0]?.status !== 'edit'
    ) {
      updateHasEntityErrors(true);
    } else {
      updateHasEntityErrors(false);
    }
  }, [fileSpecs]);

  const createCustomGroups = () => {
    const commonNames = [
      ...new Set(
        customGroups
          .filter((gr) => gr.isChecked)
          .map((gr) => gr.customGroup)
          .filter((name) =>
            existingCustGroups
              .filter((gr) => gr.dimension === selectedDimGroup.toLowerCase())
              .map((gr) => gr.name)
              .includes(name)
          )
      ),
    ];
    if (!editCustomGroup.isEditing && commonNames.length > 0) {
      return updateModal({
        type: 'warning',
        title: 'Existing custom group',
        body: (
          <div>
            <p>
              A custom group with this name already exists, please add a
              different name.
            </p>
            <ul>
              {commonNames.map((name) => (
                <li className="body-emphasis-02" key={name}>
                  {name}
                </li>
              ))}
            </ul>
          </div>
        ),
        primaryCTAText: 'OK',
      });
    } else {
      createGroups();
    }
  };

  const checkedCustomGroupsEntities = (
    customGroups: CustGroup[],
    uploadData: UploadData[]
  ) => {
    const checkedCustomGroups = customGroups.filter((group) => group.isChecked);
    const customGroupSet = new Set(
      checkedCustomGroups.map((group) => group.customGroup)
    );

    return uploadData
      .filter(({ customGroup }) => customGroupSet.has(customGroup))
      .map(({ entity_no }) => ({ entity_no }));
  };

  const entityIds = checkedCustomGroupsEntities(customGroups, uploadData);

  const createGroups = async () => {
    setLoadingCreate(true);
    try {
      const token = await getAccessTokenSilently();
      const response = await validateUploadData(
        selectedDimGroup.toLowerCase(),
        entityIds,
        token,
        bannerId,
        groupId
      );

      const payload = {
        dimension: selectedDimGroup.toLocaleLowerCase(),
        hierarchies: customGroups
          ?.filter((group) => group.isChecked)
          .map((group) => ({
            group_name: group.customGroup,
            entities: uploadData
              ?.filter((data) => data.customGroup === group.customGroup)
              .map(({ entity_id, entity_no, is_exists, label, level }) => ({
                id: entity_id,
                entity_no,
                is_exists,
                label,
                level,
              })),
          })),
        hash: response.data.hash,
      };
      const createOrUpdateCustomGroupsUrl = `/configs/${bannerId}/user-groups/${groupId}/hierarchies`;
      await apiRequest(
        editCustomGroup.id
          ? `${createOrUpdateCustomGroupsUrl}/${editCustomGroup.id}`
          : createOrUpdateCustomGroupsUrl,
        editCustomGroup.id ? 'PATCH' : 'PUT',
        token,
        payload
      );

      posthogEvent(custGroupsCreateSuccess);
      updateExistingCustGroups([]);
    } catch {
      setCreateGroupError(true);
      posthogEvent(custGroupsCreateFailure);
      updateModal({
        type: 'error',
        title: 'Something went wrong',
        body: 'There was an error creating custom groups. Please try refreshing your browser and try again. If the issue persists, please contact the helpdesk.',
        primaryCTAText: 'Go back',
        onPrimaryCTAClick: () => {
          navigate('/custom-groups');
          toggleModal(false);
        },
      });
    } finally {
      setLoadingCreate(false);
    }
  };

  const updateSelectedDimData = (data) => {
    const firstDimWithCustomHierarchy = data.find(
      (dim: Dimension) => dim.has_custom_hierarchy
    );

    if (firstDimWithCustomHierarchy && !editCustomGroup.id) {
      updateSelectedDimGroup(
        firstDimWithCustomHierarchy.dimension.replace(/^\w/, (c) =>
          c.toUpperCase()
        )
      );
    }
  };
  const stepBtnLabel = filterByCustGroup ? 'Back' : 'Next';
  return (
    <>
      <div className="CustomGroups__go-back-wrapper">
        <Button
          kind="ghost"
          data-testid="custom-group-go-back-btn"
          iconDescription="Go back"
          renderIcon={ChevronLeft}
          hasIconOnly
          size="sm"
          onClick={() => navigate('/custom-groups')}
        />
        <h3 className="CustomGroups__go-back-header">
          {editCustomGroup.isEditing
            ? 'Add New Items to Group'
            : 'Upload Group'}
        </h3>
      </div>
      <div className="CustomGroupsUpload">
        <div className="CustomGroupsUpload__progress-wrapper">
          <ProgressIndicator
            spaceEqually
            style={{ maxWidth: '55vw' }}
            currentIndex={uploadStep}
          >
            <ProgressStep label="Step 1: Upload File" />
            <ProgressStep label="Step 2: Check Your Data" />
            <ProgressStep
              label={`Step 3: Name the ${selectedDimGroup} Group`}
            />
          </ProgressIndicator>
        </div>
        {!editCustomGroup.id && (
          <div className="CustomGroupsUpload__dropdown-wrapper">
            <Fetch
              alwaysFetchOnMount={
                dimensions?.length === 0 && !editCustomGroup.isEditing
              }
              key="dimensions"
              apiUrl={`/configs/${bannerId}/user-groups/${groupId}/hierarchies`}
              initialData={[]}
              loadingMessage="Loading dimensions data..."
              cacheKey={CACHE_KEY.DIMENSIONS}
              onReceiveData={(data) => {
                if (data) {
                  updateDimensionsData(data);
                  updateSelectedDimData(data);
                }
              }}
            >
              <>
                {uploadStep === 0 && dimensions.length > 0 && (
                  <Dropdown
                    className="CustomGroupsUpload__dropdown"
                    id="upload-groups-dropdown"
                    label="Product Group"
                    titleText="Select Custom Group Type"
                    items={dimensions
                      ?.filter((dim) => dim.has_custom_hierarchy)
                      .map(
                        (dim) =>
                          `${dim.dimension.replace(/^\w/, (c) =>
                            c.toUpperCase()
                          )} Group`
                      )}
                    selectedItem={`${selectedDimGroup} Group`}
                    onChange={(selection) => {
                      updateSelectedDimGroup(
                        selection.selectedItem.split(' ')[0]
                      );
                      updateFileSpecs([]);
                      updateUploadData([]);
                      updateHasEntityErrors(true);
                    }}
                  />
                )}
              </>
            </Fetch>
          </div>
        )}
        {renderStepsContent()}
        <div className="CustomGroups__footer">
          {uploadStep === 2 && customGroups.length > 0 && (
            <Button
              className="CustomGroups__footer-back-btn"
              kind="secondary"
              onClick={() => updateUploadStep(uploadStep - 1)}
              data-testid="upload-back-btn"
              size="md"
            >
              Back
            </Button>
          )}
          {uploadStep < 3 && (
            <Button
              type={uploadStep === 2 ? 'submit' : 'button'}
              kind="primary"
              renderIcon={
                loadingCreate ? InlineLoading : uploadStep !== 2 && ChevronRight
              }
              className={`CustomGroups__footer-next-btn ${
                loadingCreate ? 'has-icon' : ''
              }`}
              onClick={(e) => {
                e.currentTarget.blur();
                uploadStep !== 2 && updateUploadStep(uploadStep + 1);
                uploadStep === 2 && createCustomGroups();
              }}
              data-testid="upload-next-btn"
              size="md"
              disabled={hasEntityErrors || loadingCreate}
            >
              {uploadStep === 2 ? `${createGroupLabel}` : stepBtnLabel}
            </Button>
          )}
        </div>
      </div>
    </>
  );
};

export default CustomGroupsUpload;
