import type { FunctionComponent } from 'react';
import { useEffect, useState } from 'react';
import { useDebounce } from '../../../hooks';
import { TextArea } from '@carbon/react';

interface Props {
  id: string;
  value: string;
  disabled?: boolean;
  onUpdate: (value: string) => void;
}

const CellNotes: FunctionComponent<Props> = ({
  id,
  value,
  disabled,
  onUpdate,
}) => {
  const [text, setText] = useState(value);
  const debouncedSearchTerm: string = useDebounce<string>(text, 200);

  useEffect(() => {
    onUpdate(debouncedSearchTerm);
  }, [debouncedSearchTerm]);

  return (
    <TextArea
      id={id}
      labelText="Action notes"
      hideLabel
      placeholder="Type to add notes"
      disabled={disabled}
      style={{
        height: '48px',
        borderBottom: 'none',
        width: '100%',
        minWidth: 0,
      }}
      value={text}
      onChange={({ target }) => {
        setText(target.value);
      }}
    />
  );
};

export default CellNotes;
