import type { FunctionComponent, ReactNode } from 'react';
import { createContext, useMemo, useReducer } from 'react';
import type { HomeState, TimePeriod } from '../reducers/HomeReducer';
import homeReducer, { initialHomeState } from '../reducers/HomeReducer';

export const HomeContext = createContext<HomeContextType | null>(null);

interface HomeProviderProps {
  children?: ReactNode;
}

export interface HomeContextType extends HomeState {
  selectedCategoryKey?: string;
  timePeriod?: TimePeriod[];
  updateSelectedCategory: (selectedCategory: HierNode | null) => void;
  updateCategories: (categories: HierNode[]) => void;
  updatePeriodEndData: (periodEnd: string) => void;
  resetAllHomeData: () => void;
  updateReportConfigMap: (reportConfigMap: Record<string, string[]>) => void;
  updateProductKeys: (productKeys: Record<string, string>) => void;
  updateHomepageDropdowns: (dropdowns: DropdownConfig[]) => void;
  updateHomepageDropdownSelections: (dropdownKey: string) => void;
  updateTimePeriods: (dropdownKey: string, timePeriod: TimePeriod[]) => void;
}

const HomeProvider: FunctionComponent<HomeProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(homeReducer, initialHomeState);

  const selectedCategoryKey = useMemo(() => {
    return state.productKeys[state.selectedCategory?.id];
  }, [state.productKeys, state.selectedCategory]);

  const timePeriod = useMemo(() => {
    return state.timePeriods?.[state.homeDropdownSelections];
  }, [state.timePeriods, state.homeDropdownSelections]);

  const updateSelectedCategory = (selectedCategory: HierNode | null) =>
    dispatch({ type: 'SET_SELECTED_CATEGORY', selectedCategory });

  const updateCategories = (categories: HierNode[]) =>
    dispatch({ type: 'UPDATE_CATEGORIES', categories });

  const updatePeriodEndData = (periodEnd: string) =>
    dispatch({ type: 'UPDATE_PERIOD_END_DATA', periodEnd });

  const updateReportConfigMap = (reportConfigMap: {
    [key: string]: string[];
  }) => dispatch({ type: 'UPDATE_REPORT_CONFIG_MAP', reportConfigMap });

  const updateProductKeys = (productKeys: Record<string, string>) =>
    dispatch({ type: 'UPDATE_PRODUCT_KEYS', productKeys });

  const updateHomepageDropdowns = (dropdowns: DropdownConfig[]) => {
    dispatch({ type: 'UPDATE_HOMEPAGE_DROPDOWNS', dropdowns });
  };

  const updateHomepageDropdownSelections = (dropdownKey: string) =>
    dispatch({
      type: 'UPDATE_DROPDOWN_SELECTIONS',
      dropdownKey,
    });

  const updateTimePeriods = (dropdownKey: string, timePeriod: TimePeriod[]) =>
    dispatch({
      type: 'UPDATE_TIME_PERIODS',
      dropdownKey,
      timePeriod,
    });

  const resetAllHomeData = () => dispatch({ type: 'RESET_ALL_HOME_DATA' });

  const homeContext: HomeContextType = useMemo(
    () => ({
      selectedCategory: state.selectedCategory,
      categories: state.categories,
      periodEnd: state.periodEnd,
      reportConfigMap: state.reportConfigMap,
      productKeys: state.productKeys,
      selectedCategoryKey,
      dropdowns: state.dropdowns,
      homeDropdownSelections: state.homeDropdownSelections,
      timePeriods: state.timePeriods,
      timePeriod,
      updateCategories,
      updatePeriodEndData,
      updateSelectedCategory,
      resetAllHomeData,
      updateReportConfigMap,
      updateProductKeys,
      updateHomepageDropdowns,
      updateHomepageDropdownSelections,
      updateTimePeriods,
    }),
    [state]
  );

  return (
    <HomeContext.Provider value={homeContext}>{children}</HomeContext.Provider>
  );
};

export default HomeProvider;
