import { Column, Grid } from '@carbon/react';
import type { FunctionComponent } from 'react';
import { useContext, useEffect } from 'react';
import { AppContext } from '../../providers/AppProvider';
import { ReportContext } from '../../providers/ReportProvider';
import HomeReportData from './HomeReportData';
import Fetch from '../Fetch';
import { CACHE_KEY } from '../../constants/api';
import HomeDropdowns from './HomeDropdowns';
import { HomeContext } from '../../providers/HomeProvider';
import { HOMEPAGE_REPORT_ID } from '../../constants/values';
import HomepageLoadingSkeleton from '../Loading/HomepageLoadingSkeleton';
import HomeDropdownsSkeleton from '../Loading/HomeDropdownsSkeleton';
import '../../styles/components/viewReport.scss';

const HomeData: FunctionComponent = () => {
  const {
    bannerId,
    groupId,
    queryCache,
    user: { user_type },
  } = useContext(AppContext);
  const { reportConfig, resetAllReportData, updateReportConfig } =
    useContext(ReportContext);
  const { updatePeriodEndData } = useContext(HomeContext);

  useEffect(() => {
    resetAllReportData();
  }, [bannerId, groupId]);

  return (
    <Fetch
      apiUrls={[
        `/reports/${bannerId}/user-groups/${groupId}/data?run_id=${HOMEPAGE_REPORT_ID}_${bannerId}`,
        `/metadata/${bannerId}/user-types/${user_type}/date-range`,
      ]}
      multiRequest
      cacheKey={CACHE_KEY.HOMEPAGE}
      initialData={null}
      loadingComponent={
        <div className="flex direction-column gap--large">
          <HomeDropdownsSkeleton />
          <HomepageLoadingSkeleton />
        </div>
      }
      hideChildrenUntilFetched
      alwaysFetchOnMount={!reportConfig.end_time}
      onReceiveData={(data) => {
        if (data) {
          updateReportConfig({ ...data[0].data[0] });
          updatePeriodEndData(data[1].data.end_date);
        }
      }}
    >
      {queryCache[CACHE_KEY.HOMEPAGE] === bannerId && (
        <>
          <Column>
            <Grid className="HomeData__filters">
              <Column
                lg={16}
                md={8}
                sm={4}
                className="HomeData__filters-column"
              >
                <HomeDropdowns />
              </Column>
            </Grid>
          </Column>
          <HomeReportData />
        </>
      )}
    </Fetch>
  );
};

export default HomeData;
