import type { FunctionComponent } from 'react';
import { Report } from '@carbon/icons-react';
import '../../styles/components/clusterStrategy.scss';
import { OptimiserStrategy } from '../../reducers/OptimiserFormReducer';
interface ClusterStrategyProps {
  strategy: OptimiserStrategy;
  targetSkuGoal?: number;
  totalSkuCount?: number;
}
const ClusterStrategy: FunctionComponent<ClusterStrategyProps> = ({
  strategy,
  targetSkuGoal,
  totalSkuCount,
}) => {
  const strategyText =
    strategy === OptimiserStrategy.Breadth ? 'Breadth' : 'Depth';

  return (
    <div className="ClusterStrategy">
      <Report />
      {typeof targetSkuGoal === 'number' && (
        <div>
          Cluster Target Goal: <strong>{targetSkuGoal} SKUs</strong>
        </div>
      )}
      {typeof totalSkuCount === 'number' && (
        <div>
          Cluster Total: <strong>{totalSkuCount} SKUs</strong>
        </div>
      )}
      <div>
        Ranging strategy: <strong>{strategyText}</strong>
      </div>
    </div>
  );
};

export default ClusterStrategy;
