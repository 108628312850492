export const slate = {
  50: '#f4f3f6',
  100: '#e5e5eb',
  200: '#cfceda',
  300: '#b5b4c6',
  400: '#9b9ab2',
  500: '#5c6784',
  600: '#656382',
  700: '#4d4b62',
  800: '#343343',
  900: '#191820',
  950: '#08090c',
} as const;
