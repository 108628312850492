import type { FunctionComponent } from 'react';
import { useContext, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  InlineNotification,
  Dropdown,
  TextInput,
  NumberInput,
} from '@carbon/react';
import { AppContext } from '../../providers/AppProvider';
import AddParameterButton from './AddParameterButton';
import ParameterCollection from './ParameterCollection';
import ParameterTitle from './ParameterTitle';
import ReportValidation from './ReportValidation';
import {
  getDateGroups,
  getParameter,
  getSplitParameters,
  getTransactionSpendGroups,
  isDateUnique,
} from '../../utils';
import { getReportModuleAndSection } from '../../utils/reportUtils';
import { ModalContext } from '../../providers/ModalProvider';
import {
  getTrimmedValue,
  renderCustomGroupNotification,
} from './utils/reportBuilderUtils';
import usePosthog from '../../utils/posthog';
import {
  reportBuilderAddGroup,
  reportBuilderAddSplitGroup,
  reportBuilderAddSplitTransactionSpendGroup,
  reportBuilderAddTDateRange,
  reportBuilderAddTransactionSpendGroup,
  reportBuilderSplitAddDateRange,
} from '../../constants/posthog';
import type { HierarchyOverlap } from './ReportBuilder';
import { ReportAttribute } from '../../constants/metadata';

interface SelectionControlsProps {
  selectedDimension: Dimension;
  selectedHierarchy: Hierarchy;
  selectedDate?: SelectedDate[];
  selection: { [key: string]: HierNode[] };
  overlap: HierarchyOverlap;
  updateParameters: (
    parameterKey: string,
    selectedParameters: ReportParameter[]
  ) => void;
  editParameter: (
    parameterKey: string,
    parameter: ReportParameter,
    index: number
  ) => void;
  removeParameter: (parameterKey: string, parameter: ReportParameter) => void;
  clearParameters: (parameterKey: string, selectionType: ParameterType) => void;
  spendBands: SpendBands;
  setSpendBands: (spendBands: SpendBands) => void;
  includeUpperBand: boolean;
  setIncludeUpperBand: (includeUpperBand: boolean) => void;
  groupedHierarchyRules: GroupedHierarchyRules;
  minRequirements: RuleSet;
  containsHiddenCustomGroups?: boolean;
}

const SelectionControls: FunctionComponent<SelectionControlsProps> = ({
  clearParameters,
  editParameter,
  removeParameter,
  selectedDimension,
  selectedHierarchy,
  selectedDate,
  selection,
  updateParameters,
  overlap,
  spendBands,
  includeUpperBand,
  groupedHierarchyRules,
  minRequirements,
  containsHiddenCustomGroups,
}) => {
  const { bannerId, hierarchies, reportModules, reportParameters } =
    useContext(AppContext);
  const posthogEvent = usePosthog();
  const { moduleId, section } = useParams();
  const [timeIncrement, setTimeIncrement] = useState<TimeIncrement>({
    timeSplit: 'weeks',
    duration: 1,
  });
  const [spendIncrement, setSpendIncrement] = useState<number>(0);
  const { updateModal } = useContext(ModalContext);

  const isFilterVisible = useMemo(() => {
    const report = getReportModuleAndSection(reportModules, section, moduleId);

    return !report.hidden_attributes?.includes(ReportAttribute.Filter);
  }, [reportModules, section, moduleId]);

  const isUniverseVisible = useMemo(() => {
    const report = getReportModuleAndSection(reportModules, section, moduleId);

    return (
      report.universe_dimensions?.includes(selectedHierarchy.key) &&
      !report.hidden_attributes?.includes(ReportAttribute.Universe)
    );
  }, [reportModules, section, moduleId, selectedHierarchy.key]);

  const addDateRange = (parameterType: 'groups' | 'filters') => {
    const parameterKey = `${selectedHierarchy.key}_${parameterType}`;
    const date = getDateGroups(
      selectedDate,
      bannerId,
      selectedHierarchy.name
    ) as ReportParameter;
    if (
      isDateUnique(date, reportParameters[parameterKey] as ReportParameter[])
    ) {
      posthogEvent(reportBuilderAddTDateRange, {
        dimension: selectedDimension.dimension,
        origin: 'date-range',
        groupName: date.name,
      });
      updateParameters(parameterKey, [date]);
    } else {
      updateModal({
        type: 'warning',
        title: 'Duplicate date selected',
        body: 'You have picked the same selection twice. Please check and amend if needed',
      });
    }
  };

  const addTransactionSpend = (parameterType: 'groups' | 'filters') => {
    const parameterKey = `${selectedDimension.dimension}_${parameterType}`;
    const newParams = getTransactionSpendGroups(
      spendBands,
      selectedHierarchy,
      bannerId,
      includeUpperBand
    );
    posthogEvent(reportBuilderAddTransactionSpendGroup, {
      type: parameterType,
      groupName: newParams.map((param) => param.name),
    });
    updateParameters(parameterKey, newParams);
  };

  const addSplitDateRange = () => {
    const parameterKey = `${selectedHierarchy.key}_groups`;
    const dateGroups = getDateGroups(
      selectedDate,
      bannerId,
      selectedHierarchy.name,
      timeIncrement
    ) as ReportParameter[];
    const hasDuplicates =
      dateGroups.filter(
        (d) =>
          !isDateUnique(d, reportParameters[parameterKey] as ReportParameter[])
      ).length > 0;
    if (!hasDuplicates) {
      posthogEvent(reportBuilderSplitAddDateRange, {
        origin: 'date-range',
        timeSplit: timeIncrement.timeSplit,
        duration: timeIncrement.duration,
        groupName: dateGroups.map((group) => group.name),
      });
      updateParameters(parameterKey, dateGroups);
    } else {
      updateModal({
        type: 'warning',
        title: 'Duplicate date selected',
        body: 'You have picked the same selection twice. Please check and amend if needed',
      });
    }
  };

  const addSplitTransactionSpend = () => {
    const parameterKey = `${selectedDimension.dimension}_groups`;
    const newParams = getTransactionSpendGroups(
      spendBands,
      selectedHierarchy,
      bannerId,
      includeUpperBand,
      spendIncrement
    );
    posthogEvent(reportBuilderAddSplitTransactionSpendGroup, {
      origin: 'transaction-spend',
      groupName: newParams.map((param) => param.name),
    });
    updateParameters(parameterKey, newParams);
  };

  const addParameter = (parameterType: 'groups' | 'filters' | 'universes') => {
    const parameterKey = `${selectedDimension.dimension}_${parameterType}`;
    const parameter = getParameter(
      selectedHierarchy,
      selection,
      hierarchies,
      bannerId
    );
    posthogEvent(reportBuilderAddGroup, {
      dimension: selectedDimension.dimension,
      type: parameterType,
      groupName: parameter.name,
    });
    if (parameter.items.length > 0 || parameter.name.length !== 0) {
      updateParameters(parameterKey, [parameter]);
    }
  };

  const addSplitParameters = () => {
    const parameterKey = `${selectedDimension.dimension}_groups`;
    const parameters = getSplitParameters(
      selectedHierarchy,
      selection,
      hierarchies,
      bannerId
    );
    posthogEvent(reportBuilderAddSplitGroup, {
      dimension: selectedDimension.dimension,
      groupName: parameters.map((param) => param.name),
    });
    updateParameters(parameterKey, parameters);
  };

  const getSelectionGroupTypes = (selectedHierarchyId: string) => {
    switch (selectedHierarchyId) {
      case 'date-range':
        return (
          <div className="selection-actions">
            <AddParameterButton
              label="Add group"
              tooltip="Add selected items as a single group to the report"
              addParameter={() => addDateRange('groups')}
            />
            {selectedDimension && (
              <div
                style={{
                  display: 'grid',
                  gridTemplateColumns: '1fr 0.5fr 0.5fr',
                  alignItems: 'end',
                }}
              >
                <AddParameterButton
                  label="Add custom split"
                  tooltip="Add selection split by custom parameters"
                  addParameter={() => addSplitDateRange()}
                />
                <TextInput
                  id="date-split-number"
                  type="number"
                  hideLabel
                  min="1"
                  labelText="Split by"
                  value={timeIncrement.duration}
                  onChange={(e) => {
                    setTimeIncrement({
                      ...timeIncrement,
                      duration: e.target.value,
                    });
                  }}
                />
                <Dropdown
                  id="date-split-duration"
                  label="Split by"
                  items={['days', 'weeks', 'months']}
                  selectedItem={timeIncrement.timeSplit}
                  onChange={({ selectedItem }) => {
                    setTimeIncrement({
                      ...timeIncrement,
                      timeSplit: selectedItem,
                    });
                  }}
                />
              </div>
            )}
          </div>
        );
      case 'transaction-spend':
        return (
          <div className="selection-actions">
            <AddParameterButton
              label="Add group"
              tooltip="Add selected items as a single group to the report"
              addParameter={() => addTransactionSpend('groups')}
            />
            {selectedDimension && includeUpperBand && (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <div>
                  <AddParameterButton
                    label="Add custom split"
                    tooltip="Add selection split by custom parameters"
                    addParameter={() =>
                      spendIncrement > 0 && addSplitTransactionSpend()
                    }
                  />
                </div>
                <div>
                  <NumberInput
                    max={1000000000}
                    min={0}
                    id="date-split-number"
                    invalidText="Custom split value must be between 0 and 1,000,000,000"
                    invalid={spendIncrement < 0}
                    hideLabel
                    labelText="Split by"
                    value={getTrimmedValue(spendIncrement)}
                    onChange={(_, { value }: { value: number | string }) =>
                      setSpendIncrement(Number(value) || 0)
                    }
                  />
                </div>
              </div>
            )}
          </div>
        );
      default:
        return (
          <div className="selection-actions">
            <AddParameterButton
              label="Add group"
              tooltip="Add selected items as a single group to the report"
              addParameter={() => addParameter('groups')}
            />
            {selectedDimension && (
              <AddParameterButton
                label="Add split group"
                tooltip="Add selected items as multiple separate groups"
                addParameter={() => addSplitParameters()}
              />
            )}
          </div>
        );
    }
  };

  return (
    <>
      <div className="selection-controls">
        <ReportValidation minRequirements={minRequirements} />
        {(overlap?.[selectedHierarchy.key]?.hasOverlap ||
          overlap?.[`${selectedHierarchy.key}_filters`]?.hasFiltersOverlap) && (
          <InlineNotification
            subtitle={
              overlap[selectedHierarchy.key].reason ||
              overlap[`${selectedHierarchy.key}_filters`].reason
            }
            kind="info"
            hideCloseButton
            lowContrast
          />
        )}
        {renderCustomGroupNotification(containsHiddenCustomGroups)}
        <ParameterTitle
          title="Groups"
          onClearParams={() =>
            clearParameters(`${selectedDimension.dimension}`, 'groups')
          }
          parameterKey={`${selectedHierarchy.key}_groups`}
        />

        <div>
          {groupedHierarchyRules[selectedDimension?.dimension]?.map(
            (hierarchyKey) => {
              return (
                <ParameterCollection
                  key={hierarchyKey}
                  editParameter={editParameter}
                  parameterKey={`${hierarchyKey}_groups`}
                  parameters={
                    reportParameters[
                      `${hierarchyKey}_groups`
                    ] as ReportParameter[]
                  }
                  onDelete={(parameter) =>
                    removeParameter(`${hierarchyKey}_groups`, parameter)
                  }
                />
              );
            }
          )}
        </div>
        {getSelectionGroupTypes(selectedHierarchy.id)}
        {isFilterVisible && (
          <>
            <ParameterTitle
              title="Filters"
              onClearParams={() =>
                clearParameters(`${selectedDimension.dimension}`, 'filters')
              }
              parameterKey={`${selectedHierarchy.key}_filters`}
            />
            <div>
              {groupedHierarchyRules[selectedDimension?.dimension]?.map(
                (hierarchyKey) => {
                  return (
                    <ParameterCollection
                      key={hierarchyKey}
                      editParameter={editParameter}
                      parameterKey={`${hierarchyKey}_filters`}
                      isFilters
                      parameters={
                        reportParameters[
                          `${hierarchyKey}_filters`
                        ] as ReportParameter[]
                      }
                      onDelete={(parameter) =>
                        removeParameter(`${hierarchyKey}_filters`, parameter)
                      }
                    />
                  );
                }
              )}
            </div>
            <div className="selection-actions">
              <AddParameterButton
                label="Add filter"
                tooltip="Add selected items as a filter for the report"
                addParameter={() => {
                  if (selectedHierarchy.id === 'transaction-spend') {
                    addTransactionSpend('filters');
                  } else if (selectedHierarchy.id === 'date-range') {
                    addDateRange('filters');
                  } else {
                    addParameter('filters');
                  }
                }}
              />
            </div>
          </>
        )}
        {isUniverseVisible && (
          <div>
            <ParameterTitle
              title="Universes"
              onClearParams={() =>
                clearParameters(`${selectedDimension.dimension}`, 'universes')
              }
              parameterKey={`${selectedHierarchy.key}_universes`}
            />
            <div>
              {groupedHierarchyRules[selectedDimension?.dimension]?.map(
                (hierarchyKey) => {
                  return (
                    <ParameterCollection
                      key={hierarchyKey}
                      editParameter={editParameter}
                      parameterKey={`${hierarchyKey}_universes`}
                      parameters={
                        reportParameters[
                          `${hierarchyKey}_universes`
                        ] as ReportParameter[]
                      }
                      onDelete={(parameter) =>
                        removeParameter(`${hierarchyKey}_universes`, parameter)
                      }
                    />
                  );
                }
              )}
            </div>
            <div className="selection-actions">
              <AddParameterButton
                label="Add universe"
                tooltip="Add selected items as a universe for the report"
                addParameter={() => addParameter('universes')}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default SelectionControls;
