import type { FunctionComponent } from 'react';
import { useEffect, useState } from 'react';
import {
  DataTable,
  RadioButton,
  RadioButtonGroup,
  TextInput,
  TableContainer,
  Table,
  TableHead,
  TableSelectAll,
  TableSelectRow,
  TableBody,
  TableHeader,
  TableCell,
  TableRow,
} from '@carbon/react';
import type { AssortmentData } from '../../../reducers/AssortmentReducer';
import { updateCDForIds } from '../../../utils/CDTUtils';
import { assortmentInfoHeaders } from './CDTNodeInfo';
import SidePanelSection from '../../SidePanel/SidePanelSection';
import SidePanelActions from '../../SidePanel/SidePanelActions';

interface DendroNodeInfoProps {
  selectedProducts: string[];
  updateSelectedProducts: (productIds: string[]) => void;
  assortmentData: AssortmentData[];
  updateAssortmentData: (data: AssortmentData[]) => void;
  toggleSidePanelExpand: (expand: boolean) => void;
  isAuthor: boolean;
}
const options = [
  {
    option: 'cd3',
    label: 'Level 3',
  },
  {
    option: 'cd2',
    label: 'Level 2',
  },
  {
    option: 'cd1',
    label: 'Level 1',
  },
];

const DendroNodeInfo: FunctionComponent<DendroNodeInfoProps> = ({
  selectedProducts,
  updateSelectedProducts,
  assortmentData,
  updateAssortmentData,
  toggleSidePanelExpand,
  isAuthor,
}) => {
  const lookupIndex = assortmentInfoHeaders.findIndex(
    (header) => header.key === 'PRODUCT_ID'
  );

  const [selectedLevel, setSelectedLevel] = useState<string>(options[0].option);
  const [checkedProducts, setCheckedProducts] =
    useState<string[]>(selectedProducts);
  const [name, setName] = useState<string>('');

  const updateData = () => {
    const updatedData = updateCDForIds(
      checkedProducts,
      selectedLevel,
      name,
      assortmentData
    );
    updateAssortmentData(updatedData);
    updateSelectedProducts([]);
    toggleSidePanelExpand(false);
  };

  useEffect(() => {
    setName('');
    setCheckedProducts(selectedProducts);
  }, [selectedProducts]);

  const filteredData = assortmentData.filter(({ PRODUCT_ID }) =>
    selectedProducts.includes(PRODUCT_ID)
  );

  const allSelected = selectedProducts.length === checkedProducts.length;
  const someSelected =
    checkedProducts.length > 0 &&
    checkedProducts.length !== selectedProducts.length;
  const noneSelected = checkedProducts.length === 0;

  const handleLevelSelect = (option: string) => {
    setSelectedLevel(option);
  };

  const handleSelectAll = () => {
    if (allSelected) {
      setCheckedProducts([]);
    } else {
      setCheckedProducts(selectedProducts);
    }
  };

  const handleSelectRow = (row: CarbonSelectedRow) => {
    if (checkedProducts.includes(row.cells[lookupIndex].value)) {
      setCheckedProducts(
        checkedProducts.filter((p) => p !== row.cells[lookupIndex].value)
      );
    } else {
      setCheckedProducts([...checkedProducts, row.cells[lookupIndex].value]);
    }
  };

  return (
    <>
      {isAuthor && (
        <>
          <SidePanelSection sectionHeader="Group name" showDivider={false}>
            <TextInput
              id="dendro-node-group-name-input"
              className="assortment-group-name-input"
              data-testid="group-name-input"
              type="text"
              labelText="Group name"
              hideLabel
              value={name}
              placeholder="Group name"
              onChange={(e) => setName(e.target.value)}
            />
          </SidePanelSection>
          <SidePanelSection sectionHeader="Level to rename" showDivider={false}>
            <RadioButtonGroup
              name="radio-button-group"
              hideLabel
              className="levels-radio-button-group"
              defaultSelected="cd3"
              onChange={(level) => handleLevelSelect(level)}
            >
              {options.map((opt) => {
                return (
                  <RadioButton
                    key={opt.option}
                    labelText={opt.label}
                    value={opt.option}
                    id={opt.option}
                  />
                );
              })}
            </RadioButtonGroup>
          </SidePanelSection>
        </>
      )}
      <SidePanelSection sectionHeader="Products" showDivider={false}>
        <div className="node-info-description">
          Select products to assign to the relevant group name
        </div>
        <DataTable headers={assortmentInfoHeaders} rows={filteredData}>
          {({
            rows,
            headers,
            getHeaderProps,
            getTableProps,
            getTableContainerProps,
            getRowProps,
            getSelectionProps,
          }) => {
            return (
              <TableContainer {...getTableContainerProps()}>
                <Table {...getTableProps()}>
                  <TableHead>
                    <TableRow>
                      <TableSelectAll
                        {...getSelectionProps()}
                        checked={allSelected}
                        indeterminate={!noneSelected && someSelected}
                        onSelect={handleSelectAll}
                        className="header-checkbox"
                      />
                      {headers.map((header) => (
                        <TableHeader
                          key={header.key}
                          {...getHeaderProps({ header })}
                          style={
                            header.key === 'PRODUCT_ID'
                              ? { display: 'none' }
                              : {}
                          }
                        >
                          {header.header}
                        </TableHeader>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row) => {
                      return (
                        <TableRow
                          key={row.id}
                          {...getRowProps({ row })}
                          isSelected={false}
                        >
                          <TableSelectRow
                            {...getSelectionProps({ row })}
                            checked={checkedProducts.includes(
                              row.cells[lookupIndex].value
                            )}
                            onChange={() => handleSelectRow(row)}
                          />
                          {row.cells.map((cell) => {
                            return (
                              <TableCell
                                key={cell.id}
                                style={
                                  cell.id.match('PRODUCT_ID')
                                    ? { display: 'none' }
                                    : {}
                                }
                              >
                                {cell.value}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            );
          }}
        </DataTable>
      </SidePanelSection>
      {isAuthor && (
        <SidePanelActions
          actions={[
            {
              name: 'Update',
              disabled:
                checkedProducts.length === 0 ||
                name.replace(/ /g, '').length === 0,
              onClick: updateData,
              dataTestId: 'update-assortment-button',
            },
          ]}
        />
      )}
    </>
  );
};

export default DendroNodeInfo;
