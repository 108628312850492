import { useContext, useEffect } from 'react';
import ReportProvider from '../../providers/ReportProvider';
import ReportContent from './ReportContent';
import { useNavigate } from 'react-router-dom';
import { PosthogContext } from '../../providers/PosthogProvider';
import '../../styles/components/viewReport.scss';

const ViewReport = () => {
  const { featureFlags } = useContext(PosthogContext);
  const navigate = useNavigate();

  const workspaceFlag = featureFlags.find(
    (flag) => flag.name === 'workspace-maintenance'
  );

  useEffect(() => {
    if (workspaceFlag) {
      navigate('/workspace/my-reports');
    }
  }, [workspaceFlag]);

  return (
    <ReportProvider>
      <ReportContent />
    </ReportProvider>
  );
};

export default ViewReport;
