/* eslint-disable indent */
import type { Dispatch, FunctionComponent } from 'react';
import { useContext } from 'react';
import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@carbon/react';
import type { GlobalSelection } from '../ReportContent';
import { ReportContext } from '../../../providers/ReportProvider';
import type { Visual } from '../../../reducers/ReportReducer';
import VisualContainer from './VisualContainer';

interface VisualsTabsContainerProps {
  readonly visuals: Visual[];
  readonly reportType: ReportType;
  readonly reportTemplateId: string;
  readonly reportTemplateIndex: number;
  readonly globalSelections: GlobalSelection[];
  readonly isFirstTabReady: boolean;
  readonly headerPrefix?: string;
  readonly selectedIndex: number;
  readonly setSelectedIndex: Dispatch<React.SetStateAction<number>>;
}

const VisualsTabsContainer: FunctionComponent<VisualsTabsContainerProps> = ({
  visuals,
  reportType,
  reportTemplateId,
  reportTemplateIndex,
  globalSelections,
  isFirstTabReady,
  headerPrefix = '',
  selectedIndex,
  setSelectedIndex,
}) => {
  const { visualsData } = useContext(ReportContext);

  return (
    <Tabs selectedIndex={selectedIndex}>
      {isFirstTabReady ? (
        <TabList
          aria-label="List of visual tabs"
          className="VisualsTabsContainer__tab-list"
        >
          {visuals.map(({ id }, index) => {
            const { title } = visualsData[id]?.visualHeader ?? {};
            return (
              <Tab
                key={id}
                onClick={() => setSelectedIndex(index)}
                data-testid={`visual-tab-${id}`}
              >
                {title}
              </Tab>
            );
          })}
        </TabList>
      ) : null}
      <TabPanels>
        {visuals.map((vis, index) => {
          return (
            <TabPanel
              key={vis.id}
              data-testid={`visual-tabs-content-${vis.id}`}
              className="VisualsTabsContainer__tab-panel"
            >
              <VisualContainer
                visual={vis}
                reportType={reportType}
                reportTemplateId={reportTemplateId}
                reportTemplateIndex={reportTemplateIndex}
                globalSelections={globalSelections}
                isVisible={index === selectedIndex}
                hideVisualHeader
                withoutBackground
                useDropdownsWrapper
                headerPrefix={headerPrefix}
              />
            </TabPanel>
          );
        })}
      </TabPanels>
    </Tabs>
  );
};

export default VisualsTabsContainer;
