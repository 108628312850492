import type { FunctionComponent } from 'react';
import { BannerPopover } from './BannerPopover';
import type { BannerData } from '../../../reducers/AdminPanelReducer';
import { iron } from '../../../constants/colour/primitives';
import { getPresentedColour } from '../../../utils/colour';

interface BannerListProps {
  readonly rowId: string;
  readonly banners: BannerData[];
  readonly scrollbarRef: React.MutableRefObject<HTMLDivElement>;
}

const BannerCell: FunctionComponent<BannerListProps> = ({
  rowId,
  banners,
  scrollbarRef,
}) => {
  const visibleItems = banners.slice(0, 3);
  const hiddenItems = banners.slice(3);

  return (
    <div className="BannerList__container">
      {visibleItems.map(
        ({ id, name, user_groups, background_colour, text_colour }) => (
          <BannerPopover
            key={id}
            rowId={rowId}
            title={name}
            listItems={user_groups}
            bannerTagColour={{
              backgroundColor: getPresentedColour(background_colour),
              color: getPresentedColour(text_colour),
            }}
            popoverHeader="User Group Access"
            scrollbarRef={scrollbarRef}
          />
        )
      )}
      {hiddenItems.length > 0 && (
        <BannerPopover
          key="hidden-items"
          rowId={rowId}
          title={`+${hiddenItems.length}`}
          listItems={hiddenItems}
          bannerTagColour={{
            backgroundColor: iron[100],
            color: iron[900],
          }}
          popoverHeader="Banners"
          scrollbarRef={scrollbarRef}
        />
      )}
    </div>
  );
};

export default BannerCell;
