import type { FunctionComponent } from 'react';
import { Checkmark } from '../assets/icons/checkmark';
import '../styles/components/gravatar.scss';
import { Crossmark } from '../assets/icons/crossmark';
import { removeHexColourPrefix } from '../utils';
interface GravatarProps {
  name: string;
  showName?: boolean;
  className?: string;
  checked?: boolean;
  crossed?: boolean;
  size?: number;
  backgroundColour?: string;
  colour?: string;
  onClick?: () => void;
}

const Gravatar: FunctionComponent<GravatarProps> = ({
  name,
  showName = true,
  className,
  checked,
  crossed,
  size = 32,
  backgroundColour = '#1b71d5',
  colour = '#fff',
  onClick,
}) => {
  return (
    <div
      data-testid={`gravatar-${name}`}
      className={`gravatar-name ${
        className !== undefined ? className : ''
      }`.trim()}
      onClick={onClick}
    >
      <img
        src={`https://ui-avatars.com/api/?name=${name}&rounded=true&size=${size}&format=svg&background=${removeHexColourPrefix(
          backgroundColour
        )}&color=${removeHexColourPrefix(colour)}`}
      />
      {checked && <Checkmark animated={false} />}
      {crossed && <Crossmark animated={false} />}
      {showName && name}
    </div>
  );
};

export default Gravatar;
