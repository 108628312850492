import type { FunctionComponent, ReactNode } from 'react';
import { createContext, useMemo, useReducer, useRef } from 'react';
import type { ChartState } from '../reducers/ChartReducer';
import chartReducer, { initialChartState } from '../reducers/ChartReducer';
import type { ScatterData } from '../components/Charts/ScatterWithSummary';
import {
  UPDATE_CHART_OPTIONS,
  UPDATE_MEASURE,
  UPDATE_SELECTED_POINTS,
} from '../constants/reducers';

export interface ChartContextType extends ChartState {
  updateSelectedPoints: (selectedPoints: ScatterData[]) => void;
  updateChartOptions: (chartOptions: Highcharts.Options) => void;
  updateMeasure: (measure: Measure) => void;
}

export const ChartContext = createContext<ChartContextType | null>(null);

interface ChartProviderProps {
  children?: ReactNode;
}

const ChartProvider: FunctionComponent<ChartProviderProps> = ({ children }) => {
  const ref = useRef(null);

  const [state, dispatch] = useReducer(chartReducer, initialChartState);

  const updateSelectedPoints = (selectedPoints: ScatterData[]) =>
    dispatch({ type: UPDATE_SELECTED_POINTS, selectedPoints });

  const updateChartOptions = (chartOptions: Highcharts.Options) =>
    dispatch({ type: UPDATE_CHART_OPTIONS, chartOptions });

  const updateMeasure = (measure: Measure) =>
    dispatch({ type: UPDATE_MEASURE, measure });

  const chartContext: ChartContextType = useMemo(
    () => ({
      ref,
      selectedPoints: state.selectedPoints,
      chartOptions: state.chartOptions,
      measure: state.measure,
      updateSelectedPoints,
      updateChartOptions,
      updateMeasure,
    }),
    [state]
  );

  return (
    <ChartContext.Provider value={chartContext}>
      {children}
    </ChartContext.Provider>
  );
};

export default ChartProvider;
