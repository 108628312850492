import type { ReactNode } from 'react';
import { Tag, InlineLoading } from '@carbon/react';
import Tooltip from '../../Tooltip';
import { AttributeStatus } from '../../../types/customAttribute';

export const AttributeCellError = ({
  name,
  description,
}: {
  name: string;
  description: ReactNode;
}): JSX.Element => (
  <div className="AttributeCell AttributeCell--error">
    <div>{name}</div>
    <Tooltip description={description} align="right" useButtonWrapper>
      <Tag size="sm" className="AttributeCell__Tag">
        Failed
      </Tag>
    </Tooltip>
  </div>
);

interface AttributeCellProps {
  readonly name: string;
  readonly status: AttributeStatus;
}

export const AttributeCell = ({
  name,
  status,
}: AttributeCellProps): JSX.Element => {
  switch (status) {
    case AttributeStatus.Creating:
      return <InlineLoading description={`${name} is being created...`} />;

    case AttributeStatus.Deleting:
      return <InlineLoading description={`${name} is being deleted...`} />;

    case AttributeStatus.CreationFailed:
      return (
        <AttributeCellError
          name={name}
          description={
            <>
              <div>Attribute creation failed.</div>
              <div>Please delete and create the attribute again.</div>
            </>
          }
        />
      );

    case AttributeStatus.DeletionFailed:
      return (
        <AttributeCellError
          name={name}
          description={
            <>
              <div>Attribute deletion failed.</div>
              <div>Please try deleting the attribute again.</div>
            </>
          }
        />
      );

    default:
      return <>{name}</>;
  }
};
