/* eslint-disable indent */
import type { FunctionComponent } from 'react';
import { useState, useEffect, useContext, useRef } from 'react';
import {
  InlineLoading,
  DataTable,
  TextInput,
  TableContainer,
  Table,
  TableHead,
  TableSelectAll,
  TableSelectRow,
  TableBody,
  TableHeader,
  TableCell,
  TableRow as CarbonTableRow,
  Pagination,
  TableToolbar,
  TableToolbarContent,
  TableBatchActions,
  TableBatchAction,
} from '@carbon/react';
import { useAuth0 } from '@auth0/auth0-react';
import {
  TrashCan,
  Save,
  WatsonHealthTextAnnotationToggle,
  Warning,
} from '@carbon/icons-react';
import { CustomGroupsContext } from '../../../../providers/CustomGroupsProvider';
import { AppContext } from '../../../../providers/AppProvider';
import { ModalContext } from '../../../../providers/ModalProvider';
import Tooltip from '../../../Tooltip';
import { Checkmark } from '../../../../assets/icons/checkmark';
import { Crossmark } from '../../../../assets/icons/crossmark';
import {
  custGroupTableHeaders,
  alphanumericPattern,
} from '../../../../constants/metadata';
import {
  custGroupsUploadRevalidateFailure,
  custGroupsUploadRevalidateSuccess,
} from '../../../../constants/posthog';
import usePosthog from '../../../../utils/posthog';
import '../../../../styles/components/viewReport.scss';
import '../../../../styles/components/CustomGroups.scss';
import {
  getSelectedRowIds,
  sortUploadDataByInvalidEntities,
} from '../../../../utils/DataGridUtils';
import { validateUploadData } from '../../../../utils/reportUtils';
import { usePagination } from '../../../../hooks';
import type { EditCustomGroup } from './CustomGroupsUpload';

interface CustomGroupsUploadTableProps {
  editCustomGroup: EditCustomGroup;
}

const CustomGroupsUploadTable: FunctionComponent<
  CustomGroupsUploadTableProps
> = ({ editCustomGroup }) => {
  const { bannerId, groupId } = useContext(AppContext);
  const { updateModal } = useContext(ModalContext);
  const {
    uploadData,
    hasEntityErrors,
    filterByCustGroup,
    selectedDimGroup,
    updateFileSpecs,
    updateUploadStep,
    updateUploadData,
    updateHasEntityErrors,
    updateFilterByCustGroup,
  } = useContext(CustomGroupsContext);
  const { getAccessTokenSilently } = useAuth0();
  const posthogEvent = usePosthog();
  const [editing, setEditing] = useState(false);
  const [newEntityOrGroup, setNewEntityOrGroup] = useState<{
    id: string;
    name: string;
  }>({ id: '', name: '' });
  const [isUpdating, setIsUpdating] = useState<boolean>(false);

  const textInputRef = useRef<HTMLInputElement>(null);

  const {
    page,
    pageSizes,
    pageSize,
    isShowAll,
    getItemRangeText,
    setPagination,
  } = usePagination();

  const idGroupCombinations = uploadData.map((row) => {
    return `${row.entity_no}-${row.customGroup}`;
  });

  const distinctCombinations = new Set(idGroupCombinations);
  useEffect(() => {
    if (uploadData.length > 0) {
      updateHasEntityErrors(uploadData?.some((each) => !each.is_exists));
    }
  }, [uploadData]);

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      updateFilterByCustGroup('');
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [setEditing]);

  useEffect(() => {
    if (!uploadData.length) {
      updateUploadStep(0);
      updateFileSpecs([]);
    }
  }, [uploadData]);

  const handleCustomGroupsDelete = (selectedRows) => {
    const newUploadData = uploadData.filter(
      (item) => !selectedRows.map((row) => row.id).includes(item.id)
    );
    updateUploadData(newUploadData);
  };

  const handleOutsideClick = (e) => {
    if (textInputRef.current && !textInputRef.current.contains(e.target)) {
      setEditing(false);
    }
  };

  const isDuplicate = (row: CarbonTableRow) => {
    const { id, name } = newEntityOrGroup;
    const isIdEdit = id.match('entity_no');

    const existingId = row.cells.find((c) => c.id.match('entity_no')).value;
    const existingGroup = row.cells.find((c) =>
      c.id.match('customGroup')
    ).value;
    const idGroupCombination = isIdEdit
      ? `${name}-${existingGroup}`
      : `${existingId}-${name}`;

    return Array.from(distinctCombinations).includes(idGroupCombination);
  };

  const saveCustomGroupsEdits = async (
    cell: CarbonCell<string>,
    row: CarbonTableRow
  ) => {
    if (cell.value === newEntityOrGroup.name) {
      setEditing(false);
      return;
    }
    if (isDuplicate(row)) {
      updateModal({
        type: 'warning',
        title: 'Duplicate row',
        body: `This ${selectedDimGroup} ID and group combination already exists.`,
      });
    } else {
      if (newEntityOrGroup && cell.id.includes('entity_no')) {
        await revalidateAndSaveData(cell, newEntityOrGroup);
      } else {
        const newUploadData = uploadData?.map((item) => {
          if (
            item.customGroup === cell.value &&
            cell.id.includes(item.id.toString())
          ) {
            return {
              ...item,
              customGroup: newEntityOrGroup.name,
            };
          }
          return item;
        });
        newEntityOrGroup.name !== '' && updateUploadData(newUploadData);
      }
    }
    setEditing(false);
  };

  const revalidateAndSaveData = async (cell, newEntityOrGroup) => {
    if (newEntityOrGroup.id === cell.id && cell.id.includes('entity_no')) {
      setEditing(false);
      setIsUpdating(true);
      const token = await getAccessTokenSilently();
      try {
        const response = await validateUploadData(
          selectedDimGroup,
          [
            {
              entity_no: newEntityOrGroup.name,
            },
          ],
          token,
          bannerId,
          groupId
        );
        posthogEvent(custGroupsUploadRevalidateSuccess, {
          oldVal: cell.value,
          newVal: newEntityOrGroup.name,
        });
        const entRes = response.data.entities[0];

        const newUploadData = uploadData?.map((item) => {
          if (item.entity_no === cell.value && cell.id.includes(item.id)) {
            return {
              id: item.id,
              customGroup: item.customGroup,
              entity_no: newEntityOrGroup.name,
              is_exists: entRes.is_exists,
              checkEdited: entRes.is_exists,
              ...(entRes.is_exists
                ? {
                    label: entRes.label,
                    level: entRes.level,
                    entity_id: entRes.id.toString(),
                  }
                : {}),
            };
          }
          return item;
        });

        updateHasEntityErrors(newUploadData?.some((each) => !each.is_exists));
        newEntityOrGroup.name !== '' && updateUploadData(newUploadData);
      } catch {
        posthogEvent(custGroupsUploadRevalidateFailure, {
          oldVal: cell.value,
          newVal: newEntityOrGroup.name,
        });
        updateModal({
          type: 'error',
          title: 'Something went wrong',
          body: 'There was an error processing revalidation request. Please try refreshing your browser and try again. If the issue persists, please contact the helpdesk.',
        });
      } finally {
        setIsUpdating(false);
      }
    } else {
      setEditing(false);
    }
  };

  const renderEditedStatus = (cell: CarbonCell<string>) => {
    const entity = uploadData.find(
      (item) =>
        String(item.entity_no) === cell.value &&
        cell.id.includes(String(item.id))
    );
    let status = null;
    if (entity) {
      if (entity.checkEdited) {
        status = <Checkmark />;
      } else if (entity.checkEdited === false) {
        status = <Crossmark />;
      }
    }
    return status;
  };

  const renderEditIcon = (cell: CarbonCell<string>) => {
    return (
      <div className="CustomGroupsUploadTable__edit-icon">
        <Tooltip description="Edit name" align="left">
          <WatsonHealthTextAnnotationToggle
            data-testid="entity-edit-icon"
            onClick={(e) => {
              e.stopPropagation();
              setEditing(true);
              setNewEntityOrGroup({
                id: cell.id,
                name: String(cell.value),
              });
            }}
          />
        </Tooltip>
      </div>
    );
  };
  const renderTextInput = (cell: CarbonCell<string>, row) => {
    return (
      <div
        ref={textInputRef}
        className="CustomGroupsUploadTable__cell-text-input"
      >
        <TextInput
          invalid={!newEntityOrGroup.name}
          invalidText="Empty field"
          id={cell.id}
          labelText="Edit name"
          data-testid={`upload-table-input-${cell.info.header}`}
          hideLabel
          value={newEntityOrGroup.name}
          onClick={(e) => {
            e.stopPropagation();
          }}
          autoFocus
          onChange={(e) => {
            setNewEntityOrGroup((prev) => ({
              ...prev,
              name:
                cell.info.header === 'entity_no'
                  ? String(e.target.value)
                      .replace(alphanumericPattern, '')
                      .trim()
                  : e.target.value,
            }));
          }}
          onKeyDown={({ code }) => {
            if (code === 'Enter') {
              newEntityOrGroup.name && saveCustomGroupsEdits(cell, row);
            }
          }}
        />
        <Tooltip description="Save custom group name" align="bottom-right">
          <Save
            data-testid="save-cell-input"
            onClick={(e) => {
              e.stopPropagation();
              newEntityOrGroup.name && saveCustomGroupsEdits(cell, row);
            }}
          />
        </Tooltip>
      </div>
    );
  };
  const uploadDataLength = filterByCustGroup
    ? uploadData.filter((gr) => gr.customGroup === filterByCustGroup).length
    : uploadData.length;

  return (
    <div className="CustomGroupsUploadTable">
      <div className="CustomGroupsUploadTable__title">
        {`${selectedDimGroup}s Found ${uploadDataLength}`}
      </div>
      {hasEntityErrors && (
        <div className="CustomGroupsUploadTable__notification-wrapper">
          <div className="CustomGroupsUploadTable__notification">
            <Warning
              size={18}
              className="CustomGroupsUploadTable__warning-icon"
            />
            {`${selectedDimGroup} not found in your database. Please edit the number or re-upload a file with existing ${selectedDimGroup.toLowerCase()} ID’s.`}
          </div>
        </div>
      )}

      <DataTable
        headers={custGroupTableHeaders}
        rows={
          filterByCustGroup
            ? uploadData.filter((gr) => gr.customGroup === filterByCustGroup)
            : uploadData
        }
        data-testid="upload-data-table"
        isSortable
      >
        {({
          rows,
          headers,
          getHeaderProps,
          getRowProps,
          getTableProps,
          getBatchActionProps,
          getSelectionProps,
          getToolbarProps,
          selectedRows,
          getTableContainerProps,
          selectRow,
        }) => {
          const rowStartIndex = (page - 1) * pageSize;

          const invalidFirstRows = sortUploadDataByInvalidEntities(
            rows,
            uploadData
          );

          const visibleRows = isShowAll
            ? invalidFirstRows
            : invalidFirstRows.slice(rowStartIndex, rowStartIndex + pageSize);

          return (
            <>
              <TableContainer
                {...getTableContainerProps()}
                className="CustomGroupsUploadTable__table"
              >
                {!filterByCustGroup && (
                  <TableToolbarContent>
                    <TableToolbar {...getToolbarProps()}>
                      <TableBatchActions
                        {...getBatchActionProps()}
                        className="toolbar-buttons"
                        data-testid="table-batch-actions"
                      >
                        <TableBatchAction
                          key="delete-custom-groups"
                          renderIcon={TrashCan}
                          onClick={() => handleCustomGroupsDelete(selectedRows)}
                          data-testid="delete-custom-groups"
                        >
                          Delete
                        </TableBatchAction>
                      </TableBatchActions>
                    </TableToolbar>
                  </TableToolbarContent>
                )}
                <Table {...getTableProps()}>
                  <TableHead>
                    <CarbonTableRow>
                      {!filterByCustGroup && (
                        <TableSelectAll
                          {...getSelectionProps()}
                          className="header-checkbox"
                          onSelect={() => {
                            const selectedRowIds = getSelectedRowIds(
                              visibleRows,
                              selectedRows
                            );
                            selectedRowIds.forEach((id) => {
                              selectRow(id);
                            });
                          }}
                        />
                      )}
                      {headers.map((header) => (
                        <TableHeader
                          key={header.key}
                          {...getHeaderProps({ header })}
                        >
                          {header.header}
                        </TableHeader>
                      ))}
                    </CarbonTableRow>
                  </TableHead>
                  <TableBody>
                    {visibleRows.map((row) => {
                      const badEntity = uploadData?.find(
                        (gr) => gr.id === row.id && !gr.is_exists
                      );
                      return (
                        <CarbonTableRow
                          key={row.id}
                          {...getRowProps({ row })}
                          style={{
                            border: badEntity && '2px solid #da1e28',
                          }}
                        >
                          {!filterByCustGroup && (
                            <TableSelectRow
                              {...getSelectionProps({ row })}
                              className={`${
                                badEntity &&
                                'CustomGroupsUploadTable__invalid-row'
                              }`}
                            />
                          )}

                          {row.cells.map((cell) => {
                            return (
                              <TableCell
                                key={cell.id}
                                className={`${
                                  badEntity &&
                                  'CustomGroupsUploadTable__invalid-row'
                                }`}
                              >
                                <div>
                                  {cell.id === newEntityOrGroup?.id &&
                                  editing ? (
                                    renderTextInput(cell, row)
                                  ) : (
                                    <>
                                      {isUpdating &&
                                      cell.id === newEntityOrGroup?.id ? (
                                        <InlineLoading description="Updating..." />
                                      ) : (
                                        <div className="CustomGroupsUploadTable__edit-wrapper">
                                          {cell.info.header === 'label' &&
                                          !cell.value ? (
                                            <span className="CustomGroupsUploadTable__invalid-row--text">
                                              {`${selectedDimGroup} ID not found in database. Edit the number or re-upload file`}{' '}
                                            </span>
                                          ) : (
                                            cell.value
                                          )}
                                          {cell.info.header === 'entity_no' &&
                                            renderEditedStatus(cell)}
                                          {editCustomGroup.isEditing
                                            ? cell.info.header ===
                                                'entity_no' &&
                                              !filterByCustGroup &&
                                              renderEditIcon(cell)
                                            : (cell.info.header ===
                                                'entity_no' ||
                                                cell.info.header ===
                                                  'customGroup') &&
                                              !filterByCustGroup &&
                                              renderEditIcon(cell)}
                                        </div>
                                      )}
                                    </>
                                  )}
                                </div>
                              </TableCell>
                            );
                          })}
                        </CarbonTableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          );
        }}
      </DataTable>
      <Pagination
        backwardText="Previous page"
        forwardText="Next page"
        itemsPerPageText="Items per page:"
        onChange={setPagination}
        page={page}
        pageSize={pageSize}
        pageSizes={pageSizes}
        itemRangeText={getItemRangeText}
        totalItems={uploadDataLength}
      />
    </div>
  );
};
export default CustomGroupsUploadTable;
