import { Warning } from '@carbon/icons-react';
import { hasInvalidItems } from '../../../utils/attributeUtils';
import type { UploadData } from '../../../reducers/AttributeValuesReducer';
import type { FunctionComponent } from 'react';

interface AttributeValuesErrorNotificationsProps {
  uploadData: UploadData;
}

const AttributeValuesErrorNotifications: FunctionComponent<
  AttributeValuesErrorNotificationsProps
> = ({ uploadData }) => {
  return (
    <div className="AttributeValuesGrid__notification-wrapper">
      {hasInvalidItems(uploadData?.products) && (
        <div className="AttributeValuesGrid__notification">
          <Warning size={18} className="AttributeValuesGrid__warning-icon" />
          Product not found in your database. Please re-upload the file with
          SKUs so they match existing product ID’s.
        </div>
      )}
      {hasInvalidItems(uploadData?.attributes) && (
        <div className="AttributeValuesGrid__notification">
          <Warning size={18} className="AttributeValues__warning-icon" />
          Attribute name doesn’t match any existing attribute group name. Please
          re-upload the file with the correct attribute name as the column
          header.
        </div>
      )}
    </div>
  );
};

export default AttributeValuesErrorNotifications;
