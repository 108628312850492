import type { FunctionComponent, ReactNode } from 'react';
import { createContext, useMemo, useReducer } from 'react';
import type {
  AdminPanelState,
  UserData,
  UserDataConfigResponse,
  UsersTableFilters,
} from '../reducers/AdminPanelReducer';
import {
  adminPanelReducer,
  adminPanelInitialState,
} from '../reducers/AdminPanelReducer';
import {
  CLEAR_USERS_TABLE_FILTERS,
  UPDATE_ADMIN_PANEL_USERS_LIST,
  UPDATE_CONFIG_DATA,
  UPDATE_USERS_TABLE_FILTERS,
} from '../constants/reducers';
import type { AuthenticationType } from '../constants/metadata';
import { getFormattedAuthType } from '../utils/adminPanelUtils';

interface AdminPanelProviderProps {
  children?: ReactNode;
}

export const AdminPanelContext = createContext<AdminPanelContextType | null>(
  null
);

export interface AdminPanelContextType extends AdminPanelState {
  updateUsersList: (users: UserData[]) => void;
  updateConfigData: (dataConfig: UserDataConfigResponse) => void;
  updateUsersTableFilters: (
    key: keyof UsersTableFilters,
    value: string | number | (string | number)[]
  ) => void;
  clearUsersTableFilters: () => void;
}

const AdminPanelProvider: FunctionComponent<AdminPanelProviderProps> = ({
  children,
}) => {
  const [state, dispatch] = useReducer(
    adminPanelReducer,
    adminPanelInitialState
  );

  const authTypes = useMemo(() => {
    const authTypeKeys = process.env.REACT_APP_AUTH0_CONNECTIONS.split(
      ','
    ) as AuthenticationType[];

    const authTypes = authTypeKeys.map((key) => ({
      name: getFormattedAuthType(key),
      type: key,
    }));

    return authTypes;
  }, []);

  const updateUsersList = (users: UserData[]) => {
    dispatch({ type: UPDATE_ADMIN_PANEL_USERS_LIST, users });
  };

  const updateConfigData = (dataConfig: UserDataConfigResponse) => {
    dispatch({
      type: UPDATE_CONFIG_DATA,
      dataConfig,
    });
  };

  const updateUsersTableFilters = (
    key: keyof UsersTableFilters,
    value: string | number | (string | number)[]
  ) => {
    dispatch({ type: UPDATE_USERS_TABLE_FILTERS, key, value });
  };

  const clearUsersTableFilters = () => {
    dispatch({ type: CLEAR_USERS_TABLE_FILTERS });
  };

  const context: AdminPanelContextType = useMemo(
    () => ({
      users: state.users,
      dataConfig: { ...state.dataConfig, authTypes },
      usersTableFilters: state.usersTableFilters,
      updateUsersList,
      updateConfigData,
      updateUsersTableFilters,
      clearUsersTableFilters,
    }),
    [state]
  );
  return (
    <AdminPanelContext.Provider value={context}>
      {children}
    </AdminPanelContext.Provider>
  );
};

export default AdminPanelProvider;
