/* eslint-disable indent */
import type { FunctionComponent } from 'react';
import { useContext, useMemo, useState } from 'react';
import { Column } from '@carbon/react';
import type { GlobalSelection } from '../ReportContent';
import CardHeader from '../../Cards/CardHeader';
import { ReportContext } from '../../../providers/ReportProvider';
import type { Visual, VisualData } from '../../../reducers/ReportReducer';
import {
  VisualContainerType,
  VisualType,
} from '../../../reducers/ReportReducer';
import {
  DEFAULT_COLUMN_SPAN,
  VISUAL_CONTAINER_KEY,
} from '../../../constants/values';
import VisualsListContainer from './VisualsListContainer';
import VisualsTabsContainer from './VisualsTabsContainer';
import { getIcon } from '../../../utils/iconUtils';
import { getCustomStyles, visualResponsive } from '../../../utils/reportUtils';
import DataVisual from '../DataVisual';

interface VisualContainerProps {
  readonly visual: Visual;
  readonly reportType: ReportType;
  readonly reportTemplateId: string;
  readonly reportTemplateIndex: number;
  readonly globalSelections: GlobalSelection[];
  readonly withoutBackground?: boolean;
  readonly hideDropdowns?: boolean;
  readonly useDropdownsWrapper?: boolean;
  readonly isVisible?: boolean;
  readonly hideVisualHeader?: boolean;
  readonly headerPrefix?: string;
}

const VisualContainer: FunctionComponent<VisualContainerProps> = ({
  visual,
  reportType,
  reportTemplateId,
  reportTemplateIndex,
  globalSelections,
  withoutBackground,
  hideDropdowns,
  useDropdownsWrapper,
  isVisible,
  hideVisualHeader,
  headerPrefix = '',
}) => {
  const {
    visualsData,
    visualRefs,
    tabIndex,
    reportConfig: { report_type },
  } = useContext(ReportContext);
  const { id, visuals, container, apiUrl, colSpan, type } = visual;
  const isFirstTabReady = !!visuals && !!visualsData[visuals[0].id];
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const isResponsive = visualResponsive(visual);

  const getHeaderData = ({
    title,
    icon,
    icon_colour,
    icon_background_colour,
  }: VisualData['visualHeader']): {
    title: string;
    icon: JSX.Element;
    iconColour: string;
    iconBackgroundColour: string;
  } => {
    return {
      title: `${headerPrefix}${title}`,
      icon: getIcon(icon),
      iconColour: icon_colour,
      iconBackgroundColour: icon_background_colour,
    };
  };

  const visualContainerHeader = useMemo(() => {
    const visualData = visualsData?.[visuals?.[selectedTabIndex]?.id];

    if (container !== VisualContainerType.TABS || !visualData?.visualHeader) {
      return null;
    }

    return getHeaderData(visualData.visualHeader);
  }, [selectedTabIndex, visualsData, headerPrefix]);

  const renderContainer = () => {
    switch (container) {
      case VisualContainerType.TABS:
        return (
          <VisualsTabsContainer
            visuals={visuals}
            reportType={reportType}
            reportTemplateId={reportTemplateId}
            reportTemplateIndex={reportTemplateIndex}
            globalSelections={globalSelections}
            isFirstTabReady={isFirstTabReady}
            headerPrefix={headerPrefix}
            selectedIndex={selectedTabIndex}
            setSelectedIndex={setSelectedTabIndex}
          />
        );
      case VisualContainerType.LIST:
        return (
          <VisualsListContainer
            visuals={visuals}
            reportType={reportType}
            reportTemplateId={reportTemplateId}
            reportTemplateIndex={reportTemplateIndex}
            headerPrefix={headerPrefix}
            globalSelections={globalSelections}
          />
        );
      default:
        return null;
    }
  };

  return container ? (
    <Column
      data-testid={`visual-container-${id}`}
      className={`VisualContainer__container ${
        !isFirstTabReady ? 'VisualContainer__container--loading' : ''
      }`}
      key={`${id}_${visuals.map(({ id }) => id).join('_')}`}
      lg={DEFAULT_COLUMN_SPAN}
      md={DEFAULT_COLUMN_SPAN / 2}
      sm={DEFAULT_COLUMN_SPAN / 4}
    >
      <div
        ref={(el) => (visualRefs.current[`${VISUAL_CONTAINER_KEY}-${id}`] = el)}
      >
        {visualContainerHeader && <CardHeader {...visualContainerHeader} />}
        {renderContainer()}
      </div>
    </Column>
  ) : (
    <Column
      data-testid={apiUrl}
      className={type}
      lg={colSpan}
      md={isResponsive ? 8 : colSpan / 2}
      sm={isResponsive ? 4 : colSpan / 4}
      style={getCustomStyles(visual)}
    >
      <DataVisual
        {...visual}
        tab={tabIndex}
        apiUrl={`/reports${apiUrl}`}
        visualData={visualsData[id]}
        reportType={report_type}
        reportTemplateId={reportTemplateId}
        reportTemplateIndex={reportTemplateIndex}
        globalSelections={globalSelections}
        useDropdownsWrapper={
          (report_type === 'Consolidated Performance Report' &&
            type === VisualType.CELL_GRID) ||
          useDropdownsWrapper
        }
        withoutBackground={withoutBackground}
        hideDropdowns={hideDropdowns}
        isVisible={isVisible}
        visualHeader={
          visualsData?.[id]?.visualHeader && !hideVisualHeader
            ? getHeaderData(visualsData[id].visualHeader)
            : null
        }
      />
    </Column>
  );
};

export default VisualContainer;
