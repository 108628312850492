import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';
import { Loading } from '@carbon/react';
import '../styles/components/loggedOutComponent.scss';
const LoggedOutComponent = () => {
  const { loginWithRedirect } = useAuth0();

  useEffect(() => {
    loginWithRedirect();
  }, []);

  return (
    <div className="redirect-loader" title="Redirecting to login">
      <Loading withOverlay={false} />
    </div>
  );
};

export default LoggedOutComponent;
