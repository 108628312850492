import type {
  AxiosRequestConfig,
  AxiosResponse,
  RawAxiosRequestHeaders,
} from 'axios';
import axios from 'axios';
import { MAX_RETRY_ATTEMPTS } from '../constants/api';
import { inflate } from 'pako';
import jwtDecode from 'jwt-decode';
import { apiRequestFailure } from '../constants/posthog';
import posthog from 'posthog-js';
import { getBaseUrl } from './configuration';
import type { PreSignedURLResponse } from '../types/apiTypes';
import { ERROR_TYPE } from './types';
import { isAbsoluteURL, wait } from './utils';

export const tryRequest = async <T>(
  options: AxiosRequestConfig,
  attempt: number,
  token: string
): Promise<T> => {
  try {
    const response: AxiosResponse<T | PreSignedURLResponse> =
      await axios.request(options);

    if (
      response.status !== 201 ||
      !(response.data as PreSignedURLResponse).presigned_url
    ) {
      return response as T;
    }

    const { decompress } = response.data as PreSignedURLResponse;
    const { data } = await axios.request({
      url: (response.data as PreSignedURLResponse).presigned_url,
      ...(decompress ? { responseType: 'arraybuffer', decompress: true } : {}),
    });

    if (!decompress) {
      return { data } as T;
    }

    const inflated = inflate(data, { to: 'string' });
    const json = JSON.parse(inflated);

    return { data: json } as T;
  } catch (error) {
    if (axios.isCancel(error)) {
      throw error;
    }

    switch (error.response?.data?.type) {
      case ERROR_TYPE.VALIDATION:
        throw error;
      case ERROR_TYPE.NOT_FOUND:
        throw new Error(
          `Backend Request Error: ${error.response?.data?.message}`
        );
      default: {
        if (attempt > MAX_RETRY_ATTEMPTS) {
          throw new Error(`Backend Request Error: ${error.message}`);
        }
      }
    }

    const { sub }: Auth0AccessToken = jwtDecode(token);
    posthog.capture(apiRequestFailure, {
      user: sub,
      user_group: 0,
      payload: {
        url: options.url,
        attempt,
      },
    });

    await wait(2 ** attempt * 100);
    return tryRequest(options, attempt + 1, token);
  }
};

export const apiRequest = async <T>(
  path: string,
  method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE',
  token: string,
  data?,
  signal?: AbortSignal,
  options?: { headers?: RawAxiosRequestHeaders }
): Promise<T> => {
  const headers: RawAxiosRequestHeaders = {
    'Content-Type': 'application/json',
    ...(!isAbsoluteURL(path) && { Authorization: `Bearer ${token}` }),
    ...(options?.headers ?? {}),
  };

  return tryRequest<T>(
    { url: path, headers, method, data, signal, baseURL: getBaseUrl() },
    1,
    token
  );
};
