import { Outlet, useNavigate } from 'react-router-dom';
import type { FunctionComponent } from 'react';
import { useContext, useEffect } from 'react';
import { AppContext } from '../../providers/AppProvider';
import { CACHE_KEY, type DynamicCacheKey } from '../../constants/api';
import { CustomGroupsContext } from '../../providers/CustomGroupsProvider';

const CustomGroupsLayout: FunctionComponent = () => {
  const navigate = useNavigate();
  const { bannerId, groupId, clearCacheForKey, updateDimensionsData } =
    useContext(AppContext);
  const { selectedDimGroup } = useContext(CustomGroupsContext);

  const hierarchiesToClear: DynamicCacheKey[] = [
    CACHE_KEY.DIMENSIONS,
    CACHE_KEY.HIERARCHIES_CUSTOM_PRODUCTS,
    CACHE_KEY.HIERARCHIES_CUSTOM_LOCATION,
  ];

  useEffect(() => {
    return () => {
      hierarchiesToClear.forEach((key) => {
        clearCacheForKey(key);
      });
      updateDimensionsData([]);
    };
  }, []);

  useEffect(() => {
    selectedDimGroup && navigate('');
  }, [bannerId, groupId]);

  return <Outlet />;
};

export default CustomGroupsLayout;
