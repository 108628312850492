import { Auth0Provider } from '@auth0/auth0-react';
import { AUTH0_SCOPE } from '../constants/auth0';

const ConfiguredAuthProvider = ({ children }: { children: JSX.Element }) => {
  const domain = process.env.REACT_APP_AUTH0_DOMAIN;
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
  const audience = process.env.REACT_APP_AUDIENCE;
  const connections = process.env.REACT_APP_AUTH0_CONNECTIONS;
  const ssoId = process.env.REACT_APP_AUTH0_SSO_ID;

  if (!(domain && clientId)) return null;

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      redirectUri={`${window.location.origin}`}
      audience={audience}
      cacheLocation="localstorage"
      useRefreshTokens
      scope={AUTH0_SCOPE}
      connectionTypes={connections}
      ssoId={ssoId}
    >
      {children}
    </Auth0Provider>
  );
};

export default ConfiguredAuthProvider;
