import { Outlet } from 'react-router-dom';
import AttributeValuesProvider from '../../providers/AttributeValuesProvider';

export const AttributeLayout = () => {
  return (
    <AttributeValuesProvider>
      <Outlet />
    </AttributeValuesProvider>
  );
};
