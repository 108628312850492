export const aquamarine = {
  50: '#d6fffe',
  100: '#b3fffc',
  200: '#66fffa',
  300: '#1afff7',
  400: '#00ccc5',
  500: '#007d79',
  600: '#006663',
  700: '#004d4a',
  800: '#003331',
  900: '#001919',
} as const;
