import {
  RadioButtonGroup,
  RadioButton,
  NumberInput,
  FormGroup,
  Checkbox,
} from '@carbon/react';
import { capitalizeFirstLetter } from '../../../utils/reportUtils';
import type { SelectionValue } from './ReportSettings';

export const getSettingsType = (
  setting: AdvancedSettings,
  analyticEngineParams: AnalyticEngineParams,
  handleOnChange: (
    selectionValue: SelectionValue,
    name: string,
    type?: string
  ) => void
) => {
  const { name, text } = setting;

  switch (setting.type) {
    case 'radio':
      return (
        <RadioButtonGroup
          legendText={setting.text}
          name={setting.name}
          onChange={(e) => handleOnChange(e, name)}
          className="advanced-settings-radio-group"
        >
          {setting.options.map((option) => {
            return (
              <RadioButton
                className="advanced-settings-radio-buttons"
                data-testid={`settings-radio-${option.id}`}
                key={`settings-radio-${option.id}`}
                id={`settings-radio-${option.id}`}
                labelText={option.option}
                value={option.option}
                disabled={option.disabled}
                checked={option.option === analyticEngineParams[name]}
              />
            );
          })}
        </RadioButtonGroup>
      );
    case 'input-number':
      return (
        <div className="report-setting-inputs-wrapper">
          <NumberInput
            id="input-number"
            invalidText="Number is not valid"
            label={text}
            max={setting.restriction ? setting.restriction.max : 100000000}
            min={0}
            onChange={(_, { value }) => handleOnChange(Number(value), name)}
            value={analyticEngineParams[setting.name]}
          />
        </div>
      );
    case 'checkbox':
      return (
        <FormGroup
          legendText={setting.text}
          className="report-setting-checkbox-title"
        >
          <div className="report-setting-checkbox-wrapper">
            {setting.options.map((item) => {
              return (
                <Checkbox
                  key={`settings-checkbox-${item.id}`}
                  id={`settings-checkbox-${item.id}`}
                  labelText={capitalizeFirstLetter(item.option)}
                  value={item.option}
                  disabled={item.disabled}
                  data-testid={`settings-checkbox-${item.id}`}
                  checked={(analyticEngineParams[name] as string[]).includes(
                    item.option
                  )}
                  onChange={({ target: { value } }, { checked }) => {
                    return handleOnChange(
                      {
                        selection: value,
                        checked,
                        defaultValue: setting.default as string,
                      },
                      setting.name,
                      setting.type
                    );
                  }}
                />
              );
            })}
          </div>
        </FormGroup>
      );
  }
};
