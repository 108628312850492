export interface SourceDestination {
  source: unknown;
  destination?: unknown;
}

export interface AttributesToCopy extends SourceDestination {
  inner_attributes?: SourceDestination[];
  transformation?: string;
  inner_key?: string;
}

export const idKey1: SourceDestination = {
  source: 'PRODUCT_ID',
  destination: 'PRODUCT_ID',
};
export const idKey2: SourceDestination = {
  source: 'PRODUCT_ID',
  destination: 'PRODUCT',
};
export const idKey3: SourceDestination = {
  source: 'PRODUCT_ID',
  destination: {
    PRODUCT: 'prod1',
    PROD2: 'prod2',
  },
};
export const idKey4: SourceDestination = {
  source: 'PRODUCT_ID',
  destination: 'lookalike.id',
};

export const attributes1: AttributesToCopy[] = [
  {
    source: 'cd1',
    destination: 'cd1',
  },
  {
    source: 'cd2',
    destination: 'cd2',
  },
  {
    source: 'cd3',
    destination: 'cd3',
  },
];

export const attributes2: AttributesToCopy[] = [
  {
    source: 'cd1',
    destination: 'cd1',
    inner_attributes: [
      {
        source: 'value',
        destination: 'name',
      },
      {
        source: 'id',
        destination: 'id',
      },
    ],
  },
  {
    source: 'cd2',
    destination: 'cd2',
    inner_attributes: [
      {
        source: 'value',
        destination: 'name',
      },
      {
        source: 'id',
        destination: 'id',
      },
    ],
  },
  {
    source: 'cd3',
    destination: 'cd3',
    inner_attributes: [
      {
        source: 'value',
        destination: 'name',
      },
      {
        source: 'id',
        destination: 'id',
      },
    ],
  },
];

export const attributes3: AttributesToCopy[] = [
  {
    source: 'cd1',
    transformation: 'inner_to_flat',
    inner_key: 'id',
  },
  {
    source: 'cd2',
    transformation: 'inner_to_flat',
    inner_key: 'id',
  },
  {
    source: 'cd3',
    transformation: 'inner_to_flat',
    inner_key: 'id',
  },
];

export const attributes4: AttributesToCopy[] = [
  {
    source: 'cd1',
    destination: 'cd1',
    inner_attributes: [
      {
        source: 'value',
        destination: 'value',
      },
      {
        source: 'id',
        destination: 'id',
      },
    ],
  },
  {
    source: 'cd2',
    destination: 'cd2',
    inner_attributes: [
      {
        source: 'value',
        destination: 'value',
      },
      {
        source: 'id',
        destination: 'id',
      },
    ],
  },
  {
    source: 'cd3',
    destination: 'cd3',
    inner_attributes: [
      {
        source: 'value',
        destination: 'value',
      },
      {
        source: 'id',
        destination: 'id',
      },
    ],
  },
];

export const DEFAULT_PRODUCT_SUMMARY = {
  current: {
    existing: { in: '-', out: '-' },
    new: { in: '-', out: '-' },
    total: '-',
  },
  optimised: {
    existing: { in: '-', out: '-' },
    new: { in: '-', out: '-' },
    total: '-',
  },
};
