import { useEffect, useRef } from 'react';

interface Options {
  readonly delay: number | null;
}

export function useInterval(callback: () => void, options: Options): void {
  const { delay } = options;

  const savedCallback = useRef(callback);

  savedCallback.current = callback;

  useEffect(() => {
    if (delay === null) {
      return;
    }

    const intervalId = setInterval(() => {
      savedCallback.current();
    }, delay);

    return () => {
      clearInterval(intervalId);
    };
  }, [delay]);
}
