import type { CustomGroupAccess } from '../constants/metadata';
import {
  UPDATE_UPLOAD_STEP,
  UPDATE_UPLOAD_DATA,
  UPDATE_FILE_SPECS,
  UPDATE_FILTER_BY_CUSTOM_GROUP,
  UPDATE_UPLOAD_CUSTOM_GROUPS,
  UPDATE_HAS_ENTITY_ERRORS,
  UPDATE_EXISTING_CUSTOM_GROUPS,
  UPDATE_SELECTED_DIMENSION_GROUP,
  UPDATE_SELECTED_CUSTOM_GROUP_ID,
  RESET_UPLOAD_DATA,
} from '../constants/reducers';

export interface UploadData {
  id: number;
  customGroup: string;
  entity_no: number | string;
  entity_id?: string;
  is_exists?: boolean;
  label?: string;
  level?: string;
  checkEdited?: boolean;
}

export interface CustGroup {
  id: number;
  customGroup: string;
  total: number;
  isChecked: boolean;
}
export interface FileSpecs {
  name: string;
  size: number;
  type: string;
  status: 'edit' | 'completed' | 'uploading';
  invalid: boolean;
  iconDescription?: string;
  errorSubject?: string;
}
export interface ExistingCustGroup {
  access: CustomGroupAccess;
  created_by: string;
  created_timestamp: string;
  dimension: string;
  id: string;
  name: string;
  path: string;
  row_count: number;
  updated_timestamp?: string;
  checked?: boolean;
}
export interface CustomGroupsState {
  uploadStep: number;
  uploadData: UploadData[];
  fileSpecs: FileSpecs[];
  filterByCustGroup: string;
  customGroups: CustGroup[];
  existingCustGroups: ExistingCustGroup[];
  hasEntityErrors: boolean;
  selectedDimGroup: string;
  selectedCustomGroupId: string;
}
interface UpdateHasEntityErrors {
  type: typeof UPDATE_HAS_ENTITY_ERRORS;
  hasEntityErrors: boolean;
}

interface UpdateUploadStep {
  type: typeof UPDATE_UPLOAD_STEP;
  uploadStep: number;
}

interface UpdateFileSpecs {
  type: typeof UPDATE_FILE_SPECS;
  fileSpecs: FileSpecs[];
}

interface UpdateUploadData {
  type: typeof UPDATE_UPLOAD_DATA;
  uploadData: UploadData[];
}
interface UpdateFilterByCustGroup {
  type: typeof UPDATE_FILTER_BY_CUSTOM_GROUP;
  filterByCustGroup: string;
}

interface UpdateCustomGroups {
  type: typeof UPDATE_UPLOAD_CUSTOM_GROUPS;
  customGroups: CustGroup[];
}

interface UpdateExistingCustGroups {
  type: typeof UPDATE_EXISTING_CUSTOM_GROUPS;
  existingCustGroups: ExistingCustGroup[];
}

interface UpdateSelectedDimGroups {
  type: typeof UPDATE_SELECTED_DIMENSION_GROUP;
  selectedDimGroup: string;
}

interface UpdateSelectedCustomGroupId {
  type: typeof UPDATE_SELECTED_CUSTOM_GROUP_ID;
  selectedCustomGroupId: string;
}
interface ResetUploadData {
  type: typeof RESET_UPLOAD_DATA;
}

export type CustomGroupsAction =
  | UpdateUploadStep
  | UpdateFileSpecs
  | UpdateFilterByCustGroup
  | UpdateCustomGroups
  | UpdateExistingCustGroups
  | UpdateHasEntityErrors
  | UpdateSelectedDimGroups
  | UpdateSelectedCustomGroupId
  | UpdateUploadData
  | ResetUploadData;

export const initialCustomGroupsState: CustomGroupsState = {
  uploadStep: 0,
  uploadData: [],
  fileSpecs: [],
  filterByCustGroup: '',
  customGroups: [],
  existingCustGroups: [],
  hasEntityErrors: false,
  selectedDimGroup: '',
  selectedCustomGroupId: '',
};

export const customGroupsReducer = (
  state: CustomGroupsState,
  action: CustomGroupsAction
): CustomGroupsState => {
  switch (action.type) {
    case UPDATE_UPLOAD_STEP:
      return {
        ...state,
        uploadStep: action.uploadStep,
      };
    case UPDATE_UPLOAD_DATA:
      return {
        ...state,
        uploadData: action.uploadData,
      };
    case UPDATE_FILE_SPECS:
      return {
        ...state,
        fileSpecs: action.fileSpecs,
      };
    case UPDATE_FILTER_BY_CUSTOM_GROUP:
      return {
        ...state,
        filterByCustGroup: action.filterByCustGroup,
      };
    case UPDATE_UPLOAD_CUSTOM_GROUPS:
      return {
        ...state,
        customGroups: action.customGroups,
      };
    case UPDATE_HAS_ENTITY_ERRORS:
      return {
        ...state,
        hasEntityErrors: action.hasEntityErrors,
      };
    case UPDATE_EXISTING_CUSTOM_GROUPS:
      return {
        ...state,
        existingCustGroups: action.existingCustGroups,
      };
    case UPDATE_SELECTED_DIMENSION_GROUP:
      return {
        ...state,
        selectedDimGroup: action.selectedDimGroup,
      };
    case UPDATE_SELECTED_CUSTOM_GROUP_ID:
      return {
        ...state,
        selectedCustomGroupId: action.selectedCustomGroupId,
      };
    case RESET_UPLOAD_DATA:
      return initialCustomGroupsState;
  }
};
