import type { FunctionComponent } from 'react';
import { useContext, useEffect, useState } from 'react';
import { Dropdown, Heading, Search } from '@carbon/react';
import { AppContext } from '../../../providers/AppProvider';
import { HierarchyTreeContext } from '../../../providers/HierarchyTreeProvider';
import { useDebounce } from '../../../hooks';
import { getHierarchyApiUrl } from '../../../utils/reportUtils';
import Fetch from '../../Fetch';
import DatePicker from '../DatePicker';
import HierarchyTree from './HierarchyTree';
import type { SelectionObject } from '../ReportBuilder';
import {
  getFavouriteNodes,
  getUpdatedSelection,
  isSelectionEmpty,
  showHierarchyTree,
} from '../utils/hierarchyUtils';
import TransactionSpend from '../TransactionSpend';
import { CACHE_KEY } from '../../../constants/api';
import usePosthog from '../../../utils/posthog';
import {
  reportBuilderClearAllNodes,
  reportBuilderHierarchyDropdown,
  reportBuilderHierarchyNodeSelection,
  reportBuilderSearchClick,
} from '../../../constants/posthog';
import NoCustomGroupsPlaceholder from '../../CustomGroups/NoCustomGroupsPlaceholder/NoCustomGroupsPlaceholder';

interface HierarchyProps {
  selectedDimension: Dimension;
  selectedHierarchy: Hierarchy;
  setSelectedHierarchy: (hierarchy: Hierarchy) => void;
  selection: SelectionObject;
  setSelection: (selection: SelectionObject) => void;
  date: SelectedDate[];
  setDate: (date: SelectedDate[]) => void;
  spendBands: SpendBands;
  setSpendBands: (spendBands: SpendBands) => void;
  includeUpperBand: boolean;
  setIncludeUpperBand: (includeUpperBand: boolean) => void;
  deleteFavourite: (
    type: 'group' | 'item',
    name: string,
    parentName?: string
  ) => Promise<void>;
}

const Hierarchy: FunctionComponent<HierarchyProps> = ({
  selectedDimension,
  selectedHierarchy,
  setSelectedHierarchy,
  selection,
  setSelection,
  date,
  setDate,
  spendBands,
  setSpendBands,
  includeUpperBand,
  setIncludeUpperBand,
  deleteFavourite,
}) => {
  const [isDefaultSet, setIsDefaultSet] = useState(false);
  const { clearTreeState } = useContext(HierarchyTreeContext);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const debouncedSearchTerm: string = useDebounce<string>(searchTerm, 500);
  const {
    bannerId,
    groupId,
    calendars,
    hierarchies,
    user,
    favouriteGroups,
    updateCalendarData,
    updateHierarchyData,
    updateFavouriteGroups,
  } = useContext(AppContext);
  const posthogEvent = usePosthog();
  const hierarchy = hierarchies[selectedHierarchy.id]?.hierarchy;
  const levels = hierarchies[selectedHierarchy.id]?.hierarchy_levels;
  const hasNoCustomGroups =
    ['products-custom', 'stores-custom'].includes(selectedHierarchy.id) &&
    hierarchy?.length === 0;

  const updateSelection = (node: HierNode) => {
    const items = getUpdatedSelection(selection, node);
    posthogEvent(reportBuilderHierarchyNodeSelection, {
      id: node.id,
      label: node.label,
      level: node.level,
      selections: items.map((item) => item.id),
    });
    setSelection({ ...selection, [node.level]: items });
  };
  const removeAllSelection = () => {
    setSelection({});
    clearTreeState();
    posthogEvent(reportBuilderClearAllNodes);
  };

  const updateSelections = (level: string, items: HierNode[]) => {
    const existingSelection = selection[level] ? [...selection[level]] : [];
    const newSelection = { ...selection, [level]: existingSelection };

    newSelection[level] = [...newSelection[level], ...items];
    setSelection({ ...newSelection });
  };

  useEffect(() => {
    if (calendars['date-range'] && !isDefaultSet) {
      const dates: SelectedDate[] = [
        // set the default selection to the max date
        {
          startDate: new Date(calendars['date-range'][0].to),
          endDate: new Date(calendars['date-range'][0].to),
          key: 'selection',
        },
      ];
      setIsDefaultSet(true);
      setDate(dates);
    }
  }, [calendars]);

  const handleOnChange = (value: string) => {
    const validate = value.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    setSearchTerm(validate);
  };

  const searchTermValid =
    debouncedSearchTerm.length === 0 || debouncedSearchTerm.length > 3;

  const renderSearchBar = (): boolean => {
    const hasHierarchy = Array.isArray(hierarchy) && hierarchy.length > 0;
    return hasHierarchy && hierarchy.every((h) => !!h.children);
  };

  useEffect(() => {
    setSearchTerm('');
    clearTreeState();
  }, [selectedDimension, selectedHierarchy]);

  const loadCalendarData = (data: { start_date: string; end_date: string }) => {
    const { start_date, end_date } = data;
    if (start_date && end_date) {
      const dates: FormattedDate = {
        from: start_date,
        to: end_date,
        hierarchy_element: 'date_id',
      };

      updateCalendarData(selectedHierarchy.id, [dates]);
    }
  };

  const renderHierarchyContent = () => {
    switch (selectedHierarchy.id) {
      case 'date-range': {
        return (
          calendars['date-range'] && (
            <DatePicker date={date} setDate={setDate} />
          )
        );
      }
      case 'transaction-spend': {
        return (
          <TransactionSpend
            spendBands={spendBands}
            setSpendBands={setSpendBands}
            includeUpperBand={includeUpperBand}
            setIncludeUpperBand={setIncludeUpperBand}
          />
        );
      }
      default: {
        return showHierarchyTree(hierarchies, selectedHierarchy) ? (
          <>
            {renderSearchBar() && (
              <Search
                id="hierarchy-search"
                placeholder="Search"
                labelText="Search"
                type="text"
                className={`hierarchy-search ${
                  !searchTermValid ? 'invalid' : ''
                }`}
                onChange={({ target }) => handleOnChange(target.value)}
                onClick={() => posthogEvent(reportBuilderSearchClick)}
              />
            )}
            {!searchTermValid && (
              <Heading className="hierarchy-search-error">
                Search term should be longer than 3 characters
              </Heading>
            )}
            <div className="hierarchy-clear-all-button">
              <Fetch
                key={selectedHierarchy.id}
                apiUrl={`/users/${encodeURIComponent(
                  user.id
                )}/report-params/favourites?tdp_id=${bannerId}&user_group=${groupId}`}
                initialData={null}
                loadingMessage="Loading favourites"
                cacheKey={CACHE_KEY.HIERARCHIES_FAVOURITES}
                onReceiveData={(data) => {
                  if (data) {
                    updateFavouriteGroups(data);
                  }
                }}
              />
              {!isSelectionEmpty(selection) ? (
                <div
                  onClick={removeAllSelection}
                  data-testid="hierarchy-clear-all-button"
                  className="clear-all clickable hoverable"
                >
                  Clear all
                </div>
              ) : null}
            </div>
            <HierarchyTree
              nodes={[
                ...getFavouriteNodes(
                  favouriteGroups,
                  selectedHierarchy,
                  hierarchies,
                  bannerId,
                  groupId
                ),
                ...(hierarchies[selectedHierarchy.id].hierarchy as HierNode[]),
              ]}
              searchTerm={debouncedSearchTerm}
              selectedHierarchy={selectedHierarchy}
              selection={selection}
              updateSelection={updateSelection}
              updateSelections={updateSelections}
              deleteFavourite={deleteFavourite}
              levels={levels}
            />
          </>
        ) : null;
      }
    }
  };

  return (
    <div className="hierarchy-controls">
      <Dropdown
        id="hierarchy-dropdown"
        data-testid="hierarchy-dropdown"
        label={selectedHierarchy.name}
        selectedItem={selectedHierarchy.name}
        items={selectedDimension.hierarchies.map(({ name }) => name)}
        onChange={({ selectedItem }) => {
          posthogEvent(reportBuilderHierarchyDropdown, {
            selectedItem,
          });
          const hierarchy = selectedDimension.hierarchies.find(
            (h) => h.name === selectedItem
          );
          setSelectedHierarchy(hierarchy);
        }}
      />

      <Fetch
        key={selectedHierarchy.id}
        apiUrl={getHierarchyApiUrl(
          selectedHierarchy,
          bannerId,
          groupId,
          user.user_type
        )}
        initialData={null}
        loadingMessage={`Loading ${selectedHierarchy.name}...`}
        cacheKey={`${CACHE_KEY.HIERARCHIES}-${selectedHierarchy.id}`}
        onReceiveData={(data) => {
          if (data) {
            if (selectedHierarchy.id === 'date-range') {
              loadCalendarData(data);
            } else {
              updateHierarchyData(selectedHierarchy.id, data);
            }
          }
        }}
      >
        {hasNoCustomGroups ? (
          <NoCustomGroupsPlaceholder />
        ) : (
          renderHierarchyContent()
        )}
      </Fetch>
    </div>
  );
};

export default Hierarchy;
