import type { FunctionComponent, ReactNode } from 'react';
import { getPresentedColour } from '../../utils/colour';

interface CardHeaderProps {
  title: string;
  icon?: ReactNode;
  iconColour?: string;
  iconBackgroundColour?: string;
}

const CardHeader: FunctionComponent<CardHeaderProps> = ({
  icon,
  title,
  iconColour,
  iconBackgroundColour,
}) => {
  return (
    <div className="CardHeader__container">
      {icon && (
        <div
          className="CardHeader__icon"
          style={{
            color: getPresentedColour(iconColour),
            backgroundColor: getPresentedColour(iconBackgroundColour),
          }}
        >
          {icon}
        </div>
      )}
      <div className="CardHeader__title">{title}</div>
    </div>
  );
};

export default CardHeader;
