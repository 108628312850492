import type { FunctionComponent, ReactNode, RefObject } from 'react';
import { createContext, useEffect, useMemo, useReducer, useRef } from 'react';
import type {
  ReportConfig,
  ReportState,
  VisualData,
} from '../reducers/ReportReducer';
import { initialReportState, reportReducer } from '../reducers/ReportReducer';
import {
  TOGGLE_VISUAL_FULLSCREEN,
  UPDATE_DROPDOWN_SELECTIONS,
  UPDATE_FULL_SCREEN_REF,
  UPDATE_REPORT_CONFIG,
  UPDATE_SHARED_DROPDOWNS,
  UPDATE_TAB_INDEX,
  UPDATE_VISUALS_DATA,
  UPDATE_SINGLE_SELECTION,
  UPDATE_TREE_CHART,
  UPDATE_DATA_FILES,
  UPDATE_FILE_DROPDOWN_SELECTION,
  UPDATE_SECONDARY_AXIS_SELECTION,
  TOGGLE_SECONDARY_AXIS,
  UPDATE_FILES_DROPDOWN_SELECTIONS,
  RESET_ALL_REPORT_DATA,
  SET_VISUAL_CONTROLS_REF,
  PATCH_VISUALS_DATA,
} from '../constants/reducers';

export interface ReportContextType extends ReportState {
  updateTabIndex: (tabIndex: number) => void;
  updateReportConfig: (reportConfig: ReportConfig) => void;
  updateVisualsData: (id: number | string, data: unknown) => void;
  toggleVisualFullscreen: (id: number | null) => void;
  updateSingleSelection: (
    visualIndex: number | string,
    visualId: number | string,
    dropdownKey: string
  ) => void;
  updateDropdownSelections: (
    visualIndex: number | string,
    dropdownId: number,
    selectedItemKey: number | string
  ) => void;
  updateSharedDropdowns: (
    tabId: number | string,
    dropdownConfigs: DropdownConfig[]
  ) => void;
  updateFullscreenRef: (ref: RefObject<HTMLDivElement>) => void;
  updateTreeCharts: (id: number, chart: TreeChart) => void;
  updateDataFiles: (
    id: number | string,
    data: VisualData,
    dropdownKey: string
  ) => void;
  updateFileDropdownSelection: (id: number | string, newKey: string) => void;
  updateSecondaryAxisSelection: (
    id: string | number,
    selection: DropdownOptions
  ) => void;
  toggleSecondaryAxis: (id: string | number, isActive: boolean) => void;
  updateFilesDropdownSelections: (
    dropdownIndex: number,
    selectedItemKey: number,
    visualIds: number[]
  ) => void;
  setVisualControlsRef: (
    id: string | number,
    ref: RefObject<HTMLDivElement>
  ) => void;
  resetAllReportData: () => void;
  patchVisualsData: (id: number | string, data: Partial<VisualData>) => void;
}

interface ReportProviderProps {
  children?: ReactNode;
}

export const ReportContext = createContext<ReportContextType | null>(null);

const ReportProvider: FunctionComponent<ReportProviderProps> = ({
  children,
}) => {
  const [state, dispatch] = useReducer(reportReducer, initialReportState);

  const updateTabIndex = (tabIndex: number) =>
    dispatch({ type: UPDATE_TAB_INDEX, tabIndex });

  const updateReportConfig = (reportConfig: ReportConfig) => {
    dispatch({ type: UPDATE_REPORT_CONFIG, reportConfig });
  };

  const updateVisualsData = (id: number | string, data: VisualData) =>
    dispatch({ type: UPDATE_VISUALS_DATA, id, data });

  const patchVisualsData = (id: number | string, data: Partial<VisualData>) =>
    dispatch({ type: PATCH_VISUALS_DATA, id, data });

  const updateSingleSelection = (
    visualIndex: number | string,
    visualId: number | string,
    dropdownKey: string
  ) =>
    dispatch({
      type: UPDATE_SINGLE_SELECTION,
      visualIndex,
      visualId,
      dropdownKey,
    });

  const updateDropdownSelections = (
    visualIndex: number | string,
    dropdownId: number,
    selectedItemKey: number | string
  ) =>
    dispatch({
      type: UPDATE_DROPDOWN_SELECTIONS,
      visualIndex,
      dropdownId,
      selectedItemKey,
    });

  const updateSharedDropdowns = (
    tabId: number | string,
    dropdownConfigs: DropdownConfig[]
  ) => {
    dispatch({
      type: UPDATE_SHARED_DROPDOWNS,
      tabId,
      dropdownConfigs,
    });
  };

  const toggleVisualFullscreen = (visualId: number) => {
    dispatch({ type: TOGGLE_VISUAL_FULLSCREEN, visualId });
  };

  const updateFullscreenRef = (ref: RefObject<HTMLDivElement>) =>
    dispatch({ type: UPDATE_FULL_SCREEN_REF, ref });

  const updateTreeCharts = (id: number, chart: TreeChart) => {
    dispatch({ type: UPDATE_TREE_CHART, id, chart });
  };

  const updateDataFiles = (
    id: number | string,
    data: VisualData,
    dropdownKey: string
  ) => {
    dispatch({ type: UPDATE_DATA_FILES, id, data, dropdownKey });
  };

  const updateFileDropdownSelection = (id: number | string, newKey: string) => {
    dispatch({
      type: UPDATE_FILE_DROPDOWN_SELECTION,
      id,
      newKey,
    });
  };
  const updateFilesDropdownSelections = (
    dropdownIndex: number,
    selectedItemKey: number,
    visualIds: number[]
  ) => {
    dispatch({
      type: UPDATE_FILES_DROPDOWN_SELECTIONS,
      dropdownIndex,
      selectedItemKey,
      visualIds,
    });
  };
  const updateSecondaryAxisSelection = (
    id: string | number,
    selection: DropdownOptions
  ) => {
    dispatch({ type: UPDATE_SECONDARY_AXIS_SELECTION, selection, id });
  };
  const toggleSecondaryAxis = (id: string | number, isActive: boolean) => {
    dispatch({ type: TOGGLE_SECONDARY_AXIS, isActive, id });
  };

  const setVisualControlsRef = (
    id: string | number,
    ref: RefObject<HTMLDivElement>
  ) => {
    dispatch({ type: SET_VISUAL_CONTROLS_REF, id, ref });
  };

  const resetAllReportData = () => {
    dispatch({ type: RESET_ALL_REPORT_DATA });
  };

  useEffect(() => {
    if (
      state.fullscreenVisual !== null &&
      !document.fullscreenElement &&
      state.fullScreenRef?.current
    ) {
      state.fullScreenRef?.current.requestFullscreen();
    } else if (state.fullscreenVisual === null && document.fullscreenElement) {
      document.exitFullscreen();
      updateFullscreenRef(null);
    }
  }, [state.fullscreenVisual, state.fullScreenRef]);

  const visualRefs = useRef({}) as RefObject<{ [key: string]: HTMLDivElement }>;

  const initialReportContext: ReportContextType = useMemo(() => {
    return {
      visualRefs,
      tabIndex: state.tabIndex,
      reportConfig: state.reportConfig,
      visualsData: state.visualsData,
      dropdownSelections: state.dropdownSelections,
      sharedDropdowns: state.sharedDropdowns,
      fullscreenVisual: state.fullscreenVisual,
      fullScreenRef: state.fullScreenRef,
      treeCharts: state.treeCharts,
      secondaryDropdownSelections: state.secondaryDropdownSelections,
      visualControlsRef: state.visualControlsRef,
      updateTabIndex,
      updateReportConfig,
      updateVisualsData,
      toggleVisualFullscreen,
      updateSingleSelection,
      updateDropdownSelections,
      updateSharedDropdowns,
      updateFullscreenRef,
      updateTreeCharts,
      updateDataFiles,
      updateFileDropdownSelection,
      updateSecondaryAxisSelection,
      toggleSecondaryAxis,
      updateFilesDropdownSelections,
      setVisualControlsRef,
      resetAllReportData,
      patchVisualsData,
    };
  }, [state]);

  return (
    <ReportContext.Provider value={initialReportContext}>
      {children}
    </ReportContext.Provider>
  );
};

export default ReportProvider;
