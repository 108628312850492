import type { FunctionComponent } from 'react';
import { useState, useEffect, useContext } from 'react';
import {
  Checkbox,
  TextInput,
  Form,
  Button,
  InlineNotification,
} from '@carbon/react';
import { CustomGroupsContext } from '../../../../providers/CustomGroupsProvider';
import { custGroupsSeeItemsBtnClick } from '../../../../constants/posthog';
import usePosthog from '../../../../utils/posthog';
import type { EditCustomGroup } from './CustomGroupsUpload';

interface CustomGroupsUploadTransformProps {
  editCustomGroup: EditCustomGroup;
}

const CustomGroupsUploadTransform: FunctionComponent<
  CustomGroupsUploadTransformProps
> = ({ editCustomGroup }) => {
  const {
    uploadData,
    selectedDimGroup,
    customGroups,
    updateUploadStep,
    updateFilterByCustGroup,
    updateUploadData,
    updateCustomGroups,
    updateHasEntityErrors,
  } = useContext(CustomGroupsContext);
  const posthogEvent = usePosthog();
  const [allChecked, setAllChecked] = useState(true);

  useEffect(() => {
    const checkedGroups = customGroups.some((group) => group.isChecked);
    if (allChecked || checkedGroups) {
      updateHasEntityErrors(false);
    } else {
      updateHasEntityErrors(true);
    }

    updateHasEntityErrors(!allChecked && !checkedGroups);
    return () => {
      updateHasEntityErrors(false);
    };
  }, [allChecked, customGroups]);

  useEffect(() => {
    const filteredGroups = [
      ...new Set(uploadData.map(({ customGroup }) => customGroup)),
    ].map((customGroup) => ({
      id: uploadData.find((item) => item.customGroup === customGroup).id,
      customGroup,
      total: uploadData.filter((item) => item.customGroup === customGroup)
        .length,
      isChecked: true,
    }));
    customGroups.length === 0 && updateCustomGroups(filteredGroups);
    return () => {
      updateCustomGroups([]);
    };
  }, []);

  const handleCustGroupDisplay = (selectedGroup: string) => {
    posthogEvent(custGroupsSeeItemsBtnClick, {
      selectedGroup,
    });
    updateFilterByCustGroup(selectedGroup);
    updateUploadStep(1);
  };

  const handleCheckboxChange = (type, checked) => {
    const updateGroup = (group) => ({ ...group, isChecked: checked });

    const updatedCustomGroups = customGroups.map((group) => {
      return type === 'select-all' || group.customGroup === type
        ? updateGroup(group)
        : group;
    });

    const allChecked = updatedCustomGroups.every((group) => group.isChecked);
    setAllChecked(type === 'select-all' ? checked : allChecked);
    updateCustomGroups(updatedCustomGroups);
  };

  const handleUpdate = (customGroup: string, id: number, value: string) => {
    const updateData = (groups, condition) =>
      groups.map((group) =>
        condition(group) ? { ...group, customGroup: value } : group
      );
    updateUploadData(
      updateData(uploadData, (group) => group.customGroup === customGroup)
    );
    updateCustomGroups(
      updateData(
        customGroups,
        (group) => group.customGroup === customGroup && group.id === id
      )
    );
  };

  const editTotal = customGroups.reduce((sum, group) => sum + group.total, 0);

  return (
    <div className="CustomGroupsUploadValidator__upload-container">
      {editCustomGroup.isEditing ? (
        <>
          <div>{editCustomGroup.dim} Group Name</div>
          <div className="CustomGroupsUploadTransform-edit-container">
            <span className="CustomGroupsUploadTransform-edit-name">
              {editCustomGroup.name}
            </span>
            <Button
              data-testid="see-items-table-btn"
              size="sm"
              kind="secondary"
              onClick={() => handleCustGroupDisplay('')}
            >
              See {editTotal} Items
            </Button>
          </div>
        </>
      ) : (
        <>
          <div className="CustomGroupsUploadTransform">
            Here you can rename each group and select which one you would like
            to create.
          </div>
          <InlineNotification
            kind="info"
            hideCloseButton
            title="Assigning the same name to multiple groups will combine the groups."
            lowContrast
            className="CustomGroupsUploadTransform-notification"
          />
          <div className="CustomGroupsUploadTransform__checkbox">
            <Checkbox
              key="key"
              labelText="Select All Groups"
              checked={allChecked}
              data-testid="custom-groups-upload-select-all-checkbox"
              className="filters-checkbox"
              id="select-all"
              onChange={(_, { checked }) =>
                handleCheckboxChange('select-all', checked)
              }
            />
          </div>
          {customGroups.map(({ customGroup, total, isChecked, id }) => (
            <div key={id}>
              <Checkbox
                key={`key-${id}`}
                labelText={`${selectedDimGroup} Group Name`}
                checked={isChecked}
                data-testid="custom-group-checkbox"
                className="filters-checkbox"
                id={`checkbox-${customGroup}`}
                onChange={(_, { checked }) =>
                  handleCheckboxChange(customGroup, checked)
                }
              />
              <div className="CustomGroupsUploadTransform__form-wrapper">
                <Form className="CustomGroupsUploadTransform__form">
                  <TextInput
                    data-testid="custom-group-change-input"
                    className="CustomGroupsUploadTransform__input"
                    id={id}
                    labelText=""
                    value={customGroup}
                    size="md"
                    invalid={!customGroup}
                    invalidText="Group must have a name"
                    onChange={({ target: { value } }) =>
                      handleUpdate(customGroup, id, value)
                    }
                  />
                  <Button
                    data-testid="see-items-table-btn"
                    size="sm"
                    kind="secondary"
                    onClick={() => handleCustGroupDisplay(customGroup)}
                  >
                    See {total} Items
                  </Button>
                </Form>
              </div>
            </div>
          ))}
        </>
      )}
    </div>
  );
};
export default CustomGroupsUploadTransform;
