/* eslint-disable indent */
import type { FunctionComponent } from 'react';
import { useContext, useState } from 'react';
import { TextInput } from '@carbon/react';
import { DateRange } from 'react-date-range';
import { DateTime } from 'luxon';
import { AppContext } from '../../providers/AppProvider';
import {
  daysToMonthStart,
  daysToYearStart,
  getHistoricalDate,
} from '../../utils/DateUtils';
import 'react-date-range/dist/styles.css';
import '../../styles/components/datePicker.scss';
import usePosthog from '../../utils/posthog';
import {
  reportBuilderLatestDateClick,
  reportBuilderMonthSelectedClick,
  reportBuilderWeeksSelectedClick,
  reportBuilderYearOnYearClick,
  reportBuilderYearSelectedClick,
} from '../../constants/posthog';

interface DatePickerProps {
  date: SelectedDate[];
  setDate: (date: SelectedDate[]) => void;
}
const DatePicker: FunctionComponent<DatePickerProps> = ({ date, setDate }) => {
  const { calendars } = useContext(AppContext);
  const posthogEvent = usePosthog();
  const [recency, setRecency] = useState(4);

  const selectMostRecent = () => {
    const newDate: SelectedDate = {
      ...date[0],
      startDate: new Date(calendars['date-range'][0].to),
      endDate: new Date(calendars['date-range'][0].to),
    };
    posthogEvent(reportBuilderLatestDateClick);
    setDate([newDate]);
  };

  const goBackBy = (days: number) => {
    const newStartDate = getHistoricalDate({ days }, date[0].endDate);
    const newDates: SelectedDate = {
      ...date[0],
      startDate: newStartDate,
    };
    setDate([newDates]);
  };

  const selectYoY = () => {
    const newStartDate = DateTime.fromJSDate(date[0].startDate)
      .minus({ weeks: 52 })
      .toJSDate();
    const newEndDate = DateTime.fromJSDate(date[0].endDate)
      .minus({ weeks: 52 })
      .toJSDate();

    const newDate: SelectedDate = {
      ...date[0],
      startDate: newStartDate,
      endDate: newEndDate,
    };

    setDate([newDate]);
  };

  const handleOnChange = (weeks: number) => {
    setRecency(weeks);
    goBackBy(weeks * 7 - 1);
  };

  return (
    <div className="calendar-container">
      <div className="quick-date-selection">
        <div
          className="date-selection body-03 clickable hoverable"
          onClick={() => selectMostRecent()}
          data-testid="most-recent"
        >
          Latest date
        </div>
        <div
          className="date-selection body-03 clickable hoverable"
          onClick={() => {
            const daysInSelectedWeeks = recency * 7 - 1;
            goBackBy(daysInSelectedWeeks);
          }}
          data-testid="x-weeks"
        >
          <div className="week-selector">
            <TextInput
              id="number-of-weeks"
              type="number"
              labelText="Weeks to go back"
              hideLabel
              min="1"
              value={recency}
              onChange={({ target }) => handleOnChange(target.value)}
              onClick={posthogEvent(reportBuilderWeeksSelectedClick, {
                weeks: recency,
              })}
            />
            weeks to selected date
          </div>
        </div>
        <div
          className="date-selection body-03 clickable hoverable"
          onClick={() => {
            posthogEvent(reportBuilderMonthSelectedClick);
            goBackBy(daysToMonthStart(date[0].endDate));
          }}
          data-testid="month-to-selected"
        >
          <div>Month to selected date</div>
        </div>
        <div
          className="date-selection body-03 clickable hoverable"
          onClick={() => {
            posthogEvent(reportBuilderYearSelectedClick);
            goBackBy(daysToYearStart(date[0].endDate));
          }}
          data-testid="year-to-selected"
        >
          <div>Year to selected date</div>
        </div>
        <div
          className="date-selection body-03 clickable hoverable"
          onClick={() => {
            posthogEvent(reportBuilderYearOnYearClick);
            selectYoY();
          }}
        >
          <div>Year on year</div>
        </div>
      </div>

      {calendars['date-range'] && (
        <DateRange
          ranges={date}
          showSelectionPreview
          editableDateInputs
          minDate={new Date(calendars['date-range'][0].from)}
          maxDate={new Date(calendars['date-range'][0].to)}
          onChange={({ selection }) => setDate([selection])}
          weekdayDisplayFormat="EEEEE"
          dateDisplayFormat="dd/MM/yyyy"
          preventSnapRefocus
          fixedHeight
        />
      )}
    </div>
  );
};

export default DatePicker;
