import type { FunctionComponent } from 'react';
import { DataTableSkeleton, SkeletonPlaceholder } from '@carbon/react';

const HomepageLoadingSkeleton: FunctionComponent = () => {
  return (
    <div
      className="HomepageLoadingSkeleton"
      data-testid="homepage-loading-skeleton"
    >
      <SkeletonPlaceholder className="HomepageLoadingSkeleton__cards" />
      <DataTableSkeleton showHeader={false} aria-label="data table skeleton" />
      <SkeletonPlaceholder className="HomepageLoadingSkeleton__visual-container" />
      <SkeletonPlaceholder className="HomepageLoadingSkeleton__visual-container" />
      <DataTableSkeleton showHeader={false} aria-label="data table skeleton" />
    </div>
  );
};

export default HomepageLoadingSkeleton;
