import type { FunctionComponent } from 'react';
import { useEffect, useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Draggable as DraggableIcon } from '@carbon/icons-react';
import type { CDTNodeData } from '../../../reducers/AssortmentReducer';
import { reorderList } from '../../../utils/CDTUtils';
import usePosthog from '../../../utils/posthog';
import { cdtDraggableNodeClick } from '../../../constants/posthog';

interface CDTReorderProps {
  nodeId: string | number;
  cdtData: CDTNodeData[];
  isAuthor: boolean;
  updateOrder: (cdtData: CDTNodeData[]) => void;
}

const CDTReorder: FunctionComponent<CDTReorderProps> = ({
  nodeId,
  cdtData,
  isAuthor,
  updateOrder,
}) => {
  const [draggables, setDraggables] = useState(cdtData);
  const posthogEvent = usePosthog();
  const onDragEnd = (result) => {
    posthogEvent(cdtDraggableNodeClick, {
      draggableId: result.draggableId,
      node: result.node,
      reportType: 'cdt',
    });
    if (!result.destination) {
      return;
    }

    const newItems = reorderList<CDTNodeData>(
      cdtData,
      result.source.index,
      result.destination.index
    );

    setDraggables(newItems);
  };

  useEffect(() => {
    updateOrder(draggables);
  }, [draggables]);

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided) => (
          <div
            ref={provided.innerRef}
            className="draggable-node-list"
            {...provided.droppableProps}
          >
            {draggables.map((item, index) => (
              <Draggable
                key={item.id}
                draggableId={item.id as string}
                index={index}
                isDragDisabled={!isAuthor}
              >
                {(provided) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    className={`draggable-node dragging-${item.id === nodeId}`}
                  >
                    <DraggableIcon />
                    {item.name}
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default CDTReorder;
