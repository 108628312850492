import type { FunctionComponent } from 'react';
import { useContext } from 'react';
import { Tabs, Tab, TabList } from '@carbon/react';
import { AppContext } from '../../providers/AppProvider';
import {
  getDimensionIcons,
  getSubmitDimensionIcons,
} from './utils/reportBuilderUtils';
import { dimensionTabClick } from '../../constants/posthog';
import usePosthog from '../../utils/posthog';

interface DimensionsProps {
  dimensions: Dimension[];
  selectedDimension: Dimension;
  setSelectedDimension: (dimension: Dimension) => void;
  showSubmit: boolean;
  showAdvanced: boolean;
  setShowSubmit: (showSubmit: boolean) => void;
  setShowAdvanced: (showAdvanced: boolean) => void;
  success: boolean;
  advancedTab: boolean;
}

const Dimensions: FunctionComponent<DimensionsProps> = ({
  dimensions,
  selectedDimension,
  setSelectedDimension,
  showAdvanced,
  showSubmit,
  setShowSubmit,
  setShowAdvanced,
  advancedTab,
  success,
}) => {
  const { reportParameters } = useContext(AppContext);
  const posthogEvent = usePosthog();

  return (
    <div className="dimensions-container">
      <Tabs>
        <TabList aria-label="List of Dimensions">
          {dimensions.map((dimension) => {
            return (
              <Tab
                key={dimension.dimension}
                className="hoverable"
                onClick={() => {
                  posthogEvent(dimensionTabClick, {
                    dimension: dimension.dimension,
                  });
                  setSelectedDimension(dimension);
                }}
              >
                {getDimensionIcons(
                  dimension,
                  reportParameters,
                  selectedDimension
                )}
                <span className="dimension-label">{dimension.dimension}</span>
              </Tab>
            );
          })}
          <Tab
            data-testid="advanced-report-options"
            key="advanced-report-options"
            tabIndex={0}
            className="hoverable"
            style={!advancedTab ? { display: 'none' } : {}}
            onClick={() => {
              posthogEvent(dimensionTabClick, {
                dimension: 'advanced',
              });
              setShowAdvanced(true);
              setShowSubmit(false);
            }}
          >
            {getSubmitDimensionIcons(success, showAdvanced)}
            <span className="dimension-label">Advanced</span>
          </Tab>

          <Tab
            key="submit-report"
            tabIndex={1}
            className="hoverable"
            onClick={() => {
              posthogEvent(dimensionTabClick, {
                dimension: 'submit-report',
              });
              setShowSubmit(true);
              setShowAdvanced(false);
            }}
          >
            {getSubmitDimensionIcons(success, showSubmit)}
            <span className="dimension-label">Submit report</span>
          </Tab>
        </TabList>
      </Tabs>
    </div>
  );
};

export default Dimensions;
