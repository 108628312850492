import type { HierarchyTreeState } from '../providers/HierarchyTreeProvider';

interface UpdateNodeState {
  type: 'UPDATE_NODE_STATE';
  nodeId: string;
  expandState: boolean;
}

interface ClearState {
  type: 'CLEAR_STATE';
}

export const initialHierarchyTreeState: HierarchyTreeState = {};

export type HierarchyTreeAction = UpdateNodeState | ClearState;

const hierarchyTreeReducer = (
  state: HierarchyTreeState,
  action: HierarchyTreeAction
): HierarchyTreeState => {
  switch (action.type) {
    case 'UPDATE_NODE_STATE':
      return { ...state, [action.nodeId]: action.expandState };
    case 'CLEAR_STATE':
      return {};
  }
};

export default hierarchyTreeReducer;
