import type { ChangeEvent, FunctionComponent } from 'react';
import { useContext, useEffect, useState } from 'react';
import {
  Button,
  ModalBody,
  ModalHeader,
  ModalFooter,
  FileUploaderDropContainer,
  FileUploaderItem,
  InlineLoading,
} from '@carbon/react';
import { UserMultiple, Download, Upload } from '@carbon/icons-react';
import { ModalContext } from '../../../providers/ModalProvider';
import type { FileSpecs } from '../../../reducers/CustomGroupsReducer';
import { read, utils } from 'xlsx-js-style';
import type {
  AuthenticationType,
  USER_ROLE,
  USER_TYPE,
} from '../../../constants/metadata';
import { USER_DATA_REQUIRED_FIELDS } from '../../../constants/metadata';
import type {
  PayloadUserData,
  UserDataConfig,
} from '../../../reducers/AdminPanelReducer';
import {
  generateUserTemplateExample,
  isUploadedUserRowValid,
  mapUploadedData,
} from '../../../utils/adminPanelUtils';
import apiRequest, { ERROR_TYPE } from '../../../api';
import { useAuth0 } from '@auth0/auth0-react';
import { AppContext } from '../../../providers/AppProvider';
import { CACHE_KEY } from '../../../constants/api';
import { useNavigate } from 'react-router-dom';
import { pick } from '../../../utils/object';
import type { ResponsePayload } from '../../Fetch';
import type { UserUploadResponse } from '../../../types/apiTypes';

export type MeasureAccessOptions = 'Y' | 'N';

export interface UploadedUserData {
  name: string;
  email: string;
  banner: string;
  user_group: string;
  user_role: USER_ROLE;
  user_type: USER_TYPE;
  measure_access: MeasureAccessOptions;
  authentication: AuthenticationType;
  department?: string;
  job_title?: string;
  supplier_name?: string;
}

interface BulkUserInviteModalProps {
  readonly dataConfig: UserDataConfig;
}

const TEMPLATE_ERROR_MESSAGE =
  'Files does not match template, please download the template and fill in accordingly.';

const BulkUserInviteModal: FunctionComponent<BulkUserInviteModalProps> = ({
  dataConfig,
}) => {
  const { clearCacheForKey } = useContext(AppContext);
  const { getAccessTokenSilently } = useAuth0();
  const { open, toggleModal, updateModal } = useContext(ModalContext);
  const [fileSpecs, setFileSpecs] = useState<FileSpecs[]>([]);
  const [uploadedUsers, setUploadedUsers] = useState<PayloadUserData[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const updateFileSpecs = ({
    fileInfo,
    invalid = false,
    iconDescription,
    errorSubject,
  }: {
    fileInfo?: Pick<File, 'name' | 'type' | 'size'>;
    invalid?: boolean;
    iconDescription?: string;
    errorSubject?: string;
  }) => {
    setFileSpecs((prev) => [
      {
        ...prev[0],
        ...fileInfo,
        status: 'edit',
        invalid,
        iconDescription: iconDescription ?? errorSubject,
        errorSubject,
      },
    ]);
  };

  const resetUploadedData = () => {
    setFileSpecs([]);
    setUploadedUsers([]);
  };

  useEffect(() => {
    if (!open) {
      resetUploadedData();
    }
  }, [open]);

  const convertFile = (
    file: File,
    successCallback: (users: UploadedUserData[]) => void
  ) => {
    const fileInfo = pick(file, ['name', 'type', 'size']);
    if (!file) {
      return;
    }
    const reader = new FileReader();
    reader.onload = (e) => {
      const { result } = e.target;
      const workbook = read(result, {
        type: 'binary',
      });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const worksheetData = utils.sheet_to_json<string[]>(worksheet, {
        header: 1,
      });
      const columnHeaders = worksheetData[0].map((h) => h.toLowerCase());
      const isColumnHeaderValid = USER_DATA_REQUIRED_FIELDS.every((header) =>
        columnHeaders.includes(header)
      );
      const fileData = utils.sheet_to_json<UploadedUserData>(worksheet, {
        blankrows: true,
        defval: '',
      });
      if (
        isColumnHeaderValid &&
        fileData.every((row) => isUploadedUserRowValid(row, dataConfig))
      ) {
        successCallback(fileData);
        updateFileSpecs({
          fileInfo,
          iconDescription: 'Upload complete',
        });
      } else {
        updateFileSpecs({
          fileInfo,
          invalid: true,
          errorSubject: TEMPLATE_ERROR_MESSAGE,
        });
      }
    };
    reader.onerror = () => {
      updateFileSpecs({
        fileInfo,
        invalid: true,
        errorSubject: 'Error processing file, please try again.',
      });
    };
    reader.readAsArrayBuffer(file);
  };

  const onAddFiles = (
    e: ChangeEvent<HTMLInputElement>,
    { addedFiles }: { addedFiles: File[] }
  ) => {
    setUploadedUsers([]);
    e.stopPropagation();
    if (addedFiles.length > 0) {
      const file = addedFiles[0];
      const fileInfo = pick(file, ['name', 'type', 'size']);
      if (
        file.type ===
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
        file.type === 'text/csv'
      ) {
        if (file.size >= 6291456) {
          updateFileSpecs({
            fileInfo,
            invalid: true,
            iconDescription: 'Upload complete',
            errorSubject: 'Please Upload a smaller file size below 6MB',
          });
        } else {
          convertFile(file, (result) => {
            if (result.length > 0) {
              setUploadedUsers(mapUploadedData(result, dataConfig.banners));
            }
          });
        }
      } else {
        updateFileSpecs({
          fileInfo,
          invalid: true,
          errorSubject:
            'Unsupported file type. Please upload an XLSX or CSV file.',
        });
      }
    }
  };

  const dragDropMessage = (
    <div className="BulkUserInviteModal__drag-and-drop-wrapper">
      <Upload size={20} className="BulkUserInviteModal__drag-and-drop-button" />
      <div>
        <span className="BulkUserInviteModal__drag-and-drop-info">
          Drag and drop file here or{' '}
          <span className="BulkUserInviteModal__drag-and-drop-info--highlighted">
            click to upload
          </span>
        </span>
      </div>
    </div>
  );

  const uploadUsers = async () => {
    setIsLoading(true);
    try {
      const token = await getAccessTokenSilently();
      const { data } = await apiRequest<ResponsePayload<UserUploadResponse>>(
        '/admin/users?batch=true',
        'POST',
        token,
        uploadedUsers
      );
      clearCacheForKey(CACHE_KEY.USER_LIST);
      if (data.error?.type === ERROR_TYPE.VALIDATION) {
        const nameErrors = data.error.messages.name
          ? `Name: ${data.error.messages.name.join('. ')}`
          : '';
        const emailErrors = data.error.messages.email
          ? `Email: ${data.error.messages.email.join('. ')}`
          : '';
        const errorSubject = `${data.message}\n${nameErrors}\n${emailErrors}`;
        updateFileSpecs({ invalid: true, errorSubject });
      } else {
        resetUploadedData();
        toggleModal(false);
      }
    } catch (error) {
      updateModal({
        type: 'error',
        title: 'Something went wrong',
        body: 'There was a problem inviting new users. Please try again, or refresh your browser.',
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <ModalHeader buttonOnClick={() => toggleModal(false)}>
        <div className="BulkUserInviteModal__header">
          <div className="BulkUserInviteModal__header-title">
            <UserMultiple size={20} />
            <div>Invite Multiple Team Members</div>
          </div>
          <p className="BulkUserInviteModal__header-description">
            Please download the template and specify the users names, email
            address, user role, restrictions and banner access.
          </p>
          <p className="BulkUserInviteModal__header-description">
            Once uploaded the names of the members will appear on the table.
          </p>
        </div>
      </ModalHeader>
      <ModalBody>
        <div className="flex justify-flex-end gap--medium">
          <Button
            kind="tertiary"
            className="BulkUserInviteModal__view-user-groups"
            size="md"
            onClick={() => {
              toggleModal(false);
              navigate('/admin-panel/users/user-groups');
            }}
          >
            View User Groups
          </Button>
          <Button
            kind="secondary"
            className="gap--medium"
            size="md"
            onClick={generateUserTemplateExample}
          >
            Download Template <Download />
          </Button>
        </div>
        <div className="BulkUserInviteModal__file-info">
          <p className="body-02">
            Supported file types are
            <span className="body-emphasis-02"> csv or xlsx.</span>
          </p>
          <p className="body-02">
            File Number & Size Limit:
            <span className="body-emphasis-02"> 1 File - Max 6MB</span>
          </p>
        </div>
        <FileUploaderDropContainer
          labelText={dragDropMessage}
          multiple={false}
          accept={['.csv', '.xlsx']}
          onAddFiles={onAddFiles}
          data-testid="upload-icon"
        />
        {fileSpecs.map(({ name, ...rest }) => (
          <FileUploaderItem
            {...rest}
            key={`file-uploader-item-${name}`}
            name={name}
            size="md"
            onDelete={resetUploadedData}
          />
        ))}
      </ModalBody>
      <ModalFooter className="BulkUserInviteModal__footer-container">
        <Button
          size="md"
          onClick={uploadUsers}
          disabled={
            fileSpecs[0]?.invalid || uploadedUsers.length === 0 || isLoading
          }
        >
          Upload Team Mates
          {isLoading && <InlineLoading />}
        </Button>
      </ModalFooter>
    </>
  );
};

export default BulkUserInviteModal;
