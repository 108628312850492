const VENN_SECTIONS = {
  1: {
    gridRowStart: 8,
    gridRowEnd: 8,
    gridColumnStart: 2,
    gridColumnEnd: 2,
  },
  2: {
    gridRowStart: 1,
    gridRowEnd: 1,
    gridColumnStart: 5,
    gridColumnEnd: 5,
  },
  3: {
    gridRowStart: 2,
    gridRowEnd: 2,
    gridColumnStart: 3,
    gridColumnEnd: 3,
  },
  4: {
    gridRowStart: 9,
    gridRowEnd: 9,
    gridColumnStart: 6,
    gridColumnEnd: 6,
  },
  5: {
    gridRowStart: 8,
    gridRowEnd: 8,
    gridColumnStart: 4,
    gridColumnEnd: 4,
  },
  6: {
    gridRowStart: 3,
    gridRowEnd: 3,
    gridColumnStart: 6,
    gridColumnEnd: 6,
  },
  7: {
    gridRowStart: 3,
    gridRowEnd: 3,
    gridColumnStart: 4,
    gridColumnEnd: 4,
  },
  8: {
    gridRowStart: 6,
    gridRowEnd: 6,
    gridColumnStart: 9,
    gridColumnEnd: 9,
  },
  9: {
    gridRowStart: 6,
    gridRowEnd: 6,
    gridColumnStart: 3,
    gridColumnEnd: 3,
  },
  10: {
    gridRowStart: 4,
    gridRowEnd: 4,
    gridColumnStart: 8,
    gridColumnEnd: 8,
  },
  11: {
    gridRowStart: 4,
    gridRowEnd: 4,
    gridColumnStart: 3,
    gridColumnEnd: 3,
  },
  12: {
    gridRowStart: 6,
    gridRowEnd: 6,
    gridColumnStart: 6,
    gridColumnEnd: 6,
  },
  13: {
    gridRowStart: 6,
    gridRowEnd: 6,
    gridColumnStart: 4,
    gridColumnEnd: 4,
  },
  14: {
    gridRowStart: 4,
    gridRowEnd: 4,
    gridColumnStart: 6,
    gridColumnEnd: 6,
  },
  15: {
    gridRowStart: 4,
    gridRowEnd: 4,
    gridColumnStart: 4,
    gridColumnEnd: 4,
  },
};

export default VENN_SECTIONS;
