import { SkeletonPlaceholder } from '@carbon/react';
import LoadingDropdown from './LoadingDropdown';

const HomeDropdownsSkeleton = () => {
  return (
    <div>
      <div className="HomeDropdowns__time-period">
        <SkeletonPlaceholder />
        <SkeletonPlaceholder />
      </div>
      <div className="flex gap--small">
        <LoadingDropdown className="home-filters--category" width="378px" />
        <LoadingDropdown className="home-filters--category" width="170px" />
        <LoadingDropdown className="home-filters--category" width="170px" />
        <LoadingDropdown className="home-filters--category" width="170px" />
        <LoadingDropdown className="home-filters--category" width="170px" />
      </div>
    </div>
  );
};

export default HomeDropdownsSkeleton;
