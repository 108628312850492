import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import {
  DataTable,
  Pagination,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableHeader,
  TableRow as CarbonTableRow,
  TableCell,
  TableRow,
  Search,
  Dropdown,
  Button,
} from '@carbon/react';
import { ChevronLeft, Information, FilterRemove } from '@carbon/icons-react';
import Fetch from '../../Fetch';
import type { UserDataConfigResponse } from '../../../reducers/AdminPanelReducer';
import { useStickyTableHeader } from '../../../utils/useStickyTableHeader';
import { usePagination } from '../../../hooks';
import { AppContext } from '../../../providers/AppProvider';
import NotFound from '../../NotFound';
import { useNavigate } from 'react-router-dom';
import NoDataPlaceholder from '../../ReportTable/NoDataPlaceholder';

interface UserGroupsRow {
  id: string;
  group_name: string;
  banner_id: number;
  banner_name: string;
}

interface FilterItem {
  value: string;
  name: string;
}

const headerData = [
  { key: 'id', header: 'User Group ID' },
  { key: 'group_name', header: 'Restriction Name' },
  { key: 'banner_name', header: 'Banner Name' },
];

const tableId = 'user-groups-table';

const UserGroupsTable = () => {
  const { showAdminControls } = useContext(AppContext);
  const [userGroupsRows, setUserGroupsRows] = useState<UserGroupsRow[]>([]);
  const [bannerList, setBannerList] = useState<FilterItem[]>([]);
  const [restrictionList, setRestrictionList] = useState<FilterItem[]>([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [bannerFilter, setBannerFilter] = useState<FilterItem>(null);
  const [restrictionFilter, setRestrictionFilter] = useState<FilterItem>(null);
  const navigate = useNavigate();
  const tableRef = useRef(null);

  const { isSticky, scrollbarRef } = useStickyTableHeader({
    rowsLength: userGroupsRows?.length,
    tableId,
  });

  const { page, pageSizes, pageSize, getItemRangeText, setPagination } =
    usePagination();

  useEffect(() => {
    if (!bannerFilter) {
      setBannerFilter(bannerList[0]);
    }
    if (!restrictionFilter) {
      setRestrictionFilter(restrictionList[0]);
    }
  }, [userGroupsRows]);

  const filteredUserGroups = useMemo(
    () =>
      userGroupsRows.filter(({ id, banner_name, group_name }) => {
        const groupIdCondition = id.toString().includes(searchQuery);
        const groupNameCondition =
          !restrictionFilter?.value || group_name === restrictionFilter.value;
        const bannerNameCondition =
          !bannerFilter?.value || banner_name === bannerFilter.value;

        return groupIdCondition && groupNameCondition && bannerNameCondition;
      }),
    [userGroupsRows, searchQuery, bannerFilter, restrictionFilter]
  );

  const isTableEmpty =
    filteredUserGroups.length === 0 && userGroupsRows.length > 0;

  if (!showAdminControls) {
    return <NotFound />;
  }

  return (
    <div className="UserGroupsTable">
      <div className="UserGroupsTable__header">
        <Button
          kind="ghost"
          iconDescription="Go back"
          renderIcon={ChevronLeft}
          data-testId="user-groups-back-button"
          hasIconOnly
          size="sm"
          onClick={() => navigate('/admin-panel/users')}
        />
        <div className="heading-05">User Groups</div>
      </div>
      <Fetch
        apiUrl="/admin/configs"
        initialData={null}
        onReceiveData={(data: UserDataConfigResponse) => {
          if (data) {
            const userGroups: UserGroupsRow[] = data.banners.flatMap(
              ({ id: banner_id, user_groups, name: banner_name }) => {
                return user_groups.map(({ id, name }) => ({
                  id: id.toString(),
                  group_name: name,
                  banner_id,
                  banner_name,
                }));
              }
            );
            const restrictions = Array.from(
              new Set(userGroups.map(({ group_name }) => group_name))
            );
            setUserGroupsRows(userGroups);
            setBannerList([
              { value: '', name: 'All Banners' },
              ...data.banners.map(({ name }) => ({ value: name, name })),
            ]);
            setRestrictionList([
              { value: '', name: 'Restriction All' },
              ...restrictions.map((name) => ({ value: name, name })),
            ]);
          }
        }}
        alwaysFetchOnMount={userGroupsRows.length === 0}
        hideChildrenUntilFetched
        loadingMessage="Loading user groups..."
      >
        <div className="UserGroupsTable__filters">
          <Search
            placeholder="Search groups"
            labelText=""
            data-testid="user-group-filter-input"
            className="UserGroupsTable__filters-search"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <Dropdown
            id="user-group-name"
            data-testid="user-group-name"
            titleText=""
            label="Select Option"
            className="UserGroupsTable__filters-dropdown"
            hideLabel
            items={restrictionList}
            selectedItem={restrictionFilter}
            itemToString={(item) => item.name}
            onChange={({ selectedItem }) => setRestrictionFilter(selectedItem)}
          />
          <Dropdown
            id="user-group-banner"
            data-testid="user-group-banner"
            titleText=""
            label="Select Option"
            className="UserGroupsTable__filters-dropdown"
            hideLabel
            items={bannerList}
            selectedItem={bannerFilter}
            itemToString={(item) => item.name}
            onChange={({ selectedItem }) => setBannerFilter(selectedItem)}
          />
        </div>
        {!isTableEmpty ? (
          <>
            <div id={tableId}>
              <DataTable
                rows={filteredUserGroups}
                headers={headerData}
                ref={tableRef}
              >
                {({
                  rows,
                  headers,
                  getHeaderProps,
                  getTableProps,
                  selectedRows,
                }) => {
                  return (
                    <TableContainer>
                      <div className="data-table-container">
                        <Table
                          {...getTableProps()}
                          className="UserGroupsTable__table"
                        >
                          <TableHead
                            data-testid="table-header"
                            className={`${
                              selectedRows.length === 0 ? 'rounded' : ''
                            }`}
                          >
                            <CarbonTableRow
                              className={
                                isSticky ? 'sticky-header' : 'visible-header'
                              }
                            >
                              {headers.map((header) => (
                                <TableHeader
                                  key={header.key}
                                  tabIndex={0}
                                  {...getHeaderProps({ header })}
                                  isSortable
                                >
                                  {header.header}
                                </TableHeader>
                              ))}
                            </CarbonTableRow>
                          </TableHead>
                          <TableBody>
                            {rows.map((row) => (
                              <TableRow
                                key={row.id}
                                data-testid={row.id}
                                tabIndex={0}
                              >
                                {row.cells.map((cell) => {
                                  return (
                                    <TableCell key={cell.id}>
                                      {cell.value}
                                    </TableCell>
                                  );
                                })}
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </div>
                    </TableContainer>
                  );
                }}
              </DataTable>
            </div>
            <div
              className="data-grid-scrollbar-container"
              data-testid="data-grid-scrollbar-container"
              ref={scrollbarRef}
            >
              <div />
            </div>
            <Pagination
              backwardText="Previous page"
              forwardText="Next page"
              itemsPerPageText="Items per page:"
              onChange={setPagination}
              page={page}
              pageSize={pageSize}
              pageSizes={pageSizes}
              itemRangeText={getItemRangeText}
              totalItems={userGroupsRows.length}
            />
          </>
        ) : (
          <NoDataPlaceholder
            title="No Groups Found"
            icon={Information}
            description={{
              info: 'Please refine your filters and try again.',
            }}
            buttonLabel="Clear Filters"
            onClick={() => {
              setBannerFilter(bannerList[0]);
              setRestrictionFilter(restrictionList[0]);
              setSearchQuery('');
            }}
            buttonIcon={FilterRemove}
          />
        )}
      </Fetch>
    </div>
  );
};

export default UserGroupsTable;
