import type { FunctionComponent } from 'react';
import '../../styles/components/crossmark.scss';

interface CrossmarkProps {
  animated?: boolean;
}

export const Crossmark: FunctionComponent<CrossmarkProps> = ({
  animated = true,
}) => {
  return (
    <svg
      className={`crossmark ${animated ? 'animated' : 'static'}`}
      data-testid="failed-crossmark"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 52 52"
    >
      <circle
        className="crossmark__circle"
        cx="24"
        cy="24"
        r="24"
        fill="none"
      />
      <path
        className="cross__path cross__path--right"
        fill="none"
        d="M16,16 l20,20"
      />
      <path
        className="cross__path cross__path--left"
        fill="none"
        d="M16,36 l20,-20"
      />
    </svg>
  );
};
