export enum CACHE_KEY {
  MODULES = 'modules',
  CUSTOM_GROUPS = 'custom-groups',
  NOTIFICATIONS = 'notifications',
  HIERARCHY_URLS = 'hierarchyUrls',
  HIERARCHIES = 'hierarchies',
  HIERARCHIES_FAVOURITES = 'hierarchies-favourites',
  DIMENSIONS = 'dimensions',
  REPORTS = 'reports',
  REPORTS_ARCHIVED = 'reports-archived',
  REPORTS_SHARED = 'reports-shared',
  REPORTS_USERS = 'reports-users',
  REPORT = 'report',
  KPI_TREE = 'kpi-tree',
  HIERARCHIES_CUSTOM_ATTRIBUTE = 'hierarchies-custom-attribute',
  CUSTOM_ATTRIBUTES = 'custom-attributes',
  HIERARCHIES_CUSTOM_PRODUCTS = 'hierarchies-products-custom',
  HIERARCHIES_CUSTOM_LOCATION = 'hierarchies-stores-custom',
  HOMEPAGE = 'homepage',
  HOMEPAGE_DROPDOWNS = 'homepage-dropdowns',
  USER_LIST = 'user-list',
  ADMIN_PANEL_CONFIG = 'admin-panel-config',
  GENAI_QUESTIONS = 'genai-questions',
  GENAI_FEEDBACK_ITEMS = 'genai-feedback-items',
  UPLOADED_CUSTOM_GROUPS = 'uploaded-custom-groups',
  VISUAL = 'visual',
}

export type VisualFileCacheKey = `${CACHE_KEY.VISUAL}-${string}`;

export type ReportConfigCacheKey = `${
  | CACHE_KEY.REPORT
  | CACHE_KEY.REPORTS_SHARED}-${string}`;

export type HierarchyCacheKey = `${CACHE_KEY.HIERARCHIES}-${string}`;

type ExtendedCacheKey =
  | CACHE_KEY.REPORT
  | CACHE_KEY.REPORTS_SHARED
  | CACHE_KEY.VISUAL
  | CACHE_KEY.HIERARCHIES;

export type DynamicCacheKey =
  | Exclude<CACHE_KEY, ExtendedCacheKey>
  | ReportConfigCacheKey
  | VisualFileCacheKey
  | HierarchyCacheKey;

export const MAX_RETRY_ATTEMPTS = 5;
