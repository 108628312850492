import type { ReactElement } from 'react';
import { DateTime } from 'luxon';
import { Link, InlineNotification } from '@carbon/react';
import {
  CheckmarkOutline,
  CircleFilled,
  RadioButton,
} from '@carbon/icons-react';
import apiRequest from '../../../api';
import type { ReportConfig } from '../../../reducers/ReportReducer';
import {
  EndsRecurrenceType,
  RepeatPeriod,
  RUN_ID_LENGTH,
} from '../../../constants/metadata';
import { filterExtraParams, formatNumber } from '../../../utils/reportUtils';
import type { ResponsePayload } from '../../Fetch';

interface AllGroupings {
  [key: string]: Record<string, ReportParameter[]>;
}

export const transformParams = (params: ReportParameters) => {
  const groupings: AllGroupings = {
    groups: {},
    filters: {},
    universes: {},
    time: {},
  };
  const filteredParams = filterExtraParams(params);
  Object.keys(filteredParams).forEach((key) => {
    const parts = key.split('_');
    const dimension = parts.slice(0, parts.length - 1).join('_');
    const parameter = parts[parts.length - 1];

    if (key === 'time_groups') {
      if (!groupings[dimension]) groupings[dimension] = {};

      (groupings[dimension] as ReportParameters)[parameter] =
        filteredParams[key];
    } else {
      if (!groupings[parameter]) groupings[parameter] = {};

      (groupings[parameter] as ReportParameters)[dimension] =
        filteredParams[key];
    }
  });

  return groupings;
};

export const datesSameDuration = (timeGroups: ReportParameter[]): boolean => {
  if (!timeGroups.length) {
    return true;
  }

  const durations: number[] = timeGroups.map((group) => {
    return DateTime.fromISO(group.to).diff(DateTime.fromISO(group.from), [
      'days',
    ]).days;
  });

  const uniqueDurations = Array.from(new Set(durations)).length;

  return uniqueDurations === 1;
};

export const showDateWarning = (
  reportParameters: ReportParameters,
  id: string
): boolean => {
  return (
    !datesSameDuration(reportParameters.time_groups as ReportParameter[]) &&
    id === 'date-range'
  );
};

export const getSubmitDimensionIcons = (
  success: boolean,
  showSubmitOrAdvanced: boolean
): JSX.Element => {
  if (success) {
    return showSubmitOrAdvanced ? (
      <CircleFilled data-testid="submit-circle-filled-icon" />
    ) : (
      <CheckmarkOutline data-testid="submit-checkmark-outline-icon" />
    );
  } else {
    return showSubmitOrAdvanced ? (
      <CircleFilled data-testid="submit-circle-filled-icon" />
    ) : (
      <RadioButton data-testid="submit-circle-icon" />
    );
  }
};

export const getDimensionIcons = (
  dimension: Dimension,
  reportParameters: ReportParameters,
  selectedDimension: Dimension
): JSX.Element => {
  const hasParams =
    reportParameters[dimension.dimension + '_groups'].length ||
    reportParameters[dimension.dimension + '_filters'].length ||
    (dimension.dimension !== 'time' &&
      reportParameters[dimension.dimension + '_universes'].length);

  if (hasParams) {
    return selectedDimension === dimension ? (
      <CircleFilled data-testid="circle-filled-icon" />
    ) : (
      <CheckmarkOutline data-testid="checkmark-outline-icon" />
    );
  } else {
    return selectedDimension === dimension ? (
      <CircleFilled data-testid="circle-filled-icon" />
    ) : (
      <RadioButton data-testid="circle-icon" />
    );
  }
};

export const getInitialParameters = (dimensions: Dimension[]) => {
  const initialParameters = {};

  dimensions.forEach(({ hierarchies }) => {
    hierarchies.forEach(({ key }) => {
      initialParameters[`${key}_groups`] = [];
      initialParameters[`${key}_filters`] = [];
      if (!key.match('time')) {
        initialParameters[`${key}_universes`] = [];
      }
    });
  });
  return initialParameters;
};

export const fetchReportData = async (
  id: string,
  groupId: number,
  bannerId: number,
  token: string
): Promise<ReportConfig> => {
  const res = await apiRequest<ResponsePayload<ReportConfig[]>>(
    `/reports/${bannerId}/user-groups/${groupId}/data?run_id=${id}&action=edit`,
    'GET',
    token
  );

  return res.data[0];
};

export const orderTimeParamsByItemId = (parameters: ReportParameter[]) => {
  if (parameters.length <= 2) {
    return [...parameters].sort((a, b) =>
      a.items[0].id < b.items[0].id ? -1 : 1
    );
  } else {
    return [...parameters]
      .sort((a, b) => (a.items[0].id < b.items[0].id ? -1 : 1))
      .sort((a, b) => a.items?.length - b.items?.length);
  }
};

export const orderTimeParams = (
  parameters: ReportParameter[],
  hierarchyId: string
) => {
  switch (hierarchyId) {
    case 'full-financial-week':
    case 'full-promo-week':
    case 'financial-week':
    case 'financial-quarter':
    case 'promo-week':
      return orderTimeParamsByItemId(parameters);
    case 'date-range':
      return parameters.sort((a, b) => (a.from > b.from ? 1 : -1));
    case 'day-of-week':
      return parameters.sort((a, b) =>
        a.items?.[0].id > b.items?.[0].id ? 1 : -1
      );
    default:
      return parameters;
  }
};

export const getScheduleOptionsPayload = (
  options: ScheduleReportOptions
): ScheduleOptionsPayload => {
  const {
    isRecurrence,
    startDate,
    notes,
    recurrenceType,
    recurrencePeriod,
    repeatEvery,
    recurrenceEnds,
    endDate,
    endsAfterOccurrences,
  } = options;

  const scheduleOptions = {
    starts: DateTime.fromJSDate(startDate).toISODate(),
    repeats: {
      period: isRecurrence ? recurrencePeriod : RepeatPeriod.ONE_TIME,
      type: isRecurrence ? recurrenceType : null,
      repeat_every:
        isRecurrence && recurrencePeriod === RepeatPeriod.DAILY
          ? repeatEvery
          : null,
    },
    ends: {
      never: isRecurrence && recurrenceEnds === EndsRecurrenceType.NEVER,
      occurrences:
        isRecurrence && recurrenceEnds === EndsRecurrenceType.OCCURRENCES
          ? endsAfterOccurrences
          : null,
      date:
        isRecurrence && recurrenceEnds === EndsRecurrenceType.CUSTOM_DATE
          ? DateTime.fromJSDate(endDate).toISODate()
          : null,
    },
    notes: notes ?? '',
  };
  return scheduleOptions;
};

export const getScheduleReportOptions = (
  schedulePayload?: ScheduleOptionsPayload
): ScheduleReportOptions => {
  if (!schedulePayload) {
    return null;
  }
  const recurrenceEnds = () => {
    if (schedulePayload.ends?.occurrences) {
      return 'occurrences';
    } else if (schedulePayload?.ends.date) {
      return 'custom-date';
    }
    return 'never';
  };

  const options: ScheduleReportOptions = {
    isRecurrence: schedulePayload.repeats.period !== RepeatPeriod.ONE_TIME,
    startDate: new Date(schedulePayload.starts),
    notes: schedulePayload.notes,
    recurrencePeriod:
      schedulePayload.repeats.period === RepeatPeriod.ONE_TIME
        ? RepeatPeriod.DAILY
        : schedulePayload.repeats.period,
    repeatEvery: schedulePayload.repeats.repeat_every || 1,
    recurrenceEnds: recurrenceEnds(),
    endsAfterOccurrences: schedulePayload.ends.occurrences || 1,
    endDate: schedulePayload.ends.date
      ? new Date(schedulePayload.ends.date)
      : null,
    recurrenceType: schedulePayload.repeats.type || 'Slide',
  };

  return options;
};

export const generateScheduleSummary = (
  options: ScheduleReportOptions
): ReactElement => {
  const {
    isRecurrence,
    startDate,
    recurrenceType,
    recurrencePeriod,
    repeatEvery,
    recurrenceEnds,
    endDate,
    endsAfterOccurrences,
  } = options;

  const start = (
    <div>
      Scheduled report is set to be built on{' '}
      <strong>{DateTime.fromJSDate(startDate).toFormat('d LLLL yyyy')}</strong>.
    </div>
  );

  const type = () => {
    switch (recurrenceType) {
      case 'Slide': {
        return (
          <div>
            This report will have a <strong>Sliding Pattern</strong>.
          </div>
        );
      }
      case 'Extend': {
        return (
          <div>
            This report will have an <strong>Extend Pattern</strong>.
          </div>
        );
      }
      default:
        return '';
    }
  };

  const repeats = () => {
    switch (recurrencePeriod) {
      case 'Daily': {
        return (
          <div>
            It will repeat every <strong>{repeatEvery} days</strong>.
          </div>
        );
      }
      case 'Weekly': {
        return (
          <div>
            It will repeat every <strong>week</strong>.
          </div>
        );
      }
      case 'Monthly': {
        return (
          <div>
            It will repeat every <strong>month</strong>.
          </div>
        );
      }
      default:
        return '';
    }
  };

  const ends = () => {
    switch (recurrenceEnds) {
      case 'never': {
        return (
          <div>
            It will end <strong>never</strong>.
          </div>
        );
      }
      case 'occurrences': {
        return (
          <div>
            It will end after <strong>{endsAfterOccurrences} occurences</strong>
            .
          </div>
        );
      }
      case 'custom-date': {
        return endDate ? (
          <div>
            It will end after{' '}
            <strong>
              {DateTime.fromJSDate(endDate).toFormat('d LLLL yyyy')}
            </strong>
            .
          </div>
        ) : (
          ''
        );
      }
      default:
        return '';
    }
  };

  return isRecurrence ? (
    <>
      {start} {type()} {repeats()} {ends()}
    </>
  ) : (
    start
  );
};

export const hasDecimals = (value: number): boolean => value % 1 !== 0;

export const validateSchedulingOptions = (
  options: ScheduleReportOptions
): boolean => {
  const {
    isRecurrence,
    startDate,
    recurrenceType,
    recurrencePeriod,
    repeatEvery,
    recurrenceEnds,
    endDate,
    endsAfterOccurrences,
  } = options;

  if (!isRecurrence) {
    return !!startDate;
  } else {
    const repeats = () => {
      if (recurrencePeriod === RepeatPeriod.DAILY) {
        return (
          typeof repeatEvery === 'number' &&
          repeatEvery >= 1 &&
          !hasDecimals(repeatEvery)
        );
      } else {
        return !!recurrencePeriod;
      }
    };
    const ends = () => {
      switch (recurrenceEnds) {
        case EndsRecurrenceType.NEVER:
          return true;
        case EndsRecurrenceType.OCCURRENCES:
          return (
            typeof endsAfterOccurrences === 'number' &&
            endsAfterOccurrences >= 1 &&
            !hasDecimals(endsAfterOccurrences)
          );
        case EndsRecurrenceType.CUSTOM_DATE:
          return !!endDate && startDate < endDate;
      }
    };
    return !!startDate && !!recurrenceType && !!repeats() && !!ends();
  }
};

export const getURLRoutes = (
  reportModules: ReportSection[],
  reportNames: string[]
): string[] => {
  return reportModules.flatMap((module) => {
    return module.reports
      .filter((r) => reportNames.includes(r.name))
      .map((r) => r.url_route);
  });
};

/** @deprecated Use a backend solution for getting the runId */
export const getRunIdFromTaskId = (taskId: string): string =>
  taskId.substring(0, RUN_ID_LENGTH);

export const renderAdvOptionsValue = (
  label: string,
  value: string | number | string[],
  labelFormat?: NumberPoint
): ReactElement => {
  if (labelFormat) {
    return (
      <>
        {formatNumber({
          value: value as number,
          ...labelFormat,
        })}
      </>
    );
  } else if (label === 'Linked Report') {
    const runId = getRunIdFromTaskId(value as string);

    return <Link href={`/workspace/view-report/${runId}`}>Linked to</Link>;
  } else {
    return <>{Array.isArray(value) ? value.join(', ') : value}</>;
  }
};

export const getSelectionsFromAdvOptions = (
  options: InitialAdvancedSettings[]
): AnalyticEngineParams | null => {
  return options.reduce(
    (acc, setting) => ({
      ...acc,
      [setting.name]:
        setting.type === 'checkbox' ? [setting.default] : setting.default,
    }),
    {}
  );
};

export const getInvalidParams = (
  params: AnalyticEngineParams,
  advancedSettings: AdvancedSettings[]
): string[] => {
  return Object.keys(params).filter((key) => {
    const value = params[key];

    const optionDisabled = advancedSettings
      .find((setting) => setting.name === key)
      ?.options?.find((opt) => opt.option === value)?.disabled;

    return value === undefined || optionDisabled;
  });
};

export const getUpdatedOptions = (
  report: ReportModule,
  reportParameters: ReportParameters
): AdvancedSettings[] | Setting[] => {
  return report.advanced_options.map((setting, settingIndex) => {
    if (setting.type === 'radio' || setting.type === 'checkbox') {
      return {
        ...setting,
        options: setting.options.map((option, index) => {
          return {
            id: `${settingIndex}-${index}`,
            option: option,
            disabled:
              setting.dimension_linked && reportParameters[`${option}_groups`]
                ? reportParameters[`${option}_groups`].length === 0
                : false,
          };
        }),
      };
    } else {
      return setting;
    }
  });
};

export const getValidParams = (
  invalidParams: string[],
  advancedSettings: AdvancedSettings[],
  reportParameters: ReportParameters
): { [key: string]: string } => {
  const params = {};
  invalidParams.forEach((key) => {
    const settingOptions = advancedSettings.find(
      (setting) => setting.name === key
    ).options;
    if (settingOptions) {
      params[key] = settingOptions.find(
        (opt) => reportParameters[`${[opt.option]}_groups`]?.length
      )?.option;
    } else {
      params[key] = 0;
    }
  });
  return params;
};

export const getReportName = (
  reportModules: ReportSection[],
  section: string,
  moduleId: string
): string => {
  const report = reportModules
    ?.find((reportModule) => reportModule.section === section)
    ?.reports.find((report) => report.url_route === moduleId);

  return report ? report.name : '';
};

export const getTrimmedValue = (num: number): string => {
  const numStr = num.toString();
  return numStr.includes('.')
    ? numStr.replace(/(\.\d*?[1-9])0*$/, (_, group) => group)
    : numStr.replace(/^0+/, '') || '0';
};

export const getExtendedName = (
  parameter: ReportParameter,
  dimension: string
) => {
  const { hierarchy_name, exclude } = parameter;
  const additionalName = exclude ? 'Excluded' : 'Included';
  return `${hierarchy_name}${
    dimension === 'filters' ? ` ${additionalName}` : ''
  }`;
};

export const renderCustomGroupNotification = (
  containsHiddenCustomGroups: boolean
): JSX.Element | null => {
  return containsHiddenCustomGroups ? (
    <InlineNotification
      subtitle="One or more custom groups used in this report have been deleted or made private."
      kind="warning"
      hideCloseButton
      lowContrast
      data-testid="custom-group-edit-notification"
    />
  ) : null;
};

export const getCustomAttributeFlag = (parameters: {
  [key: string]: ReportParameter[];
}): {
  custom_attribute: boolean;
} | null => {
  const isCustomAttributeSelected = [
    parameters.product_groups,
    parameters.product_filters,
    parameters.product_universes,
  ]
    .filter(Boolean)
    .flat()
    .some(({ hierarchy_name }) => hierarchy_name === 'Custom Attribute');
  return isCustomAttributeSelected ? { custom_attribute: true } : null;
};
