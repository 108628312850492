export const HIGHCHARTS_PALETTE = [
  // Tecsa chart colors
  '#00A5ED',
  '#9C05A7',
  '#FFB854',
  '#1D92C1',
  '#5E61FF',
  '#00B7BB',
  '#5941D4',
  '#D704DB',
  // default highcharts 3.x
  '#2f7ed8',
  '#0d233a',
  '#8bbc21',
  '#910000',
  '#1aadce',
  '#492970',
  '#f28f43',
  '#77a1e5',
  '#c42525',
  '#a6c96a',
  '#4572A7',
  // default highcarts 2.x
  '#AA4643',
  '#89A54E',
];

export const CONTINUOUS_SCALE = [
  '#030B18',
  '#071E3F',
  '#0C3166',
  '#10448D',
  '#1556B4',
  '#1969DB',
  '#317FED',
  '#5797F0',
  '#7DAEF4',
  '#A3C6F7',
];

export const INDEX_SCALE: {
  min: number;
  max: number;
  colour: string;
  label: string;
  showLabel?: boolean;
}[] = [
  { min: 150, max: Infinity, colour: '#25401c', label: 'Index > 150' },
  { min: 145, max: 150, colour: '#385f2a', label: '145-150', showLabel: false },
  { min: 140, max: 145, colour: '#4a7f38', label: '140-145', showLabel: false },
  { min: 135, max: 140, colour: '#5b9c45', label: '135-140', showLabel: false },
  { min: 130, max: 135, colour: '#66b04e', label: '130-135', showLabel: false },
  { min: 125, max: 130, colour: '#8bc479', label: '125-130', showLabel: false },
  { min: 120, max: 125, colour: '#aad39c', label: '120-125', showLabel: false },
  { min: 115, max: 120, colour: '#c5e1bc', label: '115-120', showLabel: false },
  { min: 110, max: 115, colour: '#deeed8', label: '110-115', showLabel: false },
  { min: 105, max: 110, colour: '#f0f7ed', label: '105-110', showLabel: false },
  {
    min: 100.5,
    max: 105,
    colour: '#fcfdfb',
    label: '101-105',
    showLabel: false,
  },
  { min: 99.5, max: 100.5, colour: '#ffffff', label: '100' },
  { min: 95, max: 99.5, colour: '#fefbfb', label: '95-99', showLabel: false },
  { min: 90, max: 95, colour: '#fbefef', label: '90-95', showLabel: false },
  { min: 85, max: 90, colour: '#f5dbdb', label: '85-90', showLabel: false },
  { min: 80, max: 85, colour: '#eebfbf', label: '80-85', showLabel: false },
  { min: 75, max: 80, colour: '#e59e9e', label: '75-80', showLabel: false },
  { min: 70, max: 75, colour: '#db7676', label: '70-75', showLabel: false },
  { min: 65, max: 70, colour: '#c03333', label: '65-70', showLabel: false },
  { min: 60, max: 65, colour: '#a52c2c', label: '60-65', showLabel: false },
  { min: 55, max: 60, colour: '#8d2525', label: '55-60', showLabel: false },
  { min: 50, max: 55, colour: '#5d1919', label: '50-55', showLabel: false },
  { min: 0, max: 50, colour: '#491313', label: 'Index < 50' },
];

export const DISCRETE_SCALE = [
  '#F0F8FF',
  '#FAEBD7',
  '#00FFFF',
  '#7FFFD4',
  '#F5F5DC',
  '#FFE4C4',
  '#0000FF',
  '#8A2BE2',
  '#A52A2A',
  '#DEB887',
  '#5F9EA0',
  '#FF7F50',
  '#6495ED',
  '#DC143C',
  '#00008B',
  '#008B8B',
  '#A9A9A9',
  '#006400',
  '#BDB76B',
  '#8B008B',
  '#556B2F',
  '#FF8C00',
  '#9932CC',
  '#8B0000',
  '#E9967A',
  '#8FBC8F',
  '#483D8B',
  '#2F4F4F',
  '#FF69B4',
  '#CD5C5C',
  '#E6E6FA',
  '#FFF0F5',
  '#E0FFFF',
  '#FFFACD',
  '#ADD8E6',
  '#F08080',
  '#FFB6C1',
  '#FFA07A',
  '#20B2AA',
  '#87CEFA',
  '#778899',
  '#B0C4DE',
  '#7B68EE',
  '#48D1CC',
  '#C71585',
  '#191970',
  '#FFE4E1',
  '#FFE4B5',
  '#98FB98',
  '#B0E0E6',
  '#DDA0DD',
  '#BC8F8F',
  '#4169E1',
  '#FA8072',
  '#2E8B57',
  '#A0522D',
  '#C0C0C0',
  '#008080',
  '#D8BFD8',
  '#F5F5F5',
  '#9ACD32',
  '#FFFF00',
  '#FF6347',
  '#EE82EE',
  '#40E0D0',
  '#DAA520',
  '#FFD700',
  '#808080',
  '#FF00FF',
  '#FF1493',
  '#9400D3',
  '#808000',
];
