import { useContext, useEffect } from 'react';
import { Workspace as WorkspaceIcon } from '@carbon/icons-react';
import { PosthogContext } from '../providers/PosthogProvider';
import Maintenance from './Maintenance/Maintenance';
import { useLocation, useNavigate } from 'react-router-dom';
import ReportFilterProvider from '../providers/ReportFilterProvider';
import TabsContainer from './TabsContainer';
import IconTitle from './IconTitle';
import { workspaceTabs } from '../constants/metadata';

export enum REPORT_STATUS {
  PROCESSING = 'Processing',
  COMPLETED = 'Completed',
  FAILED = 'Failed',
  NO_DATA = 'Completed with no data',
  CANCELLED = 'Cancelled',
  SCHEDULED = 'Scheduled',
  CANCELLED_SCHEDULE = 'Cancelled schedule',
}

const Workspace = () => {
  const { featureFlags } = useContext(PosthogContext);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname === '/workspace' || pathname === '/workspace/') {
      navigate('/workspace/my-reports', { replace: true });
    }
  }, [pathname]);

  const workspaceFlag = featureFlags.find(
    (flag) => flag.name === 'workspace-maintenance'
  );

  if (workspaceFlag) {
    const { title, text, duration } = workspaceFlag.payload as {
      title: string;
      text: string;
      duration?: {
        endTime: string;
      };
      banner_ids?: number[];
    };
    return (
      <Maintenance
        header={title}
        subheader={text}
        endTime={duration?.endTime}
      />
    );
  } else {
    const activeTab = workspaceTabs.findIndex(
      ({ path }) => path === pathname.split('/')[2]
    );
    return (
      <>
        <IconTitle
          icon={<WorkspaceIcon size={20} />}
          title="My Workspace"
          kind="primary"
        />

        <div className="workspace-container">
          <ReportFilterProvider>
            <TabsContainer activeTab={activeTab} tabs={workspaceTabs} />
          </ReportFilterProvider>
        </div>
      </>
    );
  }
};

export default Workspace;
